import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// customized library
import Input from '../../furnitures/Input';

const useStyles = makeStyles((theme) => ({
  input: {
    position: 'relative',
    lineHeight: '20px',
  },
  counter: (props) => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    '&:after': {
      backgroundColor: 'transparent',
      color: theme.palette?.black?.['B60'],
      content: `"${props.value?.length} of ${props.maxLength || ''} characters"`,
      position: 'absolute',
      right: '16px',
      bottom: '12px',
      borderRadius: '4px',
      padding: theme.spacing(0.5),
    },
  }),
}));

const TextArea = forwardRef(({ minRows, maxRows, ...restProps }, ref) => {
  const { maxLength, showWordCount } = restProps;
  const classes = useStyles(restProps);
  return (
    <Input
      innerRef={ref}
      multiline
      minRows={minRows}
      maxRows={maxRows}
      InputProps={{
        className: classes.input,
        ...(showWordCount ? { endAdornment: <div className={classes.counter} /> } : {}),
        'data-cy': restProps?.dataCy,
      }}
      inputProps={{
        ...(maxLength ? { maxLength } : {}),
      }}
      {...restProps}
    />
  );
});

TextArea.defaultProps = {
  minRows: 20,
  maxRows: 4,
  showWordCount: false,
};

TextArea.propTypes = {
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  showWordCount: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default TextArea;
