import { call, put, takeLatest } from 'redux-saga/effects';

import { directDownloadDocument } from '../components/DocumentsTable/directDownloadDocument';
import serviceLocator from '../serviceLocator';
import actionTypes from './actionTypes';

export function* DocumentPortalSagas() {
  yield takeLatest(actionTypes.getDocuments().start(), getDocuments);
  yield takeLatest(actionTypes.downloadDocument().start(), downloadDocuments);
}

function* getDocuments({ data: { page = 1, page_size = 10, order_number, ...restData } }) {
  const documentsService = serviceLocator.documentsService;
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'getDocuments',
    });
    const { data, pagination } = yield call(documentsService.getDocuments, {
      page,
      page_size,
      order_number,
      ...restData,
    });
    yield put({
      type: actionTypes.getDocuments().success(),
      data,
      pagination,
    });
  } catch (error) {
    yield put({
      type: actionTypes.setError(),
      errorMessage: error.message,
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'getDocuments',
    });
  }
}

function* downloadDocuments({ data: document }) {
  yield directDownloadDocument(document);
}
