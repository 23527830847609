import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { BaseService } from '@yojee/api/baseService';

export class S3Api extends BaseService {
  upload = (url, fileContent) => {
    return APIAxiosInstance.put(url, fileContent, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };
}

const s3API = new S3Api();
export default s3API;
