import * as _ from 'lodash-es';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import RRule from 'rrule';

import { getLocation } from '@yojee/helpers/LocationHelper';
import { getSingleDayTimeSchedule } from '@yojee/helpers/worker-schedule-helper';
import {
  getScheduleIsOverlappingWithNewSchedule,
  transFormedScheduleForOptimisation,
} from '@yojee/helpers/workerShedule/scheduleHelper';

const moment = extendMoment(Moment);

const getTargetSchedule = (date) => {
  const endTime = new Date(date.getTime());
  const startTime = new Date(date.getTime());

  startTime.setHours(0, 0);
  endTime.setHours(23, 59);

  return {
    type: 'work_on',
    start_event_datetime: startTime,
    end_event_datetime: endTime,
    end_repeat_datetime: date,
    recurrence_rule: new RRule({
      freq: RRule.WEEKLY,
      dtstart: date,
      until: date,
    }).toString(),
    priority: 0,
    name: 'name schedule',
  };
};

const getDateWithHours = (date, hours) => {
  const result = new Date(date.getTime());
  result.setHours.apply(result, hours);

  return result;
};

const getLatestTaskExecutionDate = (tasks) => {
  const finalTask = tasks.reduce((resultTask, task) => {
    if (new Date(task.to).getTime() > new Date(resultTask.to).getTime()) {
      return task;
    }
    return resultTask;
  }, tasks[0]);

  const latestTaskDate = moment.utc(finalTask.to).toDate();
  const endOfToday = getDateWithHours(new Date(), [23, 59]);

  return endOfToday.getTime() > latestTaskDate.getTime() ? endOfToday : latestTaskDate;
};

/**
 * Gets the real vehicles.
 *
 * @param workers
 * @param assetTypes
 * @param settings
 * @param tasks
 * @param epoch
 * @param hubs
 * @returns {*}
 */
export const mapWorkers = (workers, assetTypes, settings, tasks, epoch, hubs, startDate) => {
  let hubLocation =
    Array.isArray(hubs) && hubs.length > 0 ? hubs[0].location : { lat: settings.hubLat, lng: settings.hubLng };

  const editableFields = [
    'startLocationType',
    'startLocations',
    'startLocation',
    'startHub',
    'endLocationType',
    'endLocations',
    'endLocation',
    'endHub',
    'timeSchedule',
    'vehicleType',
  ];

  return workers.map((w) => {
    let vehicleTypeId = w['assigned_vehicle']
      ? assetTypes.findIndex((at) => `assigned_vehicle_${w['assigned_vehicle']['id']}` === at.id)
      : assetTypes.findIndex((at) => w['current_vehicle_type_id'] === at.vehicleTypeId);

    // TODO: this is hacky, we should assign the right vehicle profile when asset types are pulled
    if (vehicleTypeId === -1) {
      vehicleTypeId = assetTypes.findIndex((at) => w.profile === at.profile);
    }
    vehicleTypeId = vehicleTypeId > -1 ? vehicleTypeId : 0;

    // Add locks to completed tasks
    const completedTasks = tasks
      .filter((t) => !!t.completion_time)
      .sort((a, b) => {
        const result = moment(a.completion_time || epoch) - moment(b.completion_time || epoch);

        return parseInt(result, 10);
      });
    const lastTask = completedTasks[completedTasks.length - 1];

    // Get the vehicle initial start load if there are the completed tasks
    const startLoads = null; // TODO

    let startLocation;
    if (settings.startLocation === 'last_stop_location' && lastTask) {
      startLocation = getLocation(lastTask.location);
    } else if (settings.startLocation === 'current_location') {
      startLocation = w.location ? getLocation(w.location) : getLocation(tasks[0]);
    } else if (settings.startLocation === 'driver_start_location') {
      if (w['start_location']) {
        startLocation = getLocation(w['start_location']);
      } else if (w['assigned_vehicle'] && w['assigned_vehicle']['start_location']) {
        startLocation = getLocation(w['assigned_vehicle']['start_location']);
      } else {
        startLocation = w.location ? getLocation(w.location) : getLocation(tasks[0]);
      }
    } else if (settings.startLocation === 'hub') {
      if (settings.startHub) {
        if (hubs && hubs.length > 0) {
          const matchHubs = hubs.filter((h) => h.id === settings.startHub);
          if (matchHubs) {
            hubLocation = matchHubs[0].location;
          }
        }
      }
      startLocation = getLocation(hubLocation);
    } else {
      startLocation = getLocation(hubLocation);
    }

    // Vehicle start location
    const startLocations = {
      last_stop_location: lastTask && lastTask.location ? getLocation(lastTask.location) : null,
      current_location: w.location ? getLocation(w.location) : null,
      driver_start_location: null,
      default: w.location ? getLocation(w.location) : getLocation(tasks[0].location),
    };

    const endLocations = {
      current_location: w.location ? getLocation(w.location) : null,
      driver_start_location: null,
      driver_end_location: null,
      default: null,
    };

    if (w['start_location']) {
      startLocations['driver_start_location'] = getLocation(w['start_location']);
      endLocations['driver_start_location'] = getLocation(w['start_location']);
    } else if (w['assigned_vehicle'] && w['assigned_vehicle']['start_location']) {
      startLocations['driver_start_location'] = getLocation(w['assigned_vehicle']['start_location']);
      endLocations['driver_start_location'] = getLocation(w['assigned_vehicle']['start_location']);
    }

    if (w['end_location']) {
      endLocations['driver_end_location'] = getLocation(w['end_location']);
    } else if (w['assigned_vehicle'] && w['assigned_vehicle']['end_location']) {
      endLocations['driver_end_location'] = getLocation(w['assigned_vehicle']['end_location']);
    }

    const metadata = w.metadata || {};

    let repeatSchedules = [];

    const latestTaskExecutionDate = getLatestTaskExecutionDate(tasks);

    if (w.schedules?.length > 0) {
      const driverSchedules = w.schedules.filter((schedule) => schedule.type === 'worker_on');
      const targetSchedule = getTargetSchedule(latestTaskExecutionDate);
      const matchedSchedule = getScheduleIsOverlappingWithNewSchedule(targetSchedule, driverSchedules);

      if (matchedSchedule.length > 0) {
        repeatSchedules = transformWorkersSchedule([matchedSchedule[0]]);

        repeatSchedules[0] = {
          ...repeatSchedules[0],
          start: moment(getDateWithHours(new Date(), [0, 0, 0, 0])),
          until: moment(latestTaskExecutionDate),
        };
      } else {
        const beginOfTheDate = moment(new Date().setHours(0, 0, 0, 0));

        const noWorkingSchedule = {
          from: beginOfTheDate.format('HH:mm:ss'),
          to: beginOfTheDate.format('HH:mm:ss'),
          repeat: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          start: beginOfTheDate,
          until: beginOfTheDate,
          isDriverUnavailable: true,
        };

        repeatSchedules = [noWorkingSchedule];
      }
    } else {
      const fullWeekSchedule = {
        from: '00:00:00',
        to: '23:59:00',
        repeat: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        start: moment(getDateWithHours(new Date(), [0, 0, 0, 0])),
        until: moment(latestTaskExecutionDate),
      };

      repeatSchedules = [fullWeekSchedule];
    }

    const timeSchedules = settings.useWorkerSchedule
      ? getSingleDayTimeSchedule(w, startDate)
      : [
          {
            start: moment().subtract(1, 'year').toISOString(),
            end: moment().add(1, 'year').toISOString(),
          },
        ];

    const timeSchedule = timeSchedules.reduce(
      (acc, timeWindow) => {
        if (moment(timeWindow.start).isBefore(acc.start)) {
          acc.start = moment(timeWindow.start);
        }

        if (moment(timeWindow.end).isAfter(acc.end)) {
          acc.end = moment(timeWindow.end);
        }

        return acc;
      },
      { start: moment(), end: moment() }
    );

    const worker = {
      id: `${w.id}`,
      vehicleTypeId: assetTypes?.[vehicleTypeId]?.id,
      vehicleType: { ..._.cloneDeep(assetTypes?.[vehicleTypeId]), changed: false },
      status: 'available',
      startLocationType: 'current_location',
      startLocations,
      startLocation,
      endLocations,
      timeSchedule,
      timeSchedules,
      repeatSchedules,
      timeLimit: assetTypes?.[vehicleTypeId]?.timeLimit,
      startLoads: startLoads,
      assignedTasksOnly: !!metadata.assignedTasksOnly,
      returnToDepot: false,
      worker: w,
    };

    worker.initial = editableFields.reduce((acc, field) => {
      acc[field] = _.cloneDeep(worker[field]);
      return acc;
    }, {});

    return worker;
  });
};

export const transformWorkersSchedule = (workersSchedule) => {
  const transformedData = workersSchedule.map((schedule) => transFormedScheduleForOptimisation(schedule));
  return transformedData;
};
