export const PARTNER_SIGN_UP_PROCESS_STATUS = {
  NORMAL: 'NORMAL',
  VALIDATING_INVITATION_CODE: 'VALIDATING_INVITATION_CODE',
  VALIDATE_INVITATION_CODE_ERROR: 'VALIDATE_INVITATION_CODE_ERROR',

  SIGNUP_PARTNER_COMPANY: 'SIGNUP_PARTNER_COMPANY',
  PARTNER_COMPANY_SIGN_UP_SUCCESS: 'PARTNER_COMPANY_SIGN_UP_SUCCESS',
  PARTNER_COMPANY_SIGN_UP_ERROR: 'PARTNER_COMPANY_SIGN_UP_ERROR',

  PARTNER_COMPANY_SIGN_UP_START_LOGIN: 'PARTNER_COMPANY_SIGN_UP_START_LOGIN',
  PARTNER_COMPANY_LOGIN_SUCCESS: 'PARTNER_COMPANY_LOGIN_SUCCESS',
  PARTNER_COMPANY_LOGIN_ERROR: 'PARTNER_COMPANY_LOGIN_ERROR',
};

const defaultState = {
  status: PARTNER_SIGN_UP_PROCESS_STATUS.NORMAL, // normal, processing, error, success
  error: undefined,
  invitationInfo: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  VALIDATE_INVITATION_CODE: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.VALIDATING_INVITATION_CODE };
  },
  VALIDATE_INVITATION_CODE_SUCCESS: (state, { invitationInfo }) => {
    return { ...state, invitationInfo, status: PARTNER_SIGN_UP_PROCESS_STATUS.NORMAL };
  },
  VALIDATE_INVITATION_CODE_ERROR: (state, { error }) => {
    return { ...state, error, status: PARTNER_SIGN_UP_PROCESS_STATUS.VALIDATE_INVITATION_CODE_ERROR };
  },
  PARTNER_COMPANY_SIGN_UP: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.SIGNUP_PARTNER_COMPANY };
  },
  PARTNER_COMPANY_SIGN_UP_SUCCESS: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.PARTNER_COMPANY_SIGN_UP_SUCCESS };
  },
  PARTNER_COMPANY_SIGN_UP_ERROR: (state, error) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.PARTNER_COMPANY_SIGN_UP_ERROR, error };
  },
  PARTNER_COMPANY_SIGN_UP_START_LOGIN: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.PARTNER_COMPANY_SIGN_UP_START_LOGIN };
  },
  PARTNER_COMPANY_LOGIN_SUCCESS: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.PARTNER_COMPANY_LOGIN_SUCCESS };
  },
  PARTNER_COMPANY_LOGIN_ERROR: (state, { error }) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.PARTNER_COMPANY_LOGIN_ERROR, error };
  },
  CLEAR_MESSAGE: (state) => {
    return { ...state, status: PARTNER_SIGN_UP_PROCESS_STATUS.NORMAL, error: undefined };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
