import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const styles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  color: ({ color }) => ({
    color: theme.palette[color || 'primary'].main,
    '&:hover': {
      color: theme.palette[color || 'primary'].dark,
    },
  }),
  disabled: {
    color: 'rgba(0, 0, 0, 0.26) !important',
    pointerEvents: 'none',
  },
}));

const LinkTable = ({ onClick, children, disabled = false, className = '', tooltip = '', ...other }) => {
  const classes = styles(other);

  return (
    <Tooltip title={tooltip}>
      <span>
        <span
          className={clsx(classes.root, classes.color, className, { [classes.disabled]: disabled })}
          onClick={disabled ? undefined : onClick}
          {...other}
        >
          {children}
        </span>
      </span>
    </Tooltip>
  );
};

export default LinkTable;
