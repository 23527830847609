import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import LoadingOverlay from '@yojee/ui/common/LoadingOverlay/LoadingOverlay';
import { UIAuthActions } from '@yojee/ui/onboarding/sagas/auth/actions';

export const SwitchCompany = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get('access_token') && params.get('refresh_token')) {
      dispatch(
        UIAuthActions.saveJwtToken({
          access_token: params.get('access_token'),
          refresh_token: params.get('refresh_token'),
        })
      );
    }
  }, [params]);

  return <LoadingOverlay active={true} text="Authenticating..." />;
};
