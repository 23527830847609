import { normalizeResponse } from '@yojee/helpers/normalized-utils';

const defaultState = {
  status: 'normal',
  message: undefined,
  data: {},
  ids: [],
  pagination: null,
  searchText: undefined,
  sortAndFilter: {
    showSort: false,
    sort: 'name_asc',
  },
  dashboardUrl: '',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state) => {
    return { ...state, status: 'processing' };
  },
  DISPLAY_ERROR_MESSAGE: (state, { message }) => {
    return { ...state, status: 'error', message };
  },
  GET_PARTNER_DASHBOARD_URL_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      dashboardUrl: data.url,
      status: 'normal',
    };
  },
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SHOW_PARTNER_SORT_FILTER: (state) => {
    return { ...state, sortAndFilter: { ...state.sortAndFilter, showSort: true } };
  },
  CLOSE_PARTNER_SORT_FILTER: (state) => {
    return { ...state, sortAndFilter: { ...state.sortAndFilter, showSort: false } };
  },
  FETCH_DOWNSTREAM_PARTNER: (state) => {
    return { ...state, status: 'processing' };
  },
  FETCH_DOWNSTREAM_PARTNER_SUCCESS: (state, { partners }) => {
    const { data, ids } = normalizeResponse(partners);
    ids.sort((l, r) => {
      const boolValue =
        state.sortAndFilter.sort === 'name_desc'
          ? data[l].company_name < data[r].company_name
          : data[l].company_name > data[r].company_name;
      return boolValue ? 1 : -1;
    });
    return { ...state, data, ids, pagination: partners.pagination, status: 'normal' };
  },
  UPDATE_DOWNSTREAM_PARTNER_SEARCH_TEXT: (state, { searchText }) => {
    return { ...state, searchText };
  },
  UPDATE_SORT_PARTNER_CRITERIA: (state, { sortCriteria }) => {
    const { data, ids } = state;
    ids.sort((l, r) => {
      const boolValue =
        sortCriteria === 'name_desc'
          ? data[l].company_name < data[r].company_name
          : data[l].company_name > data[r].company_name;
      return boolValue ? 1 : -1;
    });
    return { ...state, ids: ids, sortAndFilter: { ...state.sortAndFilter, sort: sortCriteria } };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
