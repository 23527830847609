const defaultState = {
  show: false,
  price: undefined,
  team: undefined,
  timer: undefined,
  taskGroupIds: [],
  status: 'normal',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SHOW_BROADCAST_DIALOG: (state, { taskGroupIds }) => {
    return { ...state, show: true, taskGroupIds };
  },
  CLOSE_BROADCAST_DIALOG: () => {
    return defaultState;
  },
  APPLY_BROADCAST: (state, { price, timer, team }) => {
    return { ...state, price, timer, team, status: 'processing' };
  },
  APPLY_BROADCAST_SUCCESS: (state) => {
    return { ...state, show: false, price: undefined, team: undefined, timmer: undefined, status: 'success' };
  },
  APPLY_BROADCAST_ERROR: (state) => {
    return { ...state, show: false, status: 'error' };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
