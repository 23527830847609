import { BaseService } from '@yojee/api/baseService';

import { authGeodisService } from '../services/authGeodisService';

export class GeodisApi extends BaseService {
  constructor({ authGeodisService }) {
    super();
    this.authService = authGeodisService;
  }

  upload = ({ bucket, name, region }, type, slug) => {
    const key = name.charAt(0) === '/' ? name.substr(1) : name;
    return this.authService
      .post(this.getGeodisApiUrl('default'), { bucket, key, region, csv_type: type }, { slug })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };
}

const geodisApi = new GeodisApi({ authGeodisService });
export default geodisApi;
