import { saveAs } from 'file-saver';
import { createPDF, mergePDF, pdfArrayToBlob } from 'pdf-actions';
import { call, put, takeLatest } from 'redux-saga/effects';

import itemTypesApi from '../../Api/invoicesApi';

function* fetchFormaList({ payload }) {
  try {
    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataListStatus', data: 'running' } });
    const { data, pagination } = yield call(itemTypesApi.listProFormaInvoices, { ...payload });

    yield put({ type: 'FETCH_FORMA_LIST_SUCCESS', payload: { data, pagination } });
    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataListStatus', data: 'success' } });
  } catch (error) {
    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataListStatus', data: 'failed' } });
    yield put({ type: 'FETCH_FORMA_LIST_FAILED', payload: { data: error.message } });
  }
}

const mergePDFHandler = async (files) => {
  // Converting File Object Array To PDF Document Array
  const createdPDF = await Promise.all(files.map((file) => createPDF.PDFDocumentFromFile(file)));
  // Merging The PDF Files to A PDFDocument
  const mergedPDFDocument = await mergePDF(createdPDF);
  // Converting The Merged Document to Unit8Array
  const mergedPdfFile = await mergedPDFDocument.save();
  // Saving The File To Disk
  const pdfBlob = pdfArrayToBlob(mergedPdfFile);

  return pdfBlob;
};

function* downloadFormaInvoice({ payload }) {
  try {
    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataDownloadStatus', data: 'running' } });
    yield put({ type: 'DOWNLOAD_INVOICE_STARTED' });

    const { data } = payload;

    // handle case single download
    if (data.length === 1) {
      const res = yield call(itemTypesApi.downloadProFormaInvoices, data[0]);

      const url = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      saveAs(url, `proforma-invoice-${data[0]}.pdf`);

      // handle merging multiple invoices
    } else if (data.length > 1) {
      const pdfBlobs = [];
      let index = 0;

      for (const item of data) {
        const responseData = yield call(itemTypesApi.downloadProFormaInvoices, item);
        pdfBlobs.push(new File([responseData.data], `pdf_file_${index}`, { type: 'application/pdf' }));
        index++;
      }

      const mergedBlobs = yield call(mergePDFHandler, pdfBlobs);
      const url = URL.createObjectURL(mergedBlobs);
      saveAs(url, `proforma-invoice-file.pdf`);
    }

    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataDownloadStatus', data: 'success' } });
  } catch (error) {
    yield put({ type: 'SET_STATUS', payload: { status: 'formaDataDownloadStatus', data: 'failed' } });
    yield put({ type: 'DOWNLOAD_INVOICE_FAILED', payload: { data: error.message } });
  }
}

export default function* sagas() {
  yield takeLatest('FETCH_FORMA_LIST', fetchFormaList);
  yield takeLatest('DOWNLOAD_INVOICE', downloadFormaInvoice);
}
