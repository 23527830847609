import { geocodeByAddress } from 'react-places-autocomplete';
import { call, put, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';
import { getCountryCodeByName } from '@yojee/helpers/countryAndTimezoneHelper';
import CountryBounds from '@yojee/helpers/CountryBounds';

export function* AddressAutoCompleteSagas() {
  yield takeLatest('ADDRESS_AUTO_COMPLETE_SEARCH', searchAddressBook);
}

const googleAutocompleteService = window.google ? new window.google.maps.places.AutocompleteService() : null;

function* searchAddressBook({ searchKey, country = '' }) {
  try {
    if (!searchKey) {
      yield put({ type: 'ADDRESS_AUTO_COMPLETE_RESET' });
      return;
    }
    yield put({ type: 'ADDRESS_AUTO_COMPLETE_SEARCHING', searchKey: searchKey });
    const addressBookData = yield call(companyService.getCompanyAddressBookEntries, { query: searchKey });
    const transformedAddressBookData =
      addressBookData && addressBookData.length > 0 ? addressBookData.map(transformAddressToOption) : [];
    const googleResult = yield call(getGooglePredictions, { searchKey, country });
    yield put({ type: 'ADDRESS_AUTO_COMPLETE_SEARCH_GOOGLE_ADDRESS_SUCCESS', items: googleResult });
    yield put({ type: 'ADDRESS_AUTO_COMPLETE_SEARCH_ADDRESS_BOOK_SUCCESS', items: transformedAddressBookData });
  } catch (error) {
    yield put({ type: 'ADDRESS_AUTO_COMPLETE_SEARCH_FAILED', error });
  }
}

const getGooglePredictions = async ({ searchKey, country = '' }) => {
  if (!googleAutocompleteService) {
    return [];
  }
  const googleResult = await new Promise((resolve) => {
    const countryCode = getCountryCodeByName(country);
    let requestParams = {
      input: searchKey,
    };

    const { sw, ne } = CountryBounds?.[countryCode] || {};
    if (sw && ne) {
      const locationBias = new window.google.maps.LatLngBounds(sw, ne);
      requestParams = {
        ...requestParams,
        locationBias,
      };
    }

    googleAutocompleteService.getPlacePredictions(requestParams, (result, status) => {
      resolve(result);
    });
  });
  if (!googleResult) return [];
  const allGoogleLocation = await Promise.all(
    googleResult.map((googleP) => {
      return onGoogleAddressSelect(googleP.description, googleP['place_id']);
    })
  );
  return allGoogleLocation.filter((g) => !!g);
};

const onGoogleAddressSelect = (address, id) => {
  return geocodeByAddress(address)
    .then((results) => results[0])
    .then((place) => {
      const details = { id: id, value: address, name: address, type: 'google' };
      if (place?.address_components?.length) {
        place.address_components.forEach((addressComponent) => {
          if (addressComponent.types.includes('postal_code')) {
            details.postalCode = addressComponent.long_name;
          } else if (addressComponent.types.includes('country')) {
            details.country = addressComponent.long_name;
          } else if (addressComponent.types.includes('locality')) {
            details.city = addressComponent.long_name;
          } else if (addressComponent.types.includes('administrative_area_level_1')) {
            details.state = addressComponent.long_name;
          }
        });
      }
      const location = place.geometry.location;
      if (location.lat()) {
        details.lat = location.lat();
      }
      if (location.lng()) {
        details.lng = location.lng();
      }
      return details;
    })
    .catch((error) => {
      console.error(error);
    });
};

const transformAddressToOption = (item) => {
  return { id: item.id, type: 'address', value: item, name: item.address1, ...item.location };
};
