const defaultState = {
  filter: undefined,
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPDATE_TASK_FILTER_SUCCESSFUL: (state, { taskFilter }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        ...taskFilter,
      },
    };
  },
  CLEAR_TASK_FILTER_SUCCESSFUL: (state) => {
    return { ...state, filter: undefined };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
