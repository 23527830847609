import * as _ from 'lodash-es';

import { SOLVER_VOLUME_UNIT, SOLVER_WEIGHT_UNIT } from '@yojee/helpers/constants';
import { lowerCaseAndSpaceReplace } from '@yojee/helpers/string-helper';
import { convertVolume } from '@yojee/helpers/tasks-helper';
import { fromWeightUnitAToWeightUnitB } from '@yojee/helpers/unitConverter/weightConverter';
import { uuidv4 } from '@yojee/helpers/uuidv4';

export const getWorkerVehicleProfile = ({ worker, profiles, volumeUnit, weightUnit }) => {
  if (!worker['assigned_vehicle']) {
    return null;
  }

  let vehicleTypeId = profiles.findIndex((at) => worker['current_vehicle_type_id'] === at.vehicleTypeId);

  // TODO: this is hacky, we should assign the right vehicle profile when asset types are pulled
  if (vehicleTypeId === -1) {
    vehicleTypeId = profiles.findIndex((at) => worker.profile === at.profile);
  }
  vehicleTypeId = vehicleTypeId > -1 ? vehicleTypeId : 0;

  const at = profiles[vehicleTypeId];
  const volumeCapacity = worker?.['assigned_vehicle']?.['max_load_space_volume'] || at.capacityVolume;
  const volume = convertVolume(parseFloat(volumeCapacity), volumeUnit, SOLVER_VOLUME_UNIT);
  const weightCapacity = worker['assigned_vehicle']['max_carry_weight'] || at.capacityWeight || 0;
  const weight = fromWeightUnitAToWeightUnitB(parseFloat(weightCapacity) || 0, weightUnit, SOLVER_WEIGHT_UNIT);

  const workerVehicle = {
    ...at,
    id: `assigned_vehicle_${worker['assigned_vehicle']['id']}`,
    capacity: {
      weight,
      volume,
      unit: parseFloat(worker['assigned_vehicle']['carry_unit'] || at.capacityUnit),
    },
    cost: {
      fixed: parseFloat(worker['assigned_vehicle']['fixed_cost'] || at.costFixed),
      perMileage: parseFloat(worker['assigned_vehicle']['per_mileage_cost'] || at.costMileage),
      perRegularHours: parseFloat(worker['assigned_vehicle']['per_regular_hours_cost'] || at.hourlySalary),
      waitingCostCoefficient: parseInt(
        worker['assigned_vehicle']['waiting_cost_coefficient'] || at.waitingCostCoefficient,
        10
      ),
    },
    serviceTime: 0,
    name: worker['assigned_vehicle']['plate_number'],
    isSingleVehicle: true,
  };

  const keys = [
    'capacityUnit',
    'capacityVolume',
    'capacityWeight',
    'costFixed',
    'costMileage',
    'hourlySalary',
    'waitingCostCoefficient',
  ];

  keys.forEach((v) => {
    if (workerVehicle[v]) {
      delete workerVehicle[v];
    }
  });

  return workerVehicle;
};

export const mapVehicleTypes = ({ workers, vehicleTypes, profiles, volumeUnit, weightUnit }) => {
  const usedVehicleTypeIds = _.uniq(workers.map((w) => w['current_vehicle_type_id']));

  const mappedVehicles = vehicleTypes
    .filter((type) => usedVehicleTypeIds.includes(type.id))
    .map((type) => {
      let vehicleTypeId = profiles.findIndex(
        (at) => lowerCaseAndSpaceReplace(type.name) === lowerCaseAndSpaceReplace(at.profile)
      );
      vehicleTypeId = vehicleTypeId > -1 ? vehicleTypeId : 0;
      const vehicleType = profiles[vehicleTypeId];
      return {
        ...vehicleType,
        vehicleSpecs: type?.routing_vehicle_profile_name === 'truck' ? type?.['configuration_data'] : null,
        id: type.id,
        vehicleTypeId: type.id,
        profile: type['routing_vehicle_profile_name'],
        capacityUnit: type['carry_unit'] || vehicleType.capacityUnit,
        capacityWeight: type['max_carry_weight'] || vehicleType.capacityWeight,
        capacityVolume: type['max_load_space_volume'] || vehicleType.capacityVolume,
        costFixed: type['fixed_cost'] || vehicleType.costFixed,
        costMileage: type['per_mileage_cost'] || vehicleType.costMileage,
        hourlySalary: type['per_regular_hours_cost'] || vehicleType.hourlySalary,
        waitingCostCoefficient: type['waiting_cost_coefficient'] || vehicleType.waitingCostCoefficient,
        name: type['name'] || vehicleType.profile,
      };
    })
    .map((vt) => {
      const vehicleTypeJson = {
        id: uuidv4(),
        vehicleTypeId: vt.id,
        profile: vt.profile,
        averageSpeed: parseFloat(vt.averageSpeed),
        capacity: {
          weight: fromWeightUnitAToWeightUnitB(parseFloat(vt.capacityWeight), weightUnit, SOLVER_WEIGHT_UNIT),
          volume: convertVolume(parseFloat(vt.capacityVolume), volumeUnit, SOLVER_VOLUME_UNIT),
          unit: parseFloat(vt.capacityUnit),
        },
        cost: {
          fixed: parseFloat(vt.costFixed),
          perMileage: parseFloat(vt.costMileage),
          perRegularHours: parseFloat(vt.hourlySalary),
          waitingCostCoefficient: parseInt(vt.waitingCostCoefficient, 10),
        },
        serviceTime: 0,
        isSingleVehicle: false,
        name: vt.name,
      };

      if (vt.profile === 'truck') {
        vehicleTypeJson.vehicleSpecs = vt.vehicleSpecs
          ? {
              height: vt.vehicleSpecs?.height ?? 1,
              width: vt.vehicleSpecs?.width ?? 1,
              length: vt.vehicleSpecs?.length ?? 1,
              shippedHazardousGoods: vt.vehicleSpecs?.['carry_hazardous_goods'],
            }
          : null;
        vehicleTypeJson.truckRestrictionPenalty = vt.vehicleSpecs?.['restriction_penalty'];
        vehicleTypeJson.truckType = 'truck';
      }

      /**
       * Append the custom capacities.
       * In that case, the `yojee_custom_capacity_demands` should be defined in `order_item.info` field as below.
       * as the same field name as in settings json `customCapacities`.
       *
       * "yojee_custom_capacity_demands": {
       *   "custom_capacity_contract": 1,
       *   "custom_capacity_charter": 0,
       *   "custom_capacity_task": 1
       * }
       */
      if (Object.hasOwn(vt, 'customCapacities')) {
        Object.keys(vt.customCapacities).forEach(function (key) {
          vehicleTypeJson.capacity[key] = vt.customCapacities[key];
        });
      }

      return vehicleTypeJson;
    });

  const workerVehicles = workers
    .filter((w) => w['assigned_vehicle'])
    .map((w) => getWorkerVehicleProfile({ worker: w, profiles, volumeUnit, weightUnit }));

  return mappedVehicles.concat(workerVehicles).map((v) => {
    v.initial = {
      cost: { ...v.cost },
    };

    return v;
  });
};
