import './App.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { initGA } from '@yojee/helpers/GAHelper';
import lazyLoadComponent from '@yojee/helpers/lazyLoadComponent';
import Snackbar from '@yojee/ui/base/furnitures/Snackbar';
import { legacyTheme } from '@yojee/ui/base/theme';
import Spinner from '@yojee/ui/common/LoadingOverlay/Spinner';
import { Roles } from '@yojee/ui/onboarding/components/UserManagement/Roles';
import { UserManagement } from '@yojee/ui/onboarding/components/UserManagement/UserManagement';
import { HistoryRouter } from '@yojee/ui/router/Router';
import { SwitchCompany } from '@yojee/ui/switch-company/components/SwitchCompany';

import packageJson from '../../../package.json';

const Layout = lazyLoadComponent(() => import('./components/Common/Layout/Layout'));
const SignUp = lazyLoadComponent(() => import('./components/Auth/SignUp'));
const ThankYou = lazyLoadComponent(() => import('./components/Auth/ThankYou'));
const LoginContainer = lazyLoadComponent(() => import('./components/Auth/LoginContainer'));
const LoginOneTime = lazyLoadComponent(() => import('@yojee/ui/onboarding/components/Auth/LoginOneTime'));
const LoginSSOEmail = lazyLoadComponent(() => import('@yojee/ui/onboarding/components/Auth/LoginSSOEmail'));
const Login = lazyLoadComponent(() => import('@yojee/ui/onboarding/components/Auth/Login'));
const PasswordReset = lazyLoadComponent(() => import('./components/Auth/PasswordReset'));
const PasswordEdit = lazyLoadComponent(() => import('./components/Auth/PasswordEdit'));
const Home = lazyLoadComponent(() => import('./components/Home/index'));
const TrackingMap = lazyLoadComponent(() => import('./components/Map/TrackingMap'));
const Scanner = lazyLoadComponent(() => import('./components/Scanner'));
const RequireAuth = lazyLoadComponent(() => import('./components/Auth/RequireAuth'));
const Subscribe = lazyLoadComponent(() => import('./components/Auth/Subscribe'));
const PartnerInfo = lazyLoadComponent(() => import('./components/PartnerInfo/index'));
const PartnerSignup = lazyLoadComponent(() => import('./components/Auth/PartnerSignup'));
const FileViewer = lazyLoadComponent(() => import('@yojee/ui/common/FileViewer'));

window.appVersion = window.appVersion || packageJson.version;

initGA('explore', window.appVersion);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const prefix = process.env.REACT_APP_PREFIX || '';
const App = ({ history }) => (
  <React.Fragment>
    <CssBaseline />
    <HistoryRouter history={history}>
      <MuiThemeProvider theme={legacyTheme}>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <React.Suspense fallback={<Spinner />}>
            <Routes>
              <Route path={`${prefix}/login`} element={<LoginContainer />}>
                <Route path="one-time-code" element={<LoginOneTime />} />
                <Route path="" element={<Login />} />
                <Route path="sso" element={<LoginSSOEmail />} />
              </Route>
              <Route path={`${prefix}/signup/:product`} element={<SignUp />} />
              <Route path={`${prefix}/partner-signup`} element={<PartnerSignup />} />
              <Route
                path={`${prefix}/thank-you/:product/:step`}
                element={<RequireAuth ComposedComponent={ThankYou} />}
              />
              <Route path={`${prefix}/subscribe/:product`} element={<RequireAuth ComposedComponent={Subscribe} />} />
              <Route path={`${prefix}/password/reset`} element={<PasswordReset />} />
              <Route path={`${prefix}/password/edit`} element={<PasswordEdit />} />
              <Route path={`${prefix}/tracking-map`} element={<TrackingMap />} />
              <Route path={`${prefix}/embedded/user-management`} element={<UserManagement />} />
              <Route path={`${prefix}/embedded/roles`} element={<Roles />} />
              <Route path={`${prefix}/scan`} element={<RequireAuth ComposedComponent={Scanner} />} />
              <Route path={`${prefix}/scan/:action`} element={<RequireAuth ComposedComponent={Scanner} />} />
              <Route path="/assign/scan" element={<RequireAuth ComposedComponent={Scanner} />} />
              <Route path={`${prefix}/upload`} element={<RequireAuth ComposedComponent={Home} />} />
              <Route path={`${prefix}/partner/:id`} element={<PartnerInfo />} />
              <Route path={`${prefix}/switch-company`} element={<SwitchCompany />} />
              <Route path="/preview" element={<FileViewer />} />
              <Route path="*" key="layout" element={<RequireAuth ComposedComponent={Layout} />} />
            </Routes>
          </React.Suspense>
        </Snackbar>
      </MuiThemeProvider>
    </HistoryRouter>
  </React.Fragment>
);
export default App;
