export const ITEM_STORE_ID = 'BIZ_ITEM';

export const ITEM_ACTION_TYPES = {
  SET_ITEMS: `${ITEM_STORE_ID}:SET_ITEMS`,

  FETCH_HIERARCHY: `${ITEM_STORE_ID}:FETCH_HIERARCHY`,
  SET_HIERARCHY: `${ITEM_STORE_ID}:SET_HIERARCHY`,
  SET_HIERARCHY_ITEMS: `${ITEM_STORE_ID}:SET_HIERARCHY_ITEMS`,
  SET_HIERARCHY_FETCH_STATUS: `${ITEM_STORE_ID}:SET_HIERARCHY_FETCH_STATUS`,

  SET_JOBS: `${ITEM_STORE_ID}:SET_JOBS`,
  SET_JOBS_UPDATE_STATUS: `${ITEM_STORE_ID}:SET_JOBS_UPDATE_STATUS`,
  UPDATE_JOBS: `${ITEM_STORE_ID}:UPDATE_JOBS`,

  CONSOLIDATE: `${ITEM_STORE_ID}:CONSOLIDATE`,
  SET_CONSOLIDATION_STATUS: `${ITEM_STORE_ID}:SET_CONSOLIDATION_STATUS`,

  DECONSOLIDATE: `${ITEM_STORE_ID}:DECONSOLIDATE`,
  SET_DECONSOLIDATION_STATUS: `${ITEM_STORE_ID}:SET_DECONSOLIDATION_STATUS`,
  SET_DECONSOLIDATED_ITEM: `${ITEM_STORE_ID}:SET_DECONSOLIDATED_ITEM`,

  CLOSE_DECONSOLIDATION_MODAL: `${ITEM_STORE_ID}:CLOSE_DECONSOLIDATION_MODAL`,
  SHOW_DECONSOLIDATION_MODAL: `${ITEM_STORE_ID}:SHOW_DECONSOLIDATION_MODAL`,

  FETCH_ORDER_ITEMS_BY_TYPES: `${ITEM_STORE_ID}:FETCH_ORDER_ITEMS_BY_TYPES`,
  SET_FETCHED_ITEMS: `${ITEM_STORE_ID}:SET_FETCHED_ITEMS`,

  FETCH_ORDER_ITEMS_BY_IDS: `${ITEM_STORE_ID}:FETCH_ORDER_ITEMS_BY_IDS`,
  SET_GROUPED_ITEMS: `${ITEM_STORE_ID}:SET_GROUPED_ITEMS`,
};

export const setItems = (data) => ({ type: ITEM_ACTION_TYPES.SET_ITEMS, payload: data });
export const fetchItemHierarchy = (data) => ({ type: ITEM_ACTION_TYPES.FETCH_HIERARCHY, payload: data });
export const deconsolidate = (data) => ({ type: ITEM_ACTION_TYPES.DECONSOLIDATE, payload: data });
export const closeDeconsolidationModal = () => ({ type: ITEM_ACTION_TYPES.CLOSE_DECONSOLIDATION_MODAL });
export const showDeconsolidationModal = (data) => ({
  type: ITEM_ACTION_TYPES.SHOW_DECONSOLIDATION_MODAL,
  payload: data,
});
export const updateJobs = (data) => ({ type: ITEM_ACTION_TYPES.UPDATE_JOBS, payload: data });
export const fetchOrderItemsByTypes = (data) => ({ type: ITEM_ACTION_TYPES.FETCH_ORDER_ITEMS_BY_TYPES, payload: data });
export const fetchOrderItemsByIds = (data) => ({ type: ITEM_ACTION_TYPES.FETCH_ORDER_ITEMS_BY_IDS, payload: data });
