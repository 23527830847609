class Actions {
  updateBulkSelection = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_UPDATE_BULK_SELECTION', payload: payload };
  };
  requestGetUsers = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_GET_USERS', payload: payload };
  };
  requestCreateUser = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_CREATE_USER', payload: payload };
  };
  requestDeleteUser = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_DELETE_USER', payload: payload };
  };
  requestUpdateUser = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_UPDATE_USER', payload: payload };
  };
  clearMessages = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_CLEAR_MESSAGES', payload: payload };
  };
  requestGetFilters = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_GET_FILTERS', payload: payload };
  };
  requestGetRoles = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_GET_ROLES', payload: payload };
  };
  requestGetEnterprisePermissions = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_GET_ENTERPRISE_PERMISSIONS', payload: payload };
  };
  requestCreateRole = (payload, pagination) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_CREATE_ROLE', payload: payload, pagination };
  };
  requestUpdateRole = (payload, pagination) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_UPDATE_ROLE', payload: payload, pagination };
  };
  requestDeleteRole = (payload, pagination) => {
    return { type: 'UI_USER_MANAGEMENT_REQUEST_DELETE_ROLE', payload: payload, pagination };
  };
  downloadUserSample = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_DOWNLOAD_USER_TEMPLATE_SAMPLE', payload: payload };
  };
  requestBatchUploadEnterpriseUser = (payload) => {
    return { type: 'UI_USER_MANAGEMENT_BATCH_UPLOAD', payload: payload };
  };
  refreshBatchUpload = () => {
    return { type: 'REFRESH_BATCH_UPLOAD' };
  };
}

export const UIUserManagementActions = new Actions();
