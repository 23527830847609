import { call, put, takeLatest } from 'redux-saga/effects';

import { partnerService } from '@yojee/api/partnerService';

export default function* sagas() {
  yield takeLatest('SEARCH_PARTNER', searchPartner);
}

function* searchPartner({ searchKey }) {
  try {
    yield put({ type: 'SEARCH_PARTNER_START', searchKey: searchKey });
    const { data } = yield call(partnerService.searchPartner, { query: searchKey });
    yield put({ type: 'SEARCH_PARTNER_SUCCESS', partners: data });
  } catch (error) {
    yield put({ type: 'SEARCH_PARTNER_FAILED', error });
  }
}
