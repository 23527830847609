import { END, eventChannel } from 'redux-saga';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { CONNECTION_TYPES, webSocketService } from '@yojee/api/webSocketService';
import AuthSelectors from '@yojee/auth/store/selectors';

import {
  addNewNotification,
  TYPES as ACTION_TYPES,
  updateAllAsRead,
  updateAllAsSeen,
  updateNotifications,
  updateNotificationStatus,
} from './actions';

export default function* saga() {
  yield takeEvery(ACTION_TYPES.SUBSCRIBE_WEB_SOCKET, subscribeWebSocket);
  yield takeEvery(ACTION_TYPES.UNSUBSCRIBE_WEB_SOCKET, unsubscribeWebSocket);
  yield takeLatest(ACTION_TYPES.LOAD_MORE_NOTIFICATION, loadMoreNotification);
  yield takeLatest(ACTION_TYPES.MARK_AS_READ, markAsRead);
  yield takeLatest(ACTION_TYPES.MARK_AS_UNREAD, markAsUnRead);
  yield takeLatest(ACTION_TYPES.MARK_ALL_AS_READ, markAllAsRead);
  yield takeLatest(ACTION_TYPES.MARK_ALL_AS_SEEN, markAllAsSeen);
}

function* markAllAsSeen(action) {
  const { userId } = action.payload;
  const currentChannel = webSocketService.getSocket(CONNECTION_TYPES.NORMAL).getChannelByTopic({
    topic: `dispatcher:${userId}`,
  });
  currentChannel.push('mark_all_as_seen');
  yield put(updateAllAsSeen());
}

function* markAllAsRead(action) {
  const { userId } = action.payload;
  const currentChannel = webSocketService.getSocket(CONNECTION_TYPES.NORMAL).getChannelByTopic({
    topic: `dispatcher:${userId}`,
  });
  currentChannel.push('mark_all_as_read');
  yield put(updateAllAsRead());
}

function* markAsUnRead(action) {
  const { userId, activityId } = action.payload;
  const currentChannel = webSocketService.getSocket(CONNECTION_TYPES.NORMAL).getChannelByTopic({
    topic: `dispatcher:${userId}`,
  });
  currentChannel.push('mark_as_unread', { activity_id: activityId });
  yield put(updateNotificationStatus({ unread: true, id: activityId }));
}

function* markAsRead(action) {
  const { userId, activityId } = action.payload;
  const currentChannel = webSocketService.getSocket(CONNECTION_TYPES.NORMAL).getChannelByTopic({
    topic: `dispatcher:${userId}`,
  });
  currentChannel.push('mark_as_read', { activity_id: activityId });
  yield put(updateNotificationStatus({ unread: false, id: activityId }));
}

function* loadMoreNotification(action) {
  yield put({ type: 'START_UPDATE_NOTIFICATION' });
  const { userId, startId, pageSize } = action.payload;
  const currentChannel = yield call(webSocketService.getSocket(CONNECTION_TYPES.NORMAL).getChannelByTopic, {
    topic: `dispatcher:${userId}`,
  });
  currentChannel
    .push('activities', { start_id: startId, page_size: pageSize })
    .receive('error', () => console.error('===== Load activities failed'))
    .receive('timeout', () => console.error('==== Load activities timeout'));
}

function* subscribeWebSocket(action) {
  const {
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
    token: jwt,
  } = yield select(AuthSelectors.getData);

  const { userId } = action.payload;
  const channel = eventChannel((emitter) => {
    const onMessageComing = (message) => {
      if (message.event === 'phx_close') {
        emitter(END);
      }
      emitter(message);
    };
    webSocketService
      .getSocket(CONNECTION_TYPES.NORMAL, { userId, company_slug: slug, jwt }, null, onMessageComing)
      .getChannelByTopic({ topic: `dispatcher:${userId}` });
    return () => {
      console.info('===== Stop Subscribe web socket ');
    };
  });

  while (true) {
    try {
      const message = yield take(channel);
      switch (message.event) {
        case 'activities':
          yield put(updateNotifications(message.payload));
          break;
        case 'activity':
          yield put(addNewNotification(message.payload));
          break;
        default:
      }
    } catch (err) {
      channel.close();
    }
  }
}

const closeChannel = (topic) => {
  webSocketService.getSocket(CONNECTION_TYPES.NORMAL).closeChannel(topic);
};

function* unsubscribeWebSocket(action) {
  const { userId } = action.payload;
  yield call(closeChannel, `dispatcher:${userId}`);
}
