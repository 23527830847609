export function createPopupClass(maps) {
  function Popup(position, content) {
    this.position = position;
    content.classList.add('popup-bubble');
    const bubbleAnchor = document.createElement('div');
    bubbleAnchor.classList.add('popup-bubble-anchor');
    bubbleAnchor.appendChild(content);

    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-container');
    this.containerDiv.appendChild(bubbleAnchor);
    maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
  }
  Popup.prototype = Object.create(maps.OverlayView.prototype);
  Popup.prototype.onAdd = function () {
    this.getPanes().floatPane.appendChild(this.containerDiv);
  };

  Popup.prototype.onRemove = function () {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  };

  Popup.prototype.draw = function () {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';
    this.containerDiv.backgroundColor = 'yellow';
    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y - 25 + 'px';
    }
    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  };
  return Popup;
}
