import moment from 'moment';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import AuthSelectors from '@yojee/auth/store/selectors';

import * as Api from '../../Api/api';
import driversApi from '../../Api/driversApi';
export const getIdBgProcess = (state) => state.worker && state.worker.processId;
export const workerLoading = (state) => state.worker && state.worker.inProgress && state.worker.inProgress.getWorkers;

export default function* sagas() {
  yield takeLatest(['INIT_WORKER', 'CREATING_DRIVERS_SUCCESS'], initWorkers);
  yield takeLatest('TRIGGER_RECALCULATE_CAPACITY', recalculatedCapacity);
  yield debounce(1000, 'CHECK_UPDATE_ID_PROCESS', checkUpdateIdProcess);
  yield debounce(1000, 'HIGHLIGHTED_WORKER', highlightedWorker);
  yield takeLatest('VALIDATE_WORKER_CAPACITY_ERROR', checkingCapacityError);
}

function* highlightedWorker({ workerId }) {
  yield put({ type: 'SHOW_HIGHLIGHTED_WORKER', workerId });
}

function* checkUpdateIdProcess() {
  try {
    const idBG = yield select(getIdBgProcess);
    if (!idBG) {
      return;
    }
    const result = yield call(driversApi.checkStatusCapacityUpdate, { processId: idBG });
    if (result.data && result.data.completed_at) {
      yield put({ type: 'CAPACITY_CALCULATED_DONE' });
    } else {
      yield put({ type: 'CHECK_UPDATE_ID_PROCESS' });
    }
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: `checkUpdateIdProcess ${err.message}`, variant: 'error' });
  }
}

function* recalculatedCapacity({ workersWrongCapacity }) {
  try {
    const idBG = yield select(getIdBgProcess);
    if (idBG) {
      return;
    }
    const workerIdUpdate = workersWrongCapacity.map((w) => w.id);
    yield put({ type: 'UPDATE_WORKERS_WRONG_CAPACITY', workersWrongCapacity: workerIdUpdate });
    const result = yield call(driversApi.recalculateCapacity, { workers: workerIdUpdate });
    const dataResult = result && result.data;
    if (dataResult.id) {
      yield put({ type: 'UPDATE_ID_PROCESS_RECALCULATE_CAPACITY', processId: dataResult.id });
      yield put({ type: 'CHECK_UPDATE_ID_PROCESS' });
    }
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: `recalculatedCapacity ${err.message}`, variant: 'error' });
  }
}

function* checkingCapacityError({ workers }) {
  const idBG = yield select(getIdBgProcess);
  const workerArr = workers;
  if (workerArr && workerArr.length > 0) {
    // filter not updated capacity
    const workersWrongCapacity = workerArr.filter((w) => {
      if (!w.worker_sequence) return false;
      if (!w.worker_sequence.capacity_calculated_at || !w.worker_sequence.capacity_updated_at) return true;
      const capacity_updated_at = moment(w.worker_sequence.capacity_updated_at);
      const capacity_calculated_at = moment(w.worker_sequence.capacity_calculated_at);
      return capacity_calculated_at.unix() < capacity_updated_at.unix();
    });
    if (!idBG && workersWrongCapacity && workersWrongCapacity.length > 0) {
      yield put({ type: 'TRIGGER_RECALCULATE_CAPACITY', workersWrongCapacity });
    }
  }
}

function* initWorkers() {
  try {
    const isWorkerLoading = yield select(workerLoading);
    if (isWorkerLoading) {
      return;
    }
    yield put({ type: 'WORKER_LOADING', key: 'getWorkers', isLoading: true });
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const { data: workersData } = yield call(Api.fetchWorkers, {
      token,
      partnerJwt,
      slug,
    });

    const { pagination } = workersData;
    const mergedData = workersData;
    const max_pages = Math.min(pagination.total_pages, 20); // Limit to max 10 pages
    if (pagination.total_pages > 1) {
      for (let i = 1; i < max_pages; i++) {
        const { data: _workersData } = yield call(Api.fetchWorkers, {
          token,
          slug,
          partnerJwt,
          page: i + 1,
        });
        mergedData.data = mergedData.data.concat(_workersData.data);
        mergedData.pagination = _workersData.pagination;
      }
    }
    yield put({ type: 'VALIDATE_WORKER_CAPACITY_ERROR', workers: mergedData.data });
    yield put({ type: 'FETCH_WORKERS_SUCCEEDED', data: mergedData });
    yield put({ type: 'WORKER_LOADING', key: 'getWorkers', isLoading: false });
  } catch (error) {
    yield put({ type: 'FETCH_WORKERS_ERROR', error: error });
  }
}
