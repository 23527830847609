import { Route, SolverOptimiseResponse } from './solver-optimise-response';

const filterBreakTimeInRoute = (route: Route): Route => {
  const { directions, tour } = route;
  const newDirections = directions.filter((e) => e.from !== e.to);
  const newTour = tour.filter((e) => e.taskType !== 'break');

  return {
    ...route,
    directions: newDirections,
    tour: newTour,
  };
};

export const filterBreakTimeTasks = (data: SolverOptimiseResponse) => {
  data.routes = data.routes.map((route) => filterBreakTimeInRoute(route));

  return data;
};
