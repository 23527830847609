import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class OrganisationalUnitApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  fetchOrganisationalUnits = (params) =>
    this.authService
      .get(this.getUmbrellaApiUrlV4('company/organisational_units'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));

  fetchAllOrganisationalUnits = (params) =>
    this.authService
      .get(this.getUmbrellaApiUrlV4('company/organisational_units/all'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));

  createOrganisationalUnit = (data) =>
    this.authService
      .post(this.getUmbrellaApiUrlV4('company/organisational_units'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));

  updateOrganisationalUnit = (id, changes) =>
    this.authService
      .put(this.getUmbrellaApiUrlV4(`company/organisational_units/${id}`), changes)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));

  deleteOrganisationalUnit = (id) =>
    this.authService
      .delete(this.getUmbrellaApiUrlV4(`company/organisational_units/${id}`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
}

export const organisationalServiceApi = new OrganisationalUnitApi({ authService });
