import axios from 'axios';

import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { LocalStorage } from '@yojee/local-storage/local-storage';

class InvoicesApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listProFormaInvoices = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/invoice/filter'), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  downloadProFormaInvoices = (invoiceNumber) => {
    const jwt = LocalStorage.getItem('access_token');

    return axios
      .get(this.getUmbrellaApiUrlV4(`company/invoice/${invoiceNumber}?jwt=${jwt}`), {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
      })
      .then((res) => res)
      .catch((error) => this.handleNewErrorFormat(error));
  };

  listBillingJobInvoices = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/invoice_jobs'), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  createBillingJobInvoices = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/invoice_jobs'), {
        data: {
          company_slug: getSlugFromUrl(),
          ...params,
        },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  fetchBillingJobDetails = (jobId, params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${jobId}/get_orders`), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  // https://umbrella-dev.yojee.com/api/swaggerui#/%5BCompany%5D%20Invoices/ApiWeb.V4.Company.InvoiceJobController.get_invoices
  fetchBillingJobInvoices = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${jobId}/invoices`), {
        company_slug: getSlugFromUrl(),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  // https://umbrella-dev.yojee.com/api/swaggerui#/%5BCompany%5D%20Invoices/ApiWeb.V4.Company.InvoiceJobController.calculate_bulk
  calculateRate = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/invoice_job/calculate_rate_in_bulk'), {
        data: {
          company_slug: getSlugFromUrl(),
          ...params,
        },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  checkCalculateStatus = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${jobId}/get_charge_calculation_status`), {
        company_slug: getSlugFromUrl(),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  generateInvoice = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/invoice_job/bulk_invoice'), {
        data: {
          company_slug: getSlugFromUrl(),
          ...params,
        },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getJobInvoiceStatus = (jobNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${jobNumber}/invoicing_status`))
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getChargeInvoiceDetails = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice/${id}/get_details`), {
        company_slug: getSlugFromUrl(),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  invalidateInvoice = (id, params) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/invoice/${id}/invalidate`), {
        data: {
          company_slug: getSlugFromUrl(),
          ...params,
        },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  listRateChargeCodes = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/rates/rate_charge_types'), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  updateManualChargeToInvoice = (id, params) => {
    return this.authService
      .patch(this.getUmbrellaApiUrlV4(`company/invoice_job/${id}/charges/batch_update`), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  createManualChargeToInvoice = (id, params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/invoice_job/${id}/charges/batch_create`), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  deleteManualChargeInInvoice = (id, params) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/invoice_job/${id}/charges/batch_delete`), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  fetchManualCharges = (id, params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${id}/charges`), {
        company_slug: getSlugFromUrl(),
        ...params,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  fetchJobTotal = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/invoice_job/${id}/total`))
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

const invoicesApi = new InvoicesApi({ authService });

export default invoicesApi;
