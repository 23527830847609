const defaultState = {
  isLoading: false,
  options: [],
  errorMessage: undefined,
  searchKey: '',
  selectedIds: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SEARCH_ID_START: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  SEARCH_ID_SUCCESS: (state, { options }) => {
    return {
      ...state,
      isLoading: false,
      options: options,
    };
  },
  SEARCH_ID_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
  UPDATE_ID_SELECTED: (state, { id }) => {
    return {
      ...state,
      selectedIds: [id],
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
