export const distance = (locationA, locationB) => {
  const R = 6371; // km (change this constant to get miles)
  const dLat = ((locationA.lat - locationB.lat) * Math.PI) / 180;
  const dLng = ((locationA.lng - locationB.lng) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((locationA.lat * Math.PI) / 180) *
      Math.cos((locationB.lat * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c * 1000;
};
