import isEmpty from 'lodash/isEmpty';
import { all, call, cancel, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ratingsService } from '@yojee/api/ratingsService';
// booking invoice api
import { invoiceApi } from '@yojee/api/v4/invoiceApi';

import invoicesApi from '../../Api/invoicesApi';
// constants
import { CALCULATION_TAB } from '../../components/Invoices/constants';

const statePagination = (state) => state.billingJob.pagination;

const STATUS_FETCH_INTERVAL = 5000; // 5 seconds

const checkStatusTasks = [];

const handleErrorCode = (error) => {
  if (!!error === false) return null;

  return error?.message || error;
};

function* handleError(err, prefix = '', status = '') {
  try {
    if (status.length) {
      yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status, data: 'failed' } });
    }

    const errMess = handleErrorCode(err);
    yield put({ type: 'BILLING_JOB_ADD_MESSAGE', payload: { type: 'errorMessage', data: `${prefix}: \n ${errMess}` } });
  } catch (error) {
    yield put({ type: 'BILLING_JOB_ADD_MESSAGE', payload: { type: 'errorMessage', data: error } });
  }
}

function* refreshBillingJobList() {
  const { current_page: page, limit_value: page_size } = yield select(statePagination);
  yield call(fetchBillingJobList, { payload: { page, page_size } });
}

function* fetchBillingJobList({ payload }) {
  try {
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDataListStatus', data: 'running' } });
    const { data, pagination } = yield call(invoicesApi.listBillingJobInvoices, payload);

    yield put({ type: 'BILLING_JOB_FETCH_LIST_SUCCESS', payload: { data, pagination } });
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDataListStatus', data: 'success' } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job fetch list failed', 'billingDataListStatus');
  }
}

function* fetchChargeInvoiceDetails({ payload }) {
  try {
    const invoiceDetails = yield all(
      payload.filter((id) => !!id).map((id) => call(invoicesApi.getChargeInvoiceDetails, id))
    );
    const formattedData = invoiceDetails?.reduce((acc, { data: { id, number } }) => {
      if (!!acc[id] === false) acc[id] = number;

      return acc;
    }, {});

    yield put({ type: 'BILLING_JOB_FETCH_CHARGE_INVOICE_DETAILS_SUCCESS', payload: { data: formattedData } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job fetch charges details failed');
  }
}

function* fetchJobInvoiceStatus({ payload }) {
  try {
    const { job_id: jobId } = payload;
    const { data } = yield call(invoicesApi.getJobInvoiceStatus, jobId);

    yield put({ type: 'BILLING_JOB_FETCH_INVOICE_STATUS_SUCCESS', payload: { data } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job fetch invoice status failed');
  }
}

function* fetchInvoiceTotal({ payload }) {
  try {
    const { job_id } = payload;
    const { data } = yield call(invoicesApi.fetchBillingJobInvoices, job_id);
    const filteredData = data.invoices.filter((item) => item.state !== 'invalidated');

    yield put({ type: 'BILLING_JOB_FETCH_INVOICE_TOTAL_SUCCESS', payload: { data: filteredData } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job get total failed');
  }
}

function* fetchBillingJobDetails({ payload }) {
  try {
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsStatus', data: 'running' } });

    const { job_id, ...restParams } = payload;

    const { data, pagination } = yield call(invoicesApi.fetchBillingJobDetails, job_id, restParams);

    // fetch total information for this invoice job
    yield call(fetchInvoiceTotal, { payload: { job_id } });

    // populate more data
    if (!isEmpty(data)) {
      // get unique invoice_id from all charges
      const invoiceIdSet = new Set();

      data.forEach((order) => {
        const { charges } = order;
        const orderInvoicesSet = new Set();

        if (!isEmpty(charges)) {
          charges.forEach((charge) => {
            // add unique invoice id
            const { invoice_id } = charge;
            invoiceIdSet.add(invoice_id);

            // add invoices from charge
            if (charge.invoice_id) orderInvoicesSet.add(charge.invoice_id);
          });
        }
        order.orderInvoices = [...orderInvoicesSet];
      });

      // fetch detail invoice using invoice ids
      if (invoiceIdSet.size !== 0) {
        yield call(fetchChargeInvoiceDetails, { payload: [...invoiceIdSet] });
      }
    }

    yield put({ type: 'BILLING_JOB_FETCH_DETAILS_SUCCESS', payload: { data, pagination } });
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsStatus', data: 'success' } });
    yield call(fetchJobInvoiceStatus, { payload: { job_id } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job details fetch failed', 'billingDetailsStatus');
  }
}

function* createBillingJobInvoice({ payload }) {
  try {
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'createBillingJobStatus', data: 'running' } });
    const { onSuccess, ...restPayload } = payload;
    const { data } = yield call(invoicesApi.createBillingJobInvoices, restPayload);

    onSuccess?.(data);

    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'createBillingJobStatus', data: 'success' } });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Created Billing Job successfully!' },
    });
  } catch (error) {
    yield call(handleError, error, 'Create Billing Job failed', 'createBillingJobStatus');
  }
}

function* calculateRate({ payload }) {
  try {
    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsCalculateStatus', data: 'running' },
    });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Scheduling calculation!' },
    });

    const { onSuccess, job_id, chargeTypes, tabVal, mainData = [], manualData = [] } = payload;
    // save invoice charges before calculate
    if (!isEmpty(mainData)) {
      yield put({ type: 'BILLING_JOB_SAVE_INVOICES', payload: { data: mainData } });
    }

    // save manual charges before calculate
    if (!isEmpty(manualData)) {
      yield put({ type: 'BILLING_JOB_ADD_MANUAL_CHARGE', payload: { job_id, charges: manualData, tabVal } });
    }

    // schedule calculation
    yield call(invoicesApi.calculateRate, { job_id, charge_types: chargeTypes });

    // execute success callback if have
    onSuccess?.();
    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsCalculateStatus', data: 'success' },
    });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Scheduled calculation successfully!' },
    });
  } catch (error) {
    yield call(handleError, error, 'Billing Job schedule calculation failed', 'billingDetailsCalculateStatus');
  }
}

function* checkCalculateStatus({ payload }) {
  try {
    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsFetchCalculationStatus', data: 'running' },
    });
    const { job_id, showOnCalculated = false, refreshListSuccess = false } = payload;

    const {
      data: { status },
    } = yield call(invoicesApi.checkCalculateStatus, job_id);

    yield put({ type: 'BILLING_JOB_CHECK_CALCULATE_STATUS_SUCCESS', payload: { data: status } });

    if (refreshListSuccess && status === 'completed') {
      yield call(refreshBillingJobList);
    }

    if (showOnCalculated && status === 'completed') {
      yield put({
        type: 'BILLING_JOB_ADD_MESSAGE',
        payload: { type: 'successMessage', data: 'Billing Job calculation completed!' },
      });
    }
    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsFetchCalculationStatus', data: 'success' },
    });
  } catch (error) {
    yield call(handleError, error, 'Billing Job check calculate status failed', 'billingDetailsFetchCalculationStatus');
  }
}

function* bgCheckCalulateStatus({ payload }) {
  while (true) {
    yield put({ type: 'BILLING_JOB_CHECK_CALCULATE_STATUS', payload });
    yield delay(STATUS_FETCH_INTERVAL);
  }
}

function* bgCheckCalulateStatusHelper({ payload }) {
  try {
    const bgSyncTask = yield fork(bgCheckCalulateStatus, { payload });
    checkStatusTasks.push(bgSyncTask);
  } catch (error) {
    yield call(handleError, error, 'Billing Job background status check refresh failed');
  }
}

function* bgCheckCalulateStatusCancelHelper() {
  try {
    // cancel all status queue stask
    yield all(checkStatusTasks.map((task) => cancel(task)));
    checkStatusTasks.length = 0;
  } catch (error) {
    yield call(handleError, error, 'Billing Job background status check cancel failed');
  }
}

function* generateInvoice({ payload }) {
  try {
    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsGenerateInvoice', data: 'running' },
    });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Billing Job generating invoice...' },
    });
    // generate and get list of processing invoices
    const { onSuccess, ...restPayload } = payload;
    const {
      data: { invoices },
    } = yield call(invoicesApi.generateInvoice, restPayload);

    onSuccess && onSuccess();

    // finalize invoices
    yield all(invoices.map(({ id }) => call(invoiceApi.finalizeInvoice, id)));

    yield put({
      type: 'BILLING_JOB_SET_STATUS',
      payload: { status: 'billingDetailsGenerateInvoice', data: 'success' },
    });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Billing Job generated invoice successfully!' },
    });

    // refresh list
    yield call(refreshBillingJobList);

    // refresh total
    yield call(fetchInvoiceTotal, { payload: { job_id: restPayload.job_id } });
    yield call(fetchJobInvoiceStatus, { payload: { job_id: restPayload.job_id } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job generate invoice failed', 'billingDetailsGenerateInvoice');
  }
}

function* invalidateInvoices({ payload }) {
  try {
    const { job_ids, reason, job_id } = payload;
    yield all(job_ids.map((id) => call(invoicesApi.invalidateInvoice, id, { reason })));

    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Billing Job invalidated invoice successfully!' },
    });
    // refresh list
    yield call(refreshBillingJobList);

    // refresh total
    yield call(fetchInvoiceTotal, { payload: { job_id } });
    yield call(fetchJobInvoiceStatus, { payload: { job_id } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job invalidate job failed');
  }
}

function* saveInvoices({ payload }) {
  try {
    const { data, onSuccess } = payload;

    yield all(data.map(({ orderNumber, data }) => call(ratingsService.bulkUpdateCharges, orderNumber, { data })));

    onSuccess?.();

    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Billing Job saved successfully!' },
    });
  } catch (error) {
    yield call(handleError, error, 'Billing Job save job failed');
  }
}

function* fetchBillingDetailsOrderCount({ payload }) {
  try {
    const { job_id } = payload;

    const { pagination: withPricePagination } = yield call(invoicesApi.fetchBillingJobDetails, job_id, {
      with_price: true,
    });
    const { pagination: withoutPricePagination } = yield call(invoicesApi.fetchBillingJobDetails, job_id, {
      with_price: false,
    });

    const { data: manualCounts } = yield call(invoicesApi.fetchManualCharges, job_id);

    const countObj = {
      main: withPricePagination.total_count,
      warning: withoutPricePagination.total_count,
      manual: manualCounts.length,
    };

    yield put({ type: 'BILLING_JOB_FETCH_ORDERS_COUNT_SUCCESS', payload: { data: countObj } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job save job failed');
  }
}

function* fetchManualCharges({ payload }) {
  try {
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsStatus', data: 'running' } });
    const { job_id, ...restParams } = payload;
    const { data } = yield call(invoicesApi.fetchManualCharges, job_id, restParams);

    // preserve pagination
    const pagination = yield select(statePagination);
    // store data in same location as system charges to display on table
    yield put({ type: 'BILLING_JOB_FETCH_DETAILS_SUCCESS', payload: { data, pagination } });
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsStatus', data: 'success' } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job get manual charges failed', 'billingDetailsStatus');
  }
}

function* saveManualChargeToInvoice({ payload }) {
  try {
    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsManualStatus', data: 'running' } });

    const { job_id, onSuccess, charges, tabVal } = payload;
    const createdManualCharges = charges.filter((charge) => !charge.id);
    const updatedManualCharges = charges.filter((charge) => !!charge.id);
    yield all([
      createdManualCharges.length > 0
        ? call(invoicesApi.createManualChargeToInvoice, job_id, { data: { charges: createdManualCharges } })
        : call(() => Promise.resolve()),
      updatedManualCharges.length > 0
        ? call(invoicesApi.updateManualChargeToInvoice, job_id, { data: { charges: updatedManualCharges } })
        : call(() => Promise.resolve()),
    ]);
    onSuccess && onSuccess();

    yield put({ type: 'BILLING_JOB_SET_STATUS', payload: { status: 'billingDetailsManualStatus', data: 'success' } });
    yield put({ type: 'BILLING_JOB_FETCH_ORDERS_COUNT', payload: { job_id } });
    yield put({
      type: 'BILLING_JOB_ADD_MESSAGE',
      payload: { type: 'successMessage', data: 'Billing Job manual charges saved successfully!' },
    });

    if (tabVal === CALCULATION_TAB.TAB_MANUAL) {
      yield call(fetchManualCharges, { payload: { job_id } });
    }
  } catch (error) {
    yield call(handleError, error, 'Billing Job save manual charges failed', 'billingDetailsManualStatus');
  }
}

function* deleteManualChargeInInvoice({ payload }) {
  try {
    const { job_id, onSuccess, ...restParams } = payload;
    yield call(invoicesApi.deleteManualChargeInInvoice, job_id, restParams);
    yield put({ type: 'BILLING_JOB_FETCH_ORDERS_COUNT', payload: { job_id } });
    onSuccess && onSuccess();
  } catch (error) {
    yield call(handleError, error, 'Billing Job delete manual charges failed');
  }
}

function* deleteGeneratedChargeInInvoice({ payload }) {
  try {
    const { onSuccess, data, orderNumber } = payload;
    yield call(ratingsService.bulkDeleteCharges, orderNumber, { data });
    onSuccess && onSuccess();
  } catch (error) {
    yield call(handleError, error, 'Billing Job delete generated charges failed');
  }
}

function* fetchJobTotal({ payload }) {
  try {
    const { job_id } = payload;
    const { data } = yield call(invoicesApi.fetchJobTotal, job_id);
    yield put({ type: 'BILLING_JOB_FETCH_TOTAL_SUCCESS', payload: { data } });
  } catch (error) {
    yield call(handleError, error, 'Billing Job fetch job total failed');
  }
}

export default function* sagas() {
  yield takeLatest('BILLING_JOB_FETCH_LIST', fetchBillingJobList);
  yield takeLatest('BILLING_JOB_CREATE_INVOICE', createBillingJobInvoice);
  yield takeLatest('BILLING_JOB_FETCH_DETAILS', fetchBillingJobDetails);
  yield takeLatest('BILLING_JOB_CALCULATE_RATE', calculateRate);
  yield takeLatest('BILLING_JOB_CHECK_CALCULATE_STATUS', checkCalculateStatus);
  yield takeLatest('BILLING_JOB_GENERATE_INVOICE', generateInvoice);
  yield takeLatest('BILLING_JOB_INVALIDATE_INVOICE', invalidateInvoices);
  yield takeLatest('BILLING_JOB_SAVE_INVOICES', saveInvoices);
  yield takeLatest('BILLING_JOB_BG_CHECK_CALCULATE_STATUS', bgCheckCalulateStatusHelper);
  yield takeLatest('BILLING_JOB_CANCEL_BG_CHECK_CALCULATE_STATUS', bgCheckCalulateStatusCancelHelper);
  yield takeLatest('BILLING_JOB_FETCH_ORDERS_COUNT', fetchBillingDetailsOrderCount);
  yield takeLatest('BILLING_JOB_FETCH_MANUAL_CHARGES', fetchManualCharges);
  yield takeLatest('BILLING_JOB_ADD_MANUAL_CHARGE', saveManualChargeToInvoice);
  yield takeLatest('BILLING_JOB_DELETE_MANUAL_CHARGE', deleteManualChargeInInvoice);
  yield takeLatest('BILLING_JOB_DELETE_GENERATED_CHARGE', deleteGeneratedChargeInInvoice);
  yield takeLatest('BILLING_JOB_FETCH_TOTAL', fetchJobTotal);
  yield takeLatest('BILLING_JOB_INVOICE_STATUS', fetchJobInvoiceStatus);
}
