const defaultState = {
  legs: {},
  upload: {
    inProgress: false,
    batchId: null,
    successMessage: null,
    errorMessage: null,
  },
  containers: {
    inProgress: false,
    data: [],
  },
  pallets: {
    inProgress: false,
    data: [],
  },
  scannedItems: {},
  scannedItemFetchInProgress: false,
  selectAllByFilters: {
    selected: false,
    inProgress: false,
    fetched: 0,
    total: 0,
  },
  eta: {},
  etaFromSocket: {}, // key is task_id, value is { eta, eta_updated_at, id }
  markersPosition: {},
  aggregatedData: {
    total: null,
    selectedTasks: null,
    selectedWorkers: null,
  },
  loadedItemTotal: 0,
  loadedTasksTotal: 0,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  RESET_LEGS: (state) => {
    // reset legs for next assignment
    return {
      ...state,
      legs: {},
    };
  },
  LEGS_FETCH_STARTED: (state, { orderItemIds }) => {
    return {
      ...state,
      legs: {
        ...state.legs,
        ...orderItemIds.reduce((result, value) => {
          result[value] = {
            tasks: null,
            inProgress: true,
          };
          return result;
        }, {}),
      },
    };
  },
  LEGS_FETCH_DONE: (state, { groupedTasks }) => {
    return {
      ...state,
      legs: {
        ...state.legs,
        ...Object.keys(groupedTasks).reduce((result, value) => {
          result[value] = {
            tasks: groupedTasks[value],
            inProgress: false,
          };
          return result;
        }, {}),
      },
    };
  },
  UPLOAD_TASKS_STARTED: (state) => {
    return { ...state, upload: { ...state.upload, inProgress: true, success: false } };
  },
  UPLOAD_TASKS_BATCH_ID_RECEIVED: (state, { data: { id } }) => {
    return { ...state, upload: { ...state.upload, batchId: id } };
  },
  UPLOAD_TASKS_SUCCESS: (state) => {
    return { ...state, upload: { ...state.upload, successMessage: 'Tasks successfully uploaded' } };
  },
  UPLOAD_TASKS_FAILED: (state) => {
    return { ...state, upload: { ...state.upload, errorMessage: 'Tasks upload failed' } };
  },
  UPLOAD_TASKS_FINISHED: (state) => {
    return { ...state, upload: { ...state.upload, inProgress: false } };
  },
  CLEAR_TASKS_UPLOAD_MESSAGE: (state) => {
    return { ...state, upload: { ...state.upload, successMessage: null, errorMessage: null } };
  },
  CONTAINERS_FETCH_STARTED: (state) => {
    return { ...state, containers: { ...state.containers, inProgress: true } };
  },
  CONTAINERS_FETCH_DONE: (state, { data }) => {
    return { ...state, containers: { data, inProgress: false } };
  },
  PALLETS_FETCH_STARTED: (state) => {
    return { ...state, pallets: { ...state.containers, inProgress: true } };
  },
  PALLETS_FETCH_DONE: (state, { data }) => {
    return { ...state, pallets: { data, inProgress: false } };
  },
  SCANNED_ITEM_FETCH_STARTED: (state, { externalId }) => {
    return {
      ...state,
      scannedItemFetchInProgress: true,
      scannedItems: { ...state.scannedItems, [externalId]: { inProgress: true } },
    };
  },
  SCANNED_ITEM_FETCH_SUCCESSFUL: (state, { externalId, data }) => {
    return {
      ...state,
      scannedItemFetchInProgress: false,
      scannedItems: { ...state.scannedItems, [externalId]: { inProgress: false, data } },
    };
  },
  SCANNED_ITEM_NOT_FOUND: (state, { externalId }) => {
    return {
      ...state,
      scannedItemFetchInProgress: false,
      scannedItems: { ...state.scannedItems, [externalId]: { inProgress: false, notFound: true } },
    };
  },
  SCANNED_ITEM_FETCH_FAILED: (state, { externalId }) => {
    const scannedItems = state.scannedItems;
    delete scannedItems[externalId];
    return { ...state, scannedItemFetchInProgress: false, scannedItems };
  },
  REFRESH_ALL_INIT: (state) => {
    return { ...state, legs: defaultState.legs };
  },
  UPDATE_SELECT_ALL_DATA: (state, { data }) => {
    return { ...state, selectAllByFilters: { ...state.selectAllByFilters, ...data } };
  },
  APPLY_MASTER_FILTER: (state) => {
    return { ...state, selectAllByFilters: defaultState.selectAllByFilters };
  },
  MISSING_INFO_FIX_IN_PROGRESS: (state) => {
    return { ...state, isMissingInfoFixInProgress: true };
  },
  MISSING_INFO_FIX_IN_COMPLETED: (state) => {
    return { ...state, isMissingInfoFixInProgress: false };
  },
  FETCH_ETA_SUCCESSFUL: (state, { data }) => {
    const eta = { ...state.eta };
    data &&
      data.forEach((etaData) => {
        eta[etaData.root_order_item_step_id] = etaData;
      });
    return { ...state, eta };
  },
  TASK_ETA_UPDATE: (state, { etaTaskData }) => {
    const newEtaFromSocket = { ...state.etaFromSocket };
    Object.keys(etaTaskData).forEach((taskId) => {
      newEtaFromSocket[taskId] = etaTaskData[taskId];
    });

    return {
      ...state,
      etaFromSocket: newEtaFromSocket,
    };
  },
  UPDATE_MARKER_POSITION: (state, { data }) => {
    return {
      ...state,
      markersPosition: { ...data },
    };
  },
  ASSIGNMENT_SUCCEEDED: (state) => {
    return { ...state, eta: {} };
  },
  SET_AGGREGATED_DATA: (state, { aggregatedData, dataType }) => {
    return { ...state, aggregatedData: { ...state.aggregatedData, [dataType]: aggregatedData } };
  },
  RESET_AGGREGATED_DATA: (state, { dataType }) => {
    return { ...state, aggregatedData: { ...state.aggregatedData, [dataType]: defaultState.aggregatedData[dataType] } };
  },
  SET_LOADED_TOTAL: (state, { loadedItemTotal, loadedTasksTotal }) => {
    return {
      ...state,
      loadedItemTotal: loadedItemTotal,
      loadedTasksTotal: loadedTasksTotal,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
