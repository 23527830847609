const defaultState = {
  loaded: false,
  data: [],
  pagination: null,
  selectedTeams: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  FETCH_ALL_TEAM_REQUEST_SUCCESS: (state, data) => {
    return {
      ...state,
      ...data.data,
      loaded: true,
    };
  },
  UPDATE_SELECTED_TEAM: (state, { newSelectedTeams }) => {
    return {
      ...state,
      selectedTeams: newSelectedTeams,
    };
  },
  TOGGLE_TEAM_SELECTION: (state) => {
    return {
      ...state,
      selectedTeams: [],
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
