import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';
import AuthSelectors from '@yojee/auth/store/selectors';
import Utilities from '@yojee/helpers/utilities';

import {
  fetchDownStreamPartnerJWT as _fetchDownStreamPartnerJWT,
  fetchDownStreamPartnersList,
} from '../../Api/ordersApi';

const getPartnerSearchText = (state) => state.partnerList && state.partnerList.searchText;
function* fetchDownStreamPartner(payload = {}) {
  const { includeInactiveCompany, state } = payload;

  try {
    yield put({ type: 'FETCH_DOWNSTREAM_PARTNER_STARTED' });
    const searchText = yield select(getPartnerSearchText);
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const { data: partners } = yield call(fetchDownStreamPartnersList, {
      searchText,
      token,
      partnerJwt,
      slug,
      state,
      includeInactiveCompany,
    });
    yield put({ type: 'FETCH_DOWNSTREAM_PARTNER_SUCCESS', partners: partners });
  } catch (err) {
    yield put({ type: 'DISPLAY_ERROR_MESSAGE', message: err.message });
  }
}

function* fetchDownStreamPartnerJWT({ cip }) {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { downstream_company_slug, jwt },
    } = yield call(_fetchDownStreamPartnerJWT, { cip, slug });
    window.open(
      `${Utilities.getDispatcherUrlBySlug(
        slug,
        downstream_company_slug
      )}/partner/${cip}?partner_jwt=${jwt}&mainFilter=ALL`,
      '_blank'
    );
  } catch (err) {
    yield put({ type: 'DISPLAY_ERROR_MESSAGE', message: err.message });
  }
}

function* getPartnerDashboardUrl(action) {
  try {
    const companyId = action.data;
    yield put({ type: 'START_LOADING' });

    let type = 'overview_for_partners';
    if (companyId) {
      type = 'overview_for_partner';
    }

    const url = yield call(companyService.getDashboardUrl, {
      type: type,
      report_params: {
        downstream_partner_id: companyId,
      },
    });
    yield put({ type: 'GET_PARTNER_DASHBOARD_URL_SUCCESSFUL', url });
  } catch (err) {
    yield put({ type: 'DISPLAY_ERROR_MESSAGE', message: err.message });
  }
}

function* searchDownStreamPartner({ searchText, includeInactiveCompany }) {
  yield put({ type: 'UPDATE_DOWNSTREAM_PARTNER_SEARCH_TEXT', searchText });
  yield fetchDownStreamPartner({ includeInactiveCompany });
}

export default function* sagas() {
  try {
    yield takeLatest('FETCH_DOWNSTREAM_PARTNER', fetchDownStreamPartner);
    yield debounce(1000, 'SEARCH_DOWNSTREAM_PARTNER', searchDownStreamPartner);
    yield takeLatest('FETCH_DOWNSTREAM_PARTNER_JWT', fetchDownStreamPartnerJWT);
    yield takeLatest('GET_PARTNER_DASHBOARD_URL', getPartnerDashboardUrl);
  } catch (err) {
    yield put({ type: 'DISPLAY_ERROR_MESSAGE', message: err.message });
  }
}
