const defaultState = {
  message: null,
  variant: null,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  DISPLAY_MESSAGE: (state, { message, variant }) => {
    return { ...state, message, variant };
  },
  REMOVE_MESSAGE: () => {
    return defaultState;
  },
  ASSIGNMENT_FAILED: (state, { error }) => {
    if (error.response?.data) {
      const respData = error.response.data;
      const errorData = respData.data || {};
      const reason =
        respData.message || errorData[Object.keys(errorData)[0]] || 'Internal server error. Please try again';

      return { ...state, message: 'Assignment failed: ' + reason, variant: 'error' };
    }

    return { ...state, message: 'Assignment failed: ' + error, variant: 'error' };
  },
  ASSIGNMENT_SUCCEEDED: (state, { unassignment }) => {
    return {
      ...state,
      message: unassignment ? 'Unassignment succesful' : 'Assignment successful',
      variant: 'success',
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
