import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';

import { Item } from './types';

const DEBOUNCE_SEARCH_TIME = 250;

type SearchProps = {
  setSearchedItems: React.Dispatch<React.SetStateAction<Item[] | null>>;
  items: Item[];
  keySearch: string;
};

type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

type Props = {
  leftItems: Item[];
  rightItems: Item[];
};

function getKeySearchRegex(keySearch: string) {
  return new RegExp(`${keySearch}`, 'i');
}

function isMatchKeySearch(keySearch: string) {
  return (item: Item) => item.label.search(getKeySearchRegex(keySearch)) > -1;
}

function onSearch({ setSearchedItems, items, keySearch = '' }: SearchProps): void {
  if (!keySearch.trim()) return setSearchedItems(null);

  setSearchedItems(items.filter(isMatchKeySearch(keySearch)));
}

const onSearchWithDebounceTime = debounce(onSearch, DEBOUNCE_SEARCH_TIME);

export default function useSearchTransferList({ leftItems, rightItems }: Props) {
  const [leftKeySearch, setLeftKeySearch] = useState('');
  const [rightKeySearch, setRightKeySearch] = useState('');
  const [leftSearchedItems, setLeftSearchedItems] = useState<Item[] | null>(null);
  const [rightSearchedItems, setRightSearchedItems] = useState<Item[] | null>(null);

  const leftSearchProps: SearchProps = {
    setSearchedItems: setLeftSearchedItems,
    items: leftItems,
    keySearch: leftKeySearch,
  };

  const rightSearchProps: SearchProps = {
    setSearchedItems: setRightSearchedItems,
    items: rightItems,
    keySearch: rightKeySearch,
  };

  const onLeftSearchChange = ({ target: { value: keySearch } }: ChangeEvent) => {
    setLeftKeySearch(keySearch);
    onSearchWithDebounceTime({ ...leftSearchProps, keySearch });
  };

  const onRightSearchChange = ({ target: { value: keySearch } }: ChangeEvent) => {
    setRightKeySearch(keySearch);
    onSearchWithDebounceTime({ ...rightSearchProps, keySearch });
  };

  useEffect(() => {
    onSearch(leftSearchProps);
    onSearch(rightSearchProps);
  }, [rightItems.length, leftItems.length]);

  return {
    leftKeySearch,
    rightKeySearch,
    leftSearchedItems,
    rightSearchedItems,
    setLeftSearchedItems,
    setRightSearchedItems,
    onLeftSearchChange,
    onRightSearchChange,
  };
}
