import { PartnerWorker } from '@yojee/types';

export const getPartnerWorkers = () => {
  return { type: 'GET_PARTNER_WORKERS' };
};

export const getPartnerWorkerTasks = (worker: PartnerWorker) => {
  return { type: 'GET_PARTNER_WORKER_TASKS', worker };
};

export const setSelectedPartnerWorker = (worker: PartnerWorker | null) => {
  return { type: 'SET_SELECTED_PARTNER_WORKER', worker };
};
