import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class WithdrawAPI extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  transferByTrackingNumbers = ({ orderNumbers }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/sender/bulk_withdraw_order'), {
        order_numbers: orderNumbers,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkProcessWithdraw = ({ processId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/partner_transfer/sender/bulk_withdraw_order/${processId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

const withdrawAPI = new WithdrawAPI({ authService });
export default withdrawAPI;
