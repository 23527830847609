export function startOptimisation() {
  return { type: 'START_OPTIMISATION_FLOW' };
}

export function moveToNextRoutePlanningStep(id) {
  return { type: 'MOVE_TO_NEXT_ROUTE_PLANNING_STEP', id };
}

export function updateSettings(params) {
  return { type: 'UPDATE_PLANNER_SETTINGS', params };
}

export function setTransformedValue(params) {
  return { type: 'SET_TRANSFORMED_VALUE', params };
}

export function setSubmenu(params) {
  return { type: 'SET_SUBMENU', params };
}

export function updateBulkTasks(params) {
  return { type: 'UPDATE_BULK_TASKS', params };
}

export function updateVariableServiceTimeByQuantityPerTask(params) {
  return { type: 'UPDATE_VARIABLE_SERVICE_TIME_BY_QUANTITY_PER_TASKS', params };
}

export function updateBulkWorkers(params) {
  return { type: 'UPDATE_BULK_WORKERS', params };
}

export function planRoute(params) {
  return { type: 'PLAN_ROUTE', params };
}

export function exploreRoute(params) {
  return { type: 'PLAN_ROUTE', params: { ...params, explore: true } };
}

export function updateAddressUpdater(params) {
  return { type: 'SET_ADDRESS_UPDATER', params };
}

export function toggleSelectedWorker(params) {
  return { type: 'TOGGLE_SELECTED_WORKER', params };
}

export function toggleSelectedTask(params) {
  return { type: 'TOGGLE_SELECTED_TASK', params };
}

export function selectAllWorkers(params) {
  return { type: 'TOGGLE_SELECT_ALL_WORKERS', params };
}

export function selectAllTasks(params) {
  return { type: 'TOGGLE_SELECT_ALL_TASKS', params };
}

export function hideConfirmationDialog(params) {
  return { type: 'HIDE_CONFIRMATION_DIALOG', params };
}

export const sendToDriver = (params) => {
  return { type: 'SEND_TASK_TO_DRIVER', params };
};

export const startManualPlan = (params) => {
  return { type: 'START_MANUAL_PLAN', params };
};

export const toggleSelectedTaskSortableGrid = (params) => {
  return { type: 'TOGGLE_SELECTED_TASK_IN_SORTABLE_GRID', params };
};

export const selectTaskSortableGrid = (params) => {
  return { type: 'SELECT_TASK_IN_SORTABLE_GRID', params };
};

export const showSortableGrid = (params) => {
  return { type: 'SHOW_SORTABLE_GRID', params };
};

export const hideSortableGrid = (params) => {
  return { type: 'HIDE_SORTABLE_GRID', params };
};

export const resetSelectedTasksInSortableGrid = (params) => {
  return { type: 'RESET_SELECTED_TASKS_IN_SORTABLE_GRID', params };
};

export const showUnSequencedTasksDialog = (params) => {
  return { type: 'SHOW_UN_SEQUENCED_TASKS_DIALOG', params };
};

export const hideUnSequencedTasksDialog = (params) => {
  return { type: 'HIDE_UN_SEQUENCED_TASKS_DIALOG', params };
};

export const recalculateETA = (params) => {
  return { type: 'RECALCULATE_ETA', params };
};
