import { authService } from '@yojee/api/authService';

import { BaseService } from './baseService/index';

export class BatchAssignmentService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  downloadSample = (format) => {
    return this.authService.get(
      this.getUmbrellaApiUrlV4('company/delivery_execution/batch_assignment/file'),
      { format: format },
      {
        responseType: 'blob',
      }
    );
  };

  uploadBatchAssignment = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.authService.post(
      this.getUmbrellaApiUrlV4('company/delivery_execution/batch_assignment/file'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  getBatchAssignmentStatus = (id) => {
    return this.authService.get(
      this.getUmbrellaApiUrlV4(`company/delivery_execution/batch_assignment/file/${id}/status`)
    );
  };
}

export const batchAssignmentService = new BatchAssignmentService({ authService });
