import { tasksService } from '@yojee/api/tasksService';

export const getLastTask = (tasks) => {
  return tasks.reduce((lastTask, task) => {
    if (
      task.order_item.id < lastTask.order_item.id ||
      (task.order_item.id === lastTask.order_item.id && task.step_sequence > lastTask.step_sequence)
    ) {
      lastTask = task;
    }
    return lastTask;
  }, tasks[0]);
};

export const fetchAssignedTasks = async (selectedWorkers) => {
  const PAGE_SIZE = 500;
  const params = {
    worker_ids: selectedWorkers,
    cancelled: 'false',
    include_transfer_properties: false,
    task_group_states: ['assigned'],
    task_states: ['created'],
    accepted: true,
    task_query_type: 'assigned',
    query_version: 2,
    limit: PAGE_SIZE,
    include_count: true,
  };
  const {
    data: { data, count },
  } = await tasksService.fetchTasksPost(params);
  let workerTasks = data;
  if (workerTasks.length < count) {
    let lastTask = getLastTask(workerTasks);
    let page = 2;
    while (page <= Math.ceil(count / PAGE_SIZE)) {
      const {
        data: { data: fetchedWorkerTasks },
      } = await tasksService.fetchTasksPost({
        ...params,
        order_id_before: lastTask.order_item.id,
        step_sequence_after: lastTask.step_sequence,
        include_count: false,
      });
      workerTasks = workerTasks.concat(fetchedWorkerTasks);
      page++;
      lastTask = getLastTask(fetchedWorkerTasks);
    }
  }

  return workerTasks;
};
