const defaultState = {
  isLoading: false,
  options: [],
  errorMessage: undefined,
  searchKey: '',
  selectedOptions: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  CLEAR_MASTER_FILTER: () => {
    return defaultState;
  },
  MASTER_SEARCH_START: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  CLEAR_SEARCH_TEXT: () => defaultState,
  MASTER_SEARCH_SUCCESS: (state, { options }) => {
    return {
      ...state,
      isLoading: false,
      options: options,
    };
  },
  MASTER_SEARCH_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
