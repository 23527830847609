import { put, takeLatest } from 'redux-saga/effects';
export default function* sagas() {
  yield takeLatest('MASTER_SEARCH', masterSearch);
}

function* masterSearch({ searchText }) {
  try {
    yield put({ type: 'MASTER_SEARCH_START', searchKey: searchText });
    yield put({ type: 'SEARCH_DRIVER', searchKey: searchText });
    yield put({ type: 'SEARCH_PARTNER', searchKey: searchText });
    yield put({ type: 'SEARCH_ADDRESS_BOOK', searchKey: searchText });
    yield put({ type: 'SEARCH_ID', searchKey: searchText });
  } catch (err) {
    yield put({ type: 'MASTER_SEARCH_FAILED', error: err });
  }
}
