import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import { tasksService } from '@yojee/api/tasksService';
import AuthSelectors from '@yojee/auth/store/selectors';

export const getIdReportProcess = (state) => state.reportTask && state.reportTask.processId;
export const getTaskData = (state) => state.planner && state.planner.taskData;

export default function* sagas() {
  yield takeLatest('PROCESS_REPORT_TASKS', handleReportTasks);
  yield takeLatest('FETCH_TASK_EXCEPTION_REASONS', handleFetchTaskExceptionReasons);
  yield debounce(1000, 'CHECK_ID_PROCESS_REPORT_TASKS', checkIdProcessReportTasks);
}

function* handleFetchTaskExceptionReasons({ payload: { types } }) {
  try {
    yield put({
      type: 'REPORT_TASK_PROGRESS',
      key: 'getTaskExceptionReasons',
    });

    const { partnerJwt } = yield select(AuthSelectors.getData);
    const result = yield call(tasksService.getTaskExceptionsReason, { types, partnerJwt });
    yield put({
      type: 'FETCH_TASK_EXCEPTION_REASONS_SUCCESS',
      reasons: result.data && result.data.data,
    });
  } catch (err) {
    yield put({
      type: 'FETCH_TASK_EXCEPTION_REASONS_ERROR',
      error: err.message,
    });
  }
}

function* checkIdProcessReportTasks() {
  try {
    const processId = yield select(getIdReportProcess);
    if (!processId) {
      return;
    }
    const result = yield call(tasksService.checkReportBulkTasksStatus, { processId: processId });
    if (result.data && result.data.status === 'completed') {
      yield put({
        type: 'PROCESS_REPORT_TASKS_SUCCESS',
        message: 'Selected items are successfully reported',
      });
      return;
    }
    if (result.data && (result.data.status === 'failed' || result.data.status === 'fail')) {
      yield put({
        type: 'PROCESS_REPORT_TASKS_ERROR',
        error: JSON.stringify(result.data),
      });
      return;
    }
    if (result.data && result.data.status === 'processing') {
      yield put({ type: 'CHECK_ID_PROCESS_REPORT_TASKS' });
    }
  } catch (err) {
    yield put({
      type: 'PROCESS_REPORT_TASKS_ERROR',
      error: err.message,
    });
  }
}

function* handleReportTasks({ tasks, reason }) {
  try {
    const { data } = yield select(getTaskData);

    const result = yield call(tasksService.reportMultipleTask, {
      tasks: tasks.map((id) => {
        return { task_id: id, order_item_id: data[id].order_item_id, step_group: data[id].step_group };
      }),
      reason,
    });
    if (result.batch_id) {
      yield put({ type: 'UPDATE_ID_PROCESS_REPORT_TASKS', processId: result.batch_id });
      yield put({ type: 'CHECK_ID_PROCESS_REPORT_TASKS' });
    }
  } catch (err) {
    yield put({
      type: 'PROCESS_REPORT_TASKS_ERROR',
      error: err.message,
    });
  }
}
