import esb from 'elastic-builder';
import _ from 'lodash-es';
import moment from 'moment';

import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS, PAIR_TASK_TYPE_POST_FIX, SENDER_TYPES } from '@yojee/helpers/constants';
import { getTimeFrameFromTimeFrameKey, TIME_FRAME_KEY_CUSTOM } from '@yojee/helpers/timeFrame';
import Utilities from '@yojee/helpers/utilities';

/*
Search basically has three kind of fields

- match  (Full text search )
    https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-match-query.html
- term ( Exact match)
    https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-term-query.html
- range (between)
    https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-range-query.html

*/

// Master State keys
const termKeysMap = {
  driverIds: 'task_group.worker_id',
  partnerCompanyIds: 'transfer_info_metadata.partner_company_id',
  addressItemIds: 'order_step.address_item_id',
  driverIdsQueryByTags: 'task_group.worker_id',
  order_item_ids: 'order_item_id',
  senderIds: 'sender.id',
  hubs: 'service_by_hub_id',
  serviceTypeIds: 'order_item.service_type_id',
  itemTypeIds: 'item.payload_type.keyword',
};
const matchKeysMap = {};
const rangeKeys = ['volume', 'length', 'width', 'height', 'weight', 'quantity'];
const idsFilterMap = {
  order_id: 'order.number.keyword',
  order_group_number: 'order.order_group.number.keyword',
  item_id: 'item.id',
  external_id: 'order_item.external_customer_id.keyword',
  external_id2: 'order_item.external_customer_id2.keyword',
  external_id3: 'order_item.external_customer_id3.keyword',
  description: 'item.description.keyword',
  contact_name: 'order_step.contact_name.keyword',
  order_external_id: 'order.external_id.keyword',
  external_carrier_reference: 'order.external_carrier_references.value.keyword',
  item_container_no: 'item.item_container.container_no.keyword',
  vessel_imo: 'voyage_info.vessel_imo.keyword',
  voyage_number: 'voyage_info.voyage_number.keyword',
  arrival_port_code: 'voyage_info.arrival_port_code.keyword',
  departure_port_code: 'voyage_info.departure_port_code.keyword',
  shipper_name: 'shipper.organisation_name.keyword',
  order_custom_field_1: 'order.custom_field_1.keyword',
  order_custom_field_2: 'order.custom_field_2.keyword',
  order_custom_field_3: 'order.custom_field_3.keyword',
  order_custom_field_4: 'order.custom_field_4.keyword',
};

const exactMatchFields = [
  'order.number.keyword',
  'order.order_group.number.keyword',
  'order_item.external_customer_id.keyword',
  'order_item.external_customer_id2.keyword',
  'order_item.external_customer_id3.keyword',
  'order.external_id.keyword',
  'order.external_carrier_references.value.keyword',
  'item.item_container.container_no.keyword',
  'order.custom_field_1.keyword',
  'order.custom_field_2.keyword',
  'order.custom_field_3.keyword',
  'order.custom_field_4.keyword',
];

const trackingNumberFilterMap = {
  tracking_number: 'order_item.tracking_number.keyword',
  order_item_tracking_number: 'order_item.tracking_number.keyword',
  order_number: 'order.number.keyword',
  global_tracking_number: 'item.global_tracking_number.keyword',
};

const SENDER_FIELDS = ['sender.name', 'sender.organisation_name', 'order_step.contact_name'];

const VESSEL_FIELDS = [
  'voyage_info.vessel_imo',
  'voyage_info.voyage_number',
  'voyage_info.arrival_port_code',
  'voyage_info.departure_port_code',
];

const SHIPPER_FIELDS = ['shipper.organisation_name'];

// Full Text Search Fields
const FULL_TEXT_SEARCH_FIELDS = [
  // ADDRESS
  'order_step.address',
  'order_step.address2',
  'order_step.contact_company',
  'order_step.contact_name',
  'order_step.contact_phone',
  'order_step.postal_code',

  //ORDER
  'order.sender_organisation_name',
  'order.sender_name',
  'order.number',
  'order.external_id',
  'order.container_no',
  'order.order_group.number',
  'order.external_carrier_references.value',

  //Item
  'item.description',
  'item.global_tracking_number',
  'order_item.tracking_number',
  'order_item.info',
  'order_item.external_customer_id3',
  'order_item.external_customer_id2',
  'order_item.external_customer_id',
  'item.item_container.container_no',

  ...SENDER_FIELDS,
  ...VESSEL_FIELDS,
  ...SHIPPER_FIELDS,
  // WORKER
  // 'worker.name', //Not currently avaiable
];

const BOOSTED_FIELDS = [
  'order.number.keyword',
  'item.global_tracking_number.keyword',
  'order_item.tracking_number.keyword',
];

export const transformSuggestionSearch = ({ data }, searchValues = []) => {
  const {
    hits: { hits },
  } = data;
  searchValues = searchValues.map((v) => v.toLowerCase());

  return [].concat(
    ...hits.map((r) => {
      if (!r.highlight) {
        const exactMatchedValue = (Array.isArray(searchValues) ? searchValues : []).find((value) =>
          FULL_TEXT_SEARCH_FIELDS.find((key) => {
            const valueByKey = getValue(r._source, key);
            return valueByKey && valueByKey.toLowerCase() === value.toLowerCase();
          })
        );

        if (exactMatchedValue) {
          const task = r._source;
          const location = task.location;
          const keys = FULL_TEXT_SEARCH_FIELDS.filter((k) => {
            const valueByKey = getValue(r._source, k);
            return valueByKey && searchValues.includes(valueByKey.toLowerCase());
          });
          const data = location ? { ...task, location: { lat: location.lat, lng: location.lon } } : task;
          return keys.map((key) => {
            return {
              data,
              key,
              highlightValue: getValue(task, key),
              type: key,
            };
          });
        }

        return [];
      }

      return Object.keys(r.highlight).map((key) => {
        const highlightValue = r.highlight[key];
        const task = r._source;
        const location = task.location;

        return {
          data: location ? { ...task, location: { lat: location.lat, lng: location.lon } } : task,
          key,
          highlightValue,
          type: key,
        };
      });
    })
  );
};

export const transformResults = ({ data } = {}, aggregationField = '') => {
  const { hits = {} } = data || {};
  const total = hits.total?.value || 0;
  const totalType = hits.total?.relation;
  const results = hits.hits || [];

  if (aggregationField) {
    const { aggregations } = data || {};
    return {
      count: aggregations?.[aggregationField]?.buckets?.length || 0,
      data: results.map((r) => r._source),
    };
  }
  return {
    count: total,
    countType: totalType,
    data: results
      .map((r) => r._source)
      .map((t) => {
        const location = t.location;
        return location ? { ...t, location: { lat: location.lat, lng: location.lon } } : t;
      }),
  };
};

const defaultParams = { from: 0, size: 50, sort: { by: ['inserted_at'], order: 'asc', ignoreMasterStateSort: false } };

const invalidTasksFilter = (queries) => {
  return queries.push(
    esb.boolQuery().must([
      esb
        .boolQuery()
        .should([
          esb.boolQuery().must(esb.existsQuery('task.cancelled_time')),
          esb.boolQuery().mustNot(esb.termQuery('task.state', 'invalidated')),
          esb
            .boolQuery()
            .must([
              esb.existsQuery('transfer_requested_time'),
              esb.termQuery('transfer_state', 'pending'),
              esb.boolQuery().mustNot(esb.termQuery('task.state', 'invalidated')),
            ]),
          esb
            .boolQuery()
            .must([esb.existsQuery('transfer_requested_time'), esb.termQuery('transfer_state', 'accepted')]),
        ])
        .minimumShouldMatch(1),
      esb
        .boolQuery()
        .should([
          esb.boolQuery().mustNot(esb.termQuery('task.state', 'created')),
          esb.boolQuery().mustNot(esb.termQuery('order_item.state', 'cancelled')),
        ]),
    ])
  );
};

const mapVesselFilter = (queries, filters, masterFilterStates) => {
  const vesselValue = masterFilterStates.vesselValue;
  if (!vesselValue) return;

  const vesselIdKey = masterFilterStates.vesselIdKey;

  if (vesselIdKey && idsFilterMap[vesselIdKey]) {
    if (!Array.isArray(vesselValue)) {
      queries.push(esb.termQuery(idsFilterMap[vesselIdKey], vesselValue));
    } else if (vesselValue.length > 0) {
      filters.push(esb.termsQuery(idsFilterMap[vesselIdKey], vesselValue));
    }
  } else {
    const queryStr = Array.isArray(vesselValue) ? vesselValue.filter((v) => !!v).join(', ') : vesselValue;
    if (!queryStr) return;

    const query = esb
      .multiMatchQuery(VESSEL_FIELDS, queryStr)
      .type('best_fields')
      .tieBreaker(0.3)
      .minimumShouldMatch('30%');
    queries.push(query);
  }
};

const mapShipperFilter = (queries, filters, masterFilterStates) => {
  const shipper = masterFilterStates.shipper;
  if (!shipper) return;

  const shipperIdKey = masterFilterStates.shipperIdKey;

  if (shipperIdKey && idsFilterMap[shipperIdKey]) {
    if (!Array.isArray(shipper)) {
      queries.push(esb.termQuery(idsFilterMap[shipperIdKey], shipper));
    } else if (shipper.length > 0) {
      filters.push(esb.termsQuery(idsFilterMap[shipperIdKey], shipper));
    }
  } else {
    const queryStr = Array.isArray(shipper) ? shipper.filter((v) => !!v).join(', ') : shipper;
    if (!queryStr) return;

    const query = esb.multiMatchQuery(SHIPPER_FIELDS, queryStr).type('phrase_prefix');
    queries.push(query);
  }
};

export const getPartnerWorkerTasksQueryPayload = (companyId, workerId) => {
  const qry = esb
    .boolQuery()
    .must([
      esb.termQuery('company_id', companyId),
      esb.matchQuery('transfer_task_metadata.task_group_info.worker_id', workerId),
      esb.existsQuery('transfer_task_metadata.task_group_info.accepted_time'),
      esb.matchQuery('transfer_task_metadata.task_info.state', 'created'),
    ]);

  const query = esb.requestBodySearch().query(qry).size(100).from(0);

  return query.toJSON();
};

export const mapFilters = ({
  companyId,
  masterState,
  taskState,
  searchState,
  regions,
  params = {},
  serviceTypes = [],
}) => {
  if (!companyId) throw new Error('Company ID is mandatory');

  params = { ...defaultParams, ...params };
  if (masterState?.sortCriteria && !params.sort.ignoreMasterStateSort) {
    if (masterState.sortCriteria.direction) {
      params.sort.order = masterState.sortCriteria.direction;
    }
    if (masterState.sortCriteria.sortBy) {
      params.sort.by = masterState.sortCriteria.sortBy;
    }

    params.sort.by = masterState.sortCriteria.sortBy.map((by) => {
      if (by === 'commit_at') {
        return params.sort.order === 'asc' ? 'to' : 'from';
      } else if (by === 'commit_at_from') {
        return 'from';
      } else if (by === 'commit_at_to') {
        return 'to';
      }

      return by;
    });
  }
  const queries = [];
  const filterTerms = [];
  const currentFilter = masterState.filter;

  mapTaskState(queries, taskState, currentFilter);
  mapTaskConditions(queries, currentFilter);
  mapTaskTypes(queries, currentFilter);
  mapLegTypesFilter(queries, currentFilter);
  mapDateFilters(queries, masterState);
  mapSearchFilters(queries, filterTerms, searchState);
  mapIdFilters(queries, filterTerms, currentFilter);
  mapMasterFilters(queries, filterTerms, currentFilter, serviceTypes);
  mapGeoFilters(queries, currentFilter);
  mapGeoPolygons(queries, currentFilter, regions);
  invalidTasksFilter(queries);
  mapVesselFilter(queries, filterTerms, currentFilter);
  mapShipperFilter(queries, filterTerms, currentFilter);

  let qry = esb.boolQuery().must(esb.termQuery('company_id', companyId)); // Add company filter

  if ((currentFilter.reportInfoWithReason?.length ?? 0) < 1) {
    qry = qry.mustNot(esb.termQuery('task.state', 'failed'));
  }
  qry = qry.must(queries);

  if (filterTerms.length > 0) {
    qry.filter(filterTerms);
  }

  if (params.maxId) {
    qry.must(esb.rangeQuery('id').lt(params.maxId));
  }

  const query = esb
    .requestBodySearch()
    .query(qry)
    .size(params.size > 10000 ? 10000 : params.size)
    .sorts(
      currentFilter.driverIds?.length > 0
        ? [esb.sort('task_group.worker_id', params.sort.order), esb.sort('task.position', params.sort.order)]
        : [
            ...params.sort.by.map((by) => esb.sort(by, params.sort.order)),
            esb.sort('order_item_id', params.sort.order),
            esb.sort('step_sequence', params.sort.order),
          ]
    )
    .from(params.from);

  if (params.relatedLegs) {
    const sortAggregations = params.sort.by.map((by) =>
      params.sort.order.toUpperCase() === 'DESC'
        ? esb.maxAggregation('sort_by_' + by.replace('.', '_'), by).missing('0')
        : esb.minAggregation('sort_by_' + by.replace('.', '_'), by).missing('0')
    );
    const bucketsSortSorts = params.sort.by.map((by) => esb.sort('sort_by_' + by.replace('.', '_'), params.sort.order));
    query
      .aggs([
        esb
          .termsAggregation('order_item_id', 'order_item_id')
          .size(params.from + params.size > 10000 ? 10000 : params.from + params.size)
          .order('sort_by_' + params.sort.by[0].replace('.', '_'), params.sort.order)
          .aggs([
            ...sortAggregations,
            esb.bucketSortAggregation('bucket_sort').from(params.from).sort(bucketsSortSorts),
          ]),
        esb
          .termsAggregation('count_aggs')
          .script(esb.script('inline', "doc['order_item_id'].value + '#' + doc['step_group'].value"))
          .size(50000)
          .aggs([esb.maxAggregation('size', 'step_group_size')]),
        esb.sumBucketAggregation('order_item_id_count', 'count_aggs>size'),
        esb.valueCountAggregation('order_item_step_id_count', 'order_item_step_id'),
      ])
      .from(params.from)
      .size(params.size > 10000 ? 10000 : params.size);
  }

  return query.toJSON();
};

export const getSendersSearchQuery = (companyId, searchText, size = 10) => {
  const query = esb
    .boolQuery()
    .must(esb.termQuery('company_id', companyId))
    .mustNot(esb.termQuery('task.state', 'invalidated'))
    .should([
      esb
        .boolQuery()
        .should([
          esb.multiMatchQuery(['sender.organisation_name'], searchText).type('phrase_prefix'),
          esb.termQuery('sender.type', SENDER_TYPES.organisation),
        ]),
      esb.multiMatchQuery(['order_step.contact_name'], searchText).type('phrase_prefix'),
    ]);

  return esb
    .requestBodySearch()
    .query(query)
    .size(size > 10000 ? 10000 : size)
    .highlight(esb.highlight(SENDER_FIELDS).scoreOrder())
    .toJSON();
};

export const getSequences = ({ companyId, afterKey, limit, workerIds }) => {
  const queries = [];
  invalidTasksFilter(queries);
  mapTaskState(queries, 'assigned');

  const compositionAggregation = esb
    .compositeAggregation('stops_list')
    .sources(
      esb.CompositeAggregation.termsValuesSource('sequence_id', 'task.sequence_id'),
      esb.CompositeAggregation.termsValuesSource('eta', 'task.eta').missingBucket(true),
      esb.CompositeAggregation.termsValuesSource('type', 'type.keyword')
    )
    .size(limit || 500)
    .aggregations([
      esb
        .termsAggregation('tasks_data')
        .script(
          esb
            .script(
              'inline',
              'doc["task.id"].value + "_" + doc["order_item.id"].value + "_" + (doc["item.weight"].size() > 0 ? doc["item.weight"].value : 0) + "_" + doc["item.quantity"] + "_" + doc["item.volume"]'
            )
            .lang('painless')
        )
        .size(10000),
      esb
        .topHitsAggregation('data')
        .source({
          includes: [
            'from',
            'to',
            'type',
            'task_group.worker_id',
            'order_step.address',
            'task',
            'location',
            'order_item',
            'step_group',
            'step_sequence',
            'order',
          ],
        })
        .size(1),
    ]);

  if (afterKey) {
    compositionAggregation.after(afterKey);
  }

  const query = esb
    .requestBodySearch()
    .query(
      esb
        .boolQuery()
        .must(queries)
        .must(esb.termQuery('company_id', companyId))
        .must(esb.termsQuery('task_group.worker_id', workerIds))
    )
    .aggregation(compositionAggregation)
    .size(0);

  return query.toJSON();
};

export const getSearchQuery = (
  companyId,
  searchText,
  size = 10,
  ignoreReportedTasks = false,
  fields = FULL_TEXT_SEARCH_FIELDS
) => {
  const query = esb.boolQuery();
  const parts = searchText.match(/[^;\s\n]+/g);
  if (parts && parts.length > 1) {
    const ids = parts.map((id) => id.toUpperCase());
    const exactSearchQueries = [].concat(...exactMatchFields.map((field) => esb.termsQuery(field, ids)));
    query.should(exactSearchQueries);
  }

  const searchQueries = esb
    .multiMatchQuery(
      fields.map((field) => (BOOSTED_FIELDS.includes(field) ? `${field}^2` : field)),
      searchText
    )
    .type('phrase_prefix');
  query.should(searchQueries);

  const queries = [
    esb.termQuery('company_id', companyId),
    query,
    esb
      .boolQuery()
      .should([
        esb.boolQuery().must(esb.existsQuery('task.cancelled_time')),
        //esb.boolQuery().mustNot([esb.boolQuery().mustNot(esb.existsQuery('transfer_requested_time')), esb.termQuery('task.state', 'invalidated')]),
        esb.boolQuery().mustNot(esb.termQuery('task.state', 'invalidated')),
        esb.boolQuery().must(esb.existsQuery('transfer_requested_time')),
        esb
          .boolQuery()
          .mustNot([
            esb.termQuery('task.state', 'failed'),
            esb.boolQuery().must(esb.existsQuery('task.associated_task_id')),
          ]),
      ])
      .minimumShouldMatch(1),
  ];

  if (ignoreReportedTasks) {
    queries.push(esb.boolQuery().mustNot(esb.existsQuery('task.associated_task_id')));
  }

  return esb
    .requestBodySearch()
    .query(esb.boolQuery().must(queries))
    .size(size > 10000 ? 10000 : size)
    .highlight(esb.highlight(fields).scoreOrder())
    .sort(esb.sort('order_item.id', 'desc'))
    .toJSON();
};

const unassignedTasksQuery = esb
  .boolQuery()
  .must(esb.cookMissingQuery('task_group.worker_id'))
  .mustNot(esb.existsQuery('task.completion_time'))
  .mustNot(esb.existsQuery('task.cancelled_time'))
  .must(esb.termQuery('transfer_state', 'none'));

const mapTaskState = (queries, taskState, modeMasterFilter) => {
  let query = null;
  const lowerCaseTaskStates = Array.isArray(taskState)
    ? taskState.map((item) => item.toLowerCase())
    : [taskState.toLowerCase()];

  const conditionsQueries =
    lowerCaseTaskStates
      .map((state) => {
        switch (state) {
          case AVAILABLE_FILTERS.UNASSIGNED.toLowerCase(): {
            query = unassignedTasksQuery;
            break;
          }
          case AVAILABLE_FILTERS.REPORTED.toLowerCase(): {
            query = unassignedTasksQuery;
            break;
          }
          case AVAILABLE_FILTERS.ASSIGNED.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('task_group.worker_id'))
              .mustNot(esb.existsQuery('task.completion_time'))
              .mustNot(esb.existsQuery('task.cancelled_time'))
              .must(esb.termQuery('transfer_state', 'none'));
            break;
          }
          case AVAILABLE_FILTERS.COMPLETED.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('task.completion_time'))
              .mustNot(esb.termQuery('task.state', 'failed'))
              .mustNot(esb.existsQuery('task.cancelled_time'));
            break;
          }
          case AVAILABLE_FILTERS.TRANSFERRED.toLowerCase(): {
            query = esb.boolQuery().must(esb.existsQuery('transfer_requested_time'));
            break;
          }
          case AVAILABLE_FILTERS.TRANSFERRED_PENDING.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('transfer_requested_time'))
              .must(esb.termQuery('transfer_state', 'pending'))
              .mustNot(esb.termQuery('task.state', 'invalidated'));
            break;
          }
          case AVAILABLE_FILTERS.TRANSFERRED_UNASSIGNED.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('transfer_requested_time'))
              .must(esb.termQuery('transfer_state', 'accepted'))
              .must(esb.matchQuery('transfer_task_metadata.task_group_info.state', 'unassigned'))
              .mustNot(esb.existsQuery('transfer_task_metadata.task_info.completion_time'));

            break;
          }
          case AVAILABLE_FILTERS.TRANSFERRED_ASSIGNED.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('transfer_requested_time'))
              .must(esb.termQuery('transfer_state', 'accepted'))
              .must(esb.matchQuery('transfer_task_metadata.task_group_info.state', 'assigned'))
              .mustNot(esb.existsQuery('transfer_task_metadata.task_info.completion_time'));

            break;
          }
          case AVAILABLE_FILTERS.TRANSFERRED_COMPLETED.toLowerCase(): {
            query = esb
              .boolQuery()
              .must(esb.existsQuery('transfer_requested_time'))
              .must(esb.termQuery('transfer_state', 'accepted'))
              .must(esb.matchQuery('transfer_task_metadata.task_info.state', 'completed'))
              .must(esb.existsQuery('transfer_task_metadata.task_info.completion_time'));

            break;
          }
          case 'not_completed': {
            query = esb.boolQuery().mustNot(esb.existsQuery('task.completion_time'));
            break;
          }
          default:
            break;
        }
        return query;
      })
      .filter((q) => q) ?? [];

  if (
    Array.isArray(lowerCaseTaskStates) ? lowerCaseTaskStates.includes('reported') : lowerCaseTaskStates === 'reported'
  ) {
    conditionsQueries.push(mapReportedFilters(modeMasterFilter));
  }

  if (conditionsQueries.length > 0) {
    const q = esb.boolQuery();
    conditionsQueries.forEach((cq) => q.should(cq));
    queries.push(q);
  }
};

const mapTaskConditions = (queries, modeMasterFilter) => {
  let query = null;
  const conditionsQueries =
    modeMasterFilter.status
      ?.map((status) => {
        switch (status) {
          case 'missing_info':
            query = esb
              .boolQuery()
              .must(esb.termQuery('missing_info', true))
              .mustNot(esb.existsQuery('order_item.cancelled_at'));
            break;

          case 'unassigned':
            query = esb
              .boolQuery()
              .must(esb.cookMissingQuery('task_group.worker_id'))
              .must(esb.termQuery('transfer_state', 'none'))
              .mustNot(esb.existsQuery('task.completion_time'))
              .mustNot(esb.termQuery('missing_info', true))
              .mustNot(esb.existsQuery('order_item.cancelled_at'))
              .mustNot(esb.existsQuery('task.associated_task_id'));
            break;

          case 'broadcasted':
            query = esb
              .boolQuery()
              .must(esb.existsQuery('task_group.broadcast_expires_at'))
              .must(esb.rangeQuery('task_group.broadcast_expires_at').gt(moment().toISOString()));
            break;
          case 'reported':
            query = mapReportedFilters(modeMasterFilter);
            break;

          case 'pending':
            query = esb
              .boolQuery()
              .must(esb.matchQuery('task_group.state', 'assigned'))
              .mustNot(esb.existsQuery('task_group.accepted_time'));
            break;

          case 'accepted':
            query = esb
              .boolQuery()
              .must(esb.matchQuery('task_group.state', 'assigned'))
              .must(esb.existsQuery('task_group.accepted_time'));
            break;
          case 'cancelled':
            query = esb.boolQuery().must(esb.existsQuery('task.cancelled_time'));
            break;
          case 'transferred_pending':
            query = esb
              .boolQuery()
              .must([
                esb.existsQuery('transfer_requested_time'),
                esb.termQuery('transfer_state', 'pending'),
                esb.boolQuery().mustNot(esb.termQuery('task.state', 'invalidated')),
              ]);
            break;
          case 'transferred_unassigned':
            query = esb
              .boolQuery()
              .must([
                esb.existsQuery('transfer_requested_time'),
                esb.termQuery('transfer_state', 'accepted'),
                esb.boolQuery().must(esb.matchQuery('transfer_task_metadata.task_group_info.state', 'unassigned')),
                esb.boolQuery().mustNot([esb.existsQuery('transfer_task_metadata.task_info.completion_time')]),
              ]);
            break;
          case 'transferred_reported':
            query = esb
              .boolQuery()
              .must([
                esb.existsQuery('transfer_requested_time'),
                esb.termQuery('transfer_state', 'accepted'),
                esb.existsQuery('transfer_task_metadata.task_info.associated_task_id'),
                esb.boolQuery().mustNot([esb.existsQuery('transfer_task_metadata.task_info.completion_time')]),
              ]);
            break;
          case 'transferred_assigned':
            query = esb
              .boolQuery()
              .must([
                esb.existsQuery('transfer_requested_time'),
                esb.termQuery('transfer_state', 'accepted'),
                esb.boolQuery().must(esb.matchQuery('transfer_task_metadata.task_group_info.state', 'assigned')),
                esb.boolQuery().mustNot([esb.existsQuery('transfer_task_metadata.task_info.completion_time')]),
              ]);
            break;
          case 'transferred_completed':
            query = esb
              .boolQuery()
              .must([
                esb.existsQuery('transfer_requested_time'),
                esb.termQuery('transfer_state', 'accepted'),
                esb
                  .boolQuery()
                  .must([
                    esb.matchQuery('transfer_task_metadata.task_info.state', 'completed'),
                    esb.existsQuery('transfer_task_metadata.task_info.completion_time'),
                  ]),
              ]);
            break;

          case 'completed':
            query = esb
              .boolQuery()
              .must(esb.existsQuery('task.completion_time'))
              .mustNot(esb.termQuery('task.state', 'failed'))
              .mustNot(esb.existsQuery('task.cancelled_time'));
            break;
        }
        return query;
      })
      .filter((q) => q) ?? [];

  if (query && conditionsQueries.length > 0) {
    const q = esb.boolQuery();
    conditionsQueries.forEach((cq) => q.should(cq));
    queries.push(q);
  }
};

const mapReportedFilters = (modeMasterFilter) => {
  let query;
  if (modeMasterFilter.reportInfoWithReason && modeMasterFilter.reportInfoWithReason.length > 0) {
    query = esb
      .boolQuery()
      .must(esb.matchQuery('task.state', 'failed'))
      .must(esb.existsQuery('task_exception_reasons'))
      .must(esb.matchQuery('task_exception_reasons.keyword', modeMasterFilter.reportInfoWithReason[0]));
  } else {
    query = esb
      .boolQuery()
      // 4 condition bellow mean unassigned tasks
      .must(esb.matchQuery('task_group.state', 'unassigned'))
      .must(esb.cookMissingQuery('task_group.worker_id'))
      .mustNot(esb.existsQuery('task.completion_time'))
      .mustNot(esb.existsQuery('task.cancelled_time'))
      // 1 condition bellow mean not transferred tasks
      .mustNot(esb.existsQuery('transfer_requested_time'))
      // 1 condition bellow mean reported tasks
      .must(esb.existsQuery('task.associated_task_id'));
  }

  return query;
};

const mapDateFilters = (queries, masterState) => {
  const filter = masterState.filter;

  if (!(filter.dateValue || filter.timeFrame) || filter.ignoreRange || !filter.dateOf || masterState.missingInfoMode) {
    return;
  }

  let query;
  const dateKind = filter['dateOf'][0];
  const dateValue = filter['dateValue'];
  const dateType = filter['dateType'][0];
  const timeFrame = filter.timeFrame;

  let rangeFrom, rangeTo;

  if (timeFrame) {
    if (timeFrame.key === TIME_FRAME_KEY_CUSTOM) {
      rangeFrom = moment(timeFrame.from);
      rangeTo = moment(timeFrame.to);
    } else {
      const timeFrameValue = getTimeFrameFromTimeFrameKey(timeFrame.key);

      rangeFrom = moment(timeFrameValue.from);
      rangeTo = moment(timeFrameValue.to);
    }
  } else {
    rangeFrom = dateValue.from;
    rangeTo = dateType === 'fixed' ? moment(dateValue.from).endOf('day').toISOString() : dateValue.to;
  }

  if (dateKind === 'commit_at' || !dateKind) {
    query = esb
      .boolQuery()
      .should([
        esb
          .boolQuery()
          .must([
            esb.boolQuery().should([esb.rangeQuery('from').gte(rangeFrom), esb.rangeQuery('to').gte(rangeFrom)]),
            esb.boolQuery().should([esb.rangeQuery('from').lte(rangeTo), esb.rangeQuery('to').lte(rangeTo)]),
          ]),
        esb
          .boolQuery()
          .must([esb.boolQuery().mustNot(esb.existsQuery('from')), esb.boolQuery().mustNot(esb.existsQuery('to'))]),
      ]);
  } else if (dateKind === 'reported_at') {
    query = esb
      .boolQuery()
      .must([
        esb.rangeQuery('associated_task.completion_time').gte(rangeFrom).lte(rangeTo),
        esb.termQuery('associated_task.state', 'failed'),
      ]);
  } else {
    query = esb.boolQuery().should([esb.rangeQuery(dateKind).gte(rangeFrom).lte(rangeTo)]);
  }

  queries.push(query);
};

const mapTaskTypes = (queries, modeMasterFilter) => {
  if (modeMasterFilter.taskType?.length) {
    // taskType can contain some fake types to indicate when fetch pair tasks in same leg
    // Full list of task type can see in TaskTypeDropDown component
    const actualTaskTypes = modeMasterFilter.taskType.map((t) => t.replace(PAIR_TASK_TYPE_POST_FIX, ''));

    queries.push(esb.termsQuery('type', actualTaskTypes));
  }
};

const mapLegTypesFilter = (queries, modeMasterFilter) => {
  if (modeMasterFilter.legTypes?.length === 1) {
    const [legTypeFilterId] = modeMasterFilter.legTypes;

    if (legTypeFilterId === 'empty') {
      queries.push(esb.termQuery('is_empty', true));
    } else {
      queries.push(esb.boolQuery().mustNot(esb.termQuery('is_empty', true)));
    }
  }
};

const filterByOrderItemIds = (filterTerms, orderItemIds) => {
  filterTerms.push(esb.termsQuery('order_item_id', orderItemIds));
};

const mapServiceTypeFilter = (queries, val, key, serviceTypes) => {
  const serviceQueries = [];
  if (!Array.isArray(val)) {
    serviceQueries.push(esb.matchQuery(termKeysMap[key], val));
  } else if (val.length > 0) {
    serviceQueries.push(esb.termsQuery(termKeysMap[key], val));
  }

  if (Object.keys(serviceTypes).length > 0) {
    (Array.isArray(val) ? val : [val]).forEach((id) => {
      const serviceType = serviceTypes?.[parseInt(id)];
      if (serviceType) {
        serviceQueries.push(esb.termQuery('order_item.alt_service_type', serviceType.key));
      }
    });
  }

  queries.push(esb.boolQuery().should(serviceQueries));
};

const mapSendersFilter = (queries, val) => {
  const sendersByType = _.groupBy(Array.isArray(val) ? val : [val], (value) => {
    if (`${value}`.startsWith('external_')) {
      return 'external';
    }

    if (`${value}`.startsWith('contact_name_')) {
      return 'contact_name';
    }

    return 'basic';
  });

  const queriesBySenderType = [];
  Object.keys(sendersByType).forEach((type) => {
    let fieldKeys = [];
    let values = [];
    if (type === 'external') {
      fieldKeys = ['sender.external_id'];
      values = sendersByType[type].map((v) => `${v}`.replace('external_', ''));
    }

    if (type === 'contact_name') {
      fieldKeys = ['order_step.contact_name.keyword', 'sender.name.keyword'];
      values = sendersByType[type].map((v) => `${v}`.replace('contact_name_', ''));
    }

    if (type === 'basic') {
      fieldKeys = ['sender.id'];
      values = sendersByType[type];
    }

    if (fieldKeys.length > 0 && values.length > 0) {
      values.forEach((v) => {
        v && fieldKeys.forEach((fieldKey) => queriesBySenderType.push(esb.matchQuery(fieldKey, v)));
      });
    }
  });

  if (queriesBySenderType.length > 0) {
    queries.push(esb.boolQuery().should(queriesBySenderType));
  }
};

const mapMasterFilters = (queries, filterTerms, modeMasterFilter, serviceTypes) => {
  Object.keys(modeMasterFilter).forEach((key) => {
    const value = modeMasterFilter[key];
    // Term Filters
    if (key === 'senderIds') {
      mapSendersFilter(queries, value);
    } else if (key === 'serviceTypeIds') {
      mapServiceTypeFilter(queries, value, key, serviceTypes);
    } else {
      mapTermKeyFilter({ queries, value, key });
    }

    // Match Filters
    if (matchKeysMap[key] && value && (!Array.isArray(value) || value.length)) {
      queries.push(esb.matchQuery(matchKeysMap[key], value));
    }

    // Range Filters
    if (rangeKeys.includes(key) && value && (!Array.isArray(value) || value.length) && (value.from || value.to)) {
      const fieldKey = `item.${key}`;
      let rangeQuery = esb.rangeQuery(fieldKey);
      if (value.from) {
        // README
        // Example val.from = 0.08,
        // the value of 0.08 mean it can round up from at least 0.075 (cause of display value on UI that use `volume.toFixed(2)`)
        // so when given val.from has more than 2 decimals we need to subtract a delta value of 0.005
        // to make val.from can cover all of volume (mean >= 0.075)
        const delta = 0.005;
        const parsedFromVal = Utilities.parseDecimal(parseFloat(value.from), 2);
        const fromVal = Utilities.numberOfDecimal(parsedFromVal) === 2 ? parsedFromVal - delta : value.from;
        rangeQuery = rangeQuery.gte(fromVal);
      }
      if (value.to) {
        rangeQuery = rangeQuery.lte(value.to);
      }

      const shouldQueries = [rangeQuery];
      if (value.from === '0') {
        shouldQueries.push(esb.boolQuery().mustNot(esb.existsQuery(fieldKey)));
      }
      const query = esb.boolQuery().should(shouldQueries).minimumShouldMatch(1);

      queries.push(query);
    }
  });
};

function mapTermKeyFilter({ queries, value, key }) {
  const termQueries = [];

  if (termKeysMap[key] && value) {
    if (!Array.isArray(value)) {
      termQueries.push(esb.matchQuery(termKeysMap[key], value));
    } else if (value.length > 0) {
      let formattedValue = [...value];
      if (['addressItemIds', 'driverIdsQueryByTags'].includes(key)) {
        formattedValue = [...new Set(value)];
      }

      termQueries.push(esb.termsQuery(termKeysMap[key], formattedValue));
    }
  }

  if (termQueries.length > 0) {
    queries.push(esb.boolQuery().should(termQueries));
  }
}

export const getFiltersByOrderItemIds = (companyId, orderItemIds, size, onlyCreatedOrCompleted) => {
  const query = esb
    .boolQuery()
    .must(esb.termQuery('company_id', companyId))
    .filter([esb.termsQuery('order_item_id', orderItemIds)]);

  if (onlyCreatedOrCompleted) {
    query.must(esb.termsQuery('task.state', ['created', 'completed']));
  } else {
    query.mustNot(esb.termQuery('task.state', 'invalidated'));
  }

  return esb
    .requestBodySearch()
    .query(query)
    .size(size > 10000 ? 10000 : size)
    .toJSON();
};

const mapSearchFilters = (queries, filterTerms, searchState) => {
  const { searchText, order_item_ids } = searchState;
  let query;
  if (order_item_ids?.length) {
    filterByOrderItemIds(filterTerms, order_item_ids);
  } else if (searchText) {
    query = esb
      .multiMatchQuery(FULL_TEXT_SEARCH_FIELDS, searchText)
      .type('best_fields')
      .tieBreaker(0.3)
      .minimumShouldMatch('30%');
  }
  if (query) {
    queries.push(query);
  }
};

const mapIdFilters = (queries, filters, modeMasterFilter) => {
  const idValue = modeMasterFilter.idValue;
  if (idValue) {
    if (idsFilterMap[modeMasterFilter.idKey]) {
      if (!Array.isArray(idValue)) {
        queries.push(esb.termQuery(idsFilterMap[modeMasterFilter.idKey], idValue));
      } else if (idValue.length > 0) {
        filters.push(esb.termsQuery(idsFilterMap[modeMasterFilter.idKey], idValue));
      }
    }

    if (trackingNumberFilterMap[modeMasterFilter.idKey]) {
      if (!Array.isArray(idValue) || idValue.length === 1) {
        queries.push(
          esb.termQuery(trackingNumberFilterMap[modeMasterFilter.idKey], !Array.isArray(idValue) ? idValue : idValue[0])
        );
      } else if (idValue.length > 0) {
        filters.push(esb.termsQuery(trackingNumberFilterMap[modeMasterFilter.idKey], idValue));
      }
    }
  }
};

const mapGeoFilters = (queries, modeMasterFilter) => {
  const { selectedAddressOptions, radius } = modeMasterFilter;
  if (!selectedAddressOptions || !Array.isArray(selectedAddressOptions) || selectedAddressOptions.length === 0) {
    return queries;
  }

  const addressOptionsQueries = selectedAddressOptions.map((a) => {
    if (a.lat && a.lng) {
      return esb
        .geoDistanceQuery()
        .field('location')
        .distance(`${radius > 0 ? radius : 0.001}km`)
        .geoPoint(esb.geoPoint().lat(a.lat).lon(a.lng));
    } else if (!a.name) {
      return esb.boolQuery().mustNot(esb.existsQuery('order_step.address.keyword'));
    } else {
      return esb.termQuery('order_step.address.keyword', a.name);
    }
  });

  return queries.push(esb.boolQuery().should(addressOptionsQueries));
};

const mapGeoPolygons = (queries, modeMasterFilter, regions) => {
  const { region_ids } = modeMasterFilter;
  if (!region_ids) return queries;
  const regionsFiltersQueries = region_ids
    .map((id) => {
      const region = regions.find((r) => r.id === id);
      if (region) {
        return esb.geoPolygonQuery('location').points(region.coordinates[0].map(({ lat, lng }) => ({ lat, lon: lng })));
      }
      return null;
    })
    .filter((q) => q !== null);

  if (regionsFiltersQueries.length > 0) {
    queries.push(esb.boolQuery().should(regionsFiltersQueries));
  }
};
