import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';

import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';

export const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const applySearchFilterOnList = (list, field, searchText) => {
  if (!searchText) return list;
  const escapedValue = escapeRegexCharacters(searchText.trim());
  if (escapedValue === '') {
    return list;
  }
  const regex = new RegExp('\\W' + escapedValue, 'i');
  return list.filter((item) => {
    const str = `'${item[field]}'`;
    return str.match(regex) != null;
  });
};

export const getCapacityColor = (index, percentage) => {
  if (!percentage || index < 0) return 'lightgrey';
  if (percentage > 67) return 'red';
  if (percentage > 34) return index > 1 ? 'lightgrey' : 'orange';
  if (percentage > 0) return index > 0 ? 'lightgrey' : '#4a90e2';
  return 'lightgrey';
};

export const extractVolumeCapacity = ({
  vehicleType: { maxLoadSpaceVolume, maxCarryWeight, carryUnit },
  workerSequence: { maxVolume, maxWeight, maxQuantity },
}) => {
  const wMaxVolume = parseCapacityValue(maxVolume);
  const vMaxLoadSpaceVolume = parseCapacityValue(maxLoadSpaceVolume);

  const wMaxWeight = parseCapacityValue(maxWeight);
  const vMaxCarryWeight = parseCapacityValue(maxCarryWeight);

  const wMaxQuantity = parseCapacityValue(maxQuantity);
  const vCarryUnit = parseCapacityValue(carryUnit);
  const percentage =
    Math.max(
      calculateRatio(wMaxVolume, vMaxLoadSpaceVolume),
      calculateRatio(wMaxWeight, vMaxCarryWeight),
      calculateRatio(wMaxQuantity, vCarryUnit)
    ) * 100;
  return {
    percentage,
    capacityInfo: {
      wMaxVolume,
      vMaxLoadSpaceVolume,
      wMaxQuantity,
      vCarryUnit,
      wMaxWeight,
      vMaxCarryWeight,
    },
  };
};

export const parseCapacityValue = (valueInput) => {
  return !valueInput ? 0 : parseFloat(valueInput);
};

export const calculateRatio = (a, b) => {
  if (b === 0 || !b) return 0;
  return BigNumber(a).dividedBy(BigNumber(b));
};

export const getDurationInSeconds = (from, to) => {
  const dDurationStart = from.setSeconds(0, 0);
  const dDurationEnd = to.setSeconds(0, 0);
  return (dDurationEnd - dDurationStart) / 1000;
};

export const useIsOptimisationMode = () => {
  const filter = useSelector((state) => state.main?.stopsList?.filter);
  return Array.isArray(filter)
    ? filter.map((item) => item.toUpperCase().includes(AVAILABLE_FILTERS.LAST_PLANNED))
    : filter?.toUpperCase() === AVAILABLE_FILTERS.LAST_PLANNED;
};
