import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { KeyboardDoubleArrowLeftOutlined, KeyboardDoubleArrowRightOutlined } from '@mui/icons-material';
// libs
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Base UI
import Button from '../../furnitures/Button';
import EmptyZone from '../EmptyZone';
import SearchField from '../SearchField';
import { useStyles } from './styles';
import { Direction } from './types';
import useSearchTransferList from './useSearchTransferList';

function not(a, b) {
  return a?.filter((aItem) => b.findIndex((bItem) => aItem.value === bItem.value) === -1);
}

const TransferList = ({
  leftTitle,
  leftItems,
  setLeftItems,
  rightTitle,
  rightItems,
  setRightItems,
  rightSearchProps,
  leftSearchProps,
  leftRequired,
  leftError,
  leftHelperText,
  rightError,
  rightHelperText,
  rightRequired,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    leftKeySearch,
    rightKeySearch,
    leftSearchedItems,
    rightSearchedItems,
    setLeftSearchedItems,
    setRightSearchedItems,
    onLeftSearchChange,
    onRightSearchChange,
  } = useSearchTransferList({ leftItems, rightItems });

  const leftCustomItems = leftSearchedItems || leftItems;
  const rightCustomItems = rightSearchedItems || rightItems;

  const handleToggle = (selectedItem, direction) => () => {
    if (direction === Direction.LEFT) return handleCheckedRight(selectedItem);

    return handleCheckedLeft(selectedItem);
  };

  const handleAllRight = () => {
    setRightItems(rightItems.concat(leftCustomItems));

    if (leftSearchedItems) {
      setLeftItems(not(leftItems, leftSearchedItems));
      setLeftSearchedItems([]);
    } else {
      setLeftItems([]);
    }
  };

  const handleCheckedRight = (selectedItem) => {
    setRightItems([...rightItems, selectedItem]);
    setLeftItems(not(leftItems, [selectedItem]));

    setLeftSearchedItems(not(leftSearchedItems, [selectedItem]));
  };

  const handleCheckedLeft = (selectedItem) => {
    setLeftItems([...leftItems, selectedItem]);
    setRightItems(not(rightItems, [selectedItem]));

    setRightSearchedItems(not(rightSearchedItems, [selectedItem]));
  };

  const handleAllLeft = () => {
    setLeftItems(leftItems.concat(rightCustomItems));

    if (rightSearchedItems) {
      setRightItems(not(rightItems, rightSearchedItems));
      setRightSearchedItems([]);
    } else {
      setRightItems([]);
    }
  };

  const customList = ({ items, title, searchProps, error, required, helperText, direction }) => (
    <>
      {title ? (
        <Grid item className={classes.listTitle}>
          {title}
          {required ? <span className={clsx({ [classes.errorColor]: error })}> *</span> : ''}
        </Grid>
      ) : null}
      <SearchField className={classes.searchBox} {...searchProps} />
      <Paper className={clsx(classes.paper, { [classes.paperError]: error })}>
        {items.length <= 0 ? (
          <EmptyZone className={classes.emptyZone} />
        ) : (
          <List dense disablePadding component="div" role="list">
            {items.map((item) => {
              const labelId = `transfer-list-item-${item.value}-label`;

              return (
                <ListItem key={item.value} role="listitem" button onClick={handleToggle(item, direction)}>
                  <ListItemText id={labelId} primary={item.label} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        )}
      </Paper>
      {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
    </>
  );

  const leftCustomListConfigs = {
    direction: Direction.LEFT,
    items: leftCustomItems,
    title: leftTitle,
    required: leftRequired,
    error: leftError,
    helperText: leftHelperText,
    searchProps: {
      dataCy: 'left-search-box',
      value: leftKeySearch,
      onChange: onLeftSearchChange,
      ...leftSearchProps,
    },
  };

  const rightCustomListConfigs = {
    direction: Direction.RIGHT,
    items: rightCustomItems,
    title: rightTitle,
    required: rightRequired,
    error: rightError,
    helperText: rightHelperText,
    searchProps: {
      dataCy: 'right-search-box',
      value: rightKeySearch,
      onChange: onRightSearchChange,
      ...rightSearchProps,
    },
  };

  return (
    <Grid container spacing={2} alignItems="flex-start" className={classes.root}>
      <Grid item className={classes.listGrid}>
        {customList(leftCustomListConfigs)}
      </Grid>
      <Grid item className={classes.groupButtons}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={leftCustomItems.length === 0}
            data-cy="move-all-to-right-button"
            aria-label={t('move all right')}
          >
            <KeyboardDoubleArrowRightOutlined fontSize="small" />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={rightCustomItems.length === 0}
            data-cy="move-all-to-left-button"
            aria-label={t('move all left')}
          >
            <KeyboardDoubleArrowLeftOutlined fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.listGrid}>
        {customList(rightCustomListConfigs)}
      </Grid>
    </Grid>
  );
};

TransferList.propTypes = {
  leftTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setLeftItems: PropTypes.func,
  setRightItems: PropTypes.func,
  leftItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  rightItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  leftSearchProps: PropTypes.object,
  rightSearchProps: PropTypes.object,
  leftRequired: PropTypes.bool,
  rightRequired: PropTypes.bool,
  leftError: PropTypes.bool,
  rightError: PropTypes.bool,
  leftHelperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightHelperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TransferList.defaultProps = {
  leftTitle: 'Left Title',
  rightTitle: 'Right Title',
  leftItems: [],
  rightItems: [],
  leftSearchProps: {},
  rightSearchProps: {},
  leftRequired: false,
  rightRequired: false,
  leftError: false,
  rightError: false,
  leftHelperText: '',
  rightHelperText: '',
  setLeftItems: () => {
    return;
  },
  setRightItems: () => {
    return;
  },
};

export default TransferList;
