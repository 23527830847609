export function setRightPanelCommand({ rightPanelCommand }) {
  return { type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand };
}

export function setLeftPanelCommand({ leftPanelCommand }) {
  return { type: 'SET_LEFT_PANEL_COMMAND', leftPanelCommand };
}

export function setAssignmentConfig({ assignment }) {
  return { type: 'SET_ASSIGNMENT_CONFIG', assignment };
}

export function openAssignmentConfirmation(whitelistedWorkers) {
  return { type: 'OPEN_ASSIGNMENT_CONFIRMATION', whitelistedWorkers };
}

export function setOptimisationConfig({ optimisation }) {
  return { type: 'SET_OPTIMISATION_CONFIG', optimisation };
}

export function updateVisibleRoutes({ workerId, visible, source }) {
  return { type: 'UPDATE_VISIBLE_ROUTES', workerId, visible, source };
}

export function updateAllVisibleRoutes({ visible, source }) {
  return { type: 'UPDATE_ALL_VISIBLE_ROUTES', visible, source };
}

export function updateFiltersAfterAssignment() {
  return { type: 'UPDATE_FILTERS_AFTER_ASSIGNMENT' };
}

export function handleStopFilterChange({ value, isLoadTasks }) {
  return { type: 'HANDLE_STOP_FILTER_CHANGE', value, isLoadTasks };
}

export function setStopsListExpandedView(expandedView) {
  return { type: 'SET_STOPS_LIST_EXPANDED_VIEW', expandedView };
}

export function setHighlightedTasks(highlightedTasks) {
  return { type: 'SHOW_HIGHLIGHTED_TASKS', highlightedTasks };
}

export function showLegs({ tasks, anchorEl, stopId }) {
  return { type: 'SHOW_STOP_LEGS', tasks, anchorEl, stopId };
}

export function showHierarchyDialog(orderItemId) {
  return { type: 'SHOW_HIERARCHY_DIALOG', orderItemId };
}

export function hideHierarchyDialog() {
  return { type: 'HIDE_HIERARCHY_DIALOG' };
}

export function toggleNoAccessDialog() {
  return { type: 'TOGGLE_NO_ACCESS_DIALOG' };
}

export function togglePaymentDialog() {
  return { type: 'TOGGLE_PAYMENT_DIALOG' };
}

export function updateCurrentView({ selectedView }) {
  return { type: 'UPDATE_CURRENT_VIEW', selectedView };
}

export function closeOrderForm() {
  return { type: 'CLOSE_ORDER_FORM' };
}

export function showSelectionCreateOrder() {
  return { type: 'SHOW_SELECTION_CREATE_ORDER' };
}

export function closeSelectionCreateOrder() {
  return { type: 'CLOSE_SELECTION_CREATE_ORDER' };
}

export function openOrderForm({ order }) {
  return { type: 'OPEN_ORDER_FORM', order };
}

export function handleTimelineItemSelect(action, item) {
  return { type: 'HANDLE_TIMELINE_ITEM_SELECT', action, item };
}

export function openCancelTaskConfirmDialog({ tasks, allowCancelWithAssignedTask }) {
  return { type: 'OPEN_CANCEL_TASK_CONFIRM_DIALOG', tasks, allowCancelWithAssignedTask };
}

export function closeCancelTaskConfirmDialog() {
  return { type: 'CLOSE_CANCEL_TASK_CONFIRM_DIALOG' };
}

export function processCancelTasks(playload) {
  return { type: 'PROCESS_CANCEL_TASKS', ...playload };
}

export function processCancelTasksSuccess({ message }) {
  return { type: 'PROCESS_CANCEL_TASKS_SUCCESS', message };
}
export function processCancelTasksError({ error }) {
  return { type: 'PROCESS_CANCEL_TASKS_SUCCESS', error };
}

export function clearCancelTaskMessage() {
  return { type: 'CLEAR_CANCEL_TASK_MESSAGE' };
}

export function showWorkerAddDialog() {
  return { type: 'SHOW_WORKER_ADD_DIALOG' };
}

export function hideWorkerAddDialog() {
  return { type: 'HIDE_WORKER_ADD_DIALOG' };
}

export function showVehicleAddDialog() {
  return { type: 'SHOW_VEHICLE_ADD_DIALOG' };
}

export function hideVehicleAddDialog() {
  return { type: 'HIDE_VEHICLE_ADD_DIALOG' };
}

export function showMissingInfoFixDialog() {
  return { type: 'SHOW_MISSING_INFO_FIX_DIALOG' };
}

export function hideMissingInfoFixDialog() {
  return { type: 'HIDE_MISSING_INFO_DIALOG' };
}

export function resetAllStore() {
  return { type: 'RESET_ALL_STORE' };
}

export function closeOptimisation() {
  return { type: 'CLOSE_OPTIMISATION' };
}

export function setPresentationRefreshInterval({ refreshTimeInMinutes }) {
  return { type: 'SET_PRESENTATION_REFRESH_INTERVAL', refreshTimeInMinutes };
}
