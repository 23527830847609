import { retry } from 'redux-saga/effects';

export function getFileFromResponse(res, defaultFileName) {
  const fileName = getFileNameFromResponse(res, defaultFileName);
  const fileType = res.headers['content-type'];
  return new File([res.data], fileName, { type: fileType });
}

const getFileNameFromResponse = (res, defaultFileName) => {
  const respUrl = res.request?.responseURL;
  if (!respUrl) return defaultFileName;

  const url = new URL(respUrl);
  const pathTail = url.pathname.split('/').pop();

  const isSystemGenerated = !pathTail.includes('.');
  if (isSystemGenerated) return defaultFileName;

  const fileName = pathTail;
  return fileName;
};

export function saveFile(file, fileName) {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
}

export async function generateCompress(jsZipInstance, fileName) {
  const blob = await jsZipInstance.generateAsync({ type: 'blob' });
  saveFile(blob, fileName);
}

export function* sagaRetry({ request, data }) {
  try {
    // Retry maximum 3 times, delay 500ms each time
    return yield retry(3, 500, request, data);
  } catch (error) {
    console.error({ error });
    throw new Error('Unable to download, please try again!');
  }
}
