const defaultState = {
  show: false,
  tasks: [],
  status: 'normal',
  message: undefined,
  taskExceptionReasons: [],
  processId: undefined,
  inProgress: {
    getTaskExceptionReasons: false,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  FETCH_TASK_EXCEPTION_REASONS_SUCCESS: (state, { reasons }) => {
    return {
      ...state,
      taskExceptionReasons: reasons,
      inProgress: { ...state.inProgress, getTaskExceptionReasons: false },
    };
  },
  OPEN_REPORT_TASK_DIALOG: (state, { tasks }) => {
    return { ...state, show: true, tasks };
  },
  CLOSE_REPORT_TASK_DIALOG: (state) => {
    return { ...state, show: false, processId: undefined };
  },
  PROCESS_REPORT_TASKS: (state, { tasks }) => {
    return { ...state, tasks, status: 'processing' };
  },
  PROCESS_REPORT_TASKS_SUCCESS: (state, { message }) => {
    return { ...state, status: 'success', message, processId: undefined };
  },
  PROCESS_REPORT_TASKS_ERROR: (state, { error }) => {
    return { ...state, status: 'error', message: error, processId: undefined };
  },
  CLEAR_REPORT_TASK_MESSAGE: (state) => {
    return { ...state, status: 'normal', message: undefined, tasks: [] };
  },
  UPDATE_ID_PROCESS_REPORT_TASKS: (state, { processId }) => {
    return { ...state, status: 'processing', processId: processId };
  },
  REPORT_TASK_PROGRESS: (state, { key }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [key]: true,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
