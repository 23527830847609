import { call, put, select, takeLatest } from 'redux-saga/effects';

import AuthSelectors from '@yojee/auth/store/selectors';

import driverApi from '../../Api/driversApi';

export default function* sagas() {
  yield takeLatest('SEARCH_DRIVER', searchDriver);
}

function* searchDriver({ searchKey }) {
  try {
    const { partnerJwt } = yield select(AuthSelectors.getData);
    yield put({ type: 'SEARCH_DRIVER_START', searchKey: searchKey });
    const data = yield call(driverApi.searchWorkers, { query: searchKey, partnerJwt });
    yield put({ type: 'SEARCH_DRIVER_SUCCESS', drivers: data });
  } catch (error) {
    yield put({ type: 'SEARCH_DRIVER_FAILED', error });
  }
}
