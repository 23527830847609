import { Slide } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

const DEFAULT_VARIANT = 'info';
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
const TransitionComponent = Slide;
// type of variants: default, success, error, warning info

const useNotistack = ({ successMessage, errorMessage, onClose, dataCy = 'notify-message' }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const _queueHelper = (message, variant = DEFAULT_VARIANT) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin,
      TransitionComponent,
      style: { whiteSpace: 'pre-line' },
      ...(onClose ? { onExit: onClose } : {}),
      'data-cy': dataCy,
    });
  };

  const queueNotify = (message, variant) => {
    _queueHelper(message, variant);
  };

  const memorizedQueueNotify = useCallback(
    (...args) => {
      queueNotify(...args);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    successMessage && memorizedQueueNotify(successMessage, 'success');
  }, [successMessage, memorizedQueueNotify]);

  useEffect(() => {
    errorMessage && memorizedQueueNotify(errorMessage, 'error');
  }, [errorMessage, memorizedQueueNotify]);

  return { queueNotify, enqueueSnackbar, closeSnackbar };
};

export default useNotistack;
