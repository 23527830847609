export const TYPES = {
  MARK_AS_READ: 'MARK_AS_READ',
  UPDATE_STATUS: 'UPDATE_STATUS',
  MARK_AS_UNREAD: 'MARK_AS_UNREAD',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
  UPDATE_ALL_AS_READ: 'UPDATE_ALL_AS_READ',
  MARK_ALL_AS_SEEN: 'MARK_ALL_AS_SEEN',
  UPDATE_ALL_AS_SEEN: 'UPDATE_ALL_AS_SEEN',
  SUBSCRIBE_WEB_SOCKET: 'SUBSCRIBE_WEB_SOCKET',
  UNSUBSCRIBE_WEB_SOCKET: 'UNSUBSCRIBE_WEB_SOCKET',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  START_UPDATE_NOTIFICATION: 'START_UPDATE_NOTIFICATION',
  LOAD_MORE_NOTIFICATION: 'LOAD_MORE_NOTIFICATION',
  ADD_NEW_NOTIFICATION: 'ADD_NEW_NOTIFICATION',
};

export const addNewNotification = (payload) => ({
  type: TYPES.ADD_NEW_NOTIFICATION,
  payload: payload,
});

export const updateAllAsSeen = () => ({
  type: TYPES.UPDATE_ALL_AS_SEEN,
});

export const updateAllAsRead = () => ({
  type: TYPES.UPDATE_ALL_AS_READ,
});
export const loadMoreNotification = ({ userId, startId, pageSize }) => ({
  type: TYPES.LOAD_MORE_NOTIFICATION,
  payload: { userId, startId, pageSize },
});
export const updateNotificationStatus = ({ unread, id }) => ({
  type: TYPES.UPDATE_STATUS,
  payload: { unread, id },
});
export const updateNotifications = (payload) => ({
  type: TYPES.UPDATE_NOTIFICATION,
  payload: payload,
});
export const markAsRead = ({ userId, activityId }) => ({
  type: TYPES.MARK_AS_READ,
  payload: { userId, activityId },
});

export const markAsUnread = ({ userId, activityId }) => ({
  type: TYPES.MARK_AS_UNREAD,
  payload: { userId, activityId },
});

export const markAllAsRead = (userId) => ({
  type: TYPES.MARK_ALL_AS_READ,
  payload: { userId },
});

export const markAllAsSeen = (userId) => ({
  type: TYPES.MARK_ALL_AS_SEEN,
  payload: { userId },
});

export const subscribeWebSocket = ({ userId }) => ({
  type: TYPES.SUBSCRIBE_WEB_SOCKET,
  payload: { userId },
});

export const unsubscribeWebSocket = ({ userId }) => ({
  type: TYPES.UNSUBSCRIBE_WEB_SOCKET,
  payload: { userId },
});
