const defaultState = {
  show: false,
  status: 'normal', // normal, processing, error, success
  error: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  OPEN_INVITE_PARTNER_DIALOG: (state) => {
    return { ...state, show: true };
  },
  CLOSE_INVITE_PARTNER_DIALOG: (state) => {
    return defaultState;
  },
  CREATING_PARTNER_INVITATION: (state) => {
    return { ...state, status: 'processing' };
  },
  CREATING_PARTNER_INVITATION_SUCCESS: (state) => {
    return defaultState;
  },
  CREATING_PARTNER_INVITATION_ERROR: (state, { error }) => {
    return { ...state, status: 'error', error };
  },
  RESET_STATUS_INVITE_PARTNER: (state) => {
    return { ...state, status: 'normal', error: undefined };
  },
};

export const InvitePartnerDialogReducer = {
  invitePartnerDialog: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
