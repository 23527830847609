export const mapClusterToRoute = (route, tasksDataObj) => {
  const directions = [];
  let lastTask = null;
  const tour = [].concat(
    ...route.tour.map((t, index) => {
      if (route.directions[index]) {
        directions.push(route.directions[index]);
      }

      if (!t.properties) {
        return [t];
      }

      const tasksRoute = t.properties.clusteredTasks.map((ctId, tIndex) => {
        if (tIndex > 0) {
          directions.push({ encodedPolyline: null });
        }
        const task = tasksDataObj[ctId];
        if (
          lastTask &&
          lastTask['order_step']['contact_name'] === task['order_step']['contact_name'] &&
          lastTask['order_step']['address'] === task['order_step']['address']
        ) {
          task['order_step_id'] = lastTask['order_step_id'];
          task['stop_id'] = lastTask['stop_id'];
        } else {
          task['order_step_id'] = t.order_step_id;
          task['stop_id'] = `${t.type}_${index}`;
        }
        lastTask = task;
        const multiplier = task.type === 'pickup' ? 1 : -1;
        return {
          ...t,
          capacityDemand: {
            weight: task.item.weight * multiplier,
            volume: task.item.volume * multiplier,
            unit: task.item.quantity * multiplier,
          },
          id: ctId,
          directions: { encodedPolyline: null },
          task,
          location: tasksDataObj[ctId]
            ? {
                latitude: tasksDataObj[ctId].location.lat,
                longitude: tasksDataObj[ctId].location.lng,
              }
            : {},
        };
      });

      tasksRoute[0].directions = t.directions;
      return tasksRoute;
    })
  );

  return { directions, tour };
};
