import { setValue } from '@yojee/helpers/access-helper';
import { STATUSES } from '@yojee/helpers/constants';

const defaultState = {};

export const ROUTE_PLANNER_STATUSES = {
  DATA_FETCH: 0,
  DISPLAY_SETTINGS: 1,
  SOLVER_RUNNING: 3,
  DISPLAY_RESULTS: 4,
  CANCELLED: 5,
};

export const ROUTE_PLANNER_SUBMENUS = {
  NONE: null,
  DISPLAY_TASKS_LIST: 0,
  DISPLAY_WORKERS_LIST: 1,
  DISPLAY_VEHICLE_TYPES_LIST: 2,
  DISPLAY_ADDRESS_LOCATOR: 3,
  DISPLAY_BULK_WORKERS_EDIT: 4,
  DISPLAY_BULK_TASKS_EDIT: 5,
  DISPLAY_SERVICE_TIME_CONDITIONS: 6,
};

export const ROUTE_PLANNER_DATA_LISTS = [
  ROUTE_PLANNER_SUBMENUS.DISPLAY_TASKS_LIST,
  ROUTE_PLANNER_SUBMENUS.DISPLAY_WORKERS_LIST,
  ROUTE_PLANNER_SUBMENUS.DISPLAY_VEHICLE_TYPES_LIST,
  ROUTE_PLANNER_SUBMENUS.DISPLAY_SERVICE_TIME_CONDITIONS,
];

const defaultPlanState = (id, planningType) => ({
  id,
  planningType,
  status: ROUTE_PLANNER_STATUSES.DATA_FETCH,
  submenu: ROUTE_PLANNER_SUBMENUS.NONE,
  settings: {
    epochDate: new Date(),
    expanded: null,
    startLocation: null,
    endLocation: null,
    startHub: 0,
    endHub: 0,
    serviceTime: null,
    sameLocationServiceTime: null,
    subsequentPickupAndDropoff: null,
    enforceEpoch: false,
  },
  selectedTasksIds: [],
  selectedWorkersIds: [],
  tasks: {
    status: STATUSES.NOT_STARTED,
    data: {},
  },
  assignedTasks: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  workers: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  workersSchedule: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  hubs: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  vehicleTypes: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  serviceTimeConditions: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  solverRequestMetadata: {},
  solverRequestStatus: {
    status: STATUSES.NOT_STARTED,
    data: [],
  },
  transformedData: {
    tasks: [],
    vehicles: [],
    workers: [],
    workersSchedule: [],
    status: STATUSES.NOT_STARTED,
    selectedTasks: {},
    selectedWorkers: {},
    serviceTimeConditions: [],
  },
  planningResult: null,
  isPlanningResultModified: false,
  planChanges: [],
  transformedTasks: [],
  addressUpdater: {
    show: false,
    locationType: null,
    rowsIndexes: [],
    entity: null,
    currentLng: null,
    currentLat: null,
  },
  confirmationDialog: {
    show: false,
    nextSubmenu: null,
  },
  sortableGrid: {
    selectedTasks: [],
    workerId: null,
    lastInteractedTaskPosition: null,
    showGroups: false,
    expandedGroups: {},
  },
  unSequencedTasksRemoveDialog: {
    show: false,
    workerId: null,
  },
});

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_NEW_PLAN: (state, { id, planningType, settings }) => {
    const defaultState = defaultPlanState(id, planningType);
    return { ...state, [id]: { ...defaultState, settings: { ...defaultState.settings, ...settings } } };
  },
  SET_PLAN_DATA: (state, { id, selectedTasksIds, selectedWorkersIds }) => {
    const plan = state[id];
    return { ...state, [id]: { ...plan, selectedTasksIds, selectedWorkersIds } };
  },
  SET_OPTIMISATION_DATA_FETCH_DATA: (state, { id, requestType, data }) => {
    const plan = state[id];
    return { ...state, [id]: { ...plan, [requestType]: { ...plan[requestType], ...data } } };
  },
  SET_OPTIMISATION_TRANSFORMATION_DATA: (state, { id, dataType, data }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          [dataType]: data,
        },
      },
    };
  },
  UPDATE_PLAN_SETTINGS: (state, { id, settings }) => {
    const plan = state[id];
    if (!plan) {
      return state;
    }

    return { ...state, [id]: { ...plan, settings: { ...plan.settings, ...settings } } };
  },
  SET_OPTIMISATION_TRANSFORMATION_STATUS: (state, { id, status }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          status,
        },
      },
    };
  },
  MOVE_TO_NEXT_STEP: (state, { id }) => {
    const plan = state[id];
    if (!plan) {
      return state;
    }

    const status = plan.status;
    if (status === ROUTE_PLANNER_STATUSES.DATA_FETCH) {
      return { ...state, [id]: { ...plan, status: ROUTE_PLANNER_STATUSES.DISPLAY_SETTINGS } };
    }
    if (status === ROUTE_PLANNER_STATUSES.DISPLAY_SETTINGS) {
      return { ...state, [id]: { ...plan, status: ROUTE_PLANNER_STATUSES.SOLVER_RUNNING } };
    }
    if (status === ROUTE_PLANNER_STATUSES.SOLVER_RUNNING) {
      return { ...state, [id]: { ...plan, status: ROUTE_PLANNER_STATUSES.DISPLAY_RESULTS } };
    }

    return state;
  },
  SET_PLANNER_STATUS: (state, { id, status }) => {
    const plan = state[id];
    if (!plan) {
      return state;
    }
    return { ...state, [id]: { ...plan, status } };
  },
  UPDATE_PLANNER_SETTINGS: (state, { params: { id, settings } }) => {
    const plan = state[id];
    if (!plan) {
      return state;
    }

    return { ...state, [id]: { ...plan, settings: { ...plan.settings, ...settings } } };
  },
  SET_TRANSFORMED_VALUE: (state, { params }) => {
    const { id, index, valueType, value, entityType } = params;
    const plan = state[id];

    const data = setValue(plan.transformedData, `${entityType}.${index}.changed`, true);

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: setValue(data, `${entityType}.${index}.${valueType}`, value),
      },
    };
  },
  CHANGE_SUBMENU: (state, { params: { id, submenu } }) => {
    const plan = state[id];
    return { ...state, [id]: { ...plan, submenu } };
  },
  UPDATE_BULK_TASKS: (state, { params: { id, field, value, indexes } }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          tasks: plan.transformedData.tasks.map((t, index) => {
            if (Array.isArray(indexes)) {
              if (indexes.includes(index)) {
                t.changed = true;
                return setValue(t, field, value);
              }

              return t;
            } else {
              return { ...t, [field]: value, changed: true };
            }
          }),
        },
      },
    };
  },
  UPDATE_VARIABLE_SERVICE_TIME_BY_QUANTITY_PER_TASKS: (state, { params: { id, variableServiceTimeValue } }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          tasks: plan.transformedData.tasks.map((t, index) => {
            const variableServiceTime = variableServiceTimeValue * t.capacityDemand.unit;
            return { ...t, variableServiceTime, changed: true };
          }),
        },
      },
    };
  },
  UPDATE_BULK_WORKERS: (state, { params: { id, field, value, indexes } }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          workers: plan.transformedData.workers.map((w, index) => {
            if (Array.isArray(indexes) && !indexes.includes(index)) {
              return w;
            }
            w.changed = true;
            return setValue(w, field, value);
          }),
        },
      },
    };
  },
  UPDATE_SERVICE_TIME_CONDITIONS: (state, { id, data: { data } }) => {
    const plan = state[id];
    const existingIds = plan.transformedData.serviceTimeConditions.reduce((acc, c) => {
      acc[c.id] = true;
      return acc;
    }, {});

    const serviceTimeConditions = [...plan.transformedData.serviceTimeConditions];
    data.forEach((c) => {
      if (!existingIds[c.id]) {
        serviceTimeConditions.push(c);
        existingIds[c.id] = true;
      }
    });

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          serviceTimeConditions,
        },
      },
    };
  },
  SET_ADDRESS_UPDATER: (state, { params: { id, addressUpdater } }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        addressUpdater: {
          ...plan.addressUpdater,
          ...addressUpdater,
        },
      },
    };
  },
  TOGGLE_SELECTED_WORKER: (state, { params: { id, index } }) => {
    const plan = state[id];
    const selectedWorkers = plan.transformedData.selectedWorkers;
    if (selectedWorkers[index]) {
      delete selectedWorkers[index];
    } else {
      selectedWorkers[index] = true;
    }

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          selectedWorkers,
        },
      },
    };
  },
  TOGGLE_SELECTED_TASK: (state, { params: { id, index } }) => {
    const plan = state[id];
    const selectedTasks = plan.transformedData.selectedTasks;
    if (selectedTasks[index]) {
      delete selectedTasks[index];
    } else {
      selectedTasks[index] = true;
    }

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          selectedTasks,
        },
      },
    };
  },
  TOGGLE_SELECT_ALL_WORKERS: (state, { params: { id } }) => {
    const plan = state[id];
    const selectedWorkers = {};
    if (Object.keys(plan.transformedData.selectedWorkers).length !== plan.transformedData.workers.length) {
      for (let i = 0; i < plan.transformedData.workers.length; i++) {
        selectedWorkers[i] = true;
      }
    }

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          selectedWorkers,
        },
      },
    };
  },
  TOGGLE_SELECT_ALL_TASKS: (state, { params: { id } }) => {
    const plan = state[id];
    const selectedTasks = {};
    if (Object.keys(plan.transformedData.selectedTasks).length !== plan.transformedData.tasks.length) {
      for (let i = 0; i < plan.transformedData.tasks.length; i++) {
        selectedTasks[i] = true;
      }
    }

    return {
      ...state,
      [id]: {
        ...plan,
        transformedData: {
          ...plan.transformedData,
          selectedTasks,
        },
      },
    };
  },
  SHOW_CONFIRMATION_DIALOG: (state, { params: { id, show, nextSubmenu } }) => {
    const plan = state[id];
    return {
      ...state,
      [id]: {
        ...plan,
        confirmationDialog: { show, nextSubmenu },
      },
    };
  },
  OPTIMISATION_CANCELLED: (state, { requestId }) => {
    if (state[requestId]) {
      return {
        ...state,
        [requestId]: {
          ...state[requestId],
          status: ROUTE_PLANNER_STATUSES.CANCELLED,
        },
      };
    }
  },
  OPTIMISATION_STATUS: (state, { requestId, statusData: { statusMessage } }) => {
    if (state[requestId]) {
      return {
        ...state,
        [requestId]: {
          ...state[requestId],
          message: statusMessage,
        },
      };
    }
  },
  SELECT_TASK_IN_SORTABLE_GRID: (state, { params: { requestId, taskId, position } }) => {
    if (state[requestId].sortableGrid.selectedTasks.includes(taskId)) {
      return {
        ...state,
        [requestId]: {
          ...state[requestId],
          sortableGrid: {
            ...state[requestId].sortableGrid,
            lastInteractedTaskPosition: position,
          },
        },
      };
    }

    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          selectedTasks: [...state[requestId].sortableGrid.selectedTasks, taskId],
          lastInteractedTaskPosition: position,
        },
      },
    };
  },
  UNSELECT_TASK_IN_SORTABLE_GRID: (state, { params: { requestId, taskId, position } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          selectedTasks: state[requestId].sortableGrid.selectedTasks.filter((t) => t !== taskId),
          lastInteractedTaskPosition: position,
        },
      },
    };
  },
  RESET_SELECTED_TASKS_IN_SORTABLE_GRID: (state, { params: { requestId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          selectedTasks: [],
          lastInteractedTaskPosition: null,
        },
      },
    };
  },
  SHOW_SORTABLE_GRID: (state, { params: { requestId, workerId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          workerId,
          selectedTasks: [],
          lastInteractedTaskPosition: null,
          expandedGroups: { sequenced: true, unsequenced: true },
        },
      },
    };
  },
  HIDE_SORTABLE_GRID: (state, { params: { requestId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          workerId: null,
          selectedTasks: [],
          lastInteractedTaskPosition: null,
        },
      },
    };
  },
  SHOW_TASKS_GROUPED_IN_SORTABLE_GRID: (state, { params: { requestId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          showGroups: true,
        },
      },
    };
  },
  HIDE_TASKS_GROUPING: (state, { params: { requestId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          showGroups: false,
          expandedGroups: {},
        },
      },
    };
  },
  SET_EXPANDED_GROUP: (state, { params: { requestId, type } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        sortableGrid: {
          ...state[requestId].sortableGrid,
          expandedGroups: {
            ...state[requestId].sortableGrid.expandedGroups,
            [type]: !state[requestId].sortableGrid.expandedGroups?.[type],
          },
        },
      },
    };
  },
  SHOW_UN_SEQUENCED_TASKS_DIALOG: (state, { params: { requestId, workerId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        unSequencedTasksRemoveDialog: {
          ...state[requestId].unSequencedTasksRemoveDialog,
          show: true,
          workerId,
        },
      },
    };
  },
  HIDE_UN_SEQUENCED_TASKS_DIALOG: (state, { params: { requestId } }) => {
    return {
      ...state,
      [requestId]: {
        ...state[requestId],
        unSequencedTasksRemoveDialog: {
          show: false,
          workerId: null,
        },
      },
    };
  },
};
const reducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
