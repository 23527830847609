import configureMeasurements, { volume as volumeMeasurement } from 'convert-units';

import { CUBIC_UNIT_NOTIONS, fromUnitAToUnitB } from '@yojee/helpers/unitConverter/base';

const converter = configureMeasurements({
  volume: volumeMeasurement,
});

export function getShortFormOfVolumeUnit(unit) {
  const shortFormUnit = CUBIC_UNIT_NOTIONS[unit] || unit || '';
  return shortFormUnit.endsWith('3') ? shortFormUnit : `${shortFormUnit}3`;
}

export function fromCubicCentimeterTo(value, unit) {
  return fromVolumeUnitAToVolumeUnitB(value, 'cm3', unit);
}

export function fromVolumeUnitAToVolumeUnitB(value, unitA, unitB) {
  return fromUnitAToUnitB({
    converter,
    value,
    unitA,
    unitB,
    getShortFormOfUnit: getShortFormOfVolumeUnit,
  });
}
