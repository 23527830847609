import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { getHeaders } from './common/common';

export class HubsService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  fetchHubs = ({ token, partnerJwt, slug }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/network/hubs'), {
        headers: getHeaders({ token, slug, partnerJwt }),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const hubsService = new HubsService({ auth: authService });
