import { authService } from './authService/index';
import { BaseService } from './baseService/index';

class NetworkApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getHubs = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/network/hubs`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  createHub = (payload) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/network/hubs`), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  updateHub = (payload) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/network/hubs/${payload.id}`), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteHub = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/network/hubs/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  getZones = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/network/regions`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  createZone = (payload) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/network/regions`), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  updateZone = (payload) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/network/regions/${payload.id}`), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteZone = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/network/regions/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  getOperations = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/operations`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.data ?? null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const networkApi = new NetworkApi({ authService });
