import { configureStore as configureStoreFromToolkit } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';
import sagas from './sagas';

export default function configureStore(preloadedState) {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStoreFromToolkit({
    reducer: createRootReducer(routerReducer), // root reducer with router state
    preloadedState,
    devTools: process.env.REACT_APP_DEV_TOOLS_ENABLED,
    middleware: [sagaMiddleware, routerMiddleware],
  });

  const history = createReduxHistory(store);

  sagaMiddleware.run(sagas);
  return { store, history };
}
