import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { rrulestr } from 'rrule';

const moment = extendMoment(Moment);

const getDurationInSeconds = (from, to) => {
  const dDurationStart = from.setSeconds(0, 0);
  const dDurationEnd = to.setSeconds(0, 0);
  return (dDurationEnd - dDurationStart) / 1000;
};

const getRange = (schedules, from, to, onDuty = false) => {
  const onDutySchedule = schedules.filter(
    (schedule) => schedule.type === 'worker_on' && schedule.conflict_schedule_id === null
  );
  const offDutySchedule = schedules.filter(
    (schedule) => schedule.type === 'worker_off' && schedule.conflict_schedule_id === null
  );

  const hasOnDutySchedules = Array.isArray(onDutySchedule) && onDutySchedule.length > 0;
  const hasOffDutySchedules = Array.isArray(offDutySchedule) && offDutySchedule.length > 0;

  if (!hasOnDutySchedules && !hasOffDutySchedules) {
    return [];
  }

  let periodRange = onDuty ? [] : [moment.range(from, to)];
  if (!hasOnDutySchedules && hasOffDutySchedules) {
    periodRange = [];
  }

  const subtractScheduleType = onDuty ? 'worker_off' : 'worker_on';
  [...onDutySchedule, ...offDutySchedule].forEach((s) => {
    const rule = rrulestr(s['recurrence_rule']);
    const driverEventDuration = getDurationInSeconds(
      moment(s['start_event_datetime']).toDate(),
      moment(s['end_event_datetime']).toDate()
    );

    return rule.between(from.toDate(), to.toDate()).forEach((driverScheduleDate) => {
      const driverScheduleFrom = moment(driverScheduleDate);
      const driverScheduleTo = moment(driverScheduleDate).clone().add(driverEventDuration, 'seconds');
      const scheduleRange = moment.range(driverScheduleFrom, driverScheduleTo);

      if (s.type === subtractScheduleType) {
        periodRange = [].concat(...periodRange.map((r) => r.subtract(scheduleRange)));
      }

      if (s.type !== subtractScheduleType) {
        periodRange.push(scheduleRange);
      }
    });
  });

  if (Array.isArray(periodRange)) {
    return periodRange;
  }
  return [periodRange];
};

export const getAvailableRangesInPeriod = (schedules, from, to) => {
  return getRange(schedules, from, to, true);
};

export const getNotAvailableRangesInPeriod = (schedules, from, to) => {
  return getRange(schedules, from, to, false);
};

export const isTaskAvailableInWorkerSchedule = (task, worker) => {
  if (!Array.isArray(worker?.schedules) || worker?.schedules?.length === 0) {
    return true;
  }

  const taskDate = moment(task.arrivalEarliest);

  const from = taskDate.clone().subtract(1, 'week');
  const to = taskDate.clone().add(1, 'week');

  return (
    worker &&
    worker?.schedules?.every((s) => {
      const rule = rrulestr(s['recurrence_rule']);
      const driverEventDuration = getDurationInSeconds(
        moment(s['start_event_datetime']).toDate(),
        moment(s['end_event_datetime']).toDate()
      );

      const result = rule.between(from.toDate(), to.toDate()).find((driverScheduleDate) => {
        const driverScheduleFrom = moment(driverScheduleDate);
        const driverScheduleTo = moment(driverScheduleDate).clone().add(driverEventDuration, 'seconds');
        const scheduleRange = moment.range(driverScheduleFrom, driverScheduleTo);

        return scheduleRange.contains(moment(task.arrivalEarliest));
      });

      if (s.type === 'worker_on') {
        return result;
      }

      if (s.type === 'worker_off') {
        return !result;
      }

      return true;
    })
  );
};
