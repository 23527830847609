import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { getHeaders } from './common/common';

export class TasksService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  fetchTasksPost = (params = {}) => {
    return this.authService.post(
      this.getUmbrellaApiUrl('dispatcher/power/tasks'),
      params,
      getHeaders({ partnerJwt: params.partnerJwt })
    );
  };

  uploadTasks = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/batches'), formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchStatus = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/batches/check_status'), { batch_id: id })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  bulkComplete = (taskIds) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/tasks/bulk_complete'), { task_ids: taskIds })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkCompleteBulkTasksStatus = (batchId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/tasks/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  completeSingleTask = ({ taskId, taskGroupId }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/task_groups/${taskGroupId}/task/${taskId}/complete`), {})
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  reportSingleTask = ({ taskId, taskGroupId, reason }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/task_groups/${taskGroupId}/task/${taskId}/mark_as_failed`), reason)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  reportMultipleTask = ({ tasks, reason }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/tasks/bulk_report`), { tasks: tasks, description: reason })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  checkReportBulkTasksStatus = ({ processId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/tasks/bulk_report/${processId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  searchIds = ({ searchText }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/search/order_item'), {
        q: searchText,
        page_size: 100,
        page: 1,
        extended_result: true,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  bulkUpdate = ({ orderItemStepIds, update }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/tasks/bulk_update`), {
        order_item_step_ids: orderItemStepIds,
        ...update,
      })
      .catch((error) => this.handleError(error));
  };

  bulkUpdateStatus = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/tasks/bulk_update/status/${id}`))
      .catch((error) => this.handleError(error));
  };

  broadcast = ({ price_amount, price_currency, task_group_ids, timeout_in_minutes, worker_team_ids }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/broadcasts`), {
        price_amount,
        price_currency,
        task_group_ids,
        timeout_in_minutes,
        worker_team_ids,
      })
      .catch((error) => this.handleError(error));
  };

  fetchETA = ({ tasksIds, partnerJwt }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/tasks/eta`), { task_ids: tasksIds }, getHeaders({ partnerJwt }))
      .catch((error) => this.handleError(error));
  };

  getTaskExceptionsReason = ({ types = [], partnerJwt }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons`), {
        params: { task_types: types },
        headers: getHeaders({ partnerJwt }),
      })
      .catch((error) => this.handleError(error));
  };

  computeEta = ({ tasksIds, workerId }) => {
    return this.authService
      .post(
        this.getUmbrellaApiUrl(`dispatcher/tasks/compute_eta`),
        { task_ids: tasksIds, worker_id: workerId },
        getHeaders({})
      )
      .catch((error) => this.handleError(error));
  };

  fetch = ({ page, options }) => {
    const params = { page };
    if (options.sort && options.sort.by) {
      params.sort_by = options.sort.by;
      params.order = options.sort.order;
    }

    if (options.filters) {
      if (Array.isArray(options.filters.tripIds)) {
        params.trip_ids = options.filters.tripIds;
      }

      if (options.filters.status) {
        params.task_states = options.filters.status;
      }

      if (options.filters.orderItemIds) {
        params.order_item_ids = options.filters.orderItemIds;
      }
    }

    if (options.pageSize) {
      params.page_size = 100;
    }

    return this.authService.get(this.getUmbrellaApiUrl('dispatcher/tasks'), params);
  };

  fetchV2 = (params) => {
    return this.authService.get(this.getUmbrellaApiUrl('dispatcher/power/tasks'), params);
  };
}

export const tasksService = new TasksService({ authService });
