import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { handleError } from '@yojee/api/baseService';
import { getHeaders } from '@yojee/api/common';
import getEnv from '@yojee/helpers/env/getEnv';

const API_URL = getEnv('REACT_APP_API_URL');
const API_URL_V4 = getEnv('REACT_APP_API_URL_V4');

export function fetchOrders({ token, partnerJwt, slug, filters, pagination }) {
  if (filters.query) {
    return searchOrders({ token, partnerJwt, slug, filters, pagination });
  }
  return APIAxiosInstance.get(`${API_URL}/dispatcher/orders`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      page_size: pagination.pageSize,
      page: pagination.currentPage + 1,
      order: 'desc',
      order_group_number: filters.batchId || undefined,
      ...{ status: filters.statuses },
      ...{ from: filters.from },
      ...{ to: filters.to },
    },
  });
}

export function searchOrders({ token, partnerJwt, slug, filters, pagination }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/search/order`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      page_size: pagination.pageSize,
      page: pagination.currentPage + 1,
      q: filters.query,
    },
  });
}

export function fetchDownStreamPartnerJWT({ cip, slug }) {
  const params = {
    partner_cip: cip,
  };

  return APIAxiosInstance.post(`${API_URL}/dispatcher/jwt/control_tower`, params, { headers: getHeaders({ slug }) });
}

export function fetchDownStreamPartnersList({
  searchText,
  token,
  partnerJwt,
  slug,
  state = 'active',
  includeInactiveCompany = false,
}) {
  const params = {
    page_size: 300,
    page: 1,
    type: 'downstream',
    state,
    include_inactive_company: includeInactiveCompany,
  };
  if (searchText) {
    params.q = searchText;
  }
  return APIAxiosInstance.get(`${API_URL}/dispatcher/partners`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: params,
  });
}

export function transferOrderToPartner({ token, partnerJwt, slug, cip, orderNumbers }) {
  const params = {
    partner_cip: cip,
    order_numbers: orderNumbers,
    source: 'transfer-manual',
  };

  return APIAxiosInstance.post(`${API_URL}/dispatcher/partner_transfer/sender/create_order`, params, {
    headers: getHeaders({
      token,
      slug,
      partnerJwt,
    }),
  })
    .then((response) => response)
    .catch((error) => handleError(error));
}

export function rejectOrderTransfer({ token, partnerJwt, slug, numbers, cancelled_notes, reason_code }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/partner_transfer/dispatcher/bulk_reject_order`,
    { order_numbers: numbers, cancelled_notes: cancelled_notes, reason_code: reason_code },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  )
    .then((response) => response)
    .catch((error) => handleError(error));
}

export function acceptOrderTransfer({ token, partnerJwt, slug, numbers }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/partner_transfer/dispatcher/bulk_accept_order`,
    { order_numbers: numbers },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  )
    .then((response) => response)
    .catch((error) => handleError(error));
}

export function updateOrder({ token, partnerJwt, slug, number, params }) {
  return APIAxiosInstance.put(
    `${API_URL}/dispatcher/orders/${number}`,
    { order: params },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function cancelOrder({ token, partnerJwt, slug, number, cancelled_notes, reason_code }) {
  return APIAxiosInstance.put(
    `${API_URL}/dispatcher/orders/${number}/cancel`,
    { cancelled_notes, reason_code },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function createMultilegOrder({ body, token, slug, partnerJwt }) {
  return APIAxiosInstance.post(`${API_URL}/dispatcher/orders_multi_leg`, body, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function uploadOrder(
  { file, uploaderId, companyId, format, placedByUserProfileId, externalId, containerNo, batchId, multileg, type },
  { token, slug, partnerJwt }
) {
  const uploadJson = new FormData();
  uploadJson.set('file', file);
  uploadJson.set('format', format);
  if (uploaderId) uploadJson.set('uploader_id', uploaderId);
  if (companyId) uploadJson.set('company_id', companyId);
  if (placedByUserProfileId) uploadJson.set('placed_by_user_profile_id', placedByUserProfileId);
  if (externalId) {
    uploadJson.set('external_id', externalId);
  }
  if (containerNo) {
    uploadJson.set('container_no', containerNo);
  }
  if (multileg) {
    uploadJson.set('multileg', multileg);
  }

  let headers = getHeaders({ token, slug, partnerJwt });
  headers = { ...headers, 'Content-Type': 'multipart/form-data' };
  const config = {
    onUploadProgress: (progressEvent) => console.log('Event:', progressEvent.loaded),
  };

  const queryParam = type ? `?type=${type}` : '';

  if (batchId) {
    return APIAxiosInstance.put(`${API_URL}/dispatcher/batches/${batchId}/continue${queryParam}`, uploadJson, {
      headers,
      config,
    });
  } else {
    return APIAxiosInstance.post(`${API_URL}/dispatcher/batches${queryParam}`, uploadJson, { headers, config });
  }
}

export function checkBatchStatus(params = {}, { token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/batches/check_status`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: params,
  });
}

export function newUploadOrder(
  { file, uploaderId, companyId, format, placedByUserProfileId, batchId, templateId },
  { token, slug, partnerJwt }
) {
  const uploadJson = new FormData();
  uploadJson.set('file', file);
  uploadJson.set('format', format);
  if (uploaderId) {
    uploadJson.set('uploader_id', uploaderId);
  }
  if (companyId) {
    uploadJson.set('company_id', companyId);
  }
  if (placedByUserProfileId) {
    uploadJson.set('placed_by_user_profile_id', placedByUserProfileId);
  }
  if (templateId) {
    uploadJson.set('template_id', templateId);
  }

  let headers = getHeaders({ token, slug, partnerJwt });
  headers = { ...headers, 'Content-Type': 'multipart/form-data' };
  const config = {
    onUploadProgress: (progressEvent) => console.log('Event:', progressEvent.loaded),
  };

  if (batchId) {
    return APIAxiosInstance.post(`${API_URL_V4}/company/orders/batch/${batchId}/continue`, undefined, {
      headers,
      config,
    });
  } else {
    return APIAxiosInstance.post(`${API_URL_V4}/company/orders/batch`, uploadJson, { headers, config });
  }
}

export function newCheckBatchStatus(batchId, { token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL_V4}/company/orders/batch/${batchId}/status`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function downloadConnote(orderNumber) {
  return APIAxiosInstance.get(`${API_URL}/public/orders/${orderNumber}/consignment_note?format=pdf`, {
    responseType: 'blob',
  });
}

export function downloadLabel(orderNumber) {
  return APIAxiosInstance.get(`${API_URL}/public/labels/order/${orderNumber}?format=pdf`, {
    responseType: 'blob',
  });
}

export function downloadPod(orderNumber) {
  return APIAxiosInstance.get(`${API_URL}/public/pods/order/${orderNumber}?format=pdf`, {
    responseType: 'blob',
  });
}
