import { call, put, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';

export default function* sagas() {
  yield takeLatest('SEARCH_DRIVER_TAG', searchDriverTag);
}

function* searchDriverTag({ searchKey }) {
  try {
    yield put({ type: 'SEARCH_DRIVER_TAG_START' });
    const data = yield call(companyService.getCompanyTags, {
      q: searchKey,
      type: 'worker',
    });
    yield put({ type: 'SEARCH_DRIVER_TAG_SUCCESS', driverTags: data });
  } catch (error) {
    yield put({ type: 'SEARCH_DRIVER_TAG_FAILED', error });
  }
}
