import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class PaymentService extends BaseService {
  constructor({ service }) {
    super();
    this.authService = service;
  }

  getStripeKey = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/payments/stripe_key`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getPlanInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/payments/stripe/plans`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createStripeSubscription({ customerToken, planUuid, providerType }) {
    return this.authService
      .post('api/v3/dispatcher/company/subscriptions', {
        customer_token: customerToken,
        provider_plan_uuid: planUuid,
        provider_type: providerType,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  }

  updateCreditCard({ cardToken, providerType }) {
    return this.authService
      .post('api/v3/dispatcher/company/payment/card', {
        card_token: cardToken,
        provider_type: providerType,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  }
}

export const paymentService = new PaymentService({ service: authService });
