import JSzip from 'jszip';
import moment from 'moment-timezone';
import { push } from 'redux-first-history';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import * as umbrellaApi from '@yojee/api/umbrellaApi';
import AuthSelectors from '@yojee/auth/store/selectors';
import { SENDER_TYPES } from '@yojee/helpers/constants';
import { normalizedTasksToStop_ListView_byView } from '@yojee/helpers/tasksToStops';

import * as Api from '../../Api/ordersApi';
import { defaultPagination } from '../../reducers/orders';
import { consolidateContainer } from '../tasks/saga';
import { generateCompress, getFileFromResponse, sagaRetry, saveFile } from './helper';

const getFilterData = (state) => state.orders.filters;
const getPaginationData = (state) => state.orders.pagination;
const getBatchUploadData = (state) => state.orders.batchUpload;
const getReviewItemsTasksData = (state) => state.orders.reviewItemsDialog.tasks;
const getItemsTableCurrentView = (state) => state.itemsTable && state.itemsTable.currentView;

export default function* sagas() {
  yield takeLatest('REQUEST_ORDERS_LIST_INFO', requestOrdersList);
  yield takeLatest('UPDATE_ORDER', updateOrder);
  yield takeLatest('CANCEL_ORDER', cancelOrder);
  yield takeLatest('UPDATE_ORDERS_FILTERS', updateOrdersFilters);
  yield takeLatest('UPDATE_ORDERS_PAGINATION', updateOrdersPagination);
  yield takeLatest('REQUEST_DOWNSTREAM_PARTNERS_LIST_INFO', requestDownStreamPartnersList);
  yield takeLatest('TRANSFER_ORDER_TO_PARTNER', transferOrderToPartner);
  yield takeLatest('REJECT_ORDER_TRANSFER', rejectOrderTransfer);
  yield takeLatest('ACCEPT_ORDER_TRANSFER', acceptOrderTransfer);
  yield takeLatest('CREATE_MULTILEG_ORDER_AND_CONSOLIDATE', createMultilegOrderAndConsolidate);
  yield takeLatest('UPDATED_SELECTED_ORGANIZATION', updateSelectedOrganization);
  yield takeLatest('UPLOAD_ORDER', uploadOrder);
  yield takeLatest('NEW_UPLOAD_ORDER', newUploadOrder);
  yield takeLatest('UPLOAD_REFRESH', refreshUploadOrder);
  yield takeLatest('DOWNLOAD_SAMPLE', downloadSample);
  yield debounce(1000, 'SEARCH_ORGANIZATION', searchOrganizations);
  yield takeLatest('GET_ORGANISATIONS', getOrganisations);
  yield takeLatest('REQUEST_ORDER_TASKS', requestOrderTasks);
  yield takeLatest('CALCULATE_NODES_FOR_DATA', calculateNodes);
  yield takeLatest('DOWNLOAD_BULK_CONNOTE', downloadBulkConnote);
  yield takeLatest('DOWNLOAD_BULK_LABEL', downloadBulkLabel);
}

function* requestOrdersList(payload) {
  yield put({ type: 'ORDERS_LOADING', key: 'requestOrdersList' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    let filters = payload && payload.filters;
    if (!filters) {
      filters = yield select(getFilterData);
    }
    let currentPagination = payload && payload.pagination;
    if (!currentPagination) {
      currentPagination = yield select(getPaginationData);
    }
    const {
      data: { data, pagination },
    } = yield call(Api.fetchOrders, { token, slug, partnerJwt, filters, pagination: currentPagination });
    yield put({ type: 'REQUEST_ORDERS_LIST_SUCCESS', data, pagination });
  } catch (error) {
    yield put({ type: 'REQUEST_ORDERS_LIST_FAILED', error });
  }
}

function* updateOrder({ payload }) {
  yield put({ type: 'ORDERS_LOADING', key: 'updateOrder' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.updateOrder, { token, slug, partnerJwt, ...payload });
    yield put({ type: 'UPDATE_ORDER_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'UPDATE_ORDER_FAILED', error });
  }
}

function* cancelOrder({ payload }) {
  yield put({ type: 'ORDERS_LOADING', key: 'cancelOrder' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { message },
    } = yield call(Api.cancelOrder, { token, slug, partnerJwt, ...payload });
    yield put({ type: 'CANCEL_ORDER_SUCCESS', message });
  } catch (error) {
    yield put({ type: 'CANCEL_ORDER_FAILED', error });
  }
}

function* updateOrdersFilters({ payload: { filters, refresh = true } }) {
  yield put({ type: 'ORDERS_LOADING', key: 'updateOrdersFilters' });
  if (refresh) {
    yield call(requestOrdersList, { filters, pagination: defaultPagination });
  }
  yield put({ type: 'UPDATE_ORDERS_FILTERS_SUCCESS' });
}

function* updateOrdersPagination({ payload: { pagination, refresh = true } }) {
  yield put({ type: 'ORDERS_LOADING', key: 'updateOrdersPagination' });
  if (refresh) {
    yield call(requestOrdersList, { pagination });
  }
}

function* requestDownStreamPartnersList() {
  yield put({ type: 'ORDERS_LOADING', key: 'requestDownStreamPartnersList' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.fetchDownStreamPartnersList, { token, slug, partnerJwt, state: 'active' });
    yield put({ type: 'REQUEST_DOWNSTREAM_PARTNERS_LIST_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'REQUEST_DOWNSTREAM_PARTNERS_LIST_FAILED', error });
  }
}

function* transferOrderToPartner({ payload: { cip, orderNumbers } }) {
  yield put({ type: 'ORDERS_LOADING', key: 'transferOrderToPartner' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { message },
    } = yield call(Api.transferOrderToPartner, { token, slug, partnerJwt, cip, orderNumbers });
    yield put({ type: 'TRANSFER_ORDER_TO_PARTNER_SUCCESS', message });
  } catch (error) {
    console.error(error);
    yield put({ type: 'TRANSFER_ORDER_TO_PARTNER_FAILED', message: error.message });
  }
}

function* rejectOrderTransfer({ payload: { numbers, onSuccess, cancelled_notes, reason_code } }) {
  yield put({
    type: 'ORDERS_LOADING',
    key: 'rejectOrderTransfer',
    orderNumbers: numbers,
  });

  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { message },
    } = yield call(Api.rejectOrderTransfer, { token, slug, partnerJwt, numbers, cancelled_notes, reason_code });
    yield put({
      type: 'REJECT_ORDER_TRANSFER_SUCCESS',
      orderNumbers: numbers,
      message,
    });
    onSuccess?.();
  } catch (error) {
    yield put({
      type: 'REJECT_ORDER_TRANSFER_FAILED',
      orderNumbers: numbers,
      error,
    });
  }
}

function* acceptOrderTransfer({ payload: { numbers, onSuccess } }) {
  yield put({
    type: 'ORDERS_LOADING',
    key: 'acceptOrderTransfer',
    orderNumbers: numbers,
  });

  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { message },
    } = yield call(Api.acceptOrderTransfer, { token, slug, partnerJwt, numbers });
    yield put({
      type: 'ACCEPT_ORDER_TRANSFER_SUCCESS',
      orderNumbers: numbers,
      message,
    });
    onSuccess?.();
  } catch (error) {
    yield put({
      type: 'ACCEPT_ORDER_TRANSFER_FAILED',
      orderNumbers: numbers,
      error,
    });
  }
}

function* createMultilegOrder({ items, steps, itemSteps, containerId, containerData }, redirect = true, bodyData = {}) {
  yield put({ type: 'MULTILEG_ORDER_CREATION_STARTED' });
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    data: { data: organisations },
  } = yield call(umbrellaApi.getOrganisations, { token, slug, pageSize: 1 });

  const {
    data: { data },
  } = yield call(Api.createMultilegOrder, {
    body: {
      items,
      steps,
      item_steps: itemSteps,
      sender_id: organisations[0].sender.id,
      sender_type: SENDER_TYPES.organisation,
      placed_by_user_profile_id: organisations[0].sender.user_profile_id,
      ...bodyData,
    },
    token,
    slug,
  });

  yield put({ type: 'MULTILEG_ORDER_CREATION_SUCCESSFUL', createdOrder: data });
  if (redirect) {
    const prefix = process.env.REACT_APP_PREFIX || '';
    yield put(
      push(`${prefix}/scan/pick_parent`, { container: containerId, pallet: data['order_items'][0].id, containerData })
    );
  }

  return data;
}

function* createMultilegOrderAndConsolidate({ items, steps, itemSteps, containerId, containerData }) {
  const {
    token,
    dispatcher_info: {
      data: {
        company: {
          slug,
          settings: {
            company: { timezone },
          },
        },
      },
    },
  } = yield select(AuthSelectors.getData);
  const params = {
    page: 1,
    limit: 1,
    query_version: 2,
    task_query_type: 'all',
    include_count: true,
    parent_item_tracking_number: containerData[0]['order_item']['tracking_number'],
  };

  const {
    data: { count },
  } = yield call(umbrellaApi.fetchTasks, params, { token, slug });
  const containerPickupTask = containerData.find((t) => t.type === 'pickup');
  items[0]['external_customer_id'] += `-${moment.utc(containerPickupTask.from).tz(timezone).format('DDMMYY')}-${
    parseInt(count / 2, 10) + 1
  }`;

  const orderData = yield call(createMultilegOrder, { items, steps, itemSteps, containerId, containerData }, false, {
    external_id: items[0]['external_customer_id'],
  });

  const { success } = yield call(consolidateContainer, {
    containerTrackingNumber: containerData[0]['order_item']['tracking_number'],
    pallet: [
      {
        order_item: orderData['order_items'][0],
        location: {
          lat: steps[0].lat,
          lng: steps[0].lng,
        },
      },
      {
        order_item: orderData['order_items'][0],
        location: {
          lat: steps[1].lat,
          lng: steps[1].lng,
        },
      },
    ],
  });

  if (success) {
    const prefix = process.env.REACT_APP_PREFIX || '';
    yield put(
      push(`${prefix}/scan/pick_parent`, {
        container: containerId,
        pallet: orderData['order_items'][0].id,
        containerData,
      })
    );
  }
}

function* updateSelectedOrganization({ data }) {
  try {
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const senderByOrganizationId = yield call(umbrellaApi.getSenderByOrganizationId, {
      organizationId: data.id,
      token,
      slug,
    });
    const result = senderByOrganizationId.data && senderByOrganizationId.data.data;
    yield put({ type: 'UPDATE_SENDERS_BY_ORGANIZATION_ID', result });
  } catch (error) {
    yield put({ type: 'UPDATE_SENDERS_BY_ORGANIZATION_ID', result: [] });
    console.error(error);
  }
}

function* searchOrganizations({ searchText }) {
  try {
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const searchResult = yield call(umbrellaApi.searchOrganization, { searchText, token, slug });
    const { data } = searchResult.data;
    yield put({ type: 'UPDATE_SEARCH_ORGANIZATION_RESULT', data });
  } catch (error) {
    console.error(error);
    yield put({ type: 'UPDATE_SEARCH_ORGANIZATION_RESULT', data: [] });
  }
}

function* getOrganisations({ pageSize = 100, pageNo = 1 }) {
  try {
    const {
      getOrganisationsData: {
        pagination: { current_page, total_pages },
      },
    } = yield select(getBatchUploadData);
    if (current_page < total_pages) {
      const {
        token,
        dispatcher_info: {
          data: {
            company: { slug },
          },
        },
      } = yield select(AuthSelectors.getData);
      const organisationData = yield call(umbrellaApi.getOrganisations, { token, slug, pageSize, pageNo });
      const { data, pagination } = organisationData.data;
      yield put({ type: 'GET_ORGANISATIONS_SUCCESSFUL', data, pagination });
    }
  } catch (error) {
    yield put({ type: 'GET_ORGANISATIONS_FAILED', error });
  }
}

function* requestOrderTasks({ payload: { orderNumber } }) {
  try {
    yield put({ type: 'ORDERS_LOADING', key: 'requestOrderTasks' });
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      pagination: { current_page, limit_value },
      legs,
    } = yield select(getReviewItemsTasksData);

    let newTaskList = {};
    const params = {
      page: current_page + 1,
      page_size: limit_value,
      order_number: orderNumber,
      include_transfer_properties: true,
      query_version: 'v3',
      task_states: ['completed', 'created', 'pending_transfer', 'transferred'],
    };
    const response = yield call(umbrellaApi.fetchTransferedTasks, params, { token, partnerJwt, slug });

    let newLoadedLegs = response?.data?.data ?? [];
    newLoadedLegs = [...legs, ...newLoadedLegs];
    if (newLoadedLegs?.length > 0) {
      newTaskList = {
        data: newLoadedLegs.map((t, i) => ({ ...t, task: t, order_step: t, id: `transferred_task_${i}` })),
        count: newLoadedLegs.length,
        countType: 'eq',
      };
    } else {
      newTaskList = {};
    }

    yield put({
      type: 'REQUEST_ORDER_TASKS_SUCCESSFUL',
      legs: newLoadedLegs,
      tasks: newTaskList,
      pagination: response.data.pagination,
    });

    const { data, ids } = yield select(getReviewItemsTasksData);
    if (Object.keys(data || {}).length > 0 && ids?.length) {
      yield put({ type: 'CALCULATE_NODES_FOR_DATA', data, ids });
    }
  } catch (error) {
    yield put({ type: 'REQUEST_ORDER_TASKS_FAILED', error });
  }
}

function* calculateNodes({ data, ids }) {
  const taskListFiltered = ids.filter((t) => data[t].task.state !== 'failed');
  const currentView = yield select(getItemsTableCurrentView);
  const result = normalizedTasksToStop_ListView_byView(data, taskListFiltered, currentView);
  const itemsKeys = result.stopKeys;
  const items = result.stops;
  yield put({ type: 'UPDATE_ITEMS', itemsKeys, items, totalTasksInput: taskListFiltered.length });
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

function* uploadOrder(info) {
  try {
    const {
      file,
      uploaderId,
      companyId,
      format,
      placedByUserProfileId,
      externalId,
      containerNo,
      batchId: batchUploadId,
      type,
      multileg,
    } = info.uploadData;
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'UPLOAD_ORDER_STARTED' });
    const {
      data: { data },
    } = yield call(
      Api.uploadOrder,
      {
        file,
        uploaderId,
        companyId,
        format,
        placedByUserProfileId,
        externalId,
        containerNo,
        batchId: batchUploadId,
        multileg,
        type,
      },
      { token, slug }
    );

    const { id: batchId } = data;
    yield put({ type: 'UPLOAD_ORDER_PROCESSING', id: batchId });

    let status = 'processing';
    while (['processing', 'created'].includes(status)) {
      const {
        data: { data: statusData },
      } = yield call(Api.checkBatchStatus, { batch_id: batchId }, { token, slug });
      status = statusData.status;
      yield delay(1000);
    }
    if (status === 'completed') {
      yield put({ type: 'UPLOAD_ORDER_SUCCEEDED', id: batchId });
    } else if (status === 'failed') {
      yield put({ type: 'UPLOAD_ORDER_FAILED', batchId });
    }
  } catch (e) {
    const errors = e.response?.data?.data ?? [
      { serverError: e.response?.data ?? ['Something went wrong, please try again.'] },
    ];
    if (!e.response) {
      console.error(`Error during order batch upload (Status: ${e.status})`, e);
    }
    yield put({ type: 'UPLOAD_ORDER_FAILED', errors: errors });
  }
}

function* newUploadOrder(info) {
  try {
    const {
      file,
      uploaderId,
      companyId,
      format,
      placedByUserProfileId,
      batchId: batchUploadId,
      templateId,
    } = info.uploadData;
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'UPLOAD_ORDER_STARTED' });
    const {
      data: { data },
    } = yield call(
      Api.newUploadOrder,
      { file, uploaderId, companyId, format, placedByUserProfileId, batchId: batchUploadId, templateId },
      { token, slug }
    );

    const { id: batchId } = data;
    if (batchId) {
      yield put({ type: 'UPLOAD_ORDER_PROCESSING', id: batchId });

      let status = 'processing';
      let errors = {};
      while (['processing', 'created'].includes(status)) {
        const {
          data: { data: statusData },
        } = yield call(Api.newCheckBatchStatus, batchId, { token, slug });
        status = statusData.status;
        errors = statusData.errors;
        yield delay(1000);
      }
      if (status === 'completed') {
        yield put({ type: 'UPLOAD_ORDER_SUCCEEDED', id: batchId });
      } else if (status === 'failed') {
        yield put({ type: 'UPLOAD_ORDER_FAILED', batchId, errors });
      } else if (status === 'missing_info') {
        yield put({ type: 'UPLOAD_ORDER_MISSING_INFO', id: batchId, errors });
      }
    } else {
      yield put({ type: 'UPLOAD_ORDER_SUCCEEDED', id: batchUploadId });
    }
  } catch (e) {
    const errors = e?.response?.data?.errors ?? 'Internal server error. Please try again';
    yield put({ type: 'UPLOAD_ORDER_FAILED', errors });
  }
}

function* refreshUploadOrder() {
  yield put({ type: 'GET_ORGANISATIONS' });
}

function* downloadSample({ payload: { format = 'csv', type } }) {
  const {
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  window.open(umbrellaApi.getSampleUrl(format, type, { slug }), '_self');
}

function* downloadBulkConnote({ payload }) {
  try {
    if (!payload || !Array.isArray(payload) || payload.length === 0) {
      throw new Error('Please select at least one order');
    }

    if (payload.length === 1) {
      // Download the file directly if there is only 1 file
      const orderNumber = payload[0];
      const defaultFileName = `consignment-note-${orderNumber}.pdf`;
      const pdfFile = getFileFromResponse(yield call(Api.downloadConnote, orderNumber), defaultFileName);
      saveFile(pdfFile, pdfFile.name);
    } else {
      const zip = new JSzip();
      const fileName = 'Download_Connote.zip';

      // Download all pdf files
      const downloadResponses = yield all(
        payload.map((orderNumber) => call(sagaRetry, { request: Api.downloadConnote, data: orderNumber }))
      );

      // Convert each response to a PDF file and add to zip
      downloadResponses.forEach((res, index) => {
        const defaultFileName = `consignment-note-${payload[index]}.pdf`;
        const pdfFile = getFileFromResponse(res, defaultFileName);
        zip.file(pdfFile.name, pdfFile);
      });

      // Save the zip file
      yield call(generateCompress, zip, fileName);
    }

    yield put({ type: 'DOWNLOAD_BULK_CONNOTE_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'DOWNLOAD_BULK_CONNOTE_FAILURE',
      error: error.response?.data?.message || error.message || 'Something went wrong',
    });
  }
}

function* downloadBulkLabel({ payload }) {
  try {
    if (!payload || !Array.isArray(payload) || payload.length === 0) {
      throw new Error('Please select at least one order');
    }

    if (payload.length === 1) {
      // Download the file directly if there is only 1 file
      const orderNumber = payload[0];
      const defaultFileName = `waybill-${orderNumber}.pdf`;
      const pdfFile = getFileFromResponse(yield call(Api.downloadLabel, orderNumber), defaultFileName);
      saveFile(pdfFile, pdfFile.name);
    } else {
      const zip = new JSzip();
      const fileName = 'Download_Waybill.zip';

      // Download all pdf files
      const downloadResponses = yield all(
        payload.map((orderNumber) => call(sagaRetry, { request: Api.downloadLabel, data: orderNumber }))
      );

      // Convert each response to a PDF file and add to zip
      downloadResponses.forEach((res, index) => {
        const defaultFileName = `waybill-${payload[index]}.pdf`;
        const pdfFile = getFileFromResponse(res, defaultFileName);
        zip.file(pdfFile.name, pdfFile);
      });

      // Save the zip file
      yield call(generateCompress, zip, fileName);
    }

    yield put({ type: 'DOWNLOAD_BULK_LABEL_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'DOWNLOAD_BULK_LABEL_FAILURE',
      error: error.response?.data?.message || error.message || 'Something went wrong',
    });
  }
}
