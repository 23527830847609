import { call, put, takeLatest } from 'redux-saga/effects';

import { networkApi } from '@yojee/api/networkApi';

export default function* sagas() {
  yield takeLatest('GET_HUBS', getHubs);
}

function* getHubs() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getHubs' });
    const data = yield call(networkApi.getHubs);
    yield put({ type: 'GET_HUBS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_HUBS_FAILED', error });
  }
}
