import _ from 'lodash-es';
const defaultState = {
  isLoading: false,
  drivers: [],
  errorMessage: undefined,
  searchKey: '',
  selectedDriverIds: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SEARCH_DRIVER_START: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  SEARCH_DRIVER_SUCCESS: (state, { drivers }) => {
    const selectedIDs = state.selectedDriverIds;
    const oldDriverListSelected = state.drivers.filter((d) => selectedIDs.includes(d.id));
    const newValuedAdded = _.differenceWith(drivers, oldDriverListSelected, (a, b) => a.id === b.id);
    return {
      ...state,
      isLoading: false,
      drivers: newValuedAdded.concat(oldDriverListSelected),
    };
  },
  SEARCH_DRIVER_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
  UPDATE_DRIVER_IDS: (state, { driverIds }) => {
    return {
      ...state,
      selectedDriverIds: driverIds,
    };
  },
  CLEAR_MASTER_FILTER: (state) => {
    return {
      ...state,
      selectedDriverIds: [],
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
