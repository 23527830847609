import { APIAxiosInstance } from './authService/dispatcherAuthService';
import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { filterBreakTimeTasks } from './solver/filter-break-time-task';

export class SolverService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  planRoute = (jwt, requestBody, mode = 'solver') => {
    const headers = { JWT: jwt };
    return APIAxiosInstance.post(this._getSolverApiUrl(`${mode}/request`), requestBody, { headers })
      .then((result) => {
        if (result.data) {
          result.data.tasksProperties = requestBody.tasks.reduce((acc, t) => {
            acc[t.id] = t.properties;
            return acc;
          }, {});
        }

        return result;
      })
      .catch((error) => this.handleError(error));
  };

  optimisationStatus = (id, jwt, mode = 'solver') => {
    const headers = { JWT: jwt };
    return APIAxiosInstance.get(this._getSolverApiUrl(`${mode}/status/${id}`), { headers }).catch((error) =>
      this.handleError(error)
    );
  };

  optimisationResult = ({ requestId, jwt, statusData, mode = 'solver' }) => {
    const headers = statusData?.url ? {} : { JWT: jwt };
    const url = statusData?.url ? statusData.url : this._getSolverApiUrl(`${mode}/result/${requestId}`);

    return APIAxiosInstance.get(url, { headers, validateStatus: (status) => status !== 400 })
      .then((response) => {
        // Filter out break task
        filterBreakTimeTasks(response.data);

        return response;
      })
      .catch((e) => APIAxiosInstance.get(e.request.responseURL).catch((error) => this.handleError(error)));
  };
}

export const solverService = new SolverService({ auth: authService });
