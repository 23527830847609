const defaultState = {
  status: 'idle',
  billingDataList: [],
  billingJobDetailsList: [],
  billingJobDetailsTable: [],
  chargesInvoicesData: {}, // invoices details acquired from charges
  invoiceTotal: [],
  overallTotalRaw: [],
  overallTotal: [],
  ordersCount: {
    main: 0,
    warning: 0,
    manual: 0,
  },

  invoiceStatus: null, // calculation status of current invoice
  billingDataListStatus: 'idle', // idle || running || failed || success
  createBillingJobStatus: 'idle', // idle || running || failed || success
  billingDetailsStatus: 'idle', // idle || running || failed || success
  billingDetailsCalculateStatus: 'idle', // idle || running || failed || success
  billingDetailsGenerateInvoice: 'idle', // idle || running || failed || success
  billingDetailsFetchCalculationStatus: 'idle', // idle || running || failed || success
  billingDetailsManualStatus: 'idle', // idle || running || failed || success

  successMessage: undefined,
  errorMessage: undefined,
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_BILLING_JOB_STATE: () => {
    return defaultState;
  },
  BILLING_JOB_CLEAR_MESSAGE: (state) => {
    return {
      ...state,
      successMessage: undefined,
      errorMessage: undefined,
    };
  },
  BILLING_JOB_ADD_MESSAGE: (state, action) => {
    return {
      ...state,
      [action.payload.type]: action.payload.data,
    };
  },
  BILLING_JOB_SET_STATUS: (state, action) => {
    return {
      ...state,
      [action.payload.status]: action.payload.data,
    };
  },
  BILLING_JOB_FETCH_LIST_SUCCESS: (state, action) => {
    return {
      ...state,
      billingDataList: action.payload.data,
      pagination: action.payload.pagination,
    };
  },
  BILLING_JOB_FETCH_DETAILS_SUCCESS: (state, action) => {
    return {
      ...state,
      billingJobDetailsList: action.payload.data,
      pagination: action.payload.pagination,
    };
  },
  BILLING_JOB_FETCH_CHARGE_INVOICE_DETAILS_SUCCESS: (state, action) => {
    return {
      ...state,
      chargesInvoicesData: action.payload.data,
    };
  },
  BILLING_JOB_FETCH_INVOICE_TOTAL_SUCCESS: (state, action) => {
    return {
      ...state,
      invoiceTotal: action.payload.data,
    };
  },
  BILLING_JOB_CHECK_CALCULATE_STATUS_SUCCESS: (state, action) => {
    return {
      ...state,
      invoiceStatus: action.payload.data,
    };
  },
  BILLING_JOB_FETCH_ORDERS_COUNT_SUCCESS: (state, action) => {
    return {
      ...state,
      ordersCount: action.payload.data,
    };
  },
  BILLING_JOB_FETCH_TOTAL_SUCCESS: (state, action) => {
    return {
      ...state,
      overallTotalRaw: action.payload.data,
      overallTotal: action.payload.data,
    };
  },
  BILLING_JOB_ADJUST_JOB_TOTAL: (state, action) => {
    return {
      ...state,
      overallTotal: action.payload.data,
    };
  },
  BILLING_JOB_FETCH_INVOICE_STATUS_SUCCESS: (state, action) => {
    return {
      ...state,
      allInvoiceGenerationStatus: action.payload.data,
    };
  },
};

const reducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
