import { call, put, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';

export default function* sagas() {
  yield takeLatest('SEARCH_ADDRESS_TAG', searchAddressTag);
}

function* searchAddressTag({ searchKey }) {
  try {
    yield put({ type: 'SEARCH_ADDRESS_TAG_START' });
    const data = yield call(companyService.getCompanyTags, {
      q: searchKey,
      type: 'address_item',
    });
    yield put({ type: 'SEARCH_ADDRESS_TAG_SUCCESS', addressTags: data });
  } catch (error) {
    yield put({ type: 'SEARCH_ADDRESS_TAG_FAILED', error });
  }
}
