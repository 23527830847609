import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { BaseService, handleError } from '@yojee/api/baseService';

export const validatePartnerInvitationCode = (invitationCode) => {
  return APIAxiosInstance.get(
    `${BaseService.getUmbrellaApiHostUrl()}/public/partner_invitations/${invitationCode}`
  ).catch((error) => handleError(error));
};

export const partnerCompanySignup = ({ invitationCode, signupInfo }) => {
  return APIAxiosInstance.post(
    `${BaseService.getUmbrellaApiHostUrl()}/public/companies/partner_invitations/${invitationCode}`,
    signupInfo
  ).catch((error) => handleError(error));
};
