const defaultState = {
  open: false,
  data: null,
  directionsFetch: {},
  lastEffectedData: {},
  showTaskMoveOverlay: false,
};

const defaultStatusOfDirectionsUpdate = {
  inProgress: false,
  completed: false,
  error: false,
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SHOW_MOVE_CONFIRMATION: (state, { params: { data } }) => {
    return { ...state, open: true, data };
  },
  CLOSE_MOVE_CONFIRMATION: (state) => {
    return { ...state, open: false };
  },
  DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_STARTED: (state, { params: { routeIndex } }) => {
    if (!state[routeIndex]) {
      state[routeIndex] = defaultStatusOfDirectionsUpdate;
    }

    state[routeIndex].completed = false;
    state[routeIndex].error = false;
    state[routeIndex].inProgress = true;

    return state;
  },
  DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_COMPLETED: (state, { params: { routeIndex } }) => {
    if (!state[routeIndex]) {
      state[routeIndex] = defaultStatusOfDirectionsUpdate;
    }

    state[routeIndex].completed = true;
    state[routeIndex].inProgress = false;

    return state;
  },
  DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_FAILED: (state, { params: { routeIndex } }) => {
    if (!state[routeIndex]) {
      state[routeIndex] = defaultStatusOfDirectionsUpdate;
    }

    state[routeIndex].inProgress = false;
    state[routeIndex].error = true;

    return state;
  },
  SET_LAST_EFFECTED_DATA: (state, { params: { effectedData, updatedAt } }) => {
    return { ...state, lastEffectedData: { effectedData, updatedAt } };
  },
  SET_SHOW_TASK_MOVE_OVERLAY: (state, { params: { showTaskMoveOverlay } }) => {
    return { ...state, showTaskMoveOverlay };
  },
};

const reducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
