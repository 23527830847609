import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

import { getCurrentTimeZone } from '@yojee/helpers/TimeHelper';

const moment = extendMoment(Moment);

export const SOLVER_WEIGHT_UNIT = 'g';
export const SOLVER_VOLUME_UNIT = 'cm3';

export const mapRequest = ({ requestId, tasks, workers, workersSchedule, settings, hubs }) => {
  const currentDate = settings.epochDate ? moment(settings.epochDate).utc().tz(settings.timezone) : moment();
  const subsequentPickupAndDropoff = settings.subsequentPickupAndDropoff ? settings.subsequentPickupAndDropoff : false;
  console.debug('epoch:', currentDate);

  const removedItemIds = new Set(
    tasks
      .filter(
        (t) =>
          t?.task['associated_task_id'] || t?.task['state'] === 'failed' || (t.task && t?.task['state'] === 'failed')
      )
      .map((t) => t.task['order_item_id'])
  );

  tasks = tasks.filter((t) => !removedItemIds.has(t.task['order_item_id']));

  // Asset types
  const mappedVehicleTypes = workers.map((w, index) => {
    const at = w.vehicleType;
    at.id = index;

    ['fixed', 'perRegularHours', 'perMileage'].forEach((costKey) => {
      at.cost[costKey] = parseInt(at.cost[costKey]);
    });

    /**
     * Append the custom capacities.
     * In that case, the `yojee_custom_capacity_demands` should be defined in `order_item.info` field as below.
     * as the same field name as in settings json `customCapacities`.
     *
     * "yojee_custom_capacity_demands": {
     *   "custom_capacity_contract": 1,
     *   "custom_capacity_charter": 0,
     *   "custom_capacity_task": 1
     * }
     */
    if (Object.hasOwn(at, 'customCapacities')) {
      Object.keys(at.customCapacities).forEach((key) => {
        at.capacity[key] = at.customCapacities[key];
      });
    }

    return at;
  });

  // Hubs
  const _hubs = [];
  if (hubs) {
    hubs
      .filter((h) => h.location && h.location.lat && h.location.lng)
      .forEach((h) => {
        _hubs.push({
          id: h.id,
          location: {
            id: `${h.location.lat}_${h.location.lng}`,
            latitude: h.location.lat,
            longitude: h.location.lng,
          },
        });
      });
  }
  console.debug('*** plan: hubs=', _hubs);

  return {
    metadata: {
      // requestId: The request ID
      requestId: requestId,
      // epoch: The epoch date time
      epoch: moment(currentDate).toISOString(),
      enforceEpoch: settings.enforceEpoch,
      // geoServiceProvider: The geo service provider used in the geo-server(eg. osm, goole, here)
      // TODO: Change after testing
      // "geoServiceProvider": settings.geoServiceProvider ? settings.geoServiceProvider : 'here',
      geoServiceProvider: settings.mapProvider || settings.geoServiceProvider || 'osm',
      // searchTimeLimit: Google or-tools search time limit seconds
      searchTimeLimit: settings.searchTimeLimitMs ? settings.searchTimeLimitMs / 1000 : 600, // 10 minutes
      // sameLocationServiceTime: The each task service time duration of the same location.
      sameLocationServiceTime: settings.sameLocationServiceTime ? settings.sameLocationServiceTime : 100,
      // subsequentPickupAndDropoff:
      // In the pickup `A` -> dropoff `B` pair, another tasks can not be assigned between the `A` and `B`.
      subsequentPickupAndDropoff,
      encodePolyline: true,
    },
    vehicleTypes: mappedVehicleTypes,
    vehicles: workers.map((w, index) => {
      w.vehicleTypeId = index;
      if (w.startLocationType === 'hub' && _hubs.find((hub) => hub.id === w.startHub)) {
        const hub = _hubs.find((hub) => hub.id === w.startHub);
        w.startLocation = hub.location;
      } else if (w.startLocations[w.startLocationType]) {
        w.startLocation = w.startLocations[w.startLocationType];
      } else {
        w.startLocation = w.startLocations.default;
      }

      if (w.endLocationType === 'hub' && _hubs.find((hub) => hub.id === w.endHub)) {
        const hub = _hubs.find((hub) => hub.id === w.endHub);
        w.endLocation = hub.location;
      } else if (w.endLocations[w.endLocationType]) {
        w.endLocation = w.endLocations[w.endLocationType];
      } else if (w.endLocations.default) {
        w.endLocation = w.endLocations.default;
      }

      if (w.startLocation && !w.startLocation.id) {
        w.startLocation.id = `${w.startLocation.latitude}_${w.startLocation.longitude}`;
      }

      if (w.endLocation && !w.endLocation.id) {
        w.endLocation.id = `${w.endLocation.latitude}_${w.endLocation.longitude}`;
      }

      const schedule = w.repeatSchedules[0];
      schedule.start = moment(schedule.start).format();
      schedule.until = moment(schedule.until).format();
      schedule.tz = getCurrentTimeZone();

      delete w.vehicleType;

      delete w.startLocations;
      delete w.startLocationType;

      delete w.endLocations;
      delete w.endLocationType;

      delete w.worker;

      if (w.initial) {
        delete w.initial;
      }

      if (w.latestVersion) {
        delete w.latestVersion;
      }

      return w;
    }),
    tasks: tasks.map((t) => {
      if (t.taskType === 'Delivery') {
        t.capacityDemand.weight = t.capacityDemand.weight * -1;
        t.capacityDemand.volume = t.capacityDemand.volume * -1;
        t.capacityDemand.unit = t.capacityDemand.unit * -1;
      }

      if (t.clusteredTasks) {
        delete t.clusteredTasks;
      }

      if (t.task) {
        delete t.task;
      }

      if (t.initial) {
        delete t.initial;
      }

      if (t.latestVersion) {
        delete t.latestVersion;
      }

      return t;
    }),
    mapOptions: {
      mode: 'fast',
      vehicle: 'truck',
      traffic: 'disabled',
    },
  };
};
