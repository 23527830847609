const defaultState = {
  partner: undefined,
  showDialog: false,
  selectedTasks: [],
  status: 'none',
  message: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPDATE_TRANSFER_DATA: (state, { partner, selectedTasks }) => {
    return { ...state, partner, selectedTasks, showDialog: true };
  },
  TRANSFER_TASKS_TO_PARTNER_START: (state) => {
    return { ...state, status: 'processing' };
  },
  TRANSFER_TASKS_TO_PARTNER_SUCCESS: () => {
    return defaultState;
  },
  TRANSFER_TASKS_TO_PARTNER_ERROR: (state, { message }) => {
    return { ...state, status: 'error', message: message };
  },
  CLOSE_TRANSFER_TASK_DIALOG: () => {
    return defaultState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
