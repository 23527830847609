import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class ServiceTimeService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  calculateForTasks = ({ tasksIds }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/service_time/calculate'), { tasks_ids: tasksIds })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  calculateForVehicles = ({ vehicleTypeIds, locationTypes }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/service_time/calculate'), {
        vehicle_type_ids: vehicleTypeIds,
        location_types: locationTypes,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const serviceTimeService = new ServiceTimeService({ authService });
