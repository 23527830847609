import { parseJSONWithDefaultValue } from '@yojee/helpers/JSONHelper';

const getAssignedVehicleId = ({ fixedVehicleId }) => {
  return fixedVehicleId ? `${fixedVehicleId}` : null;
};

const getServiceTimeOfOrderItem = ({ task, infoField }) => {
  if (task.type === 'pickup' && infoField?.['yojee_pickup_service_time']) {
    return infoField['yojee_pickup_service_time'];
  }

  if (task.type === 'dropoff' && infoField?.['yojee_dropoff_service_time']) {
    return infoField['yojee_dropoff_service_time'];
  }

  if (task.type === 'pickup' && infoField?.['pickup_service_time']) {
    return infoField['pickup_service_time'];
  }

  if (task.type === 'dropoff' && infoField?.['dropoff_service_time']) {
    return infoField['dropoff_service_time'];
  }

  if (infoField.service_time) {
    return infoField.service_time;
  }

  return null;
};

const mapSecondaryVehicle = ({ assignableVehicleIdList, workers, infoField }) => {
  if (infoField?.['yojee_vehicle_type_ids']?.length > 0 && assignableVehicleIdList.length < 10) {
    const secondaryVehicleIdList = workers
      .filter((w) => infoField.yojee_vehicle_type_ids.includes(w.current_vehicle_type_id))
      .map((w) => w.id.toString());
    if (secondaryVehicleIdList && secondaryVehicleIdList.length > 0) {
      secondaryVehicleIdList.forEach((vehicleId) => {
        if (!assignableVehicleIdList.includes(vehicleId)) {
          assignableVehicleIdList.push(vehicleId);
        }
      });
    }
  }

  return assignableVehicleIdList;
};

const mapPredefinedVehicle = ({ fixedVehicleId, infoField, assignableVehicleIdList, workers }) => {
  // Make the predefined assignable vehicle id list from the predefined assignable vehicle type
  // Ignore if there is the fixed assigned vehicle already!!!
  if (
    !fixedVehicleId &&
    infoField &&
    (!assignableVehicleIdList || (assignableVehicleIdList && assignableVehicleIdList.length === 0)) &&
    ((infoField.yojee_vehicle_type_id && infoField.yojee_vehicle_type_id !== '') ||
      (infoField.yojee_vehicle_type_ids && infoField.yojee_vehicle_type_ids.length > 0))
  ) {
    assignableVehicleIdList = [];
    // Primary planned vehicle type id
    if (infoField.yojee_vehicle_type_id && infoField.yojee_vehicle_type_id !== '') {
      assignableVehicleIdList = workers
        .filter((w) => w.current_vehicle_type_id === infoField.yojee_vehicle_type_id)
        .map((w) => w.id.toString());
    }
    // Secondary suggested vehicle type id list recommended by Yojee ML
    assignableVehicleIdList = mapSecondaryVehicle({ assignableVehicleIdList, workers, infoField });

    // If there is no assignable vehicles in the selected worker list,
    // It will be dropped
    if (assignableVehicleIdList === null || assignableVehicleIdList.length === 0) {
      assignableVehicleIdList = [999999]; // Will be dropped
    }
  }

  return assignableVehicleIdList;
};

/**
 * Check if the task has the already predefined rule from the `order_item.info` field:
 * - predefined assigned vehicle(driver)
 * - service time
 * - predefined assignable vehicle type
 * For example: `AusTransit` use case
 * {
 *  "vehicle_type": "S/B Mini",
 *  "vehicle_id": "CVL3461",
 *  "yojee_pickup_service_time": 300,
 *  "yojee_dropoff_service_time": 300,
 *  "yojee_vehicle_type_id": 10,
 *  "yojee_vehicle_type_name": "S/B Mini",
 *  "yojee_vehicle_type_ids": [10],
 *  "yojee_vehicle_type_names": ["S/B Mini"],
 *  "yojee_vehicle_id": 2637,
 *  "yojee_vehicle_name": "CVL3461",
 *  "yojee_vehicle_ids": [2637],
 *  "yojee_vehicle_names": ["CVL3461"],
 *  "yojee_custom_capacity_demands": {
 *    "custom_capacity_contract": 1,
 *	  "custom_capacity_charter": 0,
 *	  "custom_capacity_task": 1
 *  }
 * }
 */
export const mapOrderInfoDetails = ({ task: t, workers }) => {
  let fixedVehicleId = null;
  let assignableVehicleIdList = null;
  let customCapacityDemands = null;
  let serviceTime = null;

  if (!t?.['order_item']?.info) {
    return {};
  }

  try {
    const infoField = parseJSONWithDefaultValue(t.order_item.info, {});
    // Service time
    serviceTime = getServiceTimeOfOrderItem({ task: t, infoField });

    // Predefined assigned vehicle
    if (workers && infoField && infoField.yojee_vehicle_id) {
      const fixedVehicle = workers.find((w) => w.id === infoField.yojee_vehicle_id);
      if (fixedVehicle) {
        fixedVehicleId = fixedVehicle.id; // will be assigned to this vehicle or dropped
      } else {
        fixedVehicleId = 999999; // Will be dropped
      }
    }
    // Recommended vehicles
    if (
      !fixedVehicleId &&
      workers &&
      infoField &&
      infoField.yojee_vehicle_ids &&
      infoField.yojee_vehicle_ids.length > 0
    ) {
      assignableVehicleIdList = [];
      const selectedWorkerIdList = workers.map((w) => w.id);
      infoField.yojee_vehicle_ids.forEach(function (id, i) {
        if (selectedWorkerIdList.includes(id)) {
          assignableVehicleIdList.push(id);
        }
      });
    }

    assignableVehicleIdList = mapPredefinedVehicle({ fixedVehicleId, infoField, assignableVehicleIdList, workers });

    // Custom capacity demands
    if (infoField.yojee_custom_capacity_demands) {
      customCapacityDemands = infoField.yojee_custom_capacity_demands;
    }
  } catch (error) {
    console.error('order_item.info=', t.order_item.info, error);
  }

  return {
    serviceTime,
    assignedVehicleId: getAssignedVehicleId({ fixedVehicleId }),
    assignableVehicleIdList,
    customCapacityDemands,
  };
};
