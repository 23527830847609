import { PAYMENT_ACTION_TYPES } from '../saga/actions';

const defaultState = {
  stripeKey: null,
  planInfo: null,
  loading: {
    createStripeSubscription: false,
    updateCreditCard: false,
  },
  createStripeSubscriptionSucceed: null,
  errorMessage: null,
};

const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.SET_STRIPE_KEY:
      return { ...state, stripeKey: action.stripeKey };
    case PAYMENT_ACTION_TYPES.FETCH_PLAN_INFO_RECEIVED:
      const { data } = action;
      return {
        ...state,
        planInfo: data
          ? {
              providerType: data.provider_type,
              providerPlanName: data.provider_plan_name,
              providerPlanUuid: data.provider_plan_uuid,
              amount: parseInt(data.amount),
              currency: data.currency,
              interval: data.interval,
            }
          : {},
      };
    case PAYMENT_ACTION_TYPES.CREATE_STRIPE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          createStripeSubscription: false,
        },
        createStripeSubscriptionSucceed: true,
        errorMessage: null,
      };
    case PAYMENT_ACTION_TYPES.CREATE_STRIPE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          createStripeSubscription: false,
        },
        errorMessage: 'Something went wrong. Please try again.',
      };
    case PAYMENT_ACTION_TYPES.UPDATE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateCreditCard: false,
        },
        errorMessage: null,
      };
    case PAYMENT_ACTION_TYPES.UPDATE_CREDIT_CARD_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateCreditCard: false,
        },
        errorMessage: 'Something went wrong. Please try again.',
      };
    case PAYMENT_ACTION_TYPES.SET_PAYMENT_LOADING:
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
