import { normalize, schema } from 'normalizr';

export const normalizeResponse = (response) => {
  const outputData = new schema.Entity('data');
  const mySchema = { data: [outputData] };
  const normalizedData = normalize(response, mySchema);
  const { entities, result } = normalizedData;
  const dataNormalized = entities.data;
  const idArray = result.data;
  return { data: dataNormalized, ids: idArray };
};
