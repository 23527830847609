import { call, put, select, takeEvery } from 'redux-saga/effects';

import { serviceTypesApi } from '@yojee/api/serviceTypesApi';

export default function* sagas() {
  yield takeEvery(['FETCH_SERVICE_TYPES'], fetchServiceTypes);
}

function* fetchServiceTypes() {
  try {
    const inProgress = yield select((state) => state.serviceTypes?.inProgress);
    if (!inProgress) {
      yield put({ type: 'FETCH_SERVICE_TYPES_IN_PROGRESS' });
      const result = yield call(serviceTypesApi.getServiceTypes);
      const serviceTypes = result.data;
      yield put({ type: 'FETCH_SERVICE_TYPES_SUCCESS', serviceTypes });
    }
  } catch (err) {
    yield put({ type: 'FETCH_SERVICE_TYPES_ERROR', err });
  }
}
