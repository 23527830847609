import { DATEOF_TYPE, defaultFilter, getHash } from '@yojee/helpers/MasterFilterHelper';
import { LocalStorage } from '@yojee/local-storage/local-storage';

export const defaultState = {
  previousHash: undefined,
  filter: {
    ...defaultFilter,
  },
  sortCriteria: {
    direction: 'asc',
    sortBy: ['commit_at'],
  },
  taskStatusControl: {
    availableSource: ['missing_info', 'reported', 'unassigned'],
  },
  dateOfControl: {
    availableSource: [
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.REPORTED,
      DATEOF_TYPE.VESSEL_RECEIVING_START_DATE,
      DATEOF_TYPE.VESSEL_RECEIVING_END_DATE,
      DATEOF_TYPE.VESSEL_DEPARTURE_DATE,
      DATEOF_TYPE.VESSEL_ARRIVAL_DATE,
      DATEOF_TYPE.FCL_AVAILABLE_DATE,
      DATEOF_TYPE.FCL_STORAGE_DATE,
    ],
  },
  driverControl: {
    disabled: true,
  },
  partnerControl: {
    visible: false,
  },
  missingInfoMode: false,
  requests: {
    fetchingLocation: false,
  },
  currentFilterPresetId: undefined,
  filterPresets: [],
  urlQuery: LocalStorage.getItem('listviewUrl') || '',
};

export const init = () => defaultState;

const ACTION_HANDLERS = {
  APPLY_MASTER_FILTER: (state) => {
    return { ...state, previousHash: getHash(state.filter) };
  },
  UPDATE_HASH_BY_QUERY_STRING_URL: (state, { query }) => {
    const urlQuery = query || state.urlQuery;
    LocalStorage.setItem('listviewUrl', urlQuery);

    return { ...state, previousHash: getHash(state.filter), urlQuery };
  },
  SET_CURRENT_FILTER_PRESET: (state, { currentFilterPreset }) => {
    const filterContent = currentFilterPreset.filter_content || {};

    return { ...state, currentFilterPresetId: currentFilterPreset.id, filter: { ...defaultFilter, ...filterContent } };
  },
  SET_CURRENT_FILTER_PRESET_ID: (state, { currentFilterPresetId }) => {
    return { ...state, currentFilterPresetId };
  },
  SET_FILTER_PRESETS: (state, { newFilterPresets }) => {
    const sortedNewFilterPresets = (newFilterPresets || []).sort((a, b) => a.name.localeCompare(b.name));

    return { ...state, filterPresets: sortedNewFilterPresets };
  },
  APPLY_TASK_FILTER: (state, { isResetCurrentPresetFilter }) => {
    if (isResetCurrentPresetFilter) {
      return { ...state, currentFilterPresetId: undefined };
    }

    return state;
  },
  UPDATE_SORT_DIRECTION: (state, { direction }) => {
    return { ...state, sortCriteria: { ...state.sortCriteria, direction } };
  },
  UPDATE_SORT_BY_KEY: (state, { sortBy }) => {
    return { ...state, sortCriteria: { ...state.sortCriteria, sortBy } };
  },
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPDATE_FILTER_TASK_TYPE: (state, { taskType }) => {
    if (taskType) {
      return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, taskType: taskType } };
    }

    return state;
  },
  UPDATE_FILTER_TASK_STATUS: (state, { status }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, status: status } };
  },
  UPDATE_FILTER_LEG_TYPES: (state, { legTypeIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, legTypes: legTypeIds } };
  },
  UPDATE_FILTER_VALUES: (state, { key, value }) => {
    if (state.filter[key] !== value) {
      return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, [key]: value } };
    }

    return state;
  },
  UPDATE_FILTER_DATE_OF: (state, { dateOf }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, dateOf: dateOf } };
  },
  UPDATE_FILTER_TIME_FRAME: (state, { timeFrame }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, timeFrame } };
  },
  UPDATE_FILTER_HUBS: (state, { hubs }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, hubs: hubs } };
  },
  UPDATE_SELECTED_ADDRESS_OPTION: (state) => {
    return { ...state, requests: { ...state.requests, fetchingLocation: true } };
  },
  UPDATE_SELECTED_ADDRESS_OPTION_SUCCESS: (state, { options }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, selectedAddressOptions: options },
      requests: { ...state.requests, fetchingLocation: false },
    };
  },
  UPDATE_SELECTED_ADDRESS_OPTION_FAILED: (state) => {
    return { ...state, requests: { ...state.requests, fetchingLocation: false } };
  },
  UPDATE_REGION_IDS: (state, { regions }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, region_ids: regions } };
  },
  UPDATE_FILTER_ID_KEY: (state, { idKey }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, idKey: idKey } };
  },
  UPDATE_SENDER_IDS: (state, { sendersId }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, senderIds: sendersId } };
  },
  UPDATE_SERVICE_TYPES_ID: (state, { serviceTypesId }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, serviceTypeIds: serviceTypesId } };
  },
  UPDATE_REASON_REPORTED: (state, { reportInfoWithReason }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, reportInfoWithReason: reportInfoWithReason },
    };
  },
  UPDATE_DRIVER_IDS: (state, { driverIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, driverIds: driverIds } };
  },
  UPDATE_PARTNER_COMPANY_IDS: (state, { partnerCompanyIds }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, partnerCompanyIds: partnerCompanyIds },
    };
  },
  UPDATE_ADDRESS_ITEM_IDS: (state, { addressItemIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, addressItemIds } };
  },
  UPDATE_DRIVER_IDS_QUERY_BY_TAGS: (state, { driverIdsQueryByTags }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, driverIdsQueryByTags } };
  },
  UPDATE_ITEM_TYPE_IDS: (state, { itemTypeIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, itemTypeIds: itemTypeIds } };
  },
  UPDATE_RADIUS: (state, { radius }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, radius: radius } };
  },
  UPDATE_ID_VALUE_FILTER: (state, { value, ignoreRange }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, idValue: value, ignoreRange: !!ignoreRange },
    };
  },
  UPDATE_SHIPPER: (state, { value, ignoreRange, idKey }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, shipper: value, shipperIdKey: idKey, ignoreRange: !!ignoreRange },
    };
  },
  UPDATE_VESSEL_VALUE_FILTER: (state, { value }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, vesselValue: value } };
  },
  UPDATE_VESSEL_ID_KEY: (state, { idKey, ignoreRange }) => {
    return {
      ...state,
      currentFilterPresetId: undefined,
      filter: { ...state.filter, vesselIdKey: idKey, ignoreRange: !!ignoreRange },
    };
  },
  CLEAR_VESSEL_ID_KEY: (state) => {
    return {
      ...state,
      filter: { ...state.filter, vesselIdKey: '', ignoreRange: false },
    };
  },
  CLEAR_MASTER_FILTER: () => {
    return {
      ...defaultState,
    };
  },
  UPDATE_FILTER_SOURCE: (state, { newState }) => {
    return { ...newState };
  },
  APPLY_WORKER_FILTER: (state, { workerId }) => {
    if (state.filter.driverIds.includes(workerId)) {
      return {
        ...state,
        currentFilterPresetId: undefined,
        filter: {
          ...state.filter,
          driverIds: [...state.filter.driverIds.filter((id) => id !== workerId)],
        },
      };
    } else {
      return {
        ...state,
        currentFilterPresetId: undefined,
        filter: { ...state.filter, driverIds: [...state.filter.driverIds, workerId] },
      };
    }
  },
  CLEAR_WORKER_FILTER: (state) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, driverIds: [] } };
  },
  SET_MISSING_INFO_FIX_MODE: (state, { enabled }) => {
    return { ...state, missingInfoMode: enabled };
  },
  UPDATE_SELECTED_ADDRESS_TAG_IDS: (state, { selectedAddressTagIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, selectedAddressTagIds } };
  },
  UPDATE_SELECTED_DRIVER_TAG_IDS: (state, { selectedDriverTagIds }) => {
    return { ...state, currentFilterPresetId: undefined, filter: { ...state.filter, selectedDriverTagIds } };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
