const defaultState = {
  upload: {
    inProgress: false,
    batchId: null,
    successMessage: null,
    errorMessage: null,
    errors: [],
  },
};

const STEP_MESSAGES = {
  default: [
    'Preparing files for upload',
    'Uploading files',
    'Preparing files data',
    'Creating/Updating vehicles',
    'Creating/Updating drivers',
    'Geocoding tasks addresses',
    'Creating address book',
    'Preparing tasks',
    'Creating tasks',
  ],
  geodis: ['Preparing files for upload', 'Uploading file', 'Creating tasks', 'Uploading file', 'Creating tasks'],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPLOAD_BATCH_STARTED: (state) => {
    return { ...state, upload: { ...state.upload, inProgress: true, success: false, errors: [] } };
  },
  UPLOAD_BATCH_SUCCESS: (state) => {
    return { ...state, upload: { ...state.upload, successMessage: 'Files successfully uploaded' } };
  },
  UPLOAD_BATCH_FAILED: (state, { errors }) => {
    return { ...state, upload: { ...state.upload, errorMessage: 'Batch upload failed', errors } };
  },
  ADD_BATCH_ERROR: (state, { error }) => {
    return {
      ...state,
      upload: { ...state.upload, errorMessage: 'Batch upload failed', errors: [...state.upload.errors, error] },
    };
  },
  UPLOAD_BATCH_FINISHED: (state) => {
    return { ...state, upload: { ...state.upload, inProgress: false } };
  },
  CLEAR_BATCH_UPLOAD_MESSAGE: (state) => {
    return {
      ...state,
      upload: { ...state.upload, successMessage: defaultState.successMessage, errorMessage: defaultState.errorMessage },
    };
  },
  UPDATE_UPLOAD_PROGRESS: (state, { totalSteps, completedSteps, totalSubtasks, completedSubtasks, slug }) => {
    if (completedSteps === undefined) {
      totalSteps = state.upload.totalSteps;
    }

    if (completedSteps === undefined) {
      completedSteps = state.upload.completedSteps;
    }

    let progress = completedSteps / totalSteps;
    if (totalSubtasks > 0) {
      progress += (1 / totalSubtasks / totalSteps) * completedSubtasks;
    }

    const type = slug && Array.isArray(STEP_MESSAGES[slug]) ? slug : 'default';
    let message = `${STEP_MESSAGES[type][completedSteps]}.`;
    if (totalSubtasks > 0) {
      message += ` Completed ${completedSubtasks} of ${totalSubtasks}`;
    }

    return {
      ...state,
      upload: { ...state.upload, totalSteps, completedSteps, totalSubtasks, completedSubtasks, progress, message },
    };
  },
  CLEAR_ERROR_MESSAGE: (state) => {
    return { ...state, upload: { ...state.upload, errors: defaultState.upload.errors } };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
