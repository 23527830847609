import { put, select, takeLatest } from 'redux-saga/effects';

import { normalizedTasksToStop_ListView_byView } from '@yojee/helpers/tasksToStops';

const getTaskData = (state) => state.planner && state.planner.taskData;
const getCurrentView = (state) => state.itemsTable && state.itemsTable.currentView;
const getShowList = (state) => state.main && state.main.showList;

export default function* sagas() {
  yield takeLatest(['FETCH_TASKS_COMPLETE'], calculateNodes);
}

function* calculateNodes({ resolve }) {
  const showList = yield select(getShowList);
  if (!showList) return;
  const { data, ids } = yield select(getTaskData);
  const taskListFiltered = ids.filter((t) => data[t].task.state !== 'failed');
  const currentView = yield select(getCurrentView);
  const result = normalizedTasksToStop_ListView_byView(data, taskListFiltered, currentView);
  const itemsKeys = result.stopKeys;
  const items = result.stops;
  yield put({ type: 'UPDATE_ITEMS', itemsKeys, items, totalTasksInput: taskListFiltered.length });
  if (resolve) {
    resolve();
  }
}
