import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { t } from 'i18next';
import { flatten } from 'lodash-es';

import { DATE_PICKER_FORMAT } from '@yojee/helpers/TimeHelper';

import { Option, TimeFrameKey, TimeFrameType } from './types';

export const getTimeFrameFromTimeFrameKey = (key: TimeFrameKey): TimeFrameType => {
  const today = new Date();
  let result: TimeFrameType = {
    type: 'date',
  };

  switch (key) {
    case 'today':
      result = { ...result, from: startOfDay(today), to: endOfDay(today) };
      break;
    case 'yesterday':
      const yesterday = addDays(today, -1);

      result = { ...result, from: startOfDay(yesterday), to: endOfDay(yesterday) };
      break;
    case 'tomorrow':
      const tomorrow = addDays(today, 1);
      result = { ...result, from: startOfDay(tomorrow), to: endOfDay(tomorrow) };
      break;
    case 'this_week':
      result = { ...result, from: startOfWeek(today, { weekStartsOn: 1 }), to: endOfWeek(today, { weekStartsOn: 1 }) };
      break;
    case 'last_week':
      const lastDateOfLastWeek = addDays(startOfWeek(today, { weekStartsOn: 1 }), -1);
      result = { ...result, from: startOfWeek(lastDateOfLastWeek, { weekStartsOn: 1 }), to: lastDateOfLastWeek };
      break;
    case 'next_week':
      const firstDateOfNextWeek = addDays(endOfWeek(today, { weekStartsOn: 1 }), 1);

      result = { ...result, from: firstDateOfNextWeek, to: endOfWeek(firstDateOfNextWeek, { weekStartsOn: 1 }) };
      break;
    case 'last_7_days':
      result = { ...result, from: addDays(today, -6), to: today };
      break;
    case 'next_7_days':
      result = { ...result, from: today, to: addDays(today, 6) };
      break;
    case 'last_14_days':
      result = { ...result, from: addDays(today, -13), to: today };
      break;
    case 'next_14_days':
      result = { ...result, from: today, to: addDays(today, 13) };
      break;
    case 'this_month':
      result = { ...result, type: 'month', from: startOfMonth(today), to: endOfMonth(today) };
      break;
    case 'last_month':
      const lastDateOfLastMonth = addDays(startOfMonth(today), -1);

      result = { ...result, type: 'month', from: startOfMonth(lastDateOfLastMonth), to: lastDateOfLastMonth };
      break;
    case 'next_month':
      const firstDateOfNextMonth = addDays(endOfMonth(today), 1);

      result = { ...result, type: 'month', from: firstDateOfNextMonth, to: endOfMonth(firstDateOfNextMonth) };
      break;
    case 'last_year':
      const lastYear = today.getFullYear() - 1;
      const firstDateOfLastYear = new Date(lastYear, 0, 1);

      result = { ...result, type: 'year', from: firstDateOfLastYear, to: endOfYear(firstDateOfLastYear) };
      break;
    case 'year_to_date':
      result = { ...result, type: 'year', from: startOfYear(today), to: today };
      break;
  }

  if (result.from && result.to) {
    result.from = startOfDay(result.from);
    result.to = endOfDay(result.to);
  }

  return result;
};

export const getDisplayTimeOfTimeFrame = (timeFrame: TimeFrameType): string => {
  if (timeFrame.type === 'month' && timeFrame.from) {
    return format(timeFrame.from, 'MMM');
  }

  if (timeFrame.type === 'date' && timeFrame.from && timeFrame.to) {
    if (isSameDay(timeFrame.from, timeFrame.to)) return format(timeFrame.from, 'd MMM');

    return format(timeFrame.from, 'd MMM') + ' - ' + format(timeFrame.to, 'd MMM');
  }

  return '';
};

export const formatFromAndToDate = (fromDate: Date, toDate: Date): string => {
  return format(fromDate, DATE_PICKER_FORMAT) + ' - ' + format(toDate, DATE_PICKER_FORMAT);
};

export const getTimeFrameGroups = (omitFuture?: boolean): Array<Array<Option>> => {
  return omitFuture
    ? [
        [
          {
            name: t('Today'),
            key: 'today',
          },
          {
            name: t('Yesterday'),
            key: 'yesterday',
          },
        ],
        [
          {
            name: t('This week'),
            key: 'this_week',
          },
          {
            name: t('Last week'),
            key: 'last_week',
          },
        ],
        [
          {
            name: t('Last 7 days'),
            key: 'last_7_days',
          },
          {
            name: t('Last 14 days'),
            key: 'last_14_days',
          },
        ],
        [
          {
            name: t('This month'),
            key: 'this_month',
          },
          {
            name: t('Last month'),
            key: 'last_month',
          },
        ],
      ]
    : [
        [
          {
            name: t('Today'),
            key: 'today',
          },
          {
            name: t('Yesterday'),
            key: 'yesterday',
          },
          {
            name: t('Tomorrow'),
            key: 'tomorrow',
          },
        ],
        [
          {
            name: t('This week'),
            key: 'this_week',
          },
          {
            name: t('Last week'),
            key: 'last_week',
          },
          {
            name: t('Next week'),
            key: 'next_week',
          },
        ],
        [
          {
            name: t('Last 7 days'),
            key: 'last_7_days',
          },
          {
            name: t('Next 7 days'),
            key: 'next_7_days',
          },
          {
            name: t('Last 14 days'),
            key: 'last_14_days',
          },
          {
            name: t('Next 14 days'),
            key: 'next_14_days',
          },
        ],
        [
          {
            name: t('This month'),
            key: 'this_month',
          },
          {
            name: t('Last month'),
            key: 'last_month',
          },
          {
            name: t('Next month'),
            key: 'next_month',
          },
        ],
      ];
};

type TimeFrameMapType = Record<TimeFrameKey, string>;

export const getTimeFrameKeyNameMap = (): TimeFrameMapType => {
  return flatten(getTimeFrameGroups()).reduce(
    (acc, timeFrame: Option) => ({
      ...acc,
      [timeFrame.key]: timeFrame.name,
    }),
    {}
  ) as TimeFrameMapType;
};

export const DEFAULT_TIME_FRAME_KEY: TimeFrameKey = 'today';

export const TIME_FRAME_KEY_CUSTOM: TimeFrameKey = 'custom';
