import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import AuthSelectors from '@yojee/auth/store/selectors';
import { getValue } from '@yojee/helpers/access-helper';

import batchApi from '../../Api/batchApi';
import geodisApi from '../../Api/geodisApi';
import s3Api from '../../Api/s3Api';

export default function* sagas() {
  yield takeLatest('REQUEST_UPLOAD_FILES', uploadFiles);
  yield takeLatest('REQUEST_UPLOAD_FILES_GEODIS', uploadFilesGeodis);
}

function* uploadFiles({ params: { files } }) {
  const { dispatcher_info: dispatcherInfo } = yield select(AuthSelectors.getData);
  const {
    data: {
      company: { slug },
    },
  } = dispatcherInfo;
  if (slug.includes('geodis')) {
    yield put({ type: 'REQUEST_UPLOAD_FILES_GEODIS', params: { files } });
  }
}

function* uploadFilesGeodis({ params: { files } }) {
  const { dispatcher_info: dispatcherInfo } = yield select(AuthSelectors.getData);
  const {
    data: {
      company: { slug },
    },
  } = dispatcherInfo;
  const settings = getValue(dispatcherInfo, 'data.company.settings.applications.fleet-data-entry', {});

  geodisApi.apiUrl = getValue(settings, 'api_host', null);
  try {
    yield put({ type: 'UPLOAD_BATCH_STARTED' });
    const totalSteps = Object.keys(files).filter((key) => files[key]).length * 2 + 1;
    let completedSteps = 0;
    let totalSubtasks = 0;
    let completedSubtasks = 0;
    yield put({ type: 'UPDATE_UPLOAD_PROGRESS', totalSteps, completedSteps, slug });

    const data = yield call(batchApi.getPresignedUrls, ['container.csv', 'order.csv']);
    completedSteps++;
    yield put({ type: 'UPDATE_UPLOAD_PROGRESS', totalSteps, completedSteps, slug });

    const types = ['container', 'order'];
    let hasErrors = false;
    for (const index in types) {
      const type = types[index];
      if (files[type]) {
        yield call(s3Api.upload, data[`${type}.csv`].presigned_url, files[type]);
        completedSteps++;
        yield put({ type: 'UPDATE_UPLOAD_PROGRESS', totalSteps, completedSteps, slug });

        const response = yield call(geodisApi.upload, data[`${type}.csv`].object, type, slug);
        if (response.error || response.errorMessage) {
          throw new Error(response.error || response.errorMessage);
        }

        if (!Array.isArray(response)) {
          throw new Error('Malformed response');
        }

        for (const batchIndex in response) {
          if (response[batchIndex]['error']) {
            yield put({ type: 'ADD_BATCH_ERROR', error: response[batchIndex]['error'] });
            hasErrors = true;
            continue;
          }

          const batchId = response[batchIndex]['batch_id'];
          while (true) {
            yield delay(1000);
            const { status, total, processed, info } = yield call(batchApi.batchStatus, batchId);
            if (status === 'failed') {
              throw new Error(info || 'Error in processing batch file');
            }
            if (status === 'completed') {
              break;
            }
            totalSubtasks = response.length;
            completedSubtasks = batchIndex + parseInt(processed / total, 10);
            yield put({ type: 'UPDATE_UPLOAD_PROGRESS', totalSubtasks, completedSubtasks });
          }
        }

        completedSteps++;
        yield put({
          type: 'UPDATE_UPLOAD_PROGRESS',
          totalSteps,
          completedSteps,
          slug,
          totalSubtasks: 0,
          completedSubtasks: 0,
        });
      }
    }

    if (hasErrors) {
      yield put({ type: 'UPLOAD_BATCH_FINISHED' });
    } else {
      yield put({ type: 'UPLOAD_BATCH_FINISHED' });
      yield put({ type: 'UPLOAD_BATCH_SUCCESS' });
      yield put({ type: 'TOGGLE_UPLOAD' });
      yield put({ type: 'REFRESH' });
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_BATCH_FINISHED' });
    yield put({ type: 'UPLOAD_BATCH_FAILED', errors: [getValue(error, 'message', error)] });
  }
}
