export const PAYMENT_STORE_ID = 'BIZ_PAYMENT';

export const PAYMENT_ACTION_TYPES = {
  FETCH_STRIPE_KEY: `${PAYMENT_STORE_ID}:FETCH_STRIPE_KEY`,
  SET_STRIPE_KEY: `${PAYMENT_STORE_ID}:SET_STRIPE_KEY`,
  CREATE_STRIPE_SUBSCRIPTION: `${PAYMENT_STORE_ID}:CREATE_STRIPE_SUBSCRIPTION`,
  CREATE_STRIPE_SUBSCRIPTION_SUCCESS: `${PAYMENT_STORE_ID}:CREATE_STRIPE_SUBSCRIPTION_SUCCESS`,
  CREATE_STRIPE_SUBSCRIPTION_FAILED: `${PAYMENT_STORE_ID}:CREATE_STRIPE_SUBSCRIPTION_FAILED`,
  FETCH_PLAN_INFO: `${PAYMENT_STORE_ID}:FETCH_PLAN_INFO`,
  FETCH_PLAN_INFO_RECEIVED: `${PAYMENT_STORE_ID}:FETCH_PLAN_INFO_RECEIVED`,
  FETCH_PLAN_INFO_ERROR: `${PAYMENT_STORE_ID}:FETCH_PLAN_INFO_ERROR`,
  UPDATE_CREDIT_CARD: `${PAYMENT_STORE_ID}:UPDATE_CREDIT_CARD`,
  UPDATE_CREDIT_CARD_SUCCESS: `${PAYMENT_STORE_ID}:UPDATE_CREDIT_CARD_SUCCESS`,
  UPDATE_CREDIT_CARD_FAILED: `${PAYMENT_STORE_ID}:UPDATE_CREDIT_CARD_FAILED`,
  SET_PAYMENT_LOADING: `${PAYMENT_STORE_ID}:SET_PAYMENT_LOADING`,
};

export const fetchStripeKey = (payload) => ({ type: PAYMENT_ACTION_TYPES.FETCH_STRIPE_KEY, payload });
export const createStripeSubscription = (payload) => ({
  type: PAYMENT_ACTION_TYPES.CREATE_STRIPE_SUBSCRIPTION,
  payload,
});
export const fetchPlanInfo = (payload) => ({ type: PAYMENT_ACTION_TYPES.FETCH_PLAN_INFO, payload });
export const updateCreditCard = (payload) => ({ type: PAYMENT_ACTION_TYPES.UPDATE_CREDIT_CARD, payload });
