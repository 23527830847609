import { STANDARD_UNITS } from '@yojee/helpers/unitConverter/base';

export const getPermissions = (state) => state.auth?.dispatcher_info?.data?.permissions;
export const getDefaultUnitSelector = (state) =>
  state?.auth?.['dispatcher_info']?.data?.company?.['display_settings']?.['admin_copy']?.['default.unit'];
export const getDefaultWeightUnitSelector = (state) =>
  state?.auth?.['dispatcher_info']?.data?.company?.['display_settings']?.['admin_copy']?.['default.weight_unit'] ??
  STANDARD_UNITS.weight;
export const getDefaultVolumeUnitSelector = (state) =>
  state?.auth?.['dispatcher_info']?.data?.company?.['display_settings']?.['admin_copy']?.['default.volume_unit'] ??
  STANDARD_UNITS.volume;
export const getAllowMissingInfoTransferSelector = (state) =>
  state?.features?.companyFeatures?.['allow_missing_info_transfer'];
export const hasSelectedMissingInfoTasksSelector = (state) => {
  const selectedTasks = state?.planner?.selectedTasks || [];
  const tasks = state?.planner?.taskData?.data || [];
  return selectedTasks.some((selectedId) => tasks[selectedId]?.missing_info);
};

export const companyTimezoneSelector = (state) => {
  return state.auth.dispatcher_info.data.company.settings.company.timezone;
};

export const dispatcherInfoSelector = (state) => {
  return state.auth.dispatcher_info.data;
};
