import WebSocketInstance from './webSocketInstance';

export const CONNECTION_TYPES = {
  PARTNER: 'partner',
  NORMAL: 'normal',
};

class WebSocketService {
  instances = [];

  getSocket = (key, socketParam, onOpen, onMessage) => {
    if (key === null) {
      key = JSON.stringify(socketParam);
    }

    if (this.instances[key]) {
      return this.instances[key];
    }

    this.instances[key] = new WebSocketInstance();
    this.instances[key].currentSocket(socketParam, onOpen, onMessage);

    return this.instances[key];
  };
}

export const webSocketService = new WebSocketService();
