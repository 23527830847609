import { put, takeLatest } from 'redux-saga/effects';

export default function* sagas() {
  yield takeLatest('CLEAR_TASK_FILTER', clearTaskFilters);
  yield takeLatest('UPDATE_TASK_FILTER', updateTaskFilters);
}

function* clearTaskFilters({ payload: { isLoadTasks } }) {
  yield put({ type: 'CLEAR_TASK_FILTER_SUCCESSFUL' });
  if (isLoadTasks) {
    yield put({ type: 'INIT_TASKS' });
  }
}

function* updateTaskFilters({ payload: { isLoadTasks, taskFilter } }) {
  yield put({ type: 'UPDATE_TASK_FILTER_SUCCESSFUL', taskFilter });
  if (isLoadTasks) {
    yield put({ type: 'INIT_TASKS' });
  }
}
