const defaultState = {
  status: 'idle',
  // This will only record polylines
  routes: {},
  stats: {},
  timeline: { data: {}, ids: [] },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  BUILD_ROUTES_SUCCEEDED: (state, { routes }) => {
    return { ...state, routes: { ...state.routes, ...routes } };
  },
  GENERATE_TIMELINE_SUCCEEDED: (state, { timeline }) => {
    return { ...state, timeline };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
