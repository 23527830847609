import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(2)}px`,
  },
}));

const ManagePageContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.pageContainer}>{children}</div>;
};

export default ManagePageContainer;
