import { uniqueNotification } from '../notificationUtilities';
import { TYPES } from '../saga/actions';
const defaultState = {
  data: [],
  pagination: {},
  unread_count: 0,
  unseen_count: 0,
};
export const init = () => {
  return defaultState;
};
const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case TYPES.START_UPDATE_NOTIFICATION: {
      return {
        ...state,
        inProgress: true,
      };
    }
    case TYPES.UPDATE_NOTIFICATION: {
      const { data, pagination, unseen_count } = action.payload;
      const mergeData = uniqueNotification([...data, ...state.data]);
      return {
        ...state,
        data: mergeData,
        inProgress: false,
        pagination,
        unseen_count,
      };
    }
    case TYPES.UPDATE_ALL_AS_SEEN: {
      return { ...state, unseen_count: 0 };
    }
    case TYPES.UPDATE_ALL_AS_READ: {
      const notifications = state.data;
      if (notifications && notifications.length > 0) {
        const mutableArray = notifications.map((value) => {
          return { ...value, unread: false };
        });
        return {
          ...state,
          data: mutableArray,
        };
      }
      return state;
    }
    case TYPES.ADD_NEW_NOTIFICATION: {
      const notification = action.payload;
      return {
        ...state,
        data: [notification, ...(state.data || [])],
        unseen_count: notification.unread ? (state.unseen_count || 0) + 1 : state.unseen_count,
      };
    }
    case TYPES.UPDATE_STATUS: {
      const notifications = state.data;
      if (notifications && notifications.length > 0) {
        const mutableArray = notifications.map((value) => {
          if (value.id === action.payload.id) {
            return { ...value, unread: action.payload.unread };
          }
          return value;
        });
        return {
          ...state,
          data: mutableArray,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
export default reducer;
