import { put, takeEvery, takeLatest } from 'redux-saga/effects';

function* setErrorMessage({ externalId }) {
  yield put({
    type: 'SET_SCANNER_ERROR',
    error: 'Scanned item by id ' + externalId + ' not found',
    warning: false,
  });
}

function* setIncorrectRouteErrorMessage() {
  yield put({
    type: 'SET_SCANNER_ERROR',
    error: 'Scanned item belongs to a different hub than the other items, Please scan another item',
    warning: true,
  });
}

function* resetScanner() {
  yield put({ type: 'SET_MODE', mode: null });
}

export default function* sagas() {
  yield takeEvery('SCANNED_ITEM_NOT_FOUND', setErrorMessage);
  yield takeEvery('SCANNED_ITEM_ROUTE_INCORRECT', setIncorrectRouteErrorMessage);
  yield takeLatest('RESET_SCANNER', resetScanner);
}
