import * as _ from 'lodash-es';
import get from 'lodash-es/get';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

import { getValue } from '@yojee/helpers/access-helper';
import { SOLVER_VOLUME_UNIT, SOLVER_WEIGHT_UNIT } from '@yojee/helpers/constants';
import { getLocation } from '@yojee/helpers/LocationHelper';
import { logData } from '@yojee/helpers/LogHelper';
import { clusterTasks } from '@yojee/helpers/RoutePlanningHelper/Clustering';
import { mapOrderInfoDetails } from '@yojee/helpers/RoutePlanningHelper/OrderInfoMapper';
import { convertVolume, fixSequence, getPrecedencesMap } from '@yojee/helpers/TasksHelper';
import { getNormalisedTime, getTaskTimeWindow } from '@yojee/helpers/time-helper';
import { STANDARD_UNITS } from '@yojee/helpers/unitConverter/base';
import { fromWeightUnitAToWeightUnitB } from '@yojee/helpers/unitConverter/weightConverter';

const moment = extendMoment(Moment);

const processTasks = ({ tasks, settings, vehicleTypes }) => {
  let tasksList = tasks.filter((t) => t.state !== 'completed');
  if (settings.singleDayPlanning) {
    tasksList = applyMerchantSlot(tasksList, settings, settings.epochDate);
  }
  if (settings.clusterPickups) {
    tasksList = clusterTasks(tasksList, vehicleTypes);
  }

  return tasksList;
};

const getTimeWindow = ({ task: t, hubs, settings }) => {
  const hub = t?.['service_by_hub_id'] ? hubs.find((h) => h.id === t['service_by_hub_id']) : null;
  if (hub && settings.singleDayPlanning && (hub?.properties?.['open_from'] || hub?.properties?.['closed_by'])) {
    const windowByHub = getTaskTimeWindow(
      t.from,
      t.to,
      getNormalisedTime(hub['properties']['open_from'], '00', '00', '00'),
      getNormalisedTime(hub['properties']['closed_by'], 23, 59, 59, true)
    );

    if (windowByHub) {
      return {
        ...windowByHub,
      };
    }

    return {
      start: moment.utc(t.from).toISOString(),
      end: moment.utc(t.from).toISOString(),
    };
  }

  return {
    start: moment.utc(t.from).toISOString(),
    end: moment.utc(t.to).toISOString(),
  };
};

const mapCapacityDemand = ({ task: t }) => {
  return {
    weight: Number(
      fromWeightUnitAToWeightUnitB(parseFloat(t.item.weight_si) || 0, STANDARD_UNITS.weight, SOLVER_WEIGHT_UNIT)
    ),
    volume: Number(convertVolume(parseFloat(t.item.volume_si), STANDARD_UNITS.volume, SOLVER_VOLUME_UNIT)),
    unit: t.task.quantity || 1,
  };
};

export const getPrecedenceTaskId = ({ task: t, precedenceMap }) => {
  if (t.type === 'dropoff' && t.pickupTaskId) {
    return t.pickupTaskId;
  }

  return precedenceMap?.[`${t['order_item_id']}`]?.[`${t['step_group']}`]?.[`${t['step_sequence'] - 1}`] || '';
};

export const transformTasks = (tasks, { hubs, settings, vehicleTypes, workers }) => {
  hubs = hubs && Array.isArray(hubs) ? hubs.filter((h) => h.location) : [];
  const filteredTasks = processTasks({ tasks, settings, vehicleTypes });
  const precedenceMap = getPrecedencesMap(filteredTasks);

  return filteredTasks.map((t) => {
    const serviceTimeConditionId = t['service_time_condition_id'];
    const {
      serviceTime: orderInfoServiceTime,
      assignedVehicleId,
      assignableVehicleIdList,
      customCapacityDemands,
    } = mapOrderInfoDetails({ task: t, workers });
    const serviceTime = orderInfoServiceTime || t['service_time'] || parseFloat(settings.serviceTime);
    const fixedServiceTime = t['location_service_time_value'] || 0;
    const variableServiceTime = t['item_service_time_value'] || 0;
    const variableServiceTimePerItem = t['per_item_service_time_value'] || 0;
    const isServiceTimeMultiplyByQuantity = t['service_time_multiply_by_quantity'] || false;
    const timeWindow = getTimeWindow({ task: t, hubs, settings });

    const taskDropPenalty = t.dropPenalty || parseFloat(settings.dropPenalty);

    const taskJson = {
      id: `${t.id}`,
      location: getLocation(t.location),
      locationType: (t?.order_step?.address_item_type ?? '').toLowerCase(),
      taskType: t.type === 'pickup' ? 'Pickup' : 'Delivery',
      capacityDemand: mapCapacityDemand({ task: t }),
      duration: serviceTime === null ? 0 : serviceTime,
      fixedServiceTime,
      variableServiceTime,
      isServiceTimeMultiplyByQuantity,
      variableServiceTimePerItem,
      serviceTimeConditionId,
      timeWindows: [timeWindow],
      assignedVehicleId: assignedVehicleId,
      assignableVehicleIdList: assignableVehicleIdList,
      preferredVehicleIdList:
        assignableVehicleIdList && assignableVehicleIdList.length ? assignableVehicleIdList.slice(0, 1) : null,
      nestedTasks: [],
      precedenceTaskId: getPrecedenceTaskId({ task: t, precedenceMap }),
      completed: !!t.completion_time,
      dropPenalty: assignedVehicleId ? taskDropPenalty * 3 : taskDropPenalty,
      properties: {
        orderStepId: t.order_step_id,
        orderTrackingNumber: t.order_item.tracking_number,
        clusteredTasks: t.clusteredTasksIds,
        dropOffTasks: t.dropOffTasks,
      },
      task: t,
      initial: t,
      clusteredTasks: t.clusteredTasks,
    };

    // Append the custom capacity demand constraints
    if (customCapacityDemands) {
      Object.keys(customCapacityDemands).forEach(function (key) {
        taskJson.capacityDemand[key] = customCapacityDemands[key];
      });
    }

    taskJson.initial = {
      task: _.cloneDeep(taskJson.task),
      location: taskJson.location,
      dropPenalty: taskJson.dropPenalty,
      duration: taskJson.duration,
      capacityDemand: taskJson.capacityDemand,
      timeWindows: taskJson.timeWindows,
      fixedServiceTime: taskJson.fixedServiceTime,
      variableServiceTime: taskJson.variableServiceTime,
      isServiceTimeMultiplyByQuantity: taskJson.isServiceTimeMultiplyByQuantity,
      variableServiceTimePerItem: taskJson.variableServiceTimePerItem,
    };
    return taskJson;
  });
};

export const applyMerchantSlot = (tasks, settings, currentDate) => {
  return tasks.map((t) => {
    if (getValue(t.order_step, 'metadata.open_from') && getValue(t.order_step, 'metadata.closed_by')) {
      const timezone = getValue(t.order_step, 'metadata.timezone', settings.timezone);
      const openTimeParts = getNormalisedTime(getValue(t.order_step, 'metadata.open_from'), 8, 0, 0).split(':');
      const closeTimeParts = getNormalisedTime(getValue(t.order_step, 'metadata.closed_by'), 17, 0, 0, true).split(':');

      t.from = moment(currentDate)
        .utc()
        .tz(timezone)
        .hour(openTimeParts[0])
        .minute(openTimeParts[1])
        .seconds(openTimeParts[2])
        .utc()
        .toISOString();
      t.to = moment(currentDate)
        .utc()
        .tz(timezone)
        .hour(closeTimeParts[0])
        .minute(closeTimeParts[1])
        .seconds(closeTimeParts[2])
        .utc()
        .toISOString();
    }

    return t;
  });
};

const transformTaskToSolverStructure = (t, precedence) => {
  return {
    id: parseInt(t.id),
    stop_id: t.stop_id || t.id,
    task: { ...t, stop_id: t.stop_id || t.id, tasks: [t.task] },
    arrivalEarliest: t.task.eta ? moment.utc(t.task.eta).toISOString() : null,
    location: {
      latitude: t.location.lat,
      longitude: t.location.lon || t.location.lng,
    },
    precedenceTaskId: get(precedence, [`${t['order_item_id']}`, `${t['step_group']}`, `${t['step_sequence'] - 1}`]),
  };
};

export const transformTasksToTour = (tasks) => {
  const correctSequenceTasks = fixSequence(tasks);

  logData({
    module: 'routePlanner',
    message: 'after fix',
    data: correctSequenceTasks,
    callback: (data) => data.map((t) => t.id),
  });

  const precedence = getPrecedencesMap(correctSequenceTasks);
  return correctSequenceTasks.map((t) => transformTaskToSolverStructure(t, precedence), []);
};

export const transformSingleTaskToTour = (task, relatedTasks) => {
  const precedence = getPrecedencesMap(relatedTasks);
  return transformTaskToSolverStructure(task, precedence);
};
