const defaultState = {
  isLoading: false,
  addressTags: [],
  errorMessage: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SEARCH_ADDRESS_TAG_START: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  SEARCH_ADDRESS_TAG_SUCCESS: (state, { addressTags }) => {
    return {
      ...state,
      isLoading: false,
      addressTags,
    };
  },
  SEARCH_ADDRESS_TAG_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
