import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { tasksService } from '@yojee/api/tasksService';

export const getTeams = (state) => state.team && state.team.data;
export const getTaskGroupIds = (state) => state.broadcastTaskDialog && state.broadcastTaskDialog.taskGroupIds;
export const getCompanyCurrency = (state) =>
  state.auth &&
  state.auth.dispatcher_info &&
  state.auth.dispatcher_info.data &&
  state.auth.dispatcher_info.data.company &&
  state.auth.dispatcher_info.data.company.currency;
export default function* sagas() {
  yield takeLatest('APPLY_BROADCAST', handleBroadCast);
}

function* handleBroadCast({ price, timer, team }) {
  try {
    const companyCurrency = yield select(getCompanyCurrency);
    const taskGroupIds = yield select(getTaskGroupIds);
    const teams = yield select(getTeams);
    const details = {
      price_amount: price,
      price_currency: companyCurrency,
      task_group_ids: taskGroupIds,
      timeout_in_minutes: timer,
      worker_team_ids: team,
    };
    yield call(tasksService.broadcast, details);
    yield delay(500);
    yield put({ type: 'APPLY_BROADCAST_SUCCESS' });
    yield put({ type: 'DESELECT_ALL_STOPS' });
    const displayMessage = `Successfully broadcasted to all drivers on duty${
      team && team.length > 0
        ? ` in ${teams
            .filter((t) => team.includes(t.id))
            .map((t) => t.name)
            .join(', ')}`
        : ''
    }`;
    yield put({ type: 'DISPLAY_MESSAGE', message: displayMessage, variant: 'success' });
    yield put({ type: 'REFRESH' });
  } catch (err) {
    yield put({ type: 'APPLY_BROADCAST_ERROR' });
    yield put({ type: 'DISPLAY_MESSAGE', message: `Broadcast error: ${err.message}`, variant: 'error' });
  }
}
