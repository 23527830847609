import { call, put, takeLatest } from 'redux-saga/effects';

import { partnerService } from '@yojee/api/partnerService';

export function* InvitePartnerDialogSagas() {
  yield takeLatest('CREATE_PARTNER_INVITATION', createPartnerInvitation);
}

function* createPartnerInvitation({ params }) {
  try {
    yield put({ type: 'CREATING_PARTNER_INVITATION' });
    yield call(partnerService.inviteMultiplePartner, params);
    yield put({ type: 'CREATING_PARTNER_INVITATION_SUCCESS' });
    yield put({
      type: 'DISPLAY_MESSAGE',
      message: 'Invitations have been sent to partners',
      variant: 'success',
    });
  } catch (err) {
    const response = err.message;
    yield put({
      type: 'CREATING_PARTNER_INVITATION_ERROR',
      error: Array.isArray(response) ? response : `Status ${err.statusCode} : ${err.message}`,
    });
  }
}
