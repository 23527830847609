const defaultState = {
  hubs: [],
  pagination: null,
  loading: {
    getHubs: false,
  },
  errorMessage: null,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  GET_HUBS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      hubs: sagaData.data,
      loading: {
        ...state.loading,
        getHubs: false,
      },
    };
  },
  GET_HUBS_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    console.log(error);
    return {
      ...state,
      loading: {
        ...state.loading,
        getHubs: false,
      },
      errorMessage: error,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
