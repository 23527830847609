import _ from 'lodash-es';

import { ACTION_METHODS, DataFetchStateMachine, DEFAULT_STATE_MACHINE_STATE } from '../helpers/DataFetchStateMachine';

const senderAutoCompleteStateMachine = new DataFetchStateMachine('SENDER_AUTOCOMPLETE')
  .addTransitionHook(ACTION_METHODS.START, (state, { searchKey }) => {
    const data = (state.data || []).filter((d) => state.selectedSenderIds.includes(d.id));
    return { searchKey, data };
  })
  .addTransitionHook(ACTION_METHODS.FETCH_SUCCESSFUL, (state, { data }) => {
    const oldSenderListSelected = (state.data || []).filter((d) => state.selectedSenderIds.includes(d.id));
    const newValuedAdded = _.differenceWith(data, oldSenderListSelected, (a, b) => a.id === b.id);
    return { data: newValuedAdded.concat(oldSenderListSelected) };
  });

const defaultState = {
  ...DEFAULT_STATE_MACHINE_STATE,
  searchKey: '',
  selectedSenderIds: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPDATE_SENDER_IDS: (state, { sendersId }) => {
    return {
      ...state,
      selectedSenderIds: sendersId,
    };
  },
  CLEAR_MASTER_FILTER: (state) => {
    return {
      ...state,
      selectedSenderIds: [],
    };
  },
  ADD_SENDERS: (state, { newSenders }) => {
    return {
      ...state,
      data: _.uniqBy([...newSenders, ...(state.data || [])], 'id'),
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : senderAutoCompleteStateMachine.makeTransition(state, action);
};

export default reducer;
