import moment from 'moment';

import { logData } from '@yojee/helpers/LogHelper';

const mapDirectionsToTasksPolyline = (directions) => {
  return directions.reduce((acc, direction) => {
    acc[direction.to] = direction.encodedPolyline;
    return acc;
  }, {});
};

export const getAssignmentBody = ({ tasks, workers, vehicleNumber, optimised, commissionJson }) => {
  const params = {
    task_ids: tasks.map((t) => t.id),
    worker_id: workers && workers[0].id,
    optimised,
  };
  if (vehicleNumber) {
    params['vehicle'] = vehicleNumber;
  }
  if (commissionJson && workers && workers[0].id) {
    const commsionValue = commissionJson[workers && workers[0].id];
    params['commission'] = commsionValue ? parseFloat(commsionValue) : 0.0;
  }
  return params;
};

export const getPlanReAssignedBody = (requestRoutes, commissionJson) => {
  return {
    _json: requestRoutes
      .filter((r) => r.tour.length > 0)
      .map((r) => {
        const tasksPolyline = mapDirectionsToTasksPolyline(r?.directions ?? []);
        return {
          worker_id: `${r.assignee.id}`,
          commission: commissionJson ? commissionJson[Number(r.assignee.id)] || 0 : 0,
          optimised: r.optimised,
          polyline: [],
          tasks: r.tour.map((task, position) => ({
            eta: task.arrivalEarliest ? moment(task.arrivalEarliest).toISOString() : null,
            id: `${task.id}`,
            position: position,
            polyline: [],
            encoded_polyline: tasksPolyline[`${task.id}`] || null,
          })),
        };
      }),
  };
};

export const getReAssignedBody = (requestRoutes, tasks, workers, commissionJson = {}, optimised = false) => {
  if (requestRoutes && requestRoutes.directions && requestRoutes.directions.length > 0) {
    const tasksPolyline = mapDirectionsToTasksPolyline(requestRoutes?.directions ?? []);

    return {
      _json: [
        {
          worker_id: `${workers[0].id}`,
          commission: commissionJson[Number(workers[0].id)] || 0,
          optimised,
          polyline: [],
          tasks: tasks.map((task, position) => ({
            eta: optimised ? moment(task.from).toISOString() : null,
            id: `${task.id}`,
            position: position,
            polyline: [],
            encoded_polyline: tasksPolyline[`${task.id}`] || null,
          })),
        },
      ],
    };
  }

  return {
    _json: [
      {
        worker_id: `${workers[0].id}`,
        commission: commissionJson[Number(workers[0].id)] || 0,
        optimised,
        polyline: [],
        tasks: tasks.map((task, position) => {
          return {
            eta: optimised ? moment(task.from).toISOString() : null,
            id: `${task.id}`,
            position: position,
            polyline: [],
            encoded_polyline: null,
          };
        }),
      },
    ],
  };
};

export const getAllocationBody = (
  requestRoutes,
  tasks,
  workers,
  commissionJson = {},
  optimised = true,
  droppedTasks = [],
  epochDate = null
) => {
  const body = { _json: [], mode: 'reassign' };
  if (Array.isArray(requestRoutes) && requestRoutes.length > 0) {
    body['_json'] = requestRoutes
      .filter((t) => (t.tour.length > 0 ? t.tour.some((t) => parseInt(t.id)) : false))
      .map((t) => {
        const tasksPolyline = mapDirectionsToTasksPolyline(t.directions);
        logData({
          module: 'assignHelper',
          message: `Tasks to assign driver - ${t.assignee.id}`,
          data: t.tour,
          callback: (data) => data.map((t) => t.id),
        });

        return {
          optimised: optimised !== false && t.optimised !== false,
          worker_id: `${t.assignee.id}`,
          commission: commissionJson[Number(t.assignee.id)] || 0,
          start_time: epochDate ? moment(epochDate).toISOString() : null,
          polyline: [],
          tasks: t.tour.map((tour, position) => {
            // t.directions contain distance of tour1 to tour2, tour2 to tour3, ...
            // So, we can get previous distance by find the direction of tour
            // with condition: direction.to = tour.id
            const directionOfTour = position > 0 && t.directions.find((direction) => direction.to === tour.id);

            let distanceFromPrev = null;
            if (directionOfTour) {
              // In case distance calculated by haversine, don't use it.
              if (!directionOfTour.is_haversine_distance) {
                distanceFromPrev = Number.parseInt(directionOfTour.distance);
              }
            }

            return {
              eta: optimised ? moment(tour.arrivalEarliest).toISOString() : null,
              id: `${tour.id}`,
              position: position,
              polyline: [],
              encoded_polyline: tasksPolyline[`${tour.id}`] || null,
              service_time: tour.serviceTime,
              distance_from_prev: distanceFromPrev,
            };
          }),
        };
      });
  }

  if (droppedTasks && Array.isArray(droppedTasks) && droppedTasks.length > 0) {
    body['_json'].push({
      optimised: false,
      worker_id: null,
      commission: 0,
      polyline: [],
      tasks: droppedTasks.map((t) => ({
        eta: null,
        id: `${t.id}`,
        position: 0,
        distance_from_prev: null,
        polyline: [],
        encoded_polyline: null,
      })),
    });

    logData({
      module: 'assignHelper',
      message: 'Tasks to unassign',
      data: droppedTasks,
      callback: (data) => data.map((t) => t.id),
    });
  }

  if (
    (!requestRoutes || !Array.isArray(requestRoutes) || !requestRoutes.length > 0) &&
    (!droppedTasks || droppedTasks.length === 0)
  ) {
    body['_json'] = [
      {
        worker_id: `${workers[0].id}`,
        commission: commissionJson[Number(workers[0].id)] || 0,
        polyline: [],
        tasks: tasks.map((task, position) => {
          return {
            eta: null,
            id: `${task.id}`,
            position: position,
            polyline: [],
            encoded_polyline: null,
          };
        }),
        optimised: optimised !== false,
      },
    ];
  }

  return body;
};

export const getParamsFromAttachedTo = (attachedTo = []) => {
  const obj = {
    item_ids: [],
    order_number: null,
  };

  attachedTo.forEach((value) => {
    if (value.includes('item')) {
      const itemId = parseInt(value.replace('item-', ''), 10);
      obj.item_ids.push(itemId);
    } else if (value.includes('order')) {
      obj.order_number = value.replace('order-', '');
    }
  });

  return obj;
};
