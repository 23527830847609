import { AVAILABLE_ORDER_STATUS_ENUM, getDateRange, ORDER_FILTERS } from '@yojee/helpers/orders-helper';
import { normalizeTaskResponse } from '@yojee/helpers/task-normalize-helper';
import { loadState, saveState } from '@yojee/local-storage/local-storage';

const dateRange = getDateRange(ORDER_FILTERS.DATE.TODAY);

export const defaultFilters = {
  from: dateRange.from,
  to: dateRange.to,
  statuses: Object.values(AVAILABLE_ORDER_STATUS_ENUM),
  dateRangeType: ORDER_FILTERS.DATE.TODAY,
  query: '',
  batchId: '',
  isOpen: {
    date: false,
    statuses: false,
  },
  typeOfOrder: 'all',
};

const storedFilters = loadState('orders-filters');
if (storedFilters) {
  const dateRangeForStoredType = getDateRange(storedFilters.dateRangeType);
  storedFilters.from = dateRangeForStoredType.from;
  storedFilters.to = dateRangeForStoredType.to;

  // Reset type of order filter when refresh page
  storedFilters.typeOfOrder = 'all';
}

export const defaultPagination = {
  currentPage: 0,
  pageSize: 50,
  totalPages: null,
  totalCount: null,
};

const defaultState = {
  // Orders page info
  list: [],
  checkedMap: {}, // contains id of checked orders
  loading: {
    requestOrdersList: false,
    updateOrder: false,
    cancelOrder: false,
    updateOrdersFilters: false,
    requestDownStreamPartnersList: false,
    transferOrderToPartner: false,
    rejectOrderTransfer: false,
    rejectOrderTransferSet: new Set(),
    acceptOrderTransfer: false,
    acceptOrderTransferSet: new Set(),
    requestOrderTasks: false,
    downloadBulkConnote: false,
    downloadBulkLabel: false,
  },
  orderDetailsDialog: {
    order: null,
    show: false,
  },
  orderTransferDialog: {
    partner: null,
    orders: [],
    show: false,
  },
  partnersListDialog: {
    partners: [],
    show: false,
  },
  orderCancelDialog: {
    order: null,
    show: false,
  },
  pagination: defaultPagination,
  filters: storedFilters || defaultFilters,
  status: null,
  message: null,

  // Multi Leg Order Creation - Consolidation
  multiLeg: {
    isInProgressCreation: false,
  },

  // Batch Upload
  batchUpload: {
    isOpen: false,
    state: 'not_submitted',
    successMessage: '',
    errors: {},
    file: null,
    batchId: null,
    getOrganisationsData: {
      pagination: {
        current_page: 0,
        limit_value: 100,
        total_count: null,
        total_pages: 1,
      },
      data: [],
    },
    searchData: {
      organizationSearchKey: undefined,
      isSearchOrganization: false,
      organisationSearchResult: [],
      selectedOrganization: undefined,
      organizationSenderSource: [],
      selectedSender: undefined,
    },
  },

  reviewItemsDialog: {
    show: false,
    orderNumber: undefined,
    type: undefined,
    tasks: {
      data: {},
      ids: [],
      legs: [],
      pagination: {
        current_page: 0,
        limit_value: 25,
        total_count: null,
        total_pages: 1,
      },
    },
  },

  error: {
    downloadBulkConnote: null,
    downloadBulkLabel: null,
  },
};

function removeOrderNumbersFromLoadingState(loadingState, orderNumbers, key) {
  // Ex: acceptOrderTransfer -> acceptOrderTransferSet
  const ordersKey = `${key}Set`;

  const setOfOrdersToBeRemoved = new Set(orderNumbers || []);
  const setOfOrdersInProgress = loadingState[ordersKey] || new Set();
  const difference = new Set([...setOfOrdersInProgress].filter((x) => !setOfOrdersToBeRemoved.has(x)));

  if (difference.size > 0) {
    return {
      ...loadingState,
      [ordersKey]: difference,
      [key]: true /* Some are still in progress */,
    };
  } else {
    return {
      ...loadingState,
      [ordersKey]: difference,
      [key]: false /* None in progress.*/,
    };
  }
}

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_ORDERS_LIST_SUCCESS: (state, { data, pagination }) => {
    return {
      ...state,
      list: [...(pagination['current_page'] === 1 ? [] : state.list), ...data],
      pagination: {
        currentPage: pagination['current_page'],
        pageSize: pagination['limit_value'],
        totalPages: pagination['total_pages'],
        totalCount: pagination['total_count'],
      },
      loading: {
        ...state.loading,
        requestOrdersList: false,
      },
    };
  },
  REQUEST_ORDERS_LIST_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        requestOrdersList: false,
      },
    };
  },
  UPDATE_ORDER_SUCCESS: (state) => {
    return {
      ...state,
      orderDetailsDialog: {
        order: null,
        show: false,
      },
      status: 'success',
      message: 'Order was successfully updated',
      loading: {
        ...state.loading,
        updateOrder: false,
      },
    };
  },
  UPDATE_ORDER_FAILED: (state) => {
    return {
      ...state,
      orderDetailsDialog: {
        order: null,
        show: false,
      },
      status: 'error',
      message: 'Order update was failed',
      loading: {
        ...state.loading,
        updateOrder: false,
      },
    };
  },
  CANCEL_ORDER_SUCCESS: (state, { message }) => {
    return {
      ...state,
      orderCancelDialog: {
        order: null,
        show: false,
      },
      status: 'success',
      message: message || 'Order was successfully cancelled',
      loading: {
        ...state.loading,
        cancelOrder: false,
      },
    };
  },
  CANCEL_ORDER_FAILED: (state, { message }) => {
    return {
      ...state,
      orderCancelDialog: {
        order: null,
        show: false,
      },
      status: 'error',
      message: message || 'Can not cancel this order',
      loading: {
        ...state.loading,
        cancelOrder: false,
      },
    };
  },
  TOGGLE_ORDER_DETAILS_DIALOG: (state, { payload: order }) => {
    return {
      ...state,
      orderDetailsDialog: {
        order: order || null,
        show: !state.orderDetailsDialog.show,
      },
    };
  },
  TOGGLE_PARTNERS_LIST_DIALOG: (state, { payload: orders }) => {
    return {
      ...state,
      partnersListDialog: {
        show: !state.partnersListDialog.show,
      },
      orderTransferDialog: {
        ...state.orderTransferDialog,
        orders: orders || [],
      },
    };
  },
  TOGGLE_CANCEL_ORDER_DIALOG: (state, { payload: order }) => {
    return {
      ...state,
      orderCancelDialog: {
        order: order || null,
        show: !state.orderCancelDialog.show,
      },
    };
  },
  TOGGLE_ORDER_TRANSFER_DIALOG: (state, { payload: partner }) => {
    return {
      ...state,
      orderTransferDialog: {
        ...state.orderTransferDialog,
        partner: partner || null,
        show: !state.orderTransferDialog.show,
      },
    };
  },
  UPDATE_ORDERS_FILTERS: (state, { payload: { filters } }) => {
    saveState('orders-filters', filters);
    return {
      ...state,
      filters,
    };
  },
  UPDATE_ORDERS_PAGINATION: (state, { payload: pagination }) => {
    return {
      ...state,
      pagination,
    };
  },
  UPDATE_ORDERS_FILTERS_SUCCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateOrdersFilters: false,
      },
    };
  },
  REQUEST_DOWNSTREAM_PARTNERS_LIST_SUCCESS: (state, { data }) => {
    return {
      ...state,
      partnersListDialog: {
        ...state.partnersListDialog,
        partners: data,
      },
      loading: {
        ...state.loading,
        requestDownStreamPartnersList: false,
      },
    };
  },
  TRANSFER_ORDER_TO_PARTNER_SUCCESS: (state, { message }) => {
    return {
      ...state,
      checkedMap: {},
      orderTransferDialog: {
        ...state.orderTransferDialog,
        show: false,
      },
      partnersListDialog: {
        partners: [],
        show: false,
      },
      status: 'success',
      message,
      loading: {
        ...state.loading,
        transferOrderToPartner: false,
      },
    };
  },
  TRANSFER_ORDER_TO_PARTNER_FAILED: (state, { message }) => {
    return {
      ...state,
      status: 'error',
      message: message || 'Internal server error. Please try again',
      orderTransferDialog: {
        partner: null,
        serviceTypes: [],
        show: false,
      },
      partnersListDialog: {
        partners: [],
        show: false,
      },
      loading: {
        ...state.loading,
        transferOrderToPartner: false,
      },
    };
  },
  REJECT_ORDER_TRANSFER_SUCCESS: (state, { orderNumbers, message }) => {
    return {
      ...state,
      checkedMap: {},
      status: 'success',
      message: message || 'The selected order(s) transfer has been rejected.',
      loading: removeOrderNumbersFromLoadingState(state.loading, orderNumbers, 'rejectOrderTransfer'),
    };
  },
  REJECT_ORDER_TRANSFER_FAILED: (state, { orderNumbers, error }) => {
    return {
      ...state,
      status: 'error',
      message: error?.message || 'Internal server error. Please try again',
      loading: removeOrderNumbersFromLoadingState(state.loading, orderNumbers, 'rejectOrderTransfer'),
    };
  },
  ACCEPT_ORDER_TRANSFER_SUCCESS: (state, { orderNumbers, message }) => {
    return {
      ...state,
      checkedMap: {},
      status: 'success',
      message: message || 'The selected order(s) transfer has been accepted.',
      loading: removeOrderNumbersFromLoadingState(state.loading, orderNumbers, 'acceptOrderTransfer'),
    };
  },
  ACCEPT_ORDER_TRANSFER_FAILED: (state, { orderNumbers, error }) => {
    return {
      ...state,
      status: 'error',
      message: error?.message || 'Internal server error. Please try again',
      loading: removeOrderNumbersFromLoadingState(state.loading, orderNumbers, 'acceptOrderTransfer'),
    };
  },
  CLEAR_ORDERS_STATUS_AND_MESSAGE: (state) => {
    return {
      ...state,
      status: null,
      message: null,
    };
  },
  MULTILEG_ORDER_CREATION_STARTED: (state) => {
    return {
      ...state,
      multiLeg: {
        ...state.multiLeg,
        isInProgressCreation: true,
      },
    };
  },
  MULTILEG_ORDER_CREATION_SUCCESSFUL: (state) => {
    return {
      ...state,
      multiLeg: {
        ...state.multiLeg,
        isInProgressCreation: false,
      },
    };
  },
  SEARCH_ORGANIZATION: (state, { searchText }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...state.batchUpload.searchData,
          organizationSearchKey: searchText,
          isSearchOrganization: true,
        },
      },
    };
  },
  CLEAR_SEARCH_ORGANIZATION: (state) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...defaultState.batchUpload.searchData,
        },
      },
    };
  },
  UPDATE_SEARCH_ORGANIZATION_RESULT: (state, { data }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...state.batchUpload.searchData,
          isSearchOrganization: false,
          organisationSearchResult: data,
        },
      },
    };
  },
  GET_ORGANISATIONS_SUCCESSFUL: (state, { data, pagination }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        getOrganisationsData: {
          ...state.batchUpload.getOrganisationsData,
          data: [...state.batchUpload.getOrganisationsData.data, ...data],
          pagination: { ...state.batchUpload.getOrganisationsData.pagination, ...pagination },
        },
      },
    };
  },
  GET_ORGANISATIONS_FAILED: (state) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        getOrganisationsData: defaultState.batchUpload.getOrganisationsData,
      },
    };
  },
  UPDATED_SELECTED_ORGANIZATION: (state, { data }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...state.batchUpload.searchData,
          selectedOrganization: data,
        },
      },
    };
  },
  UPDATE_SENDERS_BY_ORGANIZATION_ID: (state, data) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...state.batchUpload.searchData,
          organizationSenderSource: data.result,
          selectedSender: data.result && data.result.length > 0 ? data.result[0] : undefined,
        },
      },
    };
  },
  UPDATE_SELECTED_SENDER: (state, { selectedSender }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        searchData: {
          ...state.batchUpload.searchData,
          selectedSender: selectedSender,
        },
      },
    };
  },
  OPEN_BATCH_UPLOAD_DIALOG: (state) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        isOpen: true,
      },
    };
  },
  UPLOAD_ORDER_FAILED: (state, { errors = {}, batchId = null }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        state: 'failed',
        batchId,
        errors,
      },
    };
  },
  UPLOAD_ORDER_STARTED: (state) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        state: 'processing',
      },
    };
  },
  UPLOAD_ORDER_PROCESSING: (state, { id }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        state: 'processing',
        batchId: id,
      },
    };
  },
  UPLOAD_ORDER_MISSING_INFO: (state, { id, errors }) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        state: 'missing_info',
        batchId: id,
        errors,
      },
    };
  },
  UPLOAD_ORDER_SUCCEEDED: (state) => {
    return {
      ...state,
      batchUpload: {
        ...state.batchUpload,
        state: 'completed',
        successMessage: 'Batch Order was Successfully Uploaded',
        errors: defaultState.errors,
      },
    };
  },
  RESET_BATCH_UPLOAD: (state) => {
    return {
      ...state,
      batchUpload: {
        ...defaultState.batchUpload,
      },
    };
  },
  UPLOAD_REFRESH: (state) => {
    return {
      ...state,
      batchUpload: {
        ...defaultState.batchUpload,
        isOpen: state.batchUpload.isOpen,
      },
    };
  },
  TOGGLE_UPLOAD: (state) => {
    if (state.batchUpload.isOpen) {
      if (state.batchUpload.state === 'processing') {
        return {
          ...state,
          batchUpload: {
            ...defaultState.batchUpload,
            file: null,
            isOpen: false,
          },
        };
      } else {
        return {
          ...state,
          batchUpload: {
            ...defaultState.batchUpload,
          },
        };
      }
    } else {
      return {
        ...state,
        batchUpload: {
          ...defaultState.batchUpload,
          isOpen: true,
        },
      };
    }
  },
  TOGGLE_ORDER_REVIEW_ITEMS_DIALOG: (state, sagaData) => {
    return {
      ...state,
      reviewItemsDialog: {
        ...state.reviewItemsDialog,
        show: !state.reviewItemsDialog.show,
        orderNumber: sagaData?.payload?.orderNumber,
        type: sagaData?.payload?.type,
        tasks: defaultState.reviewItemsDialog.tasks,
      },
    };
  },
  RESET_ORDER_REVIEW_ITEMS_DIALOG_TASK_DATA: (state) => {
    return {
      ...state,
      reviewItemsDialog: {
        ...state.reviewItemsDialog,
        tasks: defaultState.reviewItemsDialog.tasks,
      },
    };
  },
  REQUEST_ORDER_TASKS_SUCCESSFUL: (state, { legs, tasks, pagination }) => {
    const { data, ids } = normalizeTaskResponse(tasks);
    return {
      ...state,
      reviewItemsDialog: {
        ...state.reviewItemsDialog,
        tasks: {
          legs,
          data,
          ids,
          pagination,
        },
      },
      loading: {
        ...state.loading,
        requestOrderTasks: false,
      },
    };
  },
  REQUEST_ORDER_TASKS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        requestOrderTasks: false,
      },
    };
  },
  RESET_ORDER_STATE: (state) => {
    return { ...state, list: [], checkedMap: {}, message: null };
  },
  ORDERS_LOADING: (state, { key, orderNumbers }) => {
    let loadingState = { ...state.loading, [key]: true };

    if (key === 'acceptOrderTransfer') {
      loadingState = {
        ...loadingState,
        // Merge two sets together & remove dupplicates
        acceptOrderTransferSet: new Set([...(loadingState.acceptOrderTransferSet || []), ...(orderNumbers || [])]),
      };
    } else if (key === 'rejectOrderTransfer') {
      loadingState = {
        ...loadingState,
        // Merge two sets together & remove dupplicates
        rejectOrderTransferSet: new Set([...(loadingState.rejectOrderTransferSet || []), ...(orderNumbers || [])]),
      };
    }

    return { ...state, loading: loadingState };
  },
  RESET_ORDERS_CHECKED: (state) => {
    return { ...state, checkedMap: {} };
  },
  TOGGLE_ALL_ORDERS_CHECKED: (state, { allOrdersDisplayed = [] }) => {
    const { checkedMap } = state;
    const isSomeOrdersChecked = (Object.keys(checkedMap).length || 0) > 0;
    const newCheckedMap = isSomeOrdersChecked
      ? {}
      : allOrdersDisplayed.reduce((acc, order) => {
          acc[order.id] = true;
          return acc;
        }, {});
    return { ...state, checkedMap: newCheckedMap };
  },
  TOGGLE_ORDER_CHECKED: (state, { orderId }) => {
    const { checkedMap } = state;
    const newCheckedMap = { ...checkedMap };

    if (checkedMap[orderId]) {
      delete newCheckedMap[orderId];
      return { ...state, checkedMap: newCheckedMap };
    } else {
      newCheckedMap[orderId] = true;
      return { ...state, checkedMap: newCheckedMap };
    }
  },
  UPDATE_TYPE_OF_ORDER_FILTER: (state, { typeOfOrder }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        typeOfOrder,
      },
    };
  },
  DOWNLOAD_BULK_CONNOTE: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkConnote: true,
      },
      error: {
        ...state.error,
        downloadBulkConnote: null,
      },
    };
  },
  DOWNLOAD_BULK_CONNOTE_SUCCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkConnote: false,
      },
      error: {
        ...state.error,
        downloadBulkConnote: null,
      },
    };
  },
  DOWNLOAD_BULK_CONNOTE_FAILURE: (state, { error }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkConnote: false,
      },
      error: {
        ...state.error,
        downloadBulkConnote: error,
      },
    };
  },
  DOWNLOAD_BULK_LABEL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkLabel: true,
      },
      error: {
        ...state.error,
        downloadBulkLabel: null,
      },
    };
  },
  DOWNLOAD_BULK_LABEL_SUCCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkLabel: false,
      },
      error: {
        ...state.error,
        downloadBulkLabel: null,
      },
    };
  },
  DOWNLOAD_BULK_LABEL_FAILURE: (state, { error }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        downloadBulkLabel: false,
      },
      error: {
        ...state.error,
        downloadBulkLabel: error,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
