import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class PartnerWorkersService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  fetchPartnerWorkers = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/partners/workers'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const partnerWorkersService = new PartnerWorkersService({ auth: authService });
