import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { batchAssignmentService } from '@yojee/api/batchAssignmentService';

import { uploadingStatus } from '../reducers/reducer';
import actionTypes from './actionTypes';

export default function* sagas() {
  yield takeLatest(actionTypes.downloadSample(), downloadSample);
  yield takeLatest(actionTypes.uploadBatchAssignment(), uploadBatchAssignment);
}

function* downloadSample({ format }) {
  const saveDataFile = (data, filename) => {
    const blob = new Blob([data], {
      type: format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const linkElement = document.createElement('a');
    linkElement.href = url;
    linkElement.download = filename;
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  };

  const { data } = yield call(batchAssignmentService.downloadSample, format);

  saveDataFile(data, `template.${format}`);
}

function* uploadBatchAssignment({ file }) {
  yield put({
    type: actionTypes.updateUploadingState(),
    newState: {
      status: uploadingStatus.PROCESSING,
    },
  });
  try {
    const {
      data: {
        data: { id: batchId },
      },
    } = yield call(batchAssignmentService.uploadBatchAssignment, file);

    let batchAssignmentResult = null;
    while (!batchAssignmentResult?.status) {
      const {
        data: { data },
      } = yield call(batchAssignmentService.getBatchAssignmentStatus, batchId);
      batchAssignmentResult = data.result;
      if (!batchAssignmentResult?.status) {
        yield delay(1000);
      }
    }

    yield put({
      type: actionTypes.updateUploadingState(),
      newState:
        batchAssignmentResult.status === 'error'
          ? { status: uploadingStatus.ASSIGNMENT_FAILED, errors: batchAssignmentResult.errors }
          : {
              status: uploadingStatus.COMPLETED,
              batchAssignmentResult,
            },
    });
  } catch (e) {
    if (!e.response) {
      console.error(`Error during task assignment batch upload (Status: ${e.status})`, e);
    }

    yield put({
      type: actionTypes.updateUploadingState(),
      newState: {
        status: uploadingStatus.API_ERROR,
        errors: e.response?.data?.data ?? 'Something went wrong, please try again.',
      },
    });
  }
}
