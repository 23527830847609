import { AVAILABLE_RIGHT_VIEWS } from '@yojee/helpers/constants';

const defaultState = {
  currentView: AVAILABLE_RIGHT_VIEWS.DRIVERS,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  UPDATE_CURRENT_RIGHT_VIEW: (state, { newView }) => {
    return {
      ...state,
      currentView: newView,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
