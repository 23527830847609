import { ITEM_ACTION_TYPES } from '../saga/actions';
const defaultState = {
  items: null,
  itemsGroupedByOrderId: {},
  hierarchy: {
    trees: {},
    items: {},
    inProgress: false,
  },
  deconsolidation: {
    inProgress: false,
    modalInfo: null,
  },
  consolidation: {
    inProgress: false,
    modalInfo: null,
  },
  jobs: {},
  jobsUpdate: {
    inProgress: false,
    success: null,
    error: null,
  },
  itemsByType: {},
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ITEM_ACTION_TYPES.SET_ITEMS:
      return _setItems(state, action);
    case ITEM_ACTION_TYPES.SET_GROUPED_ITEMS:
      return _setGroupedItems(state, action);
    case ITEM_ACTION_TYPES.SET_HIERARCHY:
      return _setHierarchy(state, action);
    case ITEM_ACTION_TYPES.SET_HIERARCHY_ITEMS:
      return _setHierarchyItems(state, action);
    case ITEM_ACTION_TYPES.SET_HIERARCHY_FETCH_STATUS:
      return _setHierarchyFetchStatus(state, action);
    case ITEM_ACTION_TYPES.SET_DECONSOLIDATION_STATUS:
      return _setDeconsolidationStatus(state, action);
    case ITEM_ACTION_TYPES.SET_CONSOLIDATION_STATUS:
      return _setConsolidationStatus(state, action);
    case ITEM_ACTION_TYPES.SET_DECONSOLIDATED_ITEM:
      return _setDeconsolidatedItem(state, action);
    case ITEM_ACTION_TYPES.CLOSE_DECONSOLIDATION_MODAL:
      return _closeDeconsolidationModal(state);
    case ITEM_ACTION_TYPES.SHOW_DECONSOLIDATION_MODAL:
      return _showDeconsolidationModal(state, action);
    case ITEM_ACTION_TYPES.SET_JOBS:
      return _setJobs(state, action);
    case ITEM_ACTION_TYPES.SET_JOBS_UPDATE_STATUS:
      return _setJobsUpdateStatus(state, action);
    case ITEM_ACTION_TYPES.SET_FETCHED_ITEMS:
      return _setFetchedItems(state, action);
    default:
      return state;
  }
};

function _setItems(state, action) {
  return {
    ...state,
    items: action.payload.items,
  };
}

function _setGroupedItems(state, action) {
  return {
    ...state,
    itemsGroupedByOrderId: action.payload.items,
  };
}

function _setHierarchy(state, { payload: { orderItemId, hierarchy } }) {
  return {
    ...state,
    hierarchy: {
      ...state.hierarchy,
      trees: {
        ...state.hierarchy.trees,
        [orderItemId]: hierarchy,
      },
    },
  };
}

function _setHierarchyItems(state, { payload: { items } }) {
  return {
    ...state,
    hierarchy: {
      ...state.hierarchy,
      items: {
        ...state.hierarchy.items,
        ...items,
      },
    },
  };
}

function _setHierarchyFetchStatus(state, { payload: { inProgress } }) {
  return {
    ...state,
    hierarchy: {
      ...state.hierarchy,
      inProgress,
    },
  };
}

function _setDeconsolidationStatus(state, { payload: { inProgress } }) {
  return {
    ...state,
    deconsolidation: {
      ...state.deconsolidation,
      inProgress,
    },
  };
}

function _setConsolidationStatus(state, { payload }) {
  return {
    ...state,
    consolidation: {
      ...state.consolidation,
      ...payload,
    },
  };
}

function _setDeconsolidatedItem(state, { payload: { parentOrderItemId, orderItemId, inProgress } }) {
  const deconsolidation = state.deconsolidation[parentOrderItemId] || {};
  return {
    ...state,
    deconsolidation: {
      ...state.deconsolidation,
      [parentOrderItemId]: {
        ...deconsolidation,
        [orderItemId]: !inProgress,
      },
    },
  };
}

function _closeDeconsolidationModal(state) {
  return {
    ...state,
    deconsolidation: {
      ...state.deconsolidation,
      modalInfo: false,
    },
  };
}

function _showDeconsolidationModal(state, { payload }) {
  return {
    ...state,
    deconsolidation: {
      ...state.deconsolidation,
      modalInfo: payload,
    },
  };
}

function _setJobs(state, { payload: { orderItemId, jobs } }) {
  return {
    ...state,
    jobs: {
      ...state.jobs,
      [orderItemId]: jobs,
    },
  };
}

function _setJobsUpdateStatus(state, { payload }) {
  return {
    ...state,
    jobsUpdate: {
      ...state.jobsUpdate,
      ...payload,
    },
  };
}

function _setFetchedItems(state, { payload: { items, key } }) {
  return {
    ...state,
    itemsByType: {
      ...state.itemsByType,
      [key]: items,
    },
  };
}

export default reducer;
