export const getHeaders = ({ slug, partnerJwt }) => {
  let json = {};
  if (partnerJwt) {
    json = { Authorization: partnerJwt };
  }
  // else {
  // 	if (token) {
  // 		json = { ...json, ACCESS_TOKEN: token }
  // 	}
  // }
  if (slug) {
    json = { ...json, COMPANY_SLUG: slug };
  }
  return json;
};
