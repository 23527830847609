import { SubTaskRule } from '@yojee/types';

export const sortSubTaskRules = (subTaskRules: SubTaskRule[]) => {
  const sortedRules = subTaskRules.sort((a, b) => {
    if (a.event === b.event) {
      return a.action.localeCompare(b.action);
    }
    return a.event.localeCompare(b.event);
  });
  return sortedRules;
};

export const isSubTaskRuleDatasEqual = (r1: SubTaskRule, r2: SubTaskRule) => {
  return (
    r1.event === r2.event &&
    r1.action === r2.action &&
    r1.optional === r2.optional &&
    r1.meta?.signature_message === r2.meta?.signature_message
  );
};

export const isSubTaskRuleDataListsEqual = (l1: SubTaskRule[] = [], l2: SubTaskRule[] = []) => {
  if (l1.length !== l2.length) return false;

  sortSubTaskRules(l1);
  sortSubTaskRules(l2);

  for (let i = 0; i < l1.length; i++) {
    if (!isSubTaskRuleDatasEqual(l1[i], l2[i])) return false;
  }

  return true;
};
