import { createSelector } from 'reselect';

import { getValue } from '@yojee/helpers/access-helper';
import { STATUSES } from '@yojee/helpers/constants';
import { getIdAndWorkerDataMapSelector } from '@yojee/helpers/TasksHelper';

import { validateTasks, VALIDATION_ERRORS_TYPES } from './Validator';

const getWorkerLocationError = (worker) => {
  if (!worker) {
    return null;
  }

  const {
    startLocationType,
    startLocations,
    endLocationType,
    endLocations,
    startHub,
    endHub,
    worker: workerDetail,
  } = worker;

  const errors = [];
  if (!startLocations[startLocationType] && startLocationType !== 'hub') {
    errors.push('start');
  }

  if (endLocationType && endLocationType !== '-' && !endLocations[endLocationType] && endLocationType !== 'hub') {
    errors.push('end');
  }

  if (endLocationType === 'hub' && !endHub) {
    errors.push('end');
  }

  if (startLocationType === 'hub' && !startHub) {
    errors.push('start');
  }

  if (!workerDetail.location) {
    errors.push('location');
  }

  return errors.length > 0 ? errors : null;
};

export const routePlannerStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].status : null
);

export const routePlannerPlanningTypeSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].planningType : null
);

export const routePlannerSubmenuSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].submenu : null
);

export const routePlannerTasksFetchStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].tasks.status : null
);

export const routePlannerWorkersFetchStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].workers.status : null
);

export const routePlannerAssignedTasksFetchStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].assignedTasks.status : null
);

export const routePlannerVehicleTypesFetchStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].vehicleTypes.status : null
);

export const routePlannerHubsFetchStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].hubs.status : null
);

export const routePlannerIsAllDataLoadedSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) => {
    if (!instanceToDisplay || !routePlanner[instanceToDisplay]) {
      return false;
    }

    return (
      routePlanner[instanceToDisplay].tasks.status === STATUSES.COMPLETED &&
      routePlanner[instanceToDisplay].workers.status === STATUSES.COMPLETED &&
      routePlanner[instanceToDisplay].assignedTasks.status === STATUSES.COMPLETED &&
      routePlanner[instanceToDisplay].vehicleTypes.status === STATUSES.COMPLETED &&
      routePlanner[instanceToDisplay].hubs.status === STATUSES.COMPLETED &&
      routePlanner[instanceToDisplay].transformedData.status === STATUSES.COMPLETED
    );
  }
);

export const routePlannerSettingsSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].settings : null
);

export const routePlannerHubsSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].hubs.data : []
);

export const routePlannerVehicleTypesSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].vehicleTypes.data : []
);

export const routePlannerWorkersSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].workers.data : []
);

export const routePlannerTasksSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].tasks.data : {}
);

export const routePlannerWorkersCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].workers.data : []).length
);

export const routePlannerTasksCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) => {
    const tasks =
      instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].tasks.data : {};
    return Object.keys(tasks).reduce((acc, key) => acc + tasks[key].length, 0);
  }
);

export const routePlannerAssignedTasksCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].assignedTasks.data : [])
      .length
);

export const routePlannerAssignedTasksSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].assignedTasks.data : []
);

export const routePlannerTransformedTasksSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].transformedData.tasks : []
);

export const routePlannerTransformedWorkersSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].transformedData.workers : []
);

export const routePlannerTransformedTasksCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].transformedData.tasks : [])
      .length
);

export const routePlannerTransformedWorkersCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay]
      ? routePlanner[instanceToDisplay].transformedData.workers
      : []
    ).length
);

export const routePlannerTransformedVehiclesCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay]
      ? routePlanner[instanceToDisplay].transformedData.vehicles
      : []
    ).length
);

export const routePlannerServiceTimesConditionsCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (instanceToDisplay && routePlanner[instanceToDisplay]
      ? routePlanner[instanceToDisplay].transformedData.serviceTimeConditions
      : []
    ).length
);

export const routePlannerDataTransformationStatusSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    instanceToDisplay && routePlanner[instanceToDisplay] ? routePlanner[instanceToDisplay].transformedData.status : null
);

export const routePlannerTransformedTasksElementValueSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index, _field) => instanceToDisplay,
    (_, _instanceToDisplay, index, _field) => index,
    (_, _instanceToDisplay, _index, field) => field,
  ],
  (routePlanner, instanceToDisplay, index, field) => {
    return getValue(routePlanner?.[instanceToDisplay]?.transformedData?.tasks?.[index], field);
  }
);

export const routePlannerTransformedServiceTimeConditionsElementValueSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index, _field) => instanceToDisplay,
    (_, _instanceToDisplay, index, _field) => index,
    (_, _instanceToDisplay, _index, field) => field,
  ],
  (routePlanner, instanceToDisplay, index, field) => {
    return getValue(routePlanner?.[instanceToDisplay]?.transformedData?.serviceTimeConditions?.[index], field);
  }
);

export const routePlannerIsTaskSelectedSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index) => instanceToDisplay,
    (_, _instanceToDisplay, index) => index,
  ],
  (routePlanner, instanceToDisplay, index) =>
    !!routePlanner?.[instanceToDisplay]?.transformedData?.selectedTasks?.[index]
);

export const routePlannerIsWorkerSelectedSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index) => instanceToDisplay,
    (_, _instanceToDisplay, index) => index,
  ],
  (routePlanner, instanceToDisplay, index) =>
    !!routePlanner?.[instanceToDisplay]?.transformedData?.selectedWorkers?.[index]
);

export const routePlannerWorkerSelectedSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    Object.keys(routePlanner?.[instanceToDisplay]?.transformedData?.selectedWorkers).map((index) => parseInt(index))
);

export const routePlannerTaskSelectedSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    Object.keys(routePlanner?.[instanceToDisplay]?.transformedData?.selectedTasks).map((index) => parseInt(index))
);

export const routePlannerSelectedWorkersCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay, _index) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    Object.values(routePlanner?.[instanceToDisplay]?.transformedData?.selectedWorkers ?? {}).length
);

export const routePlannerSelectedTasksCountSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    Object.values(routePlanner?.[instanceToDisplay]?.transformedData?.selectedTasks ?? {}).length
);

export const routePlannerTransformedWorkersElementValueSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index, _field) => instanceToDisplay,
    (_, _instanceToDisplay, index, _field) => index,
    (_, _instanceToDisplay, _index, field) => field,
  ],
  (routePlanner, instanceToDisplay, index, field) => {
    return getValue(routePlanner?.[instanceToDisplay]?.transformedData.workers?.[index], field);
  }
);

export const routePlannerTransformedVehiclesValueSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index, _field) => instanceToDisplay,
    (_, _instanceToDisplay, index, _field) => index,
    (_, _instanceToDisplay, _index, field) => field,
  ],
  (routePlanner, instanceToDisplay, index, field) => {
    return getValue(routePlanner?.[instanceToDisplay]?.transformedData.vehicles?.[index], field);
  }
);

export const routePlannerServiceTimeConditionsValueSelector = createSelector(
  [
    (state) => state.routePlanner,
    (_, instanceToDisplay, _index, _field) => instanceToDisplay,
    (_, _instanceToDisplay, index, _field) => index,
    (_, _instanceToDisplay, _index, field) => field,
  ],
  (routePlanner, instanceToDisplay, index, field) => {
    return getValue(routePlanner?.[instanceToDisplay]?.transformedData.serviceTimeConditions?.[index], field);
  }
);
export const routePlannerAddressUpdaterSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) => routePlanner?.[instanceToDisplay]?.addressUpdater
);

export const routePlannerWorkersValidation = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    (routePlanner?.[instanceToDisplay]?.transformedData?.workers ?? []).map(getWorkerLocationError)
);

export const routePlannerTasksValidation = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    validateTasks({
      tasks: routePlanner?.[instanceToDisplay]?.transformedData?.tasks ?? [],
      workers: routePlanner?.[instanceToDisplay]?.transformedData?.workers ?? [],
    }).errors
);

export const routePlannerIsOptimisationDisabled = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) =>
    Object.values(
      validateTasks({
        tasks: routePlanner?.[instanceToDisplay]?.transformedData?.tasks ?? [],
        workers: routePlanner?.[instanceToDisplay]?.transformedData?.workers ?? [],
      }).errors
    ).some((errors) =>
      errors.some((error) => error === VALIDATION_ERRORS_TYPES.MULTILEG_ORDER_ALREADY_ASSIGNED_TO_DRIVER)
    )
);

export const routePlannerShowConfirmationSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) => routePlanner?.[instanceToDisplay]?.confirmationDialog?.show
);

export const optimisationGridWorkerId = createSelector(
  [(state) => state.routePlanner, (state) => state.main?.optimisation?.instanceToDisplay],
  (routePlanner, instanceToDisplay) => routePlanner?.[instanceToDisplay]?.sortableGrid?.workerId
);

export const routePlannerShowUnSequencedTasksConfirmationSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay],
  (routePlanner, instanceToDisplay) => routePlanner?.[instanceToDisplay]?.unSequencedTasksRemoveDialog.show
);

export const routePlannerUnSequencedTasksConfirmationWorkerSelector = createSelector(
  [(state) => state.routePlanner, (_, instanceToDisplay) => instanceToDisplay, getIdAndWorkerDataMapSelector],
  (routePlanner, instanceToDisplay, idAndWorkerDataMap) => {
    const workerId = parseInt(routePlanner?.[instanceToDisplay]?.unSequencedTasksRemoveDialog?.workerId);
    return workerId ? idAndWorkerDataMap[workerId] : null;
  }
);
