import configureMeasurements, { mass as weightMeasurement } from 'convert-units';

import { fromUnitAToUnitB, WEIGHT_UNIT_NOTIONS } from '@yojee/helpers/unitConverter/base';

const converter = configureMeasurements({
  mass: weightMeasurement,
});

export function getShortFormOfWeightUnit(unit) {
  return WEIGHT_UNIT_NOTIONS[unit] || unit;
}

export function fromWeightUnitAToWeightUnitB(value, unitA, unitB) {
  return fromUnitAToUnitB({
    converter,
    value,
    unitA,
    unitB,
    getShortFormOfUnit: getShortFormOfWeightUnit,
  });
}
