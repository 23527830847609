import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userService } from '@yojee/api/userService';
import { Dialog, ReactSelectList } from '@yojee/ui/base';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: theme.spacing(2.5),
    height: theme.spacing(45),
  },
}));

const ModifyRoleUsersDialog = ({ role, onClose, onSave, open, currentDispatcherId, disabled, ...others }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const readOnly = useContext(ReadOnlyContext);
  const [isInProgress, setIsInProgress] = useState(false);

  const errorMessage = useSelector((state) => state.users?.errorMessage);
  const successMessage = useSelector((state) => state.users?.successMessage);
  const isActionInProgress = useSelector((state) => state.users?.inProgress?.requestUpdateRole);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    const selectedUserList =
      role?.dispatchers.map((user) => ({
        value: user.user_profile_id,
        label: user.name,
        disabledClose: user.id === currentDispatcherId,
      })) || [];
    setDefaultValue(selectedUserList);
  }, []);

  useEffect(() => {
    if (isActionInProgress && !isInProgress) {
      setIsInProgress(true);
    } else if (isInProgress) {
      if (successMessage) {
        handleClose();
        setIsInProgress(false);
      } else if (errorMessage) {
        setIsInProgress(false);
      }
    }
  }, [isActionInProgress]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    const listId = defaultValue.map((item) => item.value);
    onSave(role && role.id, listId);
  };

  const _handleUserSearchChange = (query) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const { data } = await userService.getUsers({ page: 1, page_size: 50, name: query });
      resolve(
        data
          .filter((u) => u.companies && Object.values(u.companies).find((c) => c.company_id === role.company_id))
          .map((user) => ({
            value: user.user_profile_id,
            label: user.name,
          }))
      );
    });
  };

  return (
    <Dialog
      primaryBtnDisabled={readOnly || disabled}
      onClose={handleClose}
      title={t('Users')}
      onSave={handleSave}
      open={open}
      dividers="paper"
      {...others}
    >
      <div className={classes.contentContainer}>
        <ReactSelectList
          label={<Trans>Find user to this role</Trans>}
          selectType="async"
          value={defaultValue}
          loadOptions={_handleUserSearchChange}
          onChange={setDefaultValue}
          maxHeight="280px"
          disabled={readOnly || disabled}
        />
      </div>
    </Dialog>
  );
};

export default ModifyRoleUsersDialog;
