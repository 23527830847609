import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { getHeaders } from '@yojee/api/common';
import getEnv from '@yojee/helpers/env/getEnv';

const API_URL = getEnv('REACT_APP_API_URL');

export function fetchWorkers({ token, partnerJwt, slug, sort = 'last_seen', pageSize = 1000, page = 1 }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/workers`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: { page_size: pageSize, page, sort, order: 'desc' },
  });
}

export function fetchChannels({ token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/chat/channels`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function updateChannel({ token, slug, partnerJwt, channelId, lastReadAt }) {
  return APIAxiosInstance.put(
    `${API_URL}/dispatcher/chat/channel`,
    { channel_id: channelId, last_read_at: lastReadAt },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function fetchChannelInfo({ token, slug, partnerJwt, channelId }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/chat/channel/${channelId}`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function fetchDriverChannelInfo({ token, slug, partnerJwt, driverId }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/chat/worker_channel/${driverId}`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function sendMessage({ token, slug, partnerJwt, channelId, message, attachment }) {
  const uploadJson = new FormData();
  if (channelId) uploadJson.set('channel_id', channelId);
  if (message) uploadJson.set('message', message);
  if (attachment) uploadJson.set('attachment', attachment);

  let headers = getHeaders({ token, slug, partnerJwt });
  headers = { ...headers, 'Content-Type': 'multipart/form-data' };

  return APIAxiosInstance.post(`${API_URL}/dispatcher/chat/send_message`, uploadJson, { headers: headers });
}
