import polylinelib from '@mapbox/polyline';
import { groupBy } from 'lodash-es/collection';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchJWT } from '@yojee/api/helpers/JwtHelper';
import AuthSelectors from '@yojee/auth/store/selectors';
import { getValue } from '@yojee/helpers/access-helper';
import { deNormalizeTaskResponse, normalizeTaskResponse } from '@yojee/helpers/task-normalize-helper';
import { getShowList } from '@yojee/helpers/tasks-helper';
import { normalizedTaskToStop } from '@yojee/helpers/tasksToStops';

import * as Api from '../../Api/api';
import {
  getOptimisationTasks_normalized,
  isOptimisationMode,
} from '../../components/BottomPane/Timeline/TimelineHelper';

export const getTaskData = (state) => state.planner && state.planner.taskData;
export const getSettings = (state) => state.planner && state.planner.settings;
export const getPlannerData = (state) => state.planner;
export const getWorkers = (state) =>
  state.worker?.data?.length > 0 ? state.worker?.data : state.planner?.workerData?.data ?? [];
export const getStopFilter = (state) => state.planner && state.planner.filters && state.planner.filters.taskFilter;
export const getTimeline = (state) => state.sequence.timeline;
export const getLatestRequest = (state) => state.optimisation.latestRequest;
export const getSelectedWorker = (state) => state.planner && state.planner.filters && state.planner.filters.workers;

function* buildRoutes() {
  const stopFilter = yield select(getStopFilter);
  let tasksNormalized = { data: {}, ids: [] };
  if (isOptimisationMode(stopFilter)) {
    const latestRequest = yield select(getLatestRequest);
    const originalTasks = yield select(getTaskData);
    const tasksArray = getOptimisationTasks_normalized(originalTasks, latestRequest);
    tasksNormalized = normalizeTaskResponse({ data: tasksArray });
  } else {
    tasksNormalized = yield select(getTaskData);
  }

  const workers = yield select(getWorkers);

  if (!tasksNormalized || !tasksNormalized.ids || tasksNormalized.ids.length < 1 || !workers.length) {
    yield put({ type: 'BUILD_ROUTES_SUCCEEDED', routes: {} });
    return;
  }
  const transformTasks = normalizedTaskToStop(tasksNormalized.data, tasksNormalized.ids);
  const denormalizedData = deNormalizeTaskResponse({ data: transformTasks.stops, ids: transformTasks.stopKeys });

  const stops = denormalizedData
    .map((s) => {
      // set execution time
      s.execution_time = moment.utc(s.completion_time || s.eta || s.from).local();
      return s;
    })
    .sort((a, b) => {
      // sort based on worker_id and then execution time
      return a.worker_id - b.worker_id || a.execution_time.valueOf() - b.execution_time.valueOf();
    });

  const routes = groupBy(
    stops.filter((s) => s.worker_id),
    'worker_id'
  );
  for (const worker_id of Object.keys(routes)) {
    const stops = [...routes[worker_id]];
    const worker = workers.find((w) => w.id === Number(worker_id));
    const assignedStops = stops.filter((s) => !s.completion_time);
    const completedStops = stops.filter((s) => s.completion_time);
    const asssignedRouteSequences = yield getRouteSequences(assignedStops, worker);
    const completedRouteSequences = yield getRouteSequences(completedStops, worker);

    routes[worker_id] = {
      completed: completedRouteSequences,
      assigned: asssignedRouteSequences,
    };
  }
  yield put({ type: 'BUILD_ROUTES_SUCCEEDED', routes });
}

function* getRouteSequences(stops, worker) {
  const sequences = groupBy(stops, 'sequence_id');
  const routeSequences = [];
  let idx = 0;
  for (const sequence_id in sequences) {
    const sequence = sequences[sequence_id];
    const { polyline, polylineStr, directions } = yield getDirections(sequence, idx === 0 ? worker : null);
    const stop_ids = sequence.map((s) => s.stop_id);
    idx++;
    routeSequences.push({ sequence_id: sequence_id, polyline, polylineStr, directions, stop_ids });
  }
  return routeSequences;
}

function* getDirections(stops, worker, real_directions = true) {
  if (!stops) {
    return { directions: [], polyline: [] };
  }
  if (real_directions) {
    try {
      const settings = yield select(getSettings);
      const authData = yield select(AuthSelectors.getData);
      const taskServiceTime = getValue(authData, 'dispatcher_info.data.company.settings.company.task_service_time');
      const jwt = yield fetchJWT();
      const {
        data: { polyline, directions, polylineStr },
      } = yield call(
        Api.fetchStopDirections,
        jwt,
        stops,
        worker,
        settings ? { ...settings.settings, serviceTime: taskServiceTime } : { serviceTime: taskServiceTime }
      );
      return { polyline: polylineStr ? [] : polyline, polylineStr, directions };
    } catch (e) {
      console.log('ERROR in parsing direction PARAMS');
      console.log(e);
      const polylineStr = polylinelib.encode(stops.map((s) => [s.location.lng, s.location.lat]));
      return { directions: [], polyline: [], polylineStr: polylineStr };
    }
  } else {
    const polylineStr = polylinelib.encode(stops.map((s) => [s.location.lng, s.location.lat]));
    return { directions: [], polyline: [], polylineStr: polylineStr };
  }
}

function* checkingBuildRoutes() {
  const showList = yield select(getShowList);
  const filterWorkers = yield select(getSelectedWorker);
  if (!showList && filterWorkers && filterWorkers.length > 0) {
    yield buildRoutes();
  }
}

export default function* sagas() {
  yield takeLatest(
    ['FETCH_TASKS_COMPLETE', 'FETCH_PLANNER_WORKERS_SUCCEEDED', 'UPDATE_WORKER_LOCATION'],
    checkingBuildRoutes
  );
  yield takeLatest(['GENERATE_TIMELINE_SUCCEEDED'], buildRoutes);
}
