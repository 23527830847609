import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../furnitures/Input';

const useStyles = makeStyles((theme) => ({
  saerchField: {
    marginRight: theme.spacing(1),
  },
  icon: (props) => ({
    color: props.value ? theme.palette.grey.G2 : theme.palette.grey.G7,
    '&:hover': {
      cursor: props.value ? 'pointer' : 'text',
    },
  }),
}));

const SearchField = ({ value, onChange, disabled, icon, placeholder, dataCy, ...restProps }) => {
  const { t } = useTranslation();
  const classes = useStyles({ value, disabled });
  const searchBarRef = useRef();

  const handleClear = () => {
    onChange({ target: { value: '' } });
  };

  return (
    <Input
      {...restProps}
      inputRef={searchBarRef}
      value={value}
      onChange={onChange}
      // if the user is typing, dont disable it
      disabled={disabled && !(searchBarRef.current === document.activeElement)}
      placeholder={placeholder || t('Search')}
      icon={icon}
      inputProps={{
        'data-cy': dataCy,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CloseIcon
              aria-label="clear text field"
              onClick={handleClear}
              data-cy="clear-search-field"
              className={classes.icon}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchField.defaultProps = {
  value: '',
  onChange: () => {},
  disabled: false,
  icon: <SearchIcon />,
  dataCy: 'search',
};

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  placeholder: PropTypes.string,
  dataCy: PropTypes.string,
};

export default SearchField;
