import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';
import { getHeaders } from '@yojee/api/common';

export class DriversApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getTemplateUrl = (slug) =>
    this.authService.getUmbrellaApiUrl(`dispatcher/workers/batches/download_sample?slug=${slug}`);

  uploadDrivers = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/workers/batches'), formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchStatus = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/workers/batches/${id}/status`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  searchWorkers = ({ query, partnerJwt }) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl(`dispatcher/search/worker`),
        { page: 1, page_size: 5000, q: query },
        getHeaders({ partnerJwt })
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  recalculateCapacity = ({ workers }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/trips/calculate_capacity_bg`), { worker_ids: workers })
      .catch((error) => this.handleError(error));
  };

  checkStatusCapacityUpdate = ({ processId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/trips/bg_status`), { id: processId })
      .catch((error) => this.handleError(error));
  };
}

const driversApi = new DriversApi({ authService });
export default driversApi;
