const defaultState = {
  isLoading: false,
  addressBookItems: [],
  googleAddressItems: [],
  errorMessage: undefined,
  searchKey: '',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  ADDRESS_AUTO_COMPLETE_RESET: () => {
    return defaultState;
  },
  ADDRESS_AUTO_COMPLETE_SEARCHING: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  ADDRESS_AUTO_COMPLETE_SEARCH_ADDRESS_BOOK_SUCCESS: (state, { items }) => {
    return {
      ...state,
      isLoading: false,
      addressBookItems: items,
    };
  },
  ADDRESS_AUTO_COMPLETE_SEARCH_GOOGLE_ADDRESS_SUCCESS: (state, { items }) => {
    return {
      ...state,
      isLoading: false,
      googleAddressItems: items,
    };
  },
  ADDRESS_AUTO_COMPLETE_SEARCH_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
};

export const AddressAutoCompleteReducer = {
  addressAutoComplete: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
