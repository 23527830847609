/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as _ from 'lodash-es';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchDirections } from '@yojee/api/geo';
import { fetchJWT } from '@yojee/api/helpers/JwtHelper';
import AuthSelectors from '@yojee/auth/store/selectors';
import { getValue } from '@yojee/helpers/access-helper';
import { ACTION_MODE } from '@yojee/helpers/constants';
import { getPrecedenceTaskId } from '@yojee/helpers/route-planning';
import { routePlannerPlanningTypeSelector } from '@yojee/helpers/RoutePlanningHelper/Selectors';
import { getPrecedencesMap } from '@yojee/helpers/TasksHelper';
import TimelineHelper from '@yojee/helpers/TimelineHelper';
import VehicleCapacity from '@yojee/helpers/VehicleCapacity';
import { isTaskAvailableInWorkerSchedule } from '@yojee/helpers/workerShedule';
import * as mainActions from '@yojee/ui/main/saga/actions';
import { resetSelectedTasksInSortableGrid } from '@yojee/ui/route-planner/saga/actions';
import { hideTasksGrouping, setExpandedGroup } from '@yojee/ui/route-planning-timeline/saga/actions';

const isTaskTimeWindowAvailable = (data, effectedTasks, workers) => {
  return !!effectedTasks.find(({ workerId, taskId }) => {
    let taskData = null;
    data.result.routes.find((route) => {
      if (parseInt(route.assignee.id) !== parseInt(workerId)) {
        return false;
      }

      taskData = route.tour.find((t) => parseInt(taskId) === parseInt(t.id));
      return true;
    });
    const worker = workers.find((w) => parseInt(w.id) === parseInt(workerId));
    return isTaskAvailableInWorkerSchedule(taskData, worker);
  });
};

const calculateMaxCapacityValue = (route, field) => {
  const calculatedValues = route.tour.reduce(
    ({ max, current }, t) => {
      if (t.capacityDemand) {
        current += t.capacityDemand[field];
        if (current > max) {
          max = current;
        }
      }

      return { max, current };
    },
    { max: 0, current: 0 }
  );

  return calculatedValues.max;
};

const isCapacityLimitReached = (data, effectedRouteKeys, workers) => {
  return !!effectedRouteKeys?.find((routeIndex) => {
    const taskRoute = data.result.routes[routeIndex];
    const maxValues = ['weight', 'unit', 'volume'].reduce((acc, key) => {
      acc[key] = calculateMaxCapacityValue(taskRoute, key);

      return acc;
    }, {});

    return (
      taskRoute?.assignee?.vehicleType &&
      (maxValues?.weight > taskRoute?.assignee?.vehicleType?.capacity?.weight ||
        maxValues?.unit > taskRoute?.assignee?.vehicleType?.capacity?.unit ||
        maxValues?.volume > taskRoute?.assignee?.vehicleType?.capacity?.volume)
    );
  });
};

function* handleChangeStopId(data) {
  const isManual = yield select((state) => state.planner?.actionMode);
  if (isManual === ACTION_MODE.MANUAL) {
    const newRoutes = _.get(data, 'result.routes', []).map((item) => ({
      ...item,
      tour: item.tour.map((t) => ({
        ...t,
        stop_id: `${t?.task?.location?.lat}_${t?.task?.location?.lng}_${t?.task?.order_step?.address}_${t?.task?.from}_${t?.task?.to}`,
        task: {
          ...t.task,
          stop_id: `${t?.task?.location?.lat}_${t?.task?.location?.lng}_${t?.task?.order_step?.address}_${t?.task?.from}_${t?.task?.to}`,
        },
      })),
    }));
    return (data = _.set(data, 'result.routes', newRoutes));
  }
  return data;
}

function* fetchDirectionsOnMove({ params: { effectedRouteKeys, data, requestId } }) {
  const jwt = yield call(fetchJWT);

  for (const i in [...new Set(effectedRouteKeys)]) {
    const routeIndex = effectedRouteKeys[i];
    yield put({ type: 'DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_STARTED', params: { routeIndex } });
    const route = data.result.routes[routeIndex];
    const tasks = route.tour.map((t, index) => ({
      stop_position: index,
      service_time:
        index === 0 ||
        route?.tour[index - 1]?.location?.latitude !== route?.tour[index]?.location?.latitude ||
        route?.tour[index - 1]?.location?.longitude !== route?.tour[index]?.location?.longitude
          ? t.duration
          : 0,
      location: {
        lat: t?.location?.latitude,
        lng: t?.location?.longitude,
      },
    }));
    try {
      const {
        data: { directions },
      } = yield call(fetchDirections, { jwt, worker: null, tasks, encodePolyline: true });
      data.result.routes[routeIndex].directions = data?.result?.routes[routeIndex].tour?.map((t, index) => {
        return {
          from: index > 0 ? data?.result?.routes[routeIndex]?.tour[index - 1]?.id : null,
          to: data?.result?.routes[routeIndex]?.tour[index]?.id,
          encodedPolyline: directions[index]?.encodedPolyline,
          distance: directions[index]?.distance,
          weight: directions[index]?.weight,
          time: directions[index]?.time,
          snappedWaypoints: directions[index]?.snappedWaypoints,
        };
      });
      yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId, data } });
      yield put({ type: 'DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_COMPLETED', params: { routeIndex } });
    } catch (e) {
      yield put({ type: 'DIRECTIONS_ON_MOVE_FAIL', e });
      yield put({ type: 'DIRECTIONS_UPDATE_ON_TIMELINE_CHANGE_FAILED', params: { routeIndex } });
    }
  }
}

function* afterDataChange(params) {
  let {
    instanceId,
    optimisationResultData,
    data,
    effectedRouteKeys,
    effectedTasks,
    manualInit = false,
    validateTimeWindow = true,
  } = params;

  const tasksData = yield select((state) => state.routePlanner[instanceId]?.tasks?.data);
  const assignedTasksData = yield select((state) => state.routePlanner[instanceId].assignedTasks.data);
  const workers = yield select((state) => state.routePlanner[instanceId].workers.data);
  const effectedData = TimelineHelper.mapTasks(
    [...assignedTasksData].concat(...Object.values(tasksData)),
    data,
    effectedRouteKeys
  );
  yield put({ type: 'SET_LAST_EFFECTED_DATA', params: { effectedData, updatedAt: moment().toISOString() } });
  if (validateTimeWindow && effectedTasks.length > 0 && !isTaskTimeWindowAvailable(data, effectedTasks, workers)) {
    if (manualInit) {
      yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId: instanceId, data } });
      yield put({ type: 'FETCH_DIRECTIONS_ON_MOVE', params: { effectedRouteKeys, requestId: instanceId, data } });
    } else {
      yield put({
        type: 'SHOW_MOVE_CONFIRMATION',
        params: {
          data: {
            effectedRouteKeys,
            requestId: instanceId,
            data,
            originalData: optimisationResultData,
            type: 'time_window',
          },
        },
      });
    }
  } else if (isCapacityLimitReached(data, effectedRouteKeys)) {
    yield put({
      type: 'SHOW_MOVE_CONFIRMATION',
      params: {
        data: {
          effectedRouteKeys,
          requestId: instanceId,
          data,
          originalData: optimisationResultData,
          type: 'capacity',
        },
      },
    });
  } else {
    data = yield call(handleChangeStopId, data);
    yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId: instanceId, data } });
    yield put({ type: 'FETCH_DIRECTIONS_ON_MOVE', params: { effectedRouteKeys, requestId: instanceId, data } });
  }

  const convertToMarkerPosition =
    effectedData &&
    effectedData.reduce((obj, item) => {
      obj[item.id] = item.position;
      return obj;
    }, {});
  yield put({ type: 'UPDATE_MARKER_POSITION', data: convertToMarkerPosition });

  for (let i = 0; i < effectedRouteKeys.length; i++) {
    const routeKey = effectedRouteKeys[i];
    const existingLength = optimisationResultData?.result?.routes?.[routeKey]?.tour?.length ?? 0;
    const newLength = data?.result?.routes?.[routeKey]?.tour?.length ?? 0;
    if (existingLength === 0 && newLength > 0) {
      yield put(
        mainActions.updateVisibleRoutes({
          workerId: parseInt(data?.result?.routes?.[routeKey]?.assignee?.id),
          visible: true,
          source: 'planned',
        })
      );
    }
  }
}

function* moveTask({ params: { movedTask, instanceId, startDate, endDate, optimisationData, manualInit = false } }) {
  if (!instanceId) {
    instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  }
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);
  const currentOptimisationData = optimisationData ? optimisationData : _.cloneDeep(optimisationResultData);
  let { data, effectedRouteKeys, effectedTasks } = TimelineHelper.moveTask(movedTask, currentOptimisationData);

  if (manualInit) {
    data = yield call(handleChangeStopId, data);
  }
  yield call(afterDataChange, {
    instanceId,
    optimisationResultData,
    data,
    effectedRouteKeys,
    effectedTasks,
    startDate,
    endDate,
    manualInit,
  });
}

function* moveTaskAfterDrag({ params: { movedTask, taskBefore, taskAfter, workerId, singleTaskMove, selectedTasks } }) {
  const instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);
  const planningType = yield select((state) => routePlannerPlanningTypeSelector(state, instanceId));

  if (!selectedTasks) {
    selectedTasks = yield select((state) => state.routePlanner[instanceId].sortableGrid.selectedTasks);
  }
  const currentOptimisationData = _.cloneDeep(optimisationResultData);

  const authData = yield select(AuthSelectors.getData);
  const serviceTime = getValue(authData, 'dispatcher_info.data.company.settings.company.task_service_time');

  let newDate = null;

  if (planningType !== 'manual') {
    newDate = moment(taskBefore?.eta ?? taskAfter?.eta);
    if (taskBefore && taskAfter) {
      const difference = moment(taskAfter.eta).diff(moment(taskBefore.eta), 'seconds');
      if (difference > 0) {
        newDate.add(difference / 2, 'seconds');
      }
    } else if (taskBefore) {
      newDate.add(serviceTime, 'seconds');
    } else if (taskAfter) {
      newDate.subtract(serviceTime, 'seconds');
    }
  }

  const movedTaskData = {
    id: movedTask.id,
    group: workerId,
    start: newDate && newDate.toISOString(),
    taskBefore,
    singleTaskMove,
  };

  const { data, effectedRouteKeys, effectedTasks } = TimelineHelper.changeTaskPosition(
    movedTaskData,
    currentOptimisationData,
    selectedTasks
  );

  const tourData = data?.result?.routes?.[effectedRouteKeys[0]]?.tour ?? [];
  const isValid = yield validateSequenceChanges({ tourData, selectedTasks, effectedTasks });

  if (isValid) {
    yield call(afterDataChange, {
      instanceId,
      optimisationResultData,
      data,
      effectedRouteKeys,
      effectedTasks,
      validateTimeWindow: false,
    });
    yield put(resetSelectedTasksInSortableGrid({ requestId: instanceId }));
    yield validateGrouping({ params: { tourData, instanceId } });
  }
}

function* validateSequenceChanges({ tourData, effectedTasks, selectedTasks }) {
  const precedenceMap = getPrecedencesMap(tourData.map((t) => t?.task).filter((t) => t));
  for (let i = 0; i < effectedTasks.length; i++) {
    const updatedTaskIndex = tourData.findIndex((t) => t.id === effectedTasks?.[i]?.taskId);
    const updatedTask = tourData[updatedTaskIndex];
    const updatedTaskData = updatedTask?.task ?? null;
    const taskBeforeIndex = tourData.findIndex(
      (t) => parseInt(t.id) === parseInt(getPrecedenceTaskId({ task: updatedTask?.task, precedenceMap }))
    );
    const taskAfterIndex = tourData.findIndex(
      (t) => t?.task && getPrecedenceTaskId({ task: t?.task, precedenceMap }) === parseInt(updatedTask.id)
    );

    const stepGroupsSequenceInvalid = TimelineHelper.validStepGroupSequence({
      updatedTaskData,
      tourData,
      updatedTaskIndex,
    });

    if (stepGroupsSequenceInvalid) {
      yield put({ type: 'DISPLAY_MESSAGE', message: 'Legs order is incorrect', variant: 'error' });
      return false;
    }

    if (
      taskBeforeIndex > -1 &&
      taskBeforeIndex > updatedTaskIndex &&
      !selectedTasks.includes(parseInt(tourData[taskBeforeIndex].id))
    ) {
      yield put({ type: 'DISPLAY_MESSAGE', message: 'Dropoff cannot be moved before pickup', variant: 'error' });
      return false;
    } else if (
      taskAfterIndex > -1 &&
      taskAfterIndex < updatedTaskIndex &&
      !selectedTasks.includes(parseInt(tourData[taskAfterIndex].id))
    ) {
      yield put({ type: 'DISPLAY_MESSAGE', message: 'Pickup cannot be moved after dropoff', variant: 'error' });
      return false;
    }
  }

  return true;
}

function* moveUnSequencedTasks({ params: { type, workerId, position } }) {
  const instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);
  const currentOptimisationData = _.cloneDeep(optimisationResultData);

  const tasks = (currentOptimisationData?.result?.droppedTaskData || []).filter(
    (t) => parseInt(t?.['task_group']?.['worker_id']) === parseInt(workerId) && (type === 'all' || type === t?.type)
  );

  const { data, effectedRouteKeys, effectedTasks } = TimelineHelper.addUnSequencedTasksToDriver({
    tasks,
    workerId,
    position,
    optimisationData: currentOptimisationData,
  });

  const tourData = data?.result?.routes?.[effectedRouteKeys[0]]?.tour ?? [];
  const selectedTasks = yield select((state) => state.routePlanner[instanceId].sortableGrid.selectedTasks);

  const isValid = yield validateSequenceChanges({ tourData, selectedTasks, effectedTasks });

  if (isValid) {
    yield call(afterDataChange, {
      instanceId,
      optimisationResultData,
      data,
      effectedRouteKeys,
      effectedTasks,
      validateTimeWindow: false,
    });

    yield validateGrouping({ params: { tourData, instanceId } });
    yield put(resetSelectedTasksInSortableGrid({ requestId: instanceId }));
  }
}

function* addTasks({ params: { tasks, workerId, instanceId, newTourData, manualInit = false } }) {
  const tasksIds = tasks && tasks.map((t) => parseInt(t.id));
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);
  const workers = yield select((state) => state.routePlanner[instanceId].workers.data);
  const vehicleTypes = yield select((state) => state.routePlanner[instanceId].vehicleTypes.data);
  const worker = workers.find((w) => parseInt(w.id) === workerId);
  const capacity = VehicleCapacity.getWorkerVehicleCapacity(worker, vehicleTypes);
  const data = _.cloneDeep(optimisationResultData);
  let routeIndex = data?.result?.routes?.findIndex((route) => parseInt(route.assignee.id) === parseInt(workerId));
  const tasksExistInRoutes = data?.result?.routes?.findIndex((route) => {
    return route.tour.find((t) => tasksIds.includes(parseInt(t.id)));
  });

  if (routeIndex === -1) {
    data.result.routes.push({
      assignee: {
        id: workerId,
        vehicleType: capacity
          ? {
              capacity: {
                volume: capacity.capacityInfo.vMaxLoadSpaceVolume,
                unit: capacity.capacityInfo.vCarryUnit,
                weight: capacity.capacityInfo.vMaxCarryWeight,
              },
            }
          : {},
      },
      tour: newTourData || [],
    });
    routeIndex = data.result.routes.length - 1;
  }

  if (tasksExistInRoutes === -1) {
    if (data.result.droppedTaskData) {
      data.result.droppedTaskData = data.result.droppedTaskData.filter((t) => !tasksIds.includes(parseInt(t.id)));
      data.result.droppedTasks = data.result.droppedTasks.filter((id) => !tasksIds.includes(parseInt(id)));
    }

    const newTour = data.result.routes[routeIndex].tour.concat(tasks);
    data.result.routes[routeIndex].tour = manualInit ? newTour : TimelineHelper.sortTourRoute(newTour);
    data.result.routes[routeIndex].updatedRoute = true;
    data.result.routes[routeIndex].optimised = false;

    yield call(afterDataChange, {
      instanceId,
      optimisationResultData,
      data,
      effectedRouteKeys: [routeIndex],
      effectedTasks: tasks?.map((t) => ({ workerId, taskId: t?.id })),
      startDate: moment().startOf('week'),
      endDate: moment().endOf('week'),
      manualInit,
    });
  } else {
    yield call(moveTask, {
      params: {
        movedTask: {
          id: tasksIds[0],
          group: workerId,
        },
        instanceId,
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week'),
        optimisationData: data,
        manualInit,
      },
    });
  }

  yield put({ type: 'SET_SHOW_TASK_MOVE_OVERLAY', params: { showTaskMoveOverlay: false } });
}

function* filterAndMoveTasksToPosition({ params: { workerId } }) {
  const instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);

  const routeIndex = optimisationResultData?.result?.routes?.findIndex(
    (route) => parseInt(route.assignee.id) === parseInt(workerId)
  );
  const tour = optimisationResultData?.result?.routes?.[routeIndex].tour;

  const { filteredTasks, otherTasks } = tour?.reduce(
    ({ filteredTasks, otherTasks }, t) => {
      if (t?.task && t.task?.type === 'pickup') {
        filteredTasks.push(t.task);
      } else {
        otherTasks.push(t.task);
      }

      return { filteredTasks, otherTasks };
    },
    { filteredTasks: [], otherTasks: [] }
  ) ?? { filteredTasks: [], otherTasks: [] };

  yield call(moveTaskAfterDrag, {
    params: {
      taskBefore: null,
      movedTask: filteredTasks[0],
      taskAfter: otherTasks?.[0] ?? null,
      workerId,
      singleTaskMove: true,
      selectedTasks: filteredTasks.map((t) => parseInt(t.id)),
    },
  });
}

function* moveTasksToStartAndGroup({ params: { workerId } }) {
  const requestId = yield select((state) => state.main.optimisation.instanceToDisplay);

  yield call(filterAndMoveTasksToPosition, { params: { workerId } });
  yield put({ type: 'SHOW_TASKS_GROUPED_IN_SORTABLE_GRID', params: { requestId } });
}

function* populateAndMoveTaskAfterDrag({ params: { workerId, movement } }) {
  const optimisationResultData = yield select(
    (state) => state.optimisation.requests[state.main.optimisation.instanceToDisplay]
  );

  const index = optimisationResultData.result.routes.findIndex((route) => {
    return parseInt(route.assignee.id) === parseInt(workerId);
  });

  const tasks = optimisationResultData.result.routes[index].tour
    .filter((t) => `${t.id}` !== 'startFromDepot' && `${t.id}` !== 'EndDepot')
    .map((t, index) => ({
      ...t.task,
      planned_position: index + 1,
      eta: t.arrivalEarliest,
      task: {
        ...t.task.task,
        eta: t.arrivalEarliest,
      },
    }));

  if (movement.oldIndex === movement.newIndex) {
    return;
  }

  const newIndex = movement.oldIndex > movement.newIndex ? movement.newIndex - 1 : movement.newIndex;

  const data = {
    movedTask: tasks[movement.oldIndex],
    taskBefore: tasks?.[newIndex] ?? null,
    taskAfter: null,
    workerId: parseInt(workerId),
    singleTaskMove: true,
  };

  if (data.taskBefore === null) {
    data.taskAfter = tasks[0];
  }

  yield moveTaskAfterDrag({ params: data });
}

function* removeAllUnSequencedTasks({ params: { workerId, type } }) {
  const instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  const optimisationResultData = yield select((state) => state.optimisation.requests[instanceId]);

  const currentOptimisationData = _.cloneDeep(optimisationResultData);

  yield call(afterDataChange, {
    instanceId,
    optimisationResultData,
    data: TimelineHelper.removeUnSequencedTasks({ optimisationResult: currentOptimisationData, workerId, type }),
    effectedRouteKeys: [],
    effectedTasks: [],
  });
}

function* validateGrouping({ params: { tourData, instanceId } }) {
  const showGroups = yield select((state) => state.routePlanner[instanceId].sortableGrid.showGroups);

  if (!showGroups) {
    return;
  }

  const { ungroup } = tourData.reduce(
    ({ lastType, previousTypes, ungroup }, { id, task }) => {
      if (`${id}` === 'EndDepot' || `${id}` === 'startFromDepot' || ungroup) {
        return { lastType, previousTypes, ungroup };
      }

      const type = task?.type;

      if (lastType !== type) {
        if (lastType) {
          if (previousTypes[type]) {
            ungroup = true;
          } else {
            previousTypes[lastType] = true;
          }
        }
      }

      lastType = type;
      return { lastType, previousTypes, ungroup };
    },
    { lastType: null, previousTypes: {}, ungroup: false }
  );

  if (ungroup) {
    yield put(hideTasksGrouping({ requestId: instanceId }));
    yield put({
      type: 'DISPLAY_MESSAGE',
      message: 'Grouping not available, because of tasks order. Please click "group" again"',
      variant: 'error',
    });
    yield put(setExpandedGroup({ type: 'sequenced', requestId: instanceId }));
    yield put(setExpandedGroup({ type: 'unsequenced', requestId: instanceId }));
  }
}

export default function* sagas() {
  yield takeLatest('FETCH_DIRECTIONS_ON_MOVE', fetchDirectionsOnMove);
  yield takeLatest('MOVE_TASK_ON_TIMELINE_CHANGE', moveTask);
  yield takeLatest('ADD_TASKS_TO_TIMELINE', addTasks);
  yield takeLatest('MOVE_TASK_AFTER_DRAG', moveTaskAfterDrag);
  yield takeLatest('POPULATE_AND_MOVE_TASK_AFTER_DRAG', populateAndMoveTaskAfterDrag);
  yield takeLatest('FILTER_AND_MOVE_TASKS_TO_POSITION', filterAndMoveTasksToPosition);
  yield takeLatest('MOVE_TASKS_TO_START_AND_GROUP', moveTasksToStartAndGroup);
  yield takeLatest('MOVE_UNSEQUENCED_TASKS', moveUnSequencedTasks);
  yield takeLatest('REMOVE_UNSEQUENCED_TASKS', removeAllUnSequencedTasks);
}
