import _ from 'lodash-es';
import moment from 'moment';

export const dateFormat = 'DD-MM-YYYY h:mm:ss a';
export const stringifyDate = (dateInput) => {
  return moment(dateInput).format(dateFormat);
};

export const parseDate = (stringDate) => {
  return moment(stringDate, dateFormat);
};

export const handleDateValue = (dateValue) => {
  if (!dateValue || !dateValue.from) return {};
  return {
    from: stringifyDate(dateValue.from),
    to: stringifyDate(dateValue.to),
  };
};

const handleTimeFrameValue = (timeFrame) => {
  if (!timeFrame?.key) return {};

  return {
    timeFrame: JSON.stringify(timeFrame),
  };
};

export const handleSelectedAddressLocation = (selectedAddressLocations) => {
  if (!selectedAddressLocations || selectedAddressLocations.length < 1) {
    return {};
  }

  return {
    selectedAddressOptions: selectedAddressLocations.map((selectedAddressLocation) => {
      const { lat, lng, type, name } = selectedAddressLocation || {};

      return `${lat}_${lng}_${type}_${name}`;
    }),
  };
};

export const parseBasicFilterToQueryString = (inputFilter) => {
  const propsManualy = ['dateValue', 'timeFrame', 'selectedAddressOptions'];
  const dateValueQueryString = handleDateValue(inputFilter.dateValue);
  const addressLocationQueryString = handleSelectedAddressLocation(inputFilter.selectedAddressOptions);
  const timeFrameQueryString = handleTimeFrameValue(inputFilter.timeFrame);
  const querryStringObj = { ...dateValueQueryString, ...addressLocationQueryString, ...timeFrameQueryString };

  const keys = Object.keys(inputFilter);
  keys &&
    keys.reduce((currentObj, key) => {
      if (propsManualy.includes(key)) {
        return currentObj;
      }
      if (['driverIdsQueryByTags', 'addressItemIds'].includes(key)) {
        return currentObj;
      }

      currentObj[key] = inputFilter[key];
      return currentObj;
    }, querryStringObj);

  return querryStringObj;
};

export const parsedQueryStringToFilterObject = (queryString, inputDefaultFilterObj) => {
  const defaultFilterObj = _.cloneDeep(inputDefaultFilterObj);
  const keysDefaultFilter = Object.keys(defaultFilterObj);
  const numberKey = ['radius', 'region_ids', 'serviceTypeIds', 'hubs', 'driverIds', 'senderIds', 'partnerCompanyIds'];
  keysDefaultFilter.forEach((key) => {
    if (queryString[key]) {
      // senderIds can be a string or number
      defaultFilterObj[key] = numberKey.includes(key) ? transformToNumberOrString(queryString[key]) : queryString[key];
    }
  });
  // handle dateValue
  if (queryString.from && queryString.to) {
    defaultFilterObj.dateValue = { from: parseDate(queryString.from), to: parseDate(queryString.to) };
  }
  // handle selectedAddressOptions
  if (queryString.selectedAddressOptions && queryString.selectedAddressOptions.length > 0) {
    defaultFilterObj.selectedAddressOptions = queryString.selectedAddressOptions
      .map((address) => {
        const arrValue = address.split('_');
        if (arrValue.length < 3) {
          return null;
        }

        const lat = Number(arrValue[0]);
        const lng = Number(arrValue[1]);
        const type = arrValue[2];

        const latLngTypeStr = `${lat}_${lng}_${type}_`;

        // Base on function handleSelectedAddressLocation, we get address by replace lat_lng_type_
        // Use replaceAll bcs previous wrong logic so have some address pattern like: lat_lng_type_lat_lng_type_address
        const addressName = address.replaceAll(latLngTypeStr, '');

        return {
          lat,
          lng,
          type,
          id: addressName,
          value: addressName,
          name: addressName,
        };
      })
      .filter((v) => !!v);
  }

  // hardcode for order_number
  if (queryString.order_number) {
    defaultFilterObj.idValue = queryString.order_number;
    defaultFilterObj.idKey = 'order_number';
    defaultFilterObj.ignoreRange = true;
  }

  if (queryString.transferred_order_number) {
    defaultFilterObj.idValue = queryString.transferred_order_number;
    defaultFilterObj.idKey = 'transferred_order_number';
    defaultFilterObj.ignoreRange = true;
  }

  return defaultFilterObj;
};

export const parseTimeFrameQueryStringToObject = (queryString) => {
  const timeFrame = JSON.parse(queryString.timeFrame);

  if (timeFrame.from) {
    timeFrame.from = new Date(timeFrame.from);
  }
  if (timeFrame.to) {
    timeFrame.to = new Date(timeFrame.to);
  }

  return timeFrame;
};

export const transformToNumberOrString = (input) => {
  if (!input) return 0;
  if (Array.isArray(input) && input.length > 0) {
    return input.map((v) => (isNaN(Number(v)) ? v : Number(v)));
  }
  return isNaN(Number(input)) ? input : Number(input);
};
