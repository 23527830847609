import moment from 'moment';

import getEnv from '@yojee/helpers/env/getEnv';

import { APIAxiosInstance } from './authService/dispatcherAuthService';

const GEO_URL = getEnv('REACT_APP_GEO_API_URL');

export function fetchDirection(jwt, from, to) {
  const headers = { JWT: jwt };

  const params = new URLSearchParams();
  params.append('point', from.lat + ',' + from.lng);
  params.append('point', to.lat + ',' + to.lng);

  return APIAxiosInstance.get(`${GEO_URL}/directions`, { headers: headers, params: params });
}

export function fetchDirections({ jwt, worker, tasks, settings, company, startDate = null }) {
  const headers = { JWT: jwt };
  const requestBody = {
    tasks: tasks
      .filter((item) => item.location.lat !== undefined || item.location.lng !== undefined)
      .map((t) => {
        return {
          id: t.stop_position >= 0 ? t.stop_position : t.id, // The `id` should be `int number` type!
          location: {
            lat: t.location.lat,
            lon: t.location.lng,
          },
          service_time: parseInt(t.service_time || (settings && settings.serviceTime) || 300),
        };
      }),
    service_type: 'OSM',
    encode_polyline: true,
    worker: {
      location: {
        lat: worker && worker.location ? worker.location.lat : tasks[0].location.lat,
        lon: worker && worker.location ? worker.location.lng : tasks[0].location.lng,
      },
      vehicle: 'car',
      avg_speed: 50,
      start_time: moment(startDate).toISOString() || new Date().toISOString(),
    },
  };

  // Select the proper GEO server url in case of specific area such as USA!!!
  // TODO: It would be better that the GEO_URL can be defined in the backend side per company level.
  let geoUrl = GEO_URL;
  if (company) {
    if (company.slug === 'manhattandemo') {
      // For temporarily workaround
      geoUrl = geoUrl.replace('://', '://newyork.');
    }
  }

  return APIAxiosInstance.post(`${geoUrl}/directions`, requestBody, { headers });
}

export function fetchStopDirections(jwt, stops, worker, settings) {
  const requestBody = {
    tasks: stops.map((s) => ({
      id: s.position, // The `id` should be `int number` type!
      location: { lat: s.location.lat, lon: s.location.lng },
      service_time: parseInt(s.service_time || (settings && settings.serviceTime) || 300),
    })),
    service_type: 'OSM',
    encode_polyline: true,
    worker: {
      location: {
        lat: worker?.location ? worker.location.lat : stops[0].location.lat,
        lon: worker?.location ? worker.location.lng : stops[0].location.lng,
      },
      vehicle: 'car',
      avg_speed: 50,
      start_time: new Date().toISOString(),
    },
  };

  return APIAxiosInstance.post(`${GEO_URL}/directions`, requestBody, { headers: { JWT: jwt } });
}
