import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

import { fetchJWT as fetchJWTAPI } from '@yojee/api/jwtApi';
import AuthSelectors from '@yojee/auth/store/selectors';

export const getJWT = (state) => state.auth.jwt;

export function* fetchJWT() {
  // Buffer time in milliseconds
  const bufferTime = 30000;
  const jwt = yield select(getJWT);
  const { partnerJwt } = yield select(AuthSelectors.getData);
  const currentJwt = jwt ? jwt : partnerJwt;
  const currentTime = moment.utc().valueOf();
  if (currentJwt) {
    // JWT expiry time is in seconds, current time is in milliseconds
    const { exp: expiry } = jwtDecode(currentJwt);
    if (expiry * 1000 > currentTime + bufferTime) {
      return currentJwt;
    }
  }
  // Only refetches token if it is missing or expired
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { jwt: new_jwt },
    } = yield call(fetchJWTAPI, { token, partnerJwt, slug });
    yield put({ type: 'FETCH_JWT_SUCCEEDED', data: new_jwt });
    return new_jwt;
  } catch (error) {
    yield put({ type: 'LOGIN_FAILED', error });
  }
}
