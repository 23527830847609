import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => {
      if (props.severity === 'default') {
        return theme.palette?.black?.B4;
      }
      return theme.palette[props.severity]?.light;
    },
  },
}));

const CloseBtn = ({ onClick }) => (
  <IconButton aria-label="close" color="inherit" size="small" onClick={onClick} data-cy="selected-delete">
    <CloseIcon fontSize="inherit" />
  </IconButton>
);

const Alert = ({ children, closeBtn, closeBtnOnClick, className, ...restProps }) => {
  const classes = useStyles(restProps);

  return (
    <MuiAlert
      className={clsx(classes.root, className)}
      action={closeBtn && <CloseBtn onClick={closeBtnOnClick} />}
      {...restProps}
    >
      {children}
    </MuiAlert>
  );
};

export default Alert;
