import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getOrderIdSelector, getOrderNumber } from '../../selectors';
import serviceLocator from '../../serviceLocator';
import { updateState } from '../actions';
import actionTypes from './actionTypes';

export function* InvoicesSagas() {
  yield takeLatest(actionTypes.redoInvoices().start(), redoInvoices);
  yield takeLatest(actionTypes.generateInvoices().start(), generateInvoices);
}

function* updateOrderChargesState() {
  yield put(updateState({ charges: { status: 'loading' } }));
  const orderChargesRes = yield call(serviceLocator.ratingsService.getCharges, yield select(getOrderNumber));
  yield put(updateState({ charges: { data: orderChargesRes, error: undefined, status: 'success' } }));
}

function* generateInvoices() {
  try {
    yield put({ type: actionTypes.generateInvoices().loading() });
    yield call(serviceLocator.invoiceApi.generateOrderInvoices, yield select(getOrderIdSelector));
    yield put({ type: actionTypes.generateInvoices().success() });
    yield call(updateOrderChargesState);
  } catch (error) {
    yield put({ type: actionTypes.generateInvoices().failed(), error });
    yield call(updateOrderChargesState);
  }
}

function* redoInvoices({ invoiceIds, reason }) {
  try {
    yield put({ type: actionTypes.redoInvoices().loading() });

    const reqPayload = { data: { reason } };
    yield all(invoiceIds.map((invoiceId) => call(serviceLocator.invoiceApi.invalidateInvoice, invoiceId, reqPayload)));

    yield put({ type: actionTypes.redoInvoices().success() });
    yield call(updateOrderChargesState);
  } catch (error) {
    yield put({ type: actionTypes.redoInvoices().failed(), error });
  }
}
