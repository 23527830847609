import { parseExternalId } from '../helpers/consolidationHelper';

const defaultState = {
  // consolidate, deconsolidate
  mode: null,
  // scan, pick_parent, create_new_parent
  action: null,
  scannedIds: [],
  scannedParsedIds: [],
  scannedTrackingNumbers: [],
  scannedItemsData: {},
  parentItem: null,
  scannerError: null,
  consolidation: {
    container: {
      inProgress: null,
      success: null,
      error: null,
    },
    pallet: {
      externalId: null,
      inProgress: null,
      success: null,
      error: null,
    },
  },
  deconsolidation: {
    inProgress: null,
    success: null,
    error: null,
    deconsolidatedItems: 0,
  },
  hierarchy: {
    trees: {},
    items: {},
    inProgress: false,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SET_MODE: (state, { mode }) => {
    return { ...state, mode };
  },
  SHOW_SCANNER: (state, { mode }) => {
    return {
      ...state,
      mode,
      scannedIds: defaultState.scannedIds,
      scannedTrackingNumbers: defaultState.scannedTrackingNumbers,
      scannedParsedIds: defaultState.scannedParsedIds,
      scannedItemsData: defaultState.scannedItemsData,
    };
  },
  ADD_SCANNED_CODE: (state, { code }) => {
    return { ...state, scannedIds: [...state.scannedIds, code] };
  },
  REMOVE_SCANNED_CODE: (state, { code }) => {
    const { parsedExternalId } = parseExternalId(code);
    return {
      ...state,
      scannedIds: state.scannedIds.filter((scannedCode) => scannedCode !== code),
      scannedParsedIds: state.scannedParsedIds.filter((scannedCode) => scannedCode !== parsedExternalId),
    };
  },
  RESET_CONSOLIDATION_STATUS: (state) => {
    return { ...state, consolidation: defaultState.consolidation };
  },
  RESET_DECONSOLIDATION_STATUS: (state) => {
    return { ...state, deconsolidation: defaultState.deconsolidation };
  },
  CONTAINER_CONSOLIDATION_STARTED: (state) => {
    return {
      ...state,
      consolidation: { ...state.consolidation, container: { inProgress: true, success: null, error: null } },
    };
  },
  CONTAINER_CONSOLIDATION_SUCCESS: (state) => {
    return { ...state, consolidation: { ...state.consolidation, container: { inProgress: false, success: true } } };
  },
  CONTAINER_CONSOLIDATION_ERROR: (state, { error }) => {
    return {
      ...state,
      consolidation: { ...state.consolidation, container: { inProgress: false, success: false, error } },
    };
  },
  PALLET_CONSOLIDATION_STARTED: (state) => {
    return {
      ...state,
      consolidation: { ...state.consolidation, pallet: { inProgress: true, success: null, error: null } },
    };
  },
  PALLET_CONSOLIDATION_SUCCESS: (state, { externalId }) => {
    return {
      ...state,
      consolidation: {
        ...state.consolidation,
        pallet: {
          inProgress: false,
          success: true,
          consolidatedItems: state.scannedIds.length,
          externalId,
        },
      },
    };
  },
  PALLET_CONSOLIDATION_ERROR: (state, { error }) => {
    return {
      ...state,
      consolidation: { ...state.consolidation, pallet: { inProgress: false, success: false, error } },
    };
  },
  DECONSOLIDATION_STARTED: (state) => {
    return { ...state, deconsolidation: { ...state.deconsolidation, inProgress: true, success: null, error: null } };
  },
  DECONSOLIDATION_SUCCESS: (state, { deconsolidatedItems }) => {
    return {
      ...state,
      deconsolidation: { ...state.deconsolidation, inProgress: false, success: true, deconsolidatedItems },
    };
  },
  DECONSOLIDATION_ERROR: (state, { error }) => {
    return { ...state, deconsolidation: { ...state.deconsolidation, inProgress: false, success: false, error } };
  },
  SET_SCANNER_ERROR: (state, { error }) => {
    return { ...state, scannerError: error };
  },
  RESET_SCANNER_ERROR: (state) => {
    return { ...state, scannerError: defaultState.scannerError };
  },
  SCANNED_ITEM_FETCH_SUCCESSFUL: (
    state,
    { externalId, parsedExternalId, quantityHashtag, data, addToScannedItems }
  ) => {
    if (state.mode === 'deconsolidate') {
      return {
        ...state,
        scannedIds: [externalId],
        scannedParsedIds: [parsedExternalId],
        scannedItemsData: { [parsedExternalId]: data },
        scannedTrackingNumbers: [`${data[0]['order_item']['tracking_number']}@quantity=${quantityHashtag}`],
      };
    }
    const updates = {
      scannedIds: addToScannedItems ? [...state.scannedIds, externalId] : state.scannedIds,
      scannedParsedIds: addToScannedItems ? [...state.scannedParsedIds, parsedExternalId] : state.scannedParsedIds,
      scannedItemsData: { ...state.scannedItemsData, [parsedExternalId]: data },
    };

    if (data[0] && addToScannedItems) {
      updates.scannedTrackingNumbers = [
        ...state.scannedTrackingNumbers,
        `${data[0]['order_item']['tracking_number']}@quantity=${quantityHashtag}`,
      ];
    }

    return { ...state, ...updates };
  },
  SET_HIERARCHY: (state, { payload: { orderItemId, hierarchy } }) => {
    return {
      ...state,
      hierarchy: {
        ...state.hierarchy,
        trees: {
          ...state.hierarchy.trees,
          [orderItemId]: hierarchy,
        },
      },
    };
  },
  SET_PARENT_ITEMS: (state, { payload: { items } }) => {
    return {
      ...state,
      hierarchy: {
        ...state.hierarchy,
        items: {
          ...state.hierarchy.items,
          ...items,
        },
      },
    };
  },
  SET_HIERARCHY_FETCH_STATUS: (state, { payload: { inProgress } }) => {
    return {
      ...state,
      hierarchy: {
        ...state.hierarchy,
        inProgress,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
