import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { DEFAULT_SEARCH_SEARCH_TIME_LIMIT_MS } from '@yojee/helpers/OptimiseHelper';

const prefix = process.env.REACT_APP_PREFIX || '';

export * from './geodisApi';
export * from './onboardingApi';
export * from '@yojee/api/geo';
export * from '@yojee/api/umbrellaApi';

const defaultSettings = {
  settings: {
    profile: 'default',
    startLocation: 'current_location',
    simulateVehicles: false,
    hubLat: 1.325625,
    hubLng: 103.678488,
    serviceTime: 0,
    sameLocationServiceTime: 0,
    dropPenalty: 5000,
    searchTimeLimitMs: DEFAULT_SEARCH_SEARCH_TIME_LIMIT_MS,
    subsequentPickupAndDropoff: false,
    filterTaskByCustomField3: false,
  },
  assetTypes: [
    {
      id: 'van',
      profile: 'van',
      count: 5,
      averageSpeed: 50,
      maxSpeed: 60,
      speedFactor: 1.0,
      distanceFactor: 1.0,
      capacityWeight: 90000000,
      capacityVolume: 90000000,
      capacityUnit: 90000000,
      costFixed: 35,
      costMileage: 1,
      waitingCostCoefficient: 1,
      timeLimit: 864000,
      hourlySalary: 0,
    },
    {
      id: 'motorcycle',
      profile: 'motorcycle',
      count: 2,
      averageSpeed: 30,
      maxSpeed: 60,
      speedFactor: 1.0,
      distanceFactor: 1.0,
      capacityWeight: 25,
      capacityVolume: 90,
      capacityUnit: 1000,
      costFixed: 15,
      costMileage: 1,
      waitingCostCoefficient: 1,
      timeLimit: 864000,
      hourlySalary: 0,
    },
    {
      id: 'bicycle',
      count: 0,
      profile: 'bicycle',
      averageSpeed: 7,
      maxSpeed: 10,
      speedFactor: 1.0,
      distanceFactor: 1.0,
      capacityWeight: 20,
      capacityVolume: 50,
      capacityUnit: 1000,
      costFixed: 2,
      costMileage: 1,
      waitingCostCoefficient: 1,
      timeLimit: 864000,
      hourlySalary: 0,
    },
    {
      id: 'walker',
      count: 0,
      profile: 'walker',
      averageSpeed: 5,
      maxSpeed: 10,
      speedFactor: 1.0,
      distanceFactor: 1.0,
      capacityWeight: 15,
      capacityVolume: 50,
      capacityUnit: 1000,
      costFixed: 1,
      costMileage: 1,
      waitingCostCoefficient: 1,
      timeLimit: 864000,
      hourlySalary: 0,
    },
  ],
};
export async function fetchSettings(profile) {
  const { data: settingsContent } = await APIAxiosInstance.get(`${prefix}/data/settings/${profile}.json`).catch(() => {
    console.error('The settings file does not exist for your company slug:', `${prefix}/data/settings/${profile}.json`);
    console.error('Instead the default setting file will be used:', `${prefix}/data/settings/default.json`);
    return { data: defaultSettings };
  });
  const { settings } = settingsContent;
  return settings && settings.startLocation ? { data: settingsContent } : { data: defaultSettings };
}
