import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../furnitures/Button';
import Tab from '../../furnitures/Tab';
import { Title } from '../../furnitures/Typography';

const BASIC_TAB = 0;
const ADVANCE_TAB = 1;

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '45vw',
    padding: theme.spacing(2),
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  tabs: {
    minHeight: 35,
  },
  actions: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.divider}`,
    '& button:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  tab: {
    padding: '0',
    minWidth: 'unset',
    minHeight: theme.spacing(4.5),
    textTransform: 'unset',
    fontWeight: 'bold',
    margin: `0 ${theme.spacing(1)}px`,
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  paperPopover: {
    overflow: 'unset',
    borderRadius: '0px',
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75);',
  },
  counter: {
    '& .MuiBadge-badge': {
      minWidth: 15,
      height: 15,
      fontWeight: 'bold',
      top: 2,
      fontSize: 11,
      padding: '0 5px',
    },
  },
  badgeRoot: {
    '& .MuiBadge-badge': {
      fontSize: '0.6875rem',
      height: '18px',
      minWidth: '18px',
      padding: 0,
    },
  },
  panelContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
  },
}));

const Filter = ({ component, basicCounterVal, advanceCounterVal, disabled, title }) => {
  const { t } = useTranslation();
  const { BasicFilter = null, AdvanceFilter = null } = component;

  const classes = useStyles();
  const tabsActions = useRef();
  const [tab, setTab] = useState(BASIC_TAB);

  const [basicCounter, setBasicCounter] = useState(0);
  const [advanceCounter, setAdvancedCounter] = useState(0);
  const [basicApplyCallback, setBasicApplyCallback] = useState(null);
  const [basicClearCallback, setBasicClearCallback] = useState(null);
  const [advanceApplyCallback, setAdvanceApplyCallback] = useState(null);
  const [advanceClearCallback, setAdvanceClearCallback] = useState(null);

  const memorizeFunction = useCallback(
    (fn) => (cb) => {
      fn(cb);
    },
    []
  );
  const memorizedSetBasicCounter = memorizeFunction(setBasicCounter);
  const memorizedSetAdvanceCounter = memorizeFunction(setAdvancedCounter);
  const memorizedSetBasicApply = memorizeFunction(setBasicApplyCallback);
  const memorizedSetBasicClear = memorizeFunction(setBasicClearCallback);
  const memorizedSetAdvanceApply = memorizeFunction(setAdvanceApplyCallback);
  const memorizedSetAdvanceClear = memorizeFunction(setAdvanceClearCallback);

  const isShowTab = BasicFilter !== null && AdvanceFilter !== null;

  useEffect(() => {
    !!basicCounterVal && setBasicCounter(basicCounterVal);
  }, [basicCounterVal]);

  useEffect(() => {
    !!advanceCounterVal && setAdvancedCounter(advanceCounterVal);
  }, [advanceCounterVal]);

  const isClearButtonDisabled = useMemo(() => {
    if (tab === BASIC_TAB) {
      // Clear button is disabled if current tab is Basic tab
      // and there is at least one filter is applied
      return basicCounter === 0;
    }

    if (tab === ADVANCE_TAB) {
      // Clear button is disabled if current tab is Advance tab
      // and there is at least one filter is applied
      return advanceCounter === 0;
    }

    return true;
  }, [tab, basicCounter, advanceCounter]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handlePopoverEntered = () => {
    if (tabsActions.current) {
      tabsActions.current.updateIndicator();
    }

    if (basicCounter === 0 && advanceCounter > 0) {
      setTab(ADVANCE_TAB);
    }
  };

  const handlePopupClose = () => {
    setTab(BASIC_TAB);
  };

  const handleSaveFilter = (popupState, tab) => () => {
    if (tab === BASIC_TAB) {
      basicApplyCallback();
    } else if (tab === ADVANCE_TAB) {
      advanceApplyCallback();
    }

    popupState.close();
  };

  const handleClearFilter = () => {
    if (typeof basicClearCallback === 'function') basicClearCallback();
    if (typeof advanceClearCallback === 'function') advanceClearCallback();
    setBasicCounter(0);
    setAdvancedCounter(0);
  };

  return (
    <PopupState variant="popover" popupId="filter-pop-over">
      {(popupState) => (
        <Box>
          <Box display="flex" alignItems="center">
            <Badge className={classes.badgeRoot} badgeContent={basicCounter + advanceCounter} color="primary">
              <Button
                {...bindTrigger(popupState)}
                aria-label="Filter"
                size="small"
                variant="outlined"
                style={{ textTransform: 'none' }}
                disabled={disabled}
                startIcon={
                  <FilterList
                    color={basicCounter || advanceCounter ? 'primary' : 'default'}
                    disablePadding
                    fontSize="small"
                  />
                }
                data-cy="filter"
              >
                <Trans>Filter</Trans>
              </Button>
            </Badge>
          </Box>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ className: classes.paperPopover }}
            TransitionProps={{ onEntered: handlePopoverEntered, onExited: handlePopupClose }}
          >
            <Box className={classes.root}>
              <Title variant="h4">{title || <Trans>Active Filters</Trans>}</Title>
              {isShowTab && (
                <AppBar className={classes.appBar} position="static">
                  <Tab.Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="simple tabs example"
                    action={tabsActions}
                    className={classes.tabs}
                  >
                    <Tab.Tab
                      // icon={<Badge style = { {verticalAlign : 'middle'} } color="primary" badgeContent={basicCounter}></Badge>}
                      className={classes.tab}
                      label={t('Basic')}
                      {...a11yProps(0)}
                    />
                    {AdvanceFilter && (
                      <Tab.Tab
                        // icon={<Badge className={classes.counter} color="primary" badgeContent={advanceCounter}></Badge>}
                        className={classes.tab}
                        label={t('Advanced')}
                        {...a11yProps(1)}
                      />
                    )}
                  </Tab.Tabs>
                </AppBar>
              )}
              {BasicFilter && (
                <Tab.TabPanel value={tab} index={0} className={classes.panelContainer}>
                  <BasicFilter
                    onApply={memorizedSetBasicApply}
                    onClear={memorizedSetBasicClear}
                    setCounter={memorizedSetBasicCounter}
                  />
                </Tab.TabPanel>
              )}
              {AdvanceFilter && (
                <Tab.TabPanel value={tab} index={1} className={classes.panelContainer}>
                  <AdvanceFilter
                    onApply={memorizedSetAdvanceApply}
                    onClear={memorizedSetAdvanceClear}
                    setCounter={memorizedSetAdvanceCounter}
                  />
                </Tab.TabPanel>
              )}
              <Box className={classes.actions}>
                <Button
                  data-cy="filter-clear"
                  onClick={handleClearFilter}
                  disabled={isClearButtonDisabled}
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  <Trans>Clear</Trans>
                </Button>
                <Box>
                  <Button
                    data-cy="filter-cancel"
                    onClick={popupState.close}
                    color="default"
                    size="small"
                    variant="contained"
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    data-cy="filter-apply"
                    onClick={handleSaveFilter(popupState, tab)}
                    color="primary"
                    size="small"
                    variant="contained"
                  >
                    <Trans>Apply</Trans>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
    </PopupState>
  );
};

Filter.defaultProps = {
  basicCounterVal: null,
  advanceCounterVal: null,
  disabled: false,
};

Filter.propTypes = {
  basicCounterVal: PropTypes.number,
  advanceCounterVal: PropTypes.number,
  component: PropTypes.shape({
    BasicFilter: PropTypes.node,
    AdvanceFilter: PropTypes.node,
  }),
  disabled: PropTypes.bool,
};

export default Filter;
