import { debounce } from '@redux-saga/core/effects';
import * as _ from 'lodash-es';
import { groupBy } from 'lodash-es/collection';
import { push } from 'redux-first-history';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { tasksService } from '@yojee/api/tasksService';
import * as Api from '@yojee/api/umbrellaApi';
import AuthSelectors from '@yojee/auth/store/selectors';
import { searchTasks } from '@yojee/data/fetch-services';
import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_VIEWS, MAX_AMOUNT_OF_SELECTED_TASKS } from '@yojee/helpers/constants';
import { getShouldFetchItemRelatedLegs } from '@yojee/helpers/MasterFilterHelper';
import { getSelectedTasks, getTaskData2 } from '@yojee/helpers/tasks-helper';

import { checkIsItemsRouteSame, parseExternalId } from '../../helpers/consolidationHelper';
import { getScannerSearchField, getScannerSearchFieldType } from '../../helpers/ScannerHelper';
import { getNumberOfLoadedTasks } from '../planner/saga';

export const getTasksData = (state) => state.tasks;
export const getScannerData = (state) => state.scanner;

const mapScannedTrackingNumbers = (trackingNumbers) => {
  return trackingNumbers.reduce((codes, code) => {
    const [trackingNumber] = code.split('@quantity=');
    if (!codes[trackingNumber]) {
      codes[trackingNumber] = 0;
    }

    codes[trackingNumber]++;
    return codes;
  }, {});
};

function* uploadTasks(action) {
  try {
    yield put({ type: 'UPLOAD_TASKS_STARTED' });
    const data = yield call(tasksService.uploadTasks, action.payload.file);
    yield put({ type: 'UPLOAD_TASKS_BATCH_ID_RECEIVED', data });

    while (true) {
      const statusData = yield call(tasksService.batchStatus, data.id);
      if (statusData.status === 'completed') {
        yield put({ type: 'UPLOAD_TASKS_FINISHED' });
        yield put({ type: 'UPLOAD_TASKS_SUCCESS', statusData });
        yield put({ type: 'REFRESH' });
        break;
      }

      yield delay(2000);
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_TASKS_FINISHED' });
    yield put({ type: 'UPLOAD_TASKS_FAILED', error });
  }
}

export function* fetchLegs({ tasks }) {
  const tasksIds = tasks.map((t) => t.id);
  const orderItemIds = tasks.map((t) => t['order_item']['id']);
  if (!tasksIds.length && !orderItemIds) {
    return;
  }

  yield put({ type: 'LEGS_FETCH_STARTED', orderItemIds });

  let fetchedTasks = tasks;

  if (tasksIds.length) {
    let taskRelated = [];
    if (taskRelated && Array.isArray(taskRelated) && taskRelated.length > 0) {
      taskRelated = taskRelated.filter((t) => t.task && t.task.state === 'created');
    }

    fetchedTasks = fetchedTasks.concat(_.uniqBy(taskRelated, 'id'));
  }

  yield put({ type: 'LEGS_FETCH_DONE', groupedTasks: groupBy(fetchedTasks, 'order_item.id') });
}

function* fetchScannedItem({ externalId }) {
  const { scannedItems } = yield select(getTasksData);
  const { scannedIds, scannedItemsData, scannedParsedIds } = yield select(getScannerData);
  const { token, dispatcher_info: dispatcherInfo } = yield select(AuthSelectors.getData);
  const {
    data: {
      company: { slug },
    },
  } = dispatcherInfo;
  try {
    const { parsedExternalId, quantityHashtag } = parseExternalId(externalId);

    if (!scannedItems[externalId]) {
      yield put({ type: 'SCANNED_ITEM_FETCH_STARTED', externalId });
      const searchField = getScannerSearchField(dispatcherInfo);
      const searchFieldType = getScannerSearchFieldType(dispatcherInfo);
      let value = '';
      if (searchFieldType === 'string') {
        value = `${parsedExternalId}`;
      }

      if (searchFieldType === 'array') {
        value = [`${parsedExternalId}`];
      }

      const {
        data: { data },
      } = yield call(Api.fetchTasksByField, searchField, value, { token, slug });
      if (data.length > 0) {
        const validation = checkIsItemsRouteSame(
          data,
          scannedParsedIds.map((externalId) => scannedItemsData[externalId])
        );
        if (!validation.isRouteValid) {
          yield put({ type: 'SCANNED_ITEM_ROUTE_INCORRECT', externalId });
        }
        yield put({
          type: 'SCANNED_ITEM_FETCH_SUCCESSFUL',
          externalId,
          parsedExternalId,
          quantityHashtag,
          data,
          addToScannedItems: validation.isRouteValid,
        });
      } else {
        yield put({ type: 'SCANNED_ITEM_NOT_FOUND', externalId });
      }
    } else if (!scannedIds.includes(externalId) && !scannedItems[externalId].inProgress) {
      const validation = checkIsItemsRouteSame(
        scannedItemsData[externalId],
        scannedParsedIds.map((externalId) => scannedItemsData[externalId])
      );
      if (!validation.isRouteValid) {
        yield put({ type: 'SCANNED_ITEM_ROUTE_INCORRECT', externalId });
      }
      yield put({
        type: 'SCANNED_ITEM_FETCH_SUCCESSFUL',
        externalId,
        parsedExternalId,
        quantityHashtag,
        data: scannedItemsData[externalId],
        addToScannedItems: validation.isRouteValid,
      });
    }
  } catch (e) {
    yield put({ type: 'SCANNED_ITEM_FETCH_FAILED', externalId });
    console.error(e);
  }
}

function* fetchContainers({ orderItemIds, taskQueryType }) {
  yield put({ type: 'CONTAINERS_FETCH_STARTED' });

  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const params = {
    item_types: ['container'],
    page: 1,
    limit: 1000,
    query_version: 2,
    task_query_type: taskQueryType || 'not_completed',
  };
  if (orderItemIds) {
    params['order_item_ids'] = orderItemIds;
  }
  const {
    data: { data },
  } = yield call(Api.fetchTasks, params, { token, slug });

  yield put({ type: 'CONTAINERS_FETCH_DONE', data: groupBy(data, 'order_item.id') });
}

function* fetchPallets({ parentItemTrackingNumber, includeNotConsolidated, fetchAll, palletOrderItemIds }) {
  yield put({ type: 'PALLETS_FETCH_STARTED' });

  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const params = {
    item_types: ['pallet'],
    page: 1,
    limit: 1000,
    query_version: 2,
    task_query_type: 'all',
  };

  if (parentItemTrackingNumber !== null) {
    params.parent_item_tracking_number = parentItemTrackingNumber;
  }

  let fetchedData = [];
  if (fetchAll || parentItemTrackingNumber) {
    const {
      data: { data },
    } = yield call(Api.fetchTasks, params, { token, slug });
    fetchedData = data;
    delete params.parent_item_tracking_number;
  }

  if (includeNotConsolidated) {
    params.not_consolidated = true;
    const {
      data: { data: notConsolidated },
    } = yield call(Api.fetchTasks, params, { token, slug });
    fetchedData = [...fetchedData, ...notConsolidated];
    delete params.not_consolidated;
  }

  if (Array.isArray(palletOrderItemIds) && palletOrderItemIds.length > 0) {
    params.order_item_ids = palletOrderItemIds;
    const {
      data: { data: tasks },
    } = yield call(Api.fetchTasks, params, { token, slug });
    fetchedData = [...fetchedData, ...tasks];
    delete params.order_item_ids;
  }

  yield put({ type: 'PALLETS_FETCH_DONE', data: groupBy(_.uniqBy(fetchedData, 'id'), 'order_item.id') });
}

export function* consolidateContainer({ containerTrackingNumber, pallet }) {
  yield put({ type: 'CONTAINER_CONSOLIDATION_STARTED' });
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const palletTrackingNumber = getValue(pallet, '0.order_item.tracking_number');
  const palletOrderItemId = getValue(pallet, '0.order_item.id');
  const consolidateLocation = getValue(pallet, '0.location');
  const deconsolidateLocation = getValue(pallet, '1.location');
  try {
    yield call(Api.consolidate, {
      body: {
        parent_tracking_number: containerTrackingNumber,
        child_tracking_numbers: { [palletTrackingNumber]: 1 },
        consolidate_step: {
          location: consolidateLocation,
        },
        deconsolidate_step: {
          location: deconsolidateLocation,
        },
      },
      token,
      slug,
    });
    yield put({ type: 'CONTAINER_CONSOLIDATION_SUCCESS' });
    yield put({
      type: 'COMPLETE_PALLET_TASKS_BY_TYPE',
      palletOrderItemIds: [palletOrderItemId],
      taskType: 'pickup',
    });
    return { success: true };
  } catch (error) {
    yield put({ type: 'CONTAINER_CONSOLIDATION_ERROR', error: getValue(error, 'response.data.data.errors.0.error') });
    return { success: false };
  }
}

function* consolidatePallet({ palletTrackingNumber, palletExternalId }) {
  yield put({ type: 'PALLET_CONSOLIDATION_STARTED' });
  const { scannedTrackingNumbers, scannedParsedIds, scannedItemsData } = yield select(getScannerData);
  //second and third step of the first item.
  //should have verified that all scanned items have the same destinations before reaching here
  const consolidateLocation = scannedItemsData[scannedParsedIds[0]][1].location;
  const deconsolidateLocation = scannedItemsData[scannedParsedIds[0]][2].location;
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  try {
    yield call(Api.consolidate, {
      body: {
        parent_tracking_number: palletTrackingNumber,
        child_tracking_numbers: mapScannedTrackingNumbers(scannedTrackingNumbers),
        consolidate_step: {
          location: consolidateLocation,
        },
        deconsolidate_step: {
          location: deconsolidateLocation,
        },
      },
      token,
      slug,
    });
    yield put({ type: 'PALLET_CONSOLIDATION_SUCCESS', externalId: palletExternalId });
    yield put({ type: 'SHOW_SCANNER', mode: 'consolidate' });
    const prefix = process.env.REACT_APP_PREFIX || '';
    yield put(push(`${prefix}/scan/scan`));
  } catch (error) {
    yield put({ type: 'PALLET_CONSOLIDATION_ERROR', error: getValue(error, 'response.data.data.errors.0.error') });
  }
}

function* deconsolidate({ parentTrackingNumber, childTrackingNumbers, childItems }) {
  yield put({ type: 'DECONSOLIDATION_STARTED' });
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  try {
    yield call(Api.deconsolidate, {
      parentTrackingNumber,
      childTrackingNumbers,
      token,
      slug,
    });

    const itemsToComplete = childItems.filter((child) => child['item']['payload_type'].toLowerCase() === 'pallet');
    if (itemsToComplete.length > 0) {
      yield call(completePalletTasksByType, {
        palletOrderItemIds: itemsToComplete.map((item) => item['order_item']['id']),
        taskType: 'dropoff',
      });
    }

    yield put({ type: 'DECONSOLIDATION_SUCCESS', deconsolidatedItems: Object.keys(childTrackingNumbers).length });
    yield put({ type: 'SHOW_SCANNER', mode: 'deconsolidate' });

    const prefix = process.env.REACT_APP_PREFIX || '';
    yield put(push(`${prefix}/scan/scan`));
  } catch (error) {
    yield put({
      type: 'DECONSOLIDATION_ERROR',
      error: getValue(error, 'response.data.data.errors.0.error'),
    });
  }
}

function* completePalletTasksByType({ palletOrderItemIds: orderItemIds, taskType }) {
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const params = {
    page: 1,
    limit: 100,
    query_version: 2,
    task_query_type: 'all',
    include_count: true,
    order_item_ids: orderItemIds,
  };

  const {
    data: { data },
  } = yield call(Api.fetchTasks, params, { token, slug });
  const tasksToComplete = data.filter((task) => task.type === taskType && task.status !== 'completed');

  if (tasksToComplete.length > 0) {
    yield call(
      tasksService.bulkComplete,
      tasksToComplete.map((task) => task.id)
    );
  }
}

function* fetchHierarchy({ orderItemId, trackingNumber }) {
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  yield put({ type: 'SET_HIERARCHY_FETCH_STATUS', payload: { inProgress: true } });
  const {
    data: { data: hierarchy },
  } = yield call(Api.fetchHierarchy, { slug, token, orderItemId });
  yield put({ type: 'SET_HIERARCHY', payload: { orderItemId, hierarchy } });

  const params = {
    limit: 1000,
    query_version: 2,
    task_query_type: 'all',
    parent_item_tracking_number: trackingNumber,
  };

  const {
    data: { data: tasksData },
  } = yield call(Api.fetchTasks, params, { token, slug });
  yield put({ type: 'SET_PARENT_ITEMS', payload: { items: _.groupBy(tasksData, 'order_item_id') } });
  yield put({ type: 'SET_HIERARCHY_FETCH_STATUS', payload: { inProgress: false } });
}

export function* selectAllTasksByFilter() {
  const PAGE_SIZE = 250;
  const relatedLegs = yield select(getShouldFetchItemRelatedLegs);

  yield put({ type: 'UPDATE_SELECT_ALL_DATA', data: { inProgress: true } });

  let fetchedTasks = [];
  const { count, countType, data } = yield searchTasks({
    relatedLegs,
    size: PAGE_SIZE,
    sort: { by: ['id'], order: 'desc', ignoreMasterStateSort: true },
  });
  let maxId = data[data.length - 1]?.id;
  fetchedTasks = fetchedTasks.concat(...data);

  yield put({ type: 'UPDATE_SELECT_ALL_DATA', data: { total: count, fetched: data.length, countType } });

  let lastCount = MAX_AMOUNT_OF_SELECTED_TASKS > count ? count : MAX_AMOUNT_OF_SELECTED_TASKS;
  let lastCountType = countType;
  while (lastCount >= PAGE_SIZE && fetchedTasks.length < MAX_AMOUNT_OF_SELECTED_TASKS && maxId !== null) {
    const {
      count: pageCount,
      data: pageData,
      countType: pageCountType,
    } = yield searchTasks({
      relatedLegs,
      size: PAGE_SIZE,
      sort: { by: ['id'], order: 'desc', ignoreMasterStateSort: true },
      maxId,
    });
    if (lastCountType === 'gte' && pageCountType === 'eq') {
      yield put({ type: 'SET_TASK_COUNT_WITH_TYPE', count: pageCount + fetchedTasks.length, countType: 'eq' });
      yield put({ type: 'UPDATE_SELECT_ALL_DATA', data: { total: pageCount + fetchedTasks.length, countType: 'eq' } });
    }

    lastCountType = pageCountType;
    lastCount = pageCount;

    maxId = pageData[pageData.length - 1] ? pageData[pageData.length - 1].id : null;

    fetchedTasks = fetchedTasks.concat(...pageData);

    yield put({ type: 'UPDATE_SELECT_ALL_DATA', data: { fetched: fetchedTasks.length } });
  }

  fetchedTasks.slice(0, MAX_AMOUNT_OF_SELECTED_TASKS);

  yield put({ type: 'LOAD_MORE_TASK_SUCCESS', tasks: { data: fetchedTasks } });
  yield put({ type: 'UPDATE_SELECT_ALL_DATA', data: { selected: true, inProgress: false } });
  yield put({ type: 'FETCH_TASKS_COMPLETE' });
  yield put({ type: 'SELECT_TASKS', tasks: fetchedTasks, select: true });
  const loadedTotal = yield select(getNumberOfLoadedTasks);

  yield put({
    type: 'SET_LOADED_TOTAL',
    loadedItemTotal: loadedTotal.loadedItemTotal,
    loadedTasksTotal: loadedTotal.loadedTasksTotal,
  });
}

function* updateAddressItemId({ addressItemId }) {
  const selectedTasksIds = yield select(getSelectedTasks);
  const { data: taskData } = yield select(getTaskData2);

  const selectedTasksOrderItemStepIds = selectedTasksIds.map((id) => taskData[id]['order_item_step_id']);
  yield put({ type: 'MISSING_INFO_FIX_IN_PROGRESS' });

  const { data } = yield call(tasksService.bulkUpdate, {
    orderItemStepIds: selectedTasksOrderItemStepIds,
    update: { address_item_id: addressItemId },
  });

  while (true) {
    const response = yield call(tasksService.bulkUpdateStatus, data.id);
    if (response.data.completed_at) {
      break;
    }

    yield delay(2000);
  }

  yield put({ type: 'UPDATE_SENDER_IDS', sendersId: [] });
  yield put({ type: 'UPDATE_SELECTED_ADDRESS_OPTION', options: [] });
  yield put({ type: 'SET_MISSING_INFO_FIX_MODE', enabled: false });
  yield put({ type: 'CHANGE_VIEW_BY_MASTER_FILTER', newView: AVAILABLE_VIEWS.ITEMS });
  yield put({ type: 'MISSING_INFO_FIX_IN_COMPLETED' });
  yield put({ type: 'APPLY_MASTER_FILTER' });
  yield put({ type: 'HIDE_MISSING_INFO_DIALOG' });
}

function* fetchETA({ tasks }) {
  try {
    if (tasks.data) {
      const tasksIds = tasks.data
        .filter((task) => task['task_group']['worker_id'] || task.transfer_requested_time)
        .map((task) => task.id);

      if (Array.isArray(tasksIds) && tasksIds.length > 0) {
        const { partnerJwt } = yield select(AuthSelectors.getData);
        const chunks = _.chunk(tasksIds, 500);
        for (let i = 0; i < chunks.length; i++) {
          const {
            data: { data },
          } = yield call(tasksService.fetchETA, { tasksIds: chunks[i], partnerJwt });
          yield put({ type: 'FETCH_ETA_SUCCESSFUL', data });
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: 'FETCH_ETA_DONE' });
  }
}

export default function* sagas() {
  yield takeEvery('START_LEGS_FETCH', fetchLegs);
  yield takeLatest('REQUEST_UPLOAD_TASKS', uploadTasks);
  yield takeLatest('START_CONTAINERS_FETCH', fetchContainers);
  yield takeLatest('START_PALLETS_FETCH', fetchPallets);
  yield takeLatest('CONSOLIDATE_CONTAINER', consolidateContainer);
  yield takeLatest('CONSOLIDATE_PALLET', consolidatePallet);
  yield takeLatest('DECONSOLIDATE', deconsolidate);
  yield debounce(100, 'START_SCANNED_ITEM_FETCH', fetchScannedItem);
  yield takeLatest('COMPLETE_PALLET_TASKS_BY_TYPE', completePalletTasksByType);
  yield takeLatest('FETCH_HIERARCHY', fetchHierarchy);
  yield takeLatest('SELECT_ALL_TASKS_BY_MASTER_FILTERS', selectAllTasksByFilter);
  yield takeLatest('UPDATE_ADDRESS_ITEM_ID_ON_SELECTED_TASKS', updateAddressItemId);
  yield takeLatest(['FETCH_TASKS_SUCCEEDED', 'FETCH_ETA'], fetchETA);
}
