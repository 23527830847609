import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Alert from '../../furnitures/Alert';
// custom libraries
import ReactSelect from '../../furnitures/Select';
import DefaultOption from '../../furnitures/Select/DefaultOption';
import ValueContainer from '../../furnitures/Select/ValueContainer';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    marginBottom: theme.spacing(2),
  },
  optionList: {
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(4, 9, 33, 0.04)',
    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}));

const ReactSelectList = ({ onChange, closeBtn, value, maxHeight, disabled, ...restProps }) => {
  const classes = useStyles();
  const [selected, setSelectded] = useState([]);

  React.useEffect(() => {
    value && setSelectded(value);
  }, [value]);

  const handleOnSelected = (valueArr) => {
    setSelectded(valueArr);
    onChange(valueArr);
  };

  const handleRemoveSelected = (target) => {
    if (!!target === false) return;
    setSelectded((prevState) => {
      const newList = prevState.filter((item) => item?.value !== target?.value);
      onChange(newList);
      return newList;
    });
  };

  return (
    <>
      <ReactSelect
        value={selected}
        isMulti
        onChange={(item) => handleOnSelected(item)}
        className={classes.selectContainer}
        components={{
          ValueContainer: ValueContainer,
          Option: DefaultOption({ optionDataCy: restProps?.optionDataCy }),
        }}
        disabled={disabled}
        {...restProps}
      />
      <div style={maxHeight ? { maxHeight: maxHeight, overflow: 'auto' } : {}}>
        {[...selected].reverse()?.map((item) => (
          <Alert
            key={item?.value}
            icon={false}
            closeBtn={!disabled && !item?.disabledClose && closeBtn}
            closeBtnOnClick={() => closeBtn && !disabled && handleRemoveSelected(item)}
            severity="info"
            className={classes.optionList}
            data-cy="selected-item"
          >
            {item?.label}
          </Alert>
        ))}
      </div>
    </>
  );
};

ReactSelectList.defaultProps = {
  closeBtn: true,
  onChange: () => ({}),
  disabled: false,
};

ReactSelectList.propTypes = {
  customSelectedMess: PropTypes.string,
  closeBtn: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ReactSelectList;
