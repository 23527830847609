const defaultState = {
  zoom: null,
  center: null,
  bounds: null,
  size: null,
  country: null,
  maps: {},
  mapLegend: {
    open: false,
    isViewFullMode: false,
    disabledLegend: [],
  },
  zoomable: true,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  ZOOM_CALCULATED: (state, { data: newState, mapKey = null, forceZoom = false }) => {
    if (!state.zoomable && !forceZoom) {
      return state;
    }

    return mapKey
      ? {
          ...state,
          maps: {
            ...state.maps,
            [mapKey]: {
              ...(state.maps?.[mapKey] ?? {}),
              ...newState,
            },
          },
        }
      : { ...state, ...newState };
  },
  UPDATE_MAP_ZOOMABLE: (state, { zoomable }) => {
    return {
      ...state,
      zoomable,
    };
  },
  UPDATE_MAP_LEGEND: (state, { key, value }) => {
    return {
      ...state,
      mapLegend: {
        ...state.mapLegend,
        [key]: value,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
