const defaultState = {
  show: false,
  orderNumbers: [],
  taskIds: [],
  status: 'normal',
  message: undefined,
  processId: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  OPEN_WITHDRAW_CONFIRM_DIALOG: (state, { orderNumbers, taskIds }) => {
    return { ...state, show: true, orderNumbers, taskIds, status: 'normal' };
  },
  CLOSE_WITHDRAW_CONFIRM_DIALOG: () => {
    return defaultState;
  },
  PROCESS_WITHDRAW_TASKS: (state) => {
    return { ...state, status: 'processing' };
  },
  PROCESS_WITHDRAW_TASKS_SUCCESS: (state, { message }) => {
    return { ...state, status: 'success', message, processId: undefined };
  },
  PROCESS_WITHDRAW_TASKS_ERROR: (state, { error }) => {
    return { ...state, status: 'error', message: error, processId: undefined };
  },
  CLEAR_WITHDRAW_TASK_MESSAGE: (state) => {
    return { ...state, status: 'normal', message: undefined, orderNumbers: undefined };
  },
  UPDATE_ID_PROCESS_WITHDRAW_TASKS: (state, { processId }) => {
    return { ...state, status: 'processing', processId: processId };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
