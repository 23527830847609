import { PartnerWorker } from '@yojee/types';

interface State {
  data: PartnerWorker[];
  selectedPartnerWorker: PartnerWorker | null;
}

const defaultState: State = {
  data: [],
  selectedPartnerWorker: null,
};

const getMappedPartnerWorkers = (workers: PartnerWorker[]) =>
  workers.map((worker) => ({ ...worker, isPartnerWorker: true }));

const ACTION_HANDLERS = {
  GET_PARTNER_WORKERS_SUCCESS: (state: State, { data }: { data: PartnerWorker[] }) => {
    return {
      ...state,
      data: getMappedPartnerWorkers(data),
    };
  },
  SET_SELECTED_PARTNER_WORKER: (state: State, { worker }: { worker: PartnerWorker }) => {
    state.selectedPartnerWorker = worker;
    return state;
  },
};

const reducer = (state = defaultState, action: { type: keyof typeof ACTION_HANDLERS }) => {
  const handler = ACTION_HANDLERS[action.type];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return handler ? handler(state, action as any) : state;
};

export default reducer;
