import poly from '@liberty-rider/flexpolyline';

export const getHighlightedPolyline = (state, { workerId, startTaskId, endTaskId } = {}) => {
  workerId = parseInt(workerId ? workerId : state?.planTimeline?.workerId);
  startTaskId = parseInt(startTaskId ? startTaskId : state?.planTimeline?.startTaskId);
  endTaskId = parseInt(endTaskId ? endTaskId : state?.planTimeline?.endTaskId);

  if (startTaskId) {
    const optimisationData = state.optimisation.requests[state.optimisation.latestRequestId];
    if (!optimisationData) {
      return [];
    }

    const workerRoute = optimisationData.result.routes.find((route) => parseInt(route.assignee.id) === workerId);

    if (!workerRoute) {
      return [];
    }

    let polylineStarted = false;
    let polylines = [];
    (workerRoute.directions || []).forEach((d) => {
      if (parseInt(d.from) === startTaskId) {
        polylineStarted = true;
      }

      if (polylineStarted) {
        polylines = polylines.concat(poly.decode(d.encodedPolyline)?.polyline?.map((t) => [t[1], t[0]]));
      }

      if (parseInt(d.to) === endTaskId) {
        polylineStarted = false;
      }
    });

    return polylines;
  }

  return [];
};

export const getHighlightedRouteWorkerId = (state) => state.workerId;
