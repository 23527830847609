import { LocalStorage } from '@yojee/local-storage/local-storage';

let defaultState = {
  status: 'idle',
  requests: {},
  showOverlay: false,
  latestRequestId: null,
  commissionJson: {}, // json - key: driverId, commission: amount
  quickTour: {
    step: 0,
    visible: false,
  },
  selectedDrivers: [],
};

if (LocalStorage.hasItem('quick_tour_visible')) {
  try {
    const quickTourVisible = LocalStorage.getItem('quick_tour_visible');
    defaultState = { ...defaultState, quickTour: { ...defaultState.quickTour, visible: quickTourVisible } };
  } catch (e) {
    console.log(e);
  }
}

export const init = () => {
  return defaultState;
};
const ACTION_HANDLERS = {
  ASSIGNMENT_REQUESTED: (state, { requestId }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'running' };
    return {
      ...state,
      requests: currentRequests,
      latestRequestId: requestId,
      quickTour: { ...state.quickTour, step: 4 },
    };
  },
  ASSIGNMENT_FAILED: (state, { requestId, error }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'failed', error: error };
    return { ...state, requests: currentRequests };
  },
  ASSIGNMENT_SUCCEEDED: (state, { requestId }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'completed' };
    return { ...state, requests: currentRequests };
  },
  SHOW_SELECTION_CREATE_ORDER: (state) => {
    return { ...state, quickTour: { ...state.quickTour, step: 1 } };
  },
  SET_STEP: (state, { step }) => {
    return { ...state, quickTour: { ...state.quickTour, step: step } };
  },
  UPDATE_SELECTED_WORKER: (state) => {
    return { ...state, quickTour: { ...state.quickTour, step: 3 } };
  },
  OPEN_ASSIGNMENT_CONFIRMATION: (state) => {
    LocalStorage.setItem('quick_tour_visible', false);
    return { ...state, quickTour: { ...state.quickTour, step: 4, visible: false } };
  },
  HIDE_ASSIGN_TOUR: (state) => {
    LocalStorage.setItem('quick_tour_visible', false);
    return { ...state, quickTour: { ...state.quickTour, visible: false } };
  },
  SHOW_ASSIGN_TOUR: (state) => {
    LocalStorage.setItem('quick_tour_visible', true);
    return { ...state, quickTour: { ...state.quickTour, visible: true } };
  },
  UPDATE_COMMISSION: (state, { commissionJson, keepExisting }) => {
    // convert to float
    const keys = Object.keys(commissionJson);
    keys.forEach((k) => {
      commissionJson[k] = parseFloat(commissionJson[k]);
    });
    return { ...state, commissionJson: keepExisting ? { ...state.commissionJson, ...commissionJson } : commissionJson };
  },
  UPDATE_SELECTED_DRIVERS_FOR_ASSIGNMENT: (state, { selectedDrivers }) => {
    return { ...state, selectedDrivers: selectedDrivers.map((id) => parseInt(id)) };
  },
  OPTIMISATION_SUCCEEDED: (state) => {
    return { ...state, commissionJson: {}, selectedDrivers: [] };
  },
  OPTIMISATION_FAILED: (state) => {
    return { ...state, commissionJson: {}, selectedDrivers: [] };
  },
  SELECT_DRIVER_FOR_ASSIGNMENT: (state, { id }) => {
    return { ...state, selectedDrivers: [...state.selectedDrivers, parseInt(id)] };
  },
  UNSELECT_DRIVER_FOR_ASSIGNMENT: (state, { id }) => {
    const workerId = parseInt(id);
    return { ...state, selectedDrivers: state.selectedDrivers.filter((id) => id !== workerId) };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
