// Library components
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import Dialog from '../Dialog/Dialog';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(3),
    fontSize: '0.9375rem',
  },
}));

const ConfirmContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.contentContainer}>
      <Grid item xs={12}>
        {children ? children : <span>This action cannot be undone!</span>}
      </Grid>
    </Grid>
  );
};

const DialogConfirm = (props) => {
  return <Dialog {...props} children={<ConfirmContent>{props.children}</ConfirmContent>} />;
};

DialogConfirm.defaultProps = {
  title: <Trans>You are about to delete</Trans>,
  primaryBtnText: <Trans>Delete</Trans>,
  primaryBtnColor: 'error',
  primaryBtnCustomStyle: {},
  maxWidth: 'xs',
};

DialogConfirm.propTypes = {
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  dividers: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  primaryBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  primaryBtnColor: PropTypes.string,
  primaryBtnCustomStyle: PropTypes.object,
  secondaryBtnText: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default DialogConfirm;
