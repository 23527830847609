import _ from 'lodash-es';
const defaultState = {
  isLoading: false,
  partners: [],
  errorMessage: undefined,
  searchKey: '',
  selectedPartnerCompanyIds: [],
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SEARCH_PARTNER_START: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  SEARCH_PARTNER_SUCCESS: (state, { partners }) => {
    const selectedPartnerCompanyIds = state.selectedPartnerCompanyIds;
    const oldPartnerListSelected = state.partners.filter((p) => selectedPartnerCompanyIds.includes(p.company_id));
    const newValuedAdded = _.differenceWith(partners, oldPartnerListSelected, (a, b) => a.id === b.id);
    return {
      ...state,
      isLoading: false,
      partners: newValuedAdded.concat(oldPartnerListSelected),
    };
  },
  SEARCH_PARTNER_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
  UPDATE_PARTNER_COMPANY_IDS: (state, { partnerCompanyIds }) => {
    return {
      ...state,
      selectedPartnerCompanyIds: partnerCompanyIds,
    };
  },
  CLEAR_MASTER_FILTER: (state) => {
    return {
      ...state,
      selectedPartnerCompanyIds: [],
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
