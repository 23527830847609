import { getValue } from '@yojee/helpers/access-helper';
import { extractVolumeCapacity } from '@yojee/helpers/planner-helper';

class VehicleCapacity {
  static getWorkerVehicleCapacity(driver, vehicleTypes) {
    if (!driver) {
      return undefined;
    }

    const vehicleId = getValue(driver, 'vehicle.id', driver['current_vehicle_type_id']);
    if (vehicleId && vehicleTypes) {
      const _driverVehicleTypes = vehicleTypes.filter((v) => v.id === vehicleId);
      if (_driverVehicleTypes && _driverVehicleTypes.length > 0) {
        const _vehicleType = _driverVehicleTypes[0];
        const _workerSequence = driver.worker_sequence;

        return extractVolumeCapacity({
          vehicleType: {
            maxLoadSpaceVolume: getValue(
              driver,
              'assigned_vehicle.max_load_space_volume',
              _vehicleType['max_load_space_volume']
            ),
            maxCarryWeight: getValue(driver, 'assigned_vehicle.max_carry_weight', _vehicleType['max_carry_weight']),
            carryUnit: getValue(driver, 'assigned_vehicle.carry_unit', _vehicleType['carry_unit']),
          },
          workerSequence: {
            maxVolume: _workerSequence ? _workerSequence['max_volume'] : 0,
            maxWeight: _workerSequence ? _workerSequence['max_weight'] : 0,
            maxQuantity: _workerSequence ? _workerSequence['max_quantity'] : 0,
          },
        });
      }
    }
    return undefined;
  }
}

export default VehicleCapacity;
