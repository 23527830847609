import { call, put, select, takeLatest } from 'redux-saga/effects';

import { addressService } from '@yojee/api/addressService';

export function* AddressBookInputSagas() {
  yield takeLatest('REQUEST_CREATE_ADDRESS', createAddress);
  yield takeLatest('REQUEST_GET_ADDRESSES', getAddresses);
}

export const getFilter = (state) => {
  return state.address.filter;
};

function* getAddresses(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getAddresses' });
    const response = yield call(addressService.getAddresses, action.params);
    yield put({ type: 'GET_ADDRESSES_SUCCESSFUL', addressData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'GET_ADDRESSES_FAILED', error });
  }
}

function* createAddress(action) {
  yield put({ type: 'START_LOADING', loadingAction: 'createAddress' });
  const { address, isLoadAddresses } = action;
  yield put({ type: 'CLEAR_NEW_ADDRESS_ID' });
  yield put({ type: 'CLEAR_ADDRESS_MESSAGE' });
  try {
    const addressData = yield call(addressService.createAddress, address);
    yield put({ type: 'CREATE_ADDRESS_SUCCESSFUL', addressData });

    if (isLoadAddresses) {
      yield put({ type: 'REQUEST_GET_ADDRESSES', params: yield select(getFilter) });
    }
  } catch (error) {
    yield put({ type: 'CREATE_ADDRESS_FAILED', error });
  }
}
