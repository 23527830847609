import { getValue } from '@yojee/helpers/access-helper';

export const filterSelectedTasksLegs = (legs, tasks) => {
  return tasks.reduce((result, task) => {
    if (legs[task['order_item_id']] && !legs[task['order_item_id']].inProgress) {
      const taskSequenceGroup = getValue(
        legs[task['order_item_id']].tasks.find((leg) => task.id === leg.id),
        'step_group'
      );
      if (taskSequenceGroup !== null && taskSequenceGroup !== undefined) {
        result[task['order_item_id'] + '_' + taskSequenceGroup] = legs[task['order_item_id']].tasks.filter(
          (leg) => taskSequenceGroup === leg['step_group']
        );
      }
    }

    return result;
  }, {});
};

export const filterSelectedTasksAndIds = (tasks, legs) => {
  return tasks.reduce(
    (result, task) => {
      if (legs[task['order_item_id']] && !legs[task['order_item_id']].inProgress) {
        const taskSequenceGroup = getValue(
          legs[task['order_item_id']].tasks.find((leg) => task.id === leg.id),
          'step_group'
        );

        legs[task['order_item_id']].tasks.forEach((leg) => {
          if (taskSequenceGroup === leg.step_group && !result.selectedTasksIds.includes(leg['id'])) {
            result.selectedTasksIds.push(leg['id']);
            result.selectedTasks.push(leg);
          }
        });
      }

      return result;
    },
    { selectedTasksIds: [], selectedTasks: [] }
  );
};

export const filterSelectedTasksGroups = (tasks, legs) => {
  return tasks.reduce(
    (result, task) => {
      if (task && legs[task['order_item_id']]) {
        legs[task['order_item_id']].forEach((leg) => {
          if (task['step_group'] === leg.step_group && !result.selectedTasksIds.includes(leg['id'])) {
            result.selectedTasksIds.push(leg['id']);
            result.selectedTasks.push(leg);
          }
        });
      }

      return result;
    },
    { selectedTasksIds: [], selectedTasks: [] }
  );
};
