const fetchFile = async (fileUrl) => {
  const res = await fetch(fileUrl);
  if (res.status === 200) {
    return res;
  } else {
    throw new Error('Fail to download file');
  }
};

export const fetchFileBlob = async (fileUrl) => {
  const res = await fetchFile(fileUrl);
  return res.blob();
};

export const downloadFile = (fileBlob, fileName) => {
  const a = window.document.createElement('a');

  a.href = window.URL.createObjectURL(fileBlob);
  a.download = decodeURI(fileName);
  a.click();
};

export const saveFile = async (url, fileName) => {
  const res = await fetchFile(url);
  const fileBlob = await res.blob?.();
  const correctedFileName = correctExtensionInFileName(fileName, res);
  downloadFile(fileBlob, correctedFileName);
};

export const directDownloadDocument = (document) => {
  return saveFile(document.document_url, document.file_name);
};

const contentTypeExtMap = {
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
};

function correctExtensionInFileName(fileName, httpResp) {
  const [name, currentExt] = extractFileNameAndExtention(fileName);

  const contentType = httpResp.headers?.get('content-type');
  const contentTypeExt = contentTypeExtMap[contentType];
  if (!contentTypeExt) return fileName;

  if (currentExt !== contentTypeExt) return `${name}.${contentTypeExt}`;

  return fileName;
}

function extractFileNameAndExtention(fileName) {
  const parts = fileName.split('.');
  if (parts.length === 1) return [fileName, null];

  const extention = fileName.split('.').pop();
  const name = fileName.substring(0, fileName.lastIndexOf('.'));
  return [name, extention];
}
