import Axios from 'axios';

import { decodeJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { LocalStorage } from '@yojee/local-storage/local-storage';

import { BaseService } from '../baseService/index';
import { addVersionHeader } from './versionHeaderHelper';

const axiosInstance = Axios.create();

export const getNewJwt = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(
        `${BaseService.getUmbrellaApiHostUrl()}/public/jwt/auth_token`,
        {
          refresh_token: LocalStorage.getItem('refresh_token'),
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            company_slug: getSlugFromUrl(),
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          //	process and store JWT data in LocalStorage
          const decodedJwtData = decodeJwtData(res.data);
          saveJwtData(decodedJwtData);
          resolve({ ...res.data, permissions_updated_at: decodedJwtData.permissions_updated_at });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//	add interceptors for axios request/response to handle jwt
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error?.response?.status ?? -1) === 401 &&
      !['public/', 'auth/'].some((key) => (error?.response?.config?.url ?? '').indexOf(key) > -1)
    ) {
      // store.dispatch(logout());
      return Promise.reject({
        ...error,
        ...{
          response: {
            ...error.response,
            message: null,
          },
        },
      });
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.url.startsWith('https://s3.ap-southeast-1.amazonaws.com')) {
      return config;
    }
    addVersionHeader(config);
    const params = new URLSearchParams(window.location.search);
    const usingPartnerJWT = params && params.get('partner_jwt');
    //	for public APIs and APIs involving `auth`, no need to include access token
    if (usingPartnerJWT || ['public/', 'auth/'].some((key) => config.url && config.url.indexOf(key) > -1)) {
      return config;
    }

    //	if the header already has 'Authorization' value (i.e. in the case of partner_jwt or tracking_jwt)
    //	proceed with the API call without checking for expiry time
    if (config.headers && config.headers['Authorization']) {
      return config;
    }

    const accessToken = LocalStorage.getItem('access_token');
    const accessTokenExpiry = LocalStorage.getItem('access_token_expiry');
    const refreshTokenExpiry = LocalStorage.getItem('refresh_token_expiry');
    // const _permissionsUpdatedAt = LocalStorage.getItem("permissions_updated_at");

    const timeNow = new Date().getTime() / 1000;
    //	if refresh token is about to expire
    //	or if access_token or access_token_expiry arent found in localStorage,
    //	cancel API call and log user out
    if (!accessToken || !accessTokenExpiry || (refreshTokenExpiry && refreshTokenExpiry - 2 * 60 < timeNow)) {
      //	if refresh token is expired, clear user data and redirect it to login page
      // store.dispatch(logout());

      //	cancel the request
      return config;
    }

    //	refresh access token 2 minutes before token is expired
    if (accessTokenExpiry && accessTokenExpiry - 2 * 60 < timeNow) {
      if (config.url && config.url.indexOf('/public/jwt/auth_token') === -1) {
        try {
          const data = await getNewJwt();
          const token = data && data['access_token'];
          config.headers['Authorization'] = `Bearer ${token}`;
        } catch (error) {
          Promise.reject(error);
          window.location.href = '/login';
        }
      }
      return config;
    }

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
