const defaultState = {
  successMessage: null,
  errorMessage: null,
  companyData: {
    branding: null,
  },
  taskTypes: [],
  dashboardUrl: null,
  companyReports: {
    reports: [],
    dashboards: [],
  },
  subscriptions: [],
  loading: {
    getTaskTypes: false,
    getDashboardUrl: false,
    getCompanyPublicInfo: false,
  },
};

export const init = () => {
  return defaultState;
};

const startLoadingReducer = (state, sagaData) => {
  return {
    ...state,
    loading: { ...state.loading, [sagaData.loadingAction]: true },
  };
};
const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  COMPANY_START_LOADING: startLoadingReducer,
  START_LOADING: startLoadingReducer,
  REQUEST_COMPANY_PUBLIC_INFO_SUCCESS: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyPublicInfo: false },
      companyData: {
        ...state.companyData,
        ...sagaData.data,
      },
    };
  },
  REQUEST_COMPANY_PUBLIC_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyPublicInfo: false },
      errorMessage: 'Failed to fetch company info.',
    };
  },
  REQUEST_COMPANY_SUBSCRIPTIONS_SUCCESS: (state, sagaData) => {
    return { ...state, subscriptions: sagaData.subscriptions };
  },
  REQUEST_COMPANY_SUBSCRIPTIONS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyPublicInfo: false },
      errorMessage: 'Failed to fetch company subscriptions.',
    };
  },
  GET_COMPANY_INFO_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      companyData: {
        ...state.companyData,
        ...sagaData.companyData,
      },
    };
  },
  GET_COMPANY_INFO_FAILED: (state) => {
    return {
      ...state,
      errorMessage: 'Failed to get company info',
    };
  },
  GET_TASK_TYPES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      taskTypes: sagaData && sagaData.data,
    };
  },
  GET_TASK_TYPE_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      errorMessage: error?.message ?? "Failed to get company's task types",
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
