import * as _ from 'lodash-es';

import { getValue } from '@yojee/helpers/access-helper';
import { distance } from '@yojee/helpers/distanceHelper';

const DISTANCE_THRESHOLD = 1000;

export const filterAvailableHubs = (hubs, items) => {
  const opts = [
    { key: 'pickup', taskIndex: 1 },
    { key: 'dropoff', taskIndex: 2 },
  ];

  return opts.reduce(
    (result, opt) => ({
      ...result,
      [opt.key]: hubs.filter((hub) =>
        _.every(
          items,
          (itemTasks) =>
            itemTasks[opt.taskIndex] && distance(hub.location, itemTasks[opt.taskIndex].location) <= DISTANCE_THRESHOLD
        )
      ),
    }),
    {}
  );
};

export const checkIsItemsRouteSame = (itemToCheck, items) => {
  const opts = [
    { key: 'pickup', taskIndex: 1 },
    { key: 'dropoff', taskIndex: 2 },
  ];

  const result = opts.reduce(
    (result, opt) => ({
      ...result,
      [opt.key]: _.every(
        items,
        (itemTasks) =>
          distance(itemToCheck[opt.taskIndex].location, itemTasks[opt.taskIndex].location) <= DISTANCE_THRESHOLD
      ),
    }),
    {}
  );

  return { result, isRouteValid: _.every(opts, (opt) => result[opt.key]) };
};

export const filterAvailableParents = (parents, items) => {
  return Object.keys(parents)
    .filter((parentKey) => {
      const parent = parents[parentKey];
      return _.every(items, (itemTasks) => {
        const pickupStep = parent.findIndex(
          ({ location }) => distance(location, itemTasks[1].location) <= DISTANCE_THRESHOLD
        );
        const dropoffStep =
          itemTasks.length > 2
            ? parent.findIndex(({ location }) => distance(location, itemTasks[2].location) <= DISTANCE_THRESHOLD)
            : undefined;

        return pickupStep >= 0 && dropoffStep >= 0 && pickupStep < dropoffStep;
      });
    })
    .reduce((filteredParents, parentKey) => ({ ...filteredParents, [parentKey]: parents[parentKey] }), {});
};

export const processError = (consolidation, deconsolidation) => {
  let error = null;
  if (consolidation && !consolidation.container.inProgress && consolidation.container.success === false) {
    if (getValue(consolidation.container, 'error.BK0553')) {
      error = 'Trying consolidate more than left quantity.';
    } else if (getValue(consolidation.container, 'error.BK0554')) {
      error = 'Container not found';
    } else if (getValue(consolidation.container, 'error.BK0555')) {
      error = 'Pallet already consolidated';
    } else {
      error = 'Unexpected error during pallet consolidation into container';
    }
  } else if (consolidation && !consolidation.pallet.inProgress && consolidation.pallet.success === false) {
    if (getValue(consolidation.pallet, 'error.BK0553')) {
      error = 'Trying consolidate more than left quantity.';
    } else if (getValue(consolidation.pallet, 'error.BK0554')) {
      error = 'Carton not found';
    } else if (getValue(consolidation.pallet, 'error.BK0555')) {
      error = 'Carton already consolidated into pallet';
    } else {
      error = 'Unexpected error during cartons consolidation into pallet';
    }
  } else if (deconsolidation && !deconsolidation.inProgress && deconsolidation.success === false) {
    if (getValue(deconsolidation, 'error.BK0556')) {
      error = 'Trying deconsolidate different amount than was consolidate';
    } else if (getValue(deconsolidation, 'error.BK0557')) {
      error = 'Already deconsolidated';
    } else if (getValue(deconsolidation, 'error.BK0558')) {
      error = 'Items not consolidated into parent item';
    } else {
      error = 'Unexpected error during deconsolidation';
    }
  }

  return error;
};

export const parseExternalId = (externalId) => {
  const parts = externalId.split('#');
  const quantityHashtag = parts.length > 1 ? parts[parts.length - 1] : 1;
  if (parts.length > 1) {
    parts.pop();
  }
  const parsedExternalId = parts.join('#');
  return { parsedExternalId, quantityHashtag };
};
