import centroid from '@turf/centroid';

export const findCentroid = (arrayLocation) => {
  let validLocationArr = arrayLocation.filter((m) => m && m.lat && m.lng);
  // remove duplicate
  validLocationArr = validLocationArr.reduce((accumulator, currentValue) => {
    const duplicated = accumulator.filter((a) => a.lat === currentValue.lat && a.lng === currentValue.lng);
    return duplicated && duplicated.length > 0 ? accumulator : accumulator.concat(currentValue);
  }, []);
  const pointFromInput = {
    type: 'FeatureCollection',
    features: validLocationArr.map((m) => {
      return {
        geometry: { type: 'Point', coordinates: [m.lat, m.lng] },
        properties: {},
        type: 'Feature',
      };
    }),
  };
  const centroidValue = centroid(pointFromInput);
  return centroidValue.geometry && centroidValue.geometry.coordinates;
};
