import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { tasksService } from '@yojee/api/tasksService';
import { initTasks } from '@yojee/data/fetch-services/tasks';

export default function* sagas() {
  yield takeLatest('PROCESS_COMPLETE_TASKS', handleCompleteTasks);
}

function* handleCompleteTasks({ tasks }) {
  try {
    if (tasks.length === 1) {
      const currentTask = tasks[0];
      yield call(tasksService.completeSingleTask, {
        taskId: currentTask.task.id,
        taskGroupId: currentTask.task_group.id,
      });

      yield put({
        type: 'PROCESS_COMPLETE_TASKS_SUCCESS',
        message: 'Selected task has been marked as "Completed"',
      });
      yield initTasks({});
    } else {
      const {
        data: { batch_id: batchId },
      } = yield call(
        tasksService.bulkComplete,
        tasks.map((task) => task.task.id)
      );

      while (true) {
        const { data: statusData } = yield call(tasksService.checkCompleteBulkTasksStatus, batchId);
        if (statusData.status === 'completed') {
          yield put({
            type: 'PROCESS_COMPLETE_TASKS_SUCCESS',
            message: 'Selected tasks have been marked as "Completed"',
          });
          yield initTasks({});
          break;
        }
        if (statusData.status === 'failed') {
          yield put({
            type: 'PROCESS_COMPLETE_TASKS_ERROR',
            error: 'Process status return failed with id ' + batchId,
          });
          break;
        }
        yield delay(2000);
      }
    }
  } catch (err) {
    yield put({
      type: 'PROCESS_COMPLETE_TASKS_ERROR',
      error: err.message,
    });
  }
}
