import { createSelector } from 'reselect';

import { FilterPresetType } from '@yojee/helpers/filterPreset/type';

import { AVAILABLE_VIEWS } from '../constants';
import { getCurrentListView, getCurrentStopView, getShowList, isPairTaskIncluded } from '../TasksHelper';
import { defaultFilter } from './MasterFilterHelper';

type StateWithMasterFilter = {
  masterFilter: {
    filter: typeof defaultFilter;
    currentFilterPresetId: number | undefined;
    filterPresets: Array<FilterPresetType>;
  };
};

export const getIsHideRelatedTasks = (state: StateWithMasterFilter) => state.masterFilter.filter.isHideRelatedTasks;

export const getIsPairTaskIncluded = (state: StateWithMasterFilter) =>
  isPairTaskIncluded(state.masterFilter.filter.taskType);

export const getCurrentView = createSelector(
  getShowList,
  getCurrentStopView,
  getCurrentListView,
  (showList, currentStopView, currentListView) => (showList ? currentListView : currentStopView)
);

export const getShouldFetchItemRelatedLegs = createSelector(
  getCurrentView,
  getIsHideRelatedTasks,
  getIsPairTaskIncluded,
  (currentView, isHideRelatedTasks, pairTaskIncluded) =>
    pairTaskIncluded || (!isHideRelatedTasks && currentView === AVAILABLE_VIEWS.ITEMS)
);

export const getSelectedLegTypes = (state: StateWithMasterFilter) => state.masterFilter.filter.legTypes;

export const getCurrentFilterPresetId = (state: StateWithMasterFilter) => state.masterFilter.currentFilterPresetId;

export const getFilterPresets = (state: StateWithMasterFilter) => state.masterFilter.filterPresets;

export const getCurrentFilter = (state: StateWithMasterFilter) => state.masterFilter.filter;
