const defaultState = {
  message: false,
  data: [],
  idAndWorkerDataMap: {},
  pagination: null,
  highlightedWorker: undefined,
  inProgress: {
    getWorkers: false,
    recalculateCapacity: false,
  },
  workersWrongCapacity: [],
  processId: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  INIT_WORKER: (state) => {
    return { ...state, data: [], idAndWorkerDataMap: {} };
  },
  FETCH_WORKERS_SUCCEEDED: (state, { data: workerData }) => {
    const workers = workerData.data;
    const idAndWorkerDataMap = workers?.reduce((acc, worker) => {
      acc[worker.id] = worker;
      return acc;
    }, {});
    return { ...state, ...workerData, idAndWorkerDataMap };
  },
  UPDATE_WORKERS_WRONG_CAPACITY: (state, { workersWrongCapacity }) => {
    return { ...state, workersWrongCapacity, inProgress: { ...state.inProgress, recalculateCapacity: true } };
  },
  UPDATE_ID_PROCESS_RECALCULATE_CAPACITY: (state, { processId }) => {
    return { ...state, processId };
  },
  CAPACITY_CALCULATED_DONE: (state) => {
    return {
      ...state,
      processId: undefined,
      inProgress: { ...state.inProgress, recalculateCapacity: false },
      workersWrongCapacity: [],
    };
  },
  SHOW_HIGHLIGHTED_WORKER: (state, { workerId }) => {
    return { ...state, highlightedWorker: workerId };
  },
  UPDATE_WORKER: (state, { updatedWorker }) => {
    const { worker } = updatedWorker;
    const lstWorker = state.data;
    worker.id = parseInt(worker.id);

    const idxWorker = lstWorker && lstWorker.findIndex((d) => d.id === worker.id);

    if (idxWorker !== undefined && idxWorker > -1) {
      const workerById = lstWorker[idxWorker];
      const newWorkerData = { ...workerById, ...worker };
      lstWorker[idxWorker] = newWorkerData;
      const newIdAndWorkerDataMap = {
        ...state.idAndWorkerDataMap,
        [worker.id]: newWorkerData,
      };
      return { ...state, data: [...lstWorker], idAndWorkerDataMap: newIdAndWorkerDataMap };
    }
    return { ...state };
  },
  UPDATE_WORKERS: (state, { updatedWorkersMap = {} }) => {
    const newWorkers = [...(state.data || [])];
    const newIdAndWorkerDataMap = { ...state.idAndWorkerDataMap };

    newWorkers.forEach((worker, index) => {
      if (updatedWorkersMap[worker.id]) {
        const newWorkerData = { ...worker, ...updatedWorkersMap[worker.id] };
        newWorkers[index] = newWorkerData;
        newIdAndWorkerDataMap[worker.id] = newWorkerData;
      }
    });

    return { ...state, data: newWorkers, idAndWorkerDataMap: newIdAndWorkerDataMap };
  },
  TOGGLE_WORKER_LOADING: (state, { key, isLoading }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [key]: isLoading,
      },
    };
  },
  FETCH_WORKERS_ERROR: (state, { error }) => {
    return { ...state, message: error.message };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
