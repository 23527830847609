export const updateDirectionOnTimelineMove = (params) => {
  return { type: 'FETCH_DIRECTIONS_ON_MOVE', params };
};

export const showConfirmation = (params) => {
  return { type: 'SHOW_MOVE_CONFIRMATION', params };
};

export const closeMoveConfirmation = () => {
  return { type: 'CLOSE_MOVE_CONFIRMATION' };
};

export const moveTaskOnTimelineChange = (params) => {
  return { type: 'MOVE_TASK_ON_TIMELINE_CHANGE', params };
};

export const setShowTaskMoveOverlay = (params) => {
  return { type: 'SET_SHOW_TASK_MOVE_OVERLAY', params };
};

export const moveTaskAfterDrag = (params) => {
  return { type: 'MOVE_TASK_AFTER_DRAG', params };
};

export const populateAndMoveTaskAfterDrag = (params) => {
  return { type: 'POPULATE_AND_MOVE_TASK_AFTER_DRAG', params };
};

export const moveTasksAndGroupTasks = (params) => {
  return { type: 'MOVE_TASKS_TO_START_AND_GROUP', params };
};

export const hideTasksGrouping = (params) => {
  return { type: 'HIDE_TASKS_GROUPING', params };
};

export const setExpandedGroup = (params) => {
  return { type: 'SET_EXPANDED_GROUP', params };
};

export const moveUnSequencedTasks = (params) => {
  return { type: 'MOVE_UNSEQUENCED_TASKS', params };
};

export const removeUnSequencedTasks = (params) => {
  return { type: 'REMOVE_UNSEQUENCED_TASKS', params };
};
