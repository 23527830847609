import actionTypes from './../sagas/actionTypes';

const defaultState = {
  open: false,
  partner: null,
  serviceTypeMappingsData: {},
  myServiceTypesData: {},
  partnerServiceTypesData: {},
  bulkActionsData: {},
};

const ACTION_HANDLERS = {
  [actionTypes.reset()]: () => {
    return defaultState;
  },
  [actionTypes.updateState()]: (state, { newState }) => {
    return {
      ...state,
      ...newState,
    };
  },
};

export const ServiceTypeMappingDialogReducer = {
  serviceTypeMappingDialog: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
