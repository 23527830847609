import center from '@turf/center';
import clustersDbscan from '@turf/clusters-dbscan';
import { fitBounds } from 'google-map-react';
import { isEmpty } from 'lodash-es';

import { countries } from './countryAndTimezoneHelper';

export const defaultZoom = 15;
export const defaultZoomForTrackingPage = 10;
export const defaultCenter = { lat: 1.352083, lng: 103.819836 };
export const minZoom = 1;
export const maxZoom = 20;

export const triggerZoom = (zoom, center, bounds) => {
  return (
    !zoom ||
    !isValidZoom(zoom) ||
    !center ||
    !isValidPoint(center) ||
    !bounds ||
    !Object.keys(bounds).every((key) => isValidPoint(bounds[key]))
  );
};

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const getDefaultCenterBounds = (country) => {
  const defaultCountryInfo = countries.find(({ name }) => name === country);

  return {
    zoom: defaultZoom,
    center: {
      lat: defaultCountryInfo?.latlng?.[0] || defaultCenter.lat,
      lng: defaultCountryInfo?.latlng?.[1] || defaultCenter.lng,
    },
  };
};

export const calculateBounds = ({ tasks, hubs, workers, countryBounds, size, locations, country = 'Singapore' }) => {
  size = size || getWindowSize();
  let result;
  // Tasks not available, Try to use hubs
  // Hubs not available, try to use workers
  if (Array.isArray(tasks) && tasks.length > 0) {
    result = calculateMarkerBounds(
      tasks.map((t) => t.location),
      size
    );
  } else if (Array.isArray(hubs) && hubs.length > 0) {
    result = calculateMarkerBounds(hubs, size);
  } else if (Array.isArray(workers) && workers.length > 0) {
    result = calculateMarkerBounds(workers, size);
  } else if (Array.isArray(locations) && locations.length > 0) {
    result = calculateMarkerBounds(locations, size);
  } else if (countryBounds) {
    result = calculateCountryBounds(countryBounds, size);
  }

  if (!result) {
    result = getDefaultCenterBounds(country);
  }

  return result;
};

const calculateCountryBounds = (countryBounds, size) => {
  return {
    ...fitBounds(
      {
        ne: {
          lat: countryBounds.getNorthEast().lat(),
          lng: countryBounds.getNorthEast().lng(),
        },
        sw: {
          lat: countryBounds.getSouthWest().lat(),
          lng: countryBounds.getSouthWest().lng(),
        },
      },
      size
    ),
    center: {
      lat: countryBounds.getNorthEast().lat(),
      lng: countryBounds.getNorthEast().lng(),
    },
    zoom: defaultZoom,
  };
};

const isValidZoom = (zoom) => {
  return !!zoom && zoom !== -Infinity && zoom >= minZoom && zoom <= maxZoom;
};

const calculateMarkerBounds = (markers, size) => {
  if (markers && markers[0]?.lon) {
    markers[0] = {
      ...markers[0],
      lng: markers[0].lon,
    };
  }
  markers = markers.filter((m) => m && m.lat && m.lng);
  markers = markers.reduce((accumulator, currentValue) => {
    const duplicated = accumulator.filter((a) => a.lat === currentValue.lat && a.lng === currentValue.lng);
    return duplicated && duplicated.length > 0 ? accumulator : accumulator.concat(currentValue);
  }, []);

  const pointFromMarkers = {
    type: 'FeatureCollection',
    features: markers.map((m) => {
      return {
        geometry: { type: 'Point', coordinates: [Number(m.lat), Number(m.lng)] },
        properties: {},
        type: 'Feature',
      };
    }),
  };
  const maxDistance = 100;
  const clustered = clustersDbscan(pointFromMarkers, maxDistance, { minPoints: 3 });
  const listCountCluster = clustered.features.reduce((accumulator, currentValue, currentIndex, array) => {
    const currentArray = accumulator[currentValue.properties.cluster];
    if (currentArray && currentArray.length > 0) {
      accumulator[currentValue.properties.cluster] = currentArray.concat(currentValue);
    } else {
      accumulator[currentValue.properties.cluster] = [currentValue];
    }
    return accumulator;
  }, {});
  let maxNumber = listCountCluster[Object.keys(listCountCluster)[0]];
  Object.keys(listCountCluster).forEach((k) => {
    if (listCountCluster && listCountCluster[k] && listCountCluster[k].length > maxNumber.length) {
      maxNumber = listCountCluster[k];
    }
  });
  if (!maxNumber) {
    maxNumber = [];
  }
  const clusterMarker = maxNumber.map((m) => {
    return { lat: m.geometry.coordinates[0], lng: m.geometry.coordinates[1] };
  });

  if (isEmpty(clusterMarker)) {
    return null;
  }

  const centerOfCluster = center({
    type: 'FeatureCollection',
    features: maxNumber,
  }).geometry.coordinates;
  if (clusterMarker.length === 1) {
    return { center: { lat: centerOfCluster[0], lng: centerOfCluster[1] }, zoom: defaultZoom };
  }
  const lats = clusterMarker.map((i) => i.lat);
  const lngs = clusterMarker.map((i) => i.lng);

  const minCoordinates = {
    lat: Math.min(...lats),
    lng: Math.min(...lngs),
  };
  const maxCoordinates = {
    lat: Math.max(...lats),
    lng: Math.max(...lngs),
  };

  const bounds = {
    nw: {
      lat: maxCoordinates.lat,
      lng: minCoordinates.lng,
    },
    se: {
      lat: minCoordinates.lat,
      lng: maxCoordinates.lng,
    },
  };

  const result = bounds.nw.lat === bounds.se.lat && bounds.nw.lng === bounds.se.lng ? null : fitBounds(bounds, size);

  if (result && result.zoom && result.zoom !== -Infinity) {
    const validMaxZoom = Math.min(result.zoom, maxZoom);
    const validZoom = Math.max(validMaxZoom, minZoom);

    result.zoom = validZoom;
    result.center = {
      lat: centerOfCluster[0],
      lng: centerOfCluster[1],
    };

    const isValidBounds = () => Object.keys(result.newBounds).every((key) => isValidPoint(result.newBounds[key]));
    const hasValidResult = isValidPoint(result.center) && isValidBounds();

    return hasValidResult ? result : null;
  } else {
    return null;
  }
};

const isValidPoint = ({ lat, lng }) => {
  return lat !== null && lat !== undefined && !isNaN(lat) && lng !== null && lng !== undefined && !isNaN(lng);
};
