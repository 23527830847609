import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class BatchApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getPresignedUrls = (files) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/generate_batch_upload_presigned_urls'), { filenames: files })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  batchStatus = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/batches/check_status'), { batch_id: id })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

const batchApi = new BatchApi({ authService });
export default batchApi;
