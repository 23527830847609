export const DEFAULT_STATE_MACHINE_STATE = {
  state: 'idle',
  isFetching: false,
  data: null,
  error: null,
};

export const ACTION_METHODS = {
  START: 'START',
  FETCH_SUCCESSFUL: 'FETCH_SUCCESSFUL',
  FETCH_FAILED: 'FETCH_FAILED',
};

export class DataFetchStateMachine {
  /**
   * @param namespace string namespace of reducer
   */
  constructor(namespace) {
    this.namespace = namespace;
    this.hooks = {};
  }

  /**
   * Make transition from one state to another.
   *
   * @param state
   * @param action
   */
  makeTransition = (state, action) => {
    const { type } = action;
    if (!state.state || ['idle', 'completed', 'failed'].includes(state.state)) {
      if (type === `${this.namespace}_START`) {
        return this.applyHook(
          ACTION_METHODS.START,
          { state: 'fetching', isFetching: true, data: null, error: null },
          state,
          action
        );
      }
    }

    if (state.state === 'fetching') {
      if (type === `${this.namespace}_FETCH_SUCCESSFUL`) {
        return this.applyHook(
          ACTION_METHODS.FETCH_SUCCESSFUL,
          { state: 'completed', isFetching: false, data: action.data },
          state,
          action
        );
      }

      if (type === `${this.namespace}_FETCH_FAILED`) {
        return this.applyHook(
          ACTION_METHODS.FETCH_FAILED,
          { state: 'failed', isFetching: false, error: action.error },
          state,
          action
        );
      }
    }

    return state;
  };

  /**
   * Additional hooks is used to transform state data after transition is completed
   *
   * @param method string
   * @param callback function
   */
  addTransitionHook(method, callback) {
    this.hooks[method] = callback;

    return this;
  }

  /**
   * Apply hook if it exists
   *
   * @param method
   * @param result
   * @param state
   * @param action
   */
  applyHook(method, result, state, action) {
    if (this.hooks[method]) {
      return { ...state, ...result, ...this.hooks[method](state, action) };
    }

    return { ...state, ...result };
  }
}
