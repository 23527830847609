import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import Dialog from '@yojee/ui/base/corners/Dialog';
import Tab from '@yojee/ui/base/furnitures/Tab';
import { BATCH_UPLOAD_ITEM_TYPES } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import AddEditUserContent from '../AddEditUserContent';

const ADD_EDIT_TAB = 0;
const BULK_UPLOAD_TAB = 1;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
}));

const ModifyUserDialog = ({
  user = undefined,
  onClose,
  onSave,
  open,
  BatchUploadContent,
  onSuccessBatchUpload,
  isLoading,
}) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(ADD_EDIT_TAB);
  const onSuccess = () => {
    onSuccessBatchUpload();
    onClose();
  };

  const isEditMode = !!user;
  const addEditRef = useRef();
  const batchComponentRef = useRef();

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    onClose();
    setTabValue(ADD_EDIT_TAB);
  };

  const handleSave = () => {
    if (tabValue === ADD_EDIT_TAB) {
      addEditRef.current.saveRef();
    } else if (tabValue === BULK_UPLOAD_TAB) {
      batchComponentRef.current.saveRef();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={tabValue === ADD_EDIT_TAB ? 'xs' : 'md'}
      onSave={handleSave}
      isLoading={isLoading}
      title={
        <>
          <div>{isEditMode ? <Trans i18nKey="Edit User" /> : <Trans i18nKey="Add User" />}</div>
          {!isEditMode && (
            <div>
              <Tab.Tabs noBorder={true} value={tabValue} onChange={handleChange}>
                <Tab.Tab label={<Trans>Create New</Trans>} />
                <Tab.Tab label={<Trans>Batch Upload</Trans>} />
              </Tab.Tabs>
            </div>
          )}
        </>
      }
      open={open}
      dividers="paper"
      haveTopDivider={!isEditMode}
    >
      <Grid container className={classes.dialogContent}>
        {isEditMode ? (
          <AddEditUserContent ref={addEditRef} user={user} onClose={onClose} onSave={onSave} />
        ) : (
          <>
            <Tab.TabPanel value={tabValue} index={ADD_EDIT_TAB}>
              <AddEditUserContent ref={addEditRef} user={user} onClose={onClose} onSave={onSave} />
            </Tab.TabPanel>
            <Tab.TabPanel value={tabValue} index={BULK_UPLOAD_TAB}>
              <BatchUploadContent
                ref={batchComponentRef}
                uploadItemType={BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE}
                onSuccess={onSuccess}
              />
            </Tab.TabPanel>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default ModifyUserDialog;
