const defaultState = {
  isLoading: false,
  addressBookItems: [],
  googleAddressItems: [],
  errorMessage: undefined,
  searchKey: '',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  CLEAR_ADDRESS_OPTIONS: () => {
    return defaultState;
  },
  SEARCH_ADDRESS_BOOK_START: (state, { searchKey }) => {
    return {
      ...state,
      isLoading: true,
      searchKey,
    };
  },
  SEARCH_ADDRESS_BOOK_SUCCESS: (state, { items }) => {
    return {
      ...state,
      isLoading: false,
      addressBookItems: items,
    };
  },
  SEARCH_ADDRESS_BOOK_FAILED: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      errorMessage: error.message,
    };
  },
  SEARCH_GOOGLE_ADDRESS_SUCCESS: (state, { items }) => {
    return {
      ...state,
      isLoading: false,
      googleAddressItems: items,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
