import TreeState from 'react-virtualized-tree/lib/state/TreeState';
import { put, select, takeLatest } from 'redux-saga/effects';

import { convertStopsToTreeNodes_normalized } from '@yojee/helpers/stop-helper';
import { normalizedTaskToStop_byView } from '@yojee/helpers/tasksToStops';

const getTaskData = (state) => state.planner && state.planner.taskData;
const getCurrentView = (state) => state.main && state.main.stopsList && state.main.stopsList.currentView;
const getShowList = (state) => state.main && state.main.showList;

export default function* sagas() {
  yield takeLatest(['FETCH_TASKS_COMPLETE', 'UPDATE_CURRENT_VIEW'], calculateNodes);
}

function* calculateNodes() {
  const showList = yield select(getShowList);
  if (showList) return;
  const { data, ids } = yield select(getTaskData);
  const currentView = yield select(getCurrentView);
  const taskListFiltered = ids.filter((t) => data[t].task.state !== 'failed');
  const result = normalizedTaskToStop_byView(data, taskListFiltered, currentView);
  const stopKeys = result.stopKeys;
  const stops = result.stops;
  const nodes = TreeState.createFromTree(convertStopsToTreeNodes_normalized(stops, stopKeys));
  yield put({ type: 'UPDATE_STOP_NODES', nodes });
}
