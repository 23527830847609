import actionTypes from '../saga/actionTypes';

export const defaultState = {
  inProgress: {
    getDocuments: false,
  },
  documents: {
    list: [],
    pagination: {
      current_page: 1,
      limit_value: 10,
      total_count: null,
      total_pages: 1,
    },
  },
  documentFormDialog: {
    open: false,
    document: null,
  },
  deleteDocumentDialog: {
    open: false,
    document: null,
  },
  logs: {
    list: [],
    pagination: {
      current_page: 1,
      limit_value: 10,
      total_count: null,
      total_pages: 1,
    },
  },
  errorMessage: undefined,
  successMessage: undefined,
};

const ACTION_HANDLERS = {
  [actionTypes.reset()]: () => {
    return {
      ...defaultState,
    };
  },
  [actionTypes.addSuccessMessage()]: (state, { successMessage }) => ({
    ...state,
    successMessage,
  }),
  [actionTypes.addErrorMessage()]: (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }),
  [actionTypes.clearMessage()]: (state) => ({
    ...state,
    errorMessage: undefined,
    successMessage: undefined,
  }),
  [actionTypes.startProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: true,
    },
  }),
  [actionTypes.endProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: false,
    },
  }),
  [actionTypes.updateState()]: (state, { newState }) => {
    return {
      ...state,
      ...newState,
    };
  },
  [actionTypes.resetState()]: () => defaultState,
  [actionTypes.getDocuments().success()]: (state, { data, pagination }) => ({
    ...state,
    documents: {
      list: data,
      pagination,
    },
    inProgress: {
      ...state.inProgress,
      getDocuments: false,
    },
  }),
  [actionTypes.toggleDocumentFormDialog()]: (state, { document }) => ({
    ...state,
    documentFormDialog: {
      open: !state?.documentFormDialog?.open,
      document,
    },
  }),
  [actionTypes.toggleDeleteDocumentDialog()]: (state, { document }) => ({
    ...state,
    deleteDocumentDialog: {
      open: !state?.deleteDocumentDialog?.open,
      document,
    },
  }),
};

export const DocumentPortalReducer = {
  documentPortal: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    const newState = handler ? handler(state, action) : state;
    // For debug
    window.documentPortal = newState;
    return newState;
  },
};
