import { takeEvery } from '@redux-saga/core/effects';
import { groupBy } from 'lodash-es/collection';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchJWT } from '@yojee/api/helpers/JwtHelper';
import { solverService } from '@yojee/api/solverService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { fetchAssignedTasks } from '@yojee/data/fetch-services/assignedTasks';
import { getValue } from '@yojee/helpers/access-helper';
import { mapClusterToRoute } from '@yojee/helpers/ClusterizationHelper';
import { generateColor } from '@yojee/helpers/ColorGenerator';
import { filterSelectedTasksAndIds } from '@yojee/helpers/LegsHelper';
import { checkValidation } from '@yojee/helpers/optimise-helper';
import { lowerCaseAndSpaceReplace } from '@yojee/helpers/string-helper';
import { uuidv4 } from '@yojee/helpers/uuidv4';

//
//
// TODO: MOVE THIS TO UI OPTIMISE LIBRARY
//

export const getPlannerData = (state) => state.planner;
export const getOptimisationData = (state) => state.optimisation;
export const getTasks = (state) => state.tasks;

function delay(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

function* optimise({ params }) {
  const { dispatcher_info: dispatcherInfo } = yield select(AuthSelectors.getData);
  const {
    data: {
      company: {
        settings: {
          company: { timezone },
        },
      },
    },
  } = dispatcherInfo;
  const planData = yield select(getPlannerData);
  const jwt = yield fetchJWT();
  const requestId = params && params.requestId ? params.requestId : uuidv4();
  const { legs } = yield select(getTasks);
  let selectedWorkers = planData.selectedWorkers;
  if (params && params.selectedWorkers) {
    selectedWorkers = params.selectedWorkers;
  }
  let selectedTasksIdsList = planData.selectedTasks;
  if (params && params.selectedTasks) {
    selectedTasksIdsList = params.selectedTasks;
  }
  if (params && params.settings) {
    planData.settings = params.settings;
  }
  const tasksData = params && params.tasks ? params.tasks : planData.taskData.data;
  const settings = getValue(dispatcherInfo, 'data.company.settings.applications.optimisation', {
    use_real_vehicles: false,
  });

  try {
    const requestedAt = moment.utc().toISOString();
    yield put({
      type: 'OPTIMISATION_REQUESTED',
      requestId,
      requestedAt,
      selectedWorkers,
    });

    if (
      !selectedWorkers ||
      selectedWorkers.length === 0 ||
      !selectedTasksIdsList ||
      selectedTasksIdsList.length === 0
    ) {
      const errorMessage = 'No task or worker is selected!';
      if (window.parent) {
        window.parent.postMessage({ type: 'OPTIMISATION_FAILED', requestId: requestId, message: errorMessage }, '*');
      }
      yield put({ type: 'OPTIMISATION_FAILED', requestId: requestId, error: errorMessage });
      return;
    }

    if (getValue(settings, 'use_real_vehicles', null)) {
      const defaultAssetsTypes = planData.defaultAssetTypes || {};
      planData.assetTypes = planData.vehicleTypes.map((type) => {
        let vehicleTypeId = defaultAssetsTypes.findIndex(
          (at) => lowerCaseAndSpaceReplace(type.name) === lowerCaseAndSpaceReplace(at.profile)
        );
        vehicleTypeId = vehicleTypeId > -1 ? vehicleTypeId : 0;
        const vehicleType = defaultAssetsTypes[vehicleTypeId];
        return {
          ...vehicleType,
          id: type.id,
          vehicleTypeId: type.id,
          profile: type['routing_vehicle_profile_name'],
          capacityUnit: type['carry_unit'] || vehicleType.capacityUnit,
          capacityWeight: type['max_carry_weight'] || vehicleType.capacityWeight,
          capacityVolume: type['max_load_space_volume'] || vehicleType.capacityVolume,
          costFixed: type['fixed_cost'] || vehicleType.costFixed,
          costMileage: type['per_mileage_cost'] || vehicleType.costMileage,
          hourlySalary: type['per_regular_hours_cost'] || vehicleType.hourlySalary,
          waitingCostCoefficient: type['waiting_cost_coefficient'] || vehicleType.waitingCostCoefficient,
        };
      });
    }

    const workerTasks = call(fetchAssignedTasks, selectedWorkers);

    const workersTasksData = groupBy(workerTasks, 'task_group.worker_id');
    const tasksDataObj = Array.isArray(tasksData)
      ? tasksData.reduce((result, task) => {
          result[task.id] = task;
          return result;
        }, {})
      : tasksData;
    const tasks = selectedTasksIdsList.map((id) => tasksDataObj[id]);
    const { selectedTasksIds, selectedTasks } = filterSelectedTasksAndIds(tasks, legs);

    selectedTasks.forEach((t) => {
      if (!tasksDataObj[t.id]) {
        tasksDataObj[t.id] = t;
      }
    });

    selectedWorkers.forEach((workerId) => {
      if (workersTasksData[workerId]) {
        workersTasksData[workerId].forEach((task) => {
          if (!selectedTasksIds.includes(task['id'])) {
            selectedTasksIds.push(task['id']);
            selectedTasks.push(task);
            tasksDataObj[task['id']] = task;
          }
        });
      }
    });

    try {
      checkValidation(requestId, {
        ...planData,
        selectedWorkers,
        taskData: {
          data: selectedTasks,
        },
        selectedTasks: selectedTasksIds,
      });
      console.debug('Validation: OK!');
    } catch (e) {
      console.error('Validation: Check your selected tasks or vehicles!!!', e);
      // TODO: Handling whether to proceed `optimisation` or `cancel` on the UI side when the validation fails.
    }

    const singleDayPlanning = getValue(
      dispatcherInfo,
      'data.company.settings.applications.optimisation.single_day_planning',
      false
    );

    const useWorkerSchedule = getValue(
      dispatcherInfo,
      'data.company.settings.applications.optimisation.use_worker_schedule',
      false
    );

    const mapProvider = getValue(dispatcherInfo, 'data.company.settings.applications.optimisation.map_provider', null);

    // Submit Request
    const { data } = yield call(solverService.planRoute, jwt, requestId, {
      ...{
        ...planData,
        settings: {
          ...planData.settings,
          singleDayPlanning,
          useWorkerSchedule,
          mapProvider,
          timezone,
        },
      },
      selectedWorkers,
      taskData: {
        data: selectedTasks,
      },
      selectedTasks: selectedTasksIds,
    });
    yield put({ type: 'OPTIMISATION_SUBMITTED', requestId: requestId, data: data });
    let optimisationSucceeded = false;
    let optimisationCanceled = false;
    let optimisationStatusData;

    // Status
    while (true) {
      const optimisation = yield select(getOptimisationData);
      if (optimisation?.requests?.[requestId]?.status === 'cancelled') {
        optimisationCanceled = true;
        break;
      }

      const jwt = yield fetchJWT();
      const { data: statusData } = yield call(solverService.optimisationStatus, requestId, jwt);
      if (statusData.status !== 'running') {
        optimisationSucceeded = statusData.status === 'completed';
        optimisationStatusData = statusData;
        // Save request history to local storage
        // saveRequestHistoryToLocal(dispatcherId, requestId, statusData.status);
        break;
      } else {
        yield put({ type: 'OPTIMISATION_STATUS', statusData });
        // Save request history to local storage
        // saveRequestHistoryToLocal(dispatcherId, requestId, statusData);
        yield delay(2000);
      }
    }

    if (optimisationSucceeded) {
      const jwt = yield fetchJWT();
      // Result
      const { data: resultData } = yield call(solverService.optimisationResult, {
        requestId,
        jwt,
        statusData: optimisationStatusData,
      });
      if (window.parent) {
        window.parent.postMessage({ type: 'OPTIMISATION_SUCCEEDED', requestId: requestId, data: resultData }, '*');
      }

      // Set the route legend color for visualisation
      if (resultData && resultData.routes) {
        for (let i = 0; i < resultData.routes.length; i++) {
          const route = resultData.routes[i];
          route.color = generateColor(i);

          // Set task information from taskData
          if (singleDayPlanning) {
            const { tour: newTour, directions: newDirections } = mapClusterToRoute(route, tasksDataObj);
            route.tour = newTour;
            route.directions = newDirections;
          } else {
            route.tour.forEach((t) => {
              t.task = selectedTasks.find((task) => `${task.id}` === `${t.id}`);
            });
          }
        }

        if (singleDayPlanning) {
          resultData.droppedTaskData = [].concat(
            ...resultData.droppedTasks.map((tid) => {
              return data.tasksProperties[tid].clusteredTasks.map((clusteredTaskId) => {
                const clusteredTask = tasksDataObj[parseInt(clusteredTaskId)];
                const t = tasksDataObj[tid];

                return {
                  ...t,
                  ...clusteredTask,
                  task: { ...clusteredTask.task, order_step_id: t.order_step_id, stop_id: `dropped_${tid}` },
                  order_step_id: t.order_step_id,
                  id: parseInt(clusteredTaskId),
                  location: clusteredTask
                    ? {
                        lat: clusteredTask.location.lat,
                        lng: clusteredTask.location.lng,
                      }
                    : {},
                };
              });
            })
          );
        } else {
          // Set dropped task information from taskData
          resultData.droppedTaskData = resultData.droppedTasks.map((tid) =>
            selectedTasks.find((task) => `${task.id}` === `${tid}`)
          );
        }

        resultData.epoch = planData.settings.epochDate
          ? moment(planData.settings.epochDate).utc().tz(planData.settings.timezone)
          : moment();
      }

      yield put({ type: 'OPTIMISATION_SUCCEEDED', requestId, data: resultData, requestedAt, completed: true });
      yield put({ type: 'DISPLAY_OPTIMISATION_RESULT', resultData, requestId });
    } else {
      if (!optimisationCanceled) {
        if (window.parent) {
          window.parent.postMessage(
            { type: 'OPTIMISATION_FAILED', requestId: requestId, message: 'Optimisation failed' },
            '*'
          );
        }
        let message = 'Optimisation failed: something is wrong with your capacity configuration and tasks provided';

        if (optimisationStatusData.message && optimisationStatusData.status === 'failed') {
          message = optimisationStatusData.message;
        }

        yield put({ type: 'OPTIMISATION_FAILED', requestId, error: message });

        // Save request history to local storage
        // saveRequestHistoryToLocal(dispatcherId, requestId, "failed");
      }
    }
  } catch (error) {
    console.error(error);
    if (window.parent) {
      window.parent.postMessage({ type: 'OPTIMISATION_FAILED', requestId: requestId, message: error.message }, '*');
    }

    console.error(error, { extra: params });
    yield put({ type: 'OPTIMISATION_FAILED', requestId, error });

    // Save request history to local storage
    // saveRequestHistoryToLocal(dispatcherId, requestId, "failed");
  }
}

function* cancelOptimisation({ params: { requestId } }) {
  yield put({ type: 'OPTIMISATION_CANCELLED', requestId });
}

function* init() {
  const {
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  const key = 'OPTIMISATION_REQUESTS_' + slug;

  let requests = [];
  if (localStorage.getItem(key)) {
    requests = JSON.parse(localStorage.getItem(key)) || [];
  }
  if (requests.length === 0) {
    return;
  }
  // The latest request id
  const latestRequest = requests[requests.length - 1];
  const requestId = latestRequest.requestId;
  const requestedAt = latestRequest.requestedAt;

  try {
    const optimisation = yield select(getOptimisationData);
    if (optimisation?.requests?.[requestId]?.status === 'cancelled') {
      yield put({ type: 'OPTIMISATION_INIT', requestId: requestId, requestedAt: requestedAt });
    }
    let optimisationSucceeded = false;
    let optimisationCanceled = false;

    const jwt = yield fetchJWT();
    let optimisationStatusData = null;
    // Status
    while (true) {
      const optimisation = yield select(getOptimisationData);
      if (optimisation?.requests?.[requestId]?.status === 'cancelled') {
        optimisationCanceled = true;
        break;
      }

      const { data: statusData } = yield call(solverService.optimisationStatus, requestId, jwt);
      optimisationStatusData = statusData;
      if (statusData.status !== 'running') {
        optimisationSucceeded = statusData.status === 'completed';
        break;
      } else {
        yield put({ type: 'OPTIMISATION_STATUS', statusData });
        yield delay(2000);
      }
    }

    if (optimisationSucceeded) {
      const jwt = yield fetchJWT();
      // Result
      const { data: resultData } = yield call(solverService.optimisationResult, {
        requestId,
        jwt,
        statusData: optimisationStatusData,
      });
      if (window.parent) {
        window.parent.postMessage({ type: 'OPTIMISATION_SUCCEEDED', requestId: requestId, data: resultData }, '*');
      }

      // Set the route legend color for visualisation
      if (resultData && resultData.routes) {
        resultData.routes.forEach(function (route, i) {
          route.color = generateColor(i);
        });
      }

      yield put({
        type: 'OPTIMISATION_SUCCEEDED',
        requestId: requestId,
        data: resultData,
        requestedAt: requestedAt,
        completed: true,
      });
    } else {
      if (!optimisationCanceled) {
        if (window.parent) {
          window.parent.postMessage(
            { type: 'OPTIMISATION_FAILED', requestId: requestId, message: 'Optimisation failed' },
            '*'
          );
        }
        const message = 'Optimisation failed';
        yield put({ type: 'OPTIMISATION_FAILED', requestId, message });
      }
    }
  } catch (error) {
    console.log('Error=', error);
    yield put({ type: 'OPTIMISATION_FAILED', requestId, error });
  }
}

export default function* sagas() {
  yield takeLatest('INIT_OPTIMISATION', init);
  yield takeEvery('REQUEST_OPTIMISATION', optimise);
  yield takeLatest('CANCEL_OPTIMISATION', cancelOptimisation);
}
