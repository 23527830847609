import React from 'react';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import withdrawAPI from '../../Api/withdrawAPI';

export const getIdWithdrawProcess = (state) => state.withdrawTasks?.processId;
export const getOrderNumbersWithdraw = (state) => state.withdrawTasks?.orderNumbers;

export default function* sagas() {
  yield takeLatest('PROCESS_WITHDRAW_TASKS', handleWithdrawTasks);
  yield debounce(1000, 'CHECK_ID_PROCESS_WITHDRAW_TASKS', checkIdProcessWithdrawTasks);
}

const multiLineErrorStyle = {
  display: 'block',
  marginBottom: 5,
};

function* checkIdProcessWithdrawTasks() {
  try {
    const processId = yield select(getIdWithdrawProcess);
    if (!processId) {
      return;
    }
    const result = yield call(withdrawAPI.checkProcessWithdraw, { processId: processId });
    if (result.failed_at && result.failed_result) {
      /**
       * Example result.failed_result
       * {
       *   "O-AYT8RUUY9PKY": {
       *    "data": { "00000": ["invalid_tracking_numbers"] },
       *    "metadata": { "00000": [{ "tracking_numbers": ["YOJ-6YVHA5ZIPXXP"] }] }
       *   }
       * }
       */
      const ordersFailResult = result.failed_result;
      let errors = [];

      Object.keys(ordersFailResult).forEach((orderNumber) => {
        const orderFailResult = ordersFailResult[orderNumber]?.data ?? {};
        const firstErrorKey = Object.keys(orderFailResult)?.[0];
        const firstErrorValues = orderFailResult?.[firstErrorKey] ?? [];

        errors = errors.concat(
          firstErrorValues?.map((message) => {
            if (message === 'invalid_tracking_numbers') {
              const orderFailMetaData = ordersFailResult[orderNumber]?.metadata ?? {};
              const firstMetaDataKey = Object.keys(orderFailMetaData)?.[0];
              const firstMetaDataValues = orderFailMetaData?.[firstMetaDataKey] ?? [];
              const trackingNumbers = firstMetaDataValues?.[0]?.tracking_numbers ?? [];

              return `Order #${orderNumber} contains the following TN(s) that are already completed. You can’t withdraw this order anymore: ${trackingNumbers.join(
                ', '
              )}`;
            } else {
              return `Order #${orderNumber}: ${message}`;
            }
          })
        );
      });

      const message = errors.length ? (
        <div>
          {errors?.map((error) => (
            <span key={error} style={multiLineErrorStyle}>
              {error}
            </span>
          ))}
        </div>
      ) : (
        'Something went wrong'
      );

      yield put({
        type: 'PROCESS_WITHDRAW_TASKS_ERROR',
        error: message,
      });
      return;
    }
    if (result.completed_at && !result.failed_at) {
      yield put({
        type: 'PROCESS_WITHDRAW_TASKS_SUCCESS',
        message: 'Selected task has been withdraw successfully',
      });
      return;
    }
    yield put({ type: 'CHECK_ID_PROCESS_WITHDRAW_TASKS' });
  } catch (err) {
    yield put({
      type: 'PROCESS_WITHDRAW_TASKS_ERROR',
      error: err.message,
    });
  }
}

function* handleWithdrawTasks() {
  const orderNumbers = yield select(getOrderNumbersWithdraw);
  try {
    const result = yield call(withdrawAPI.transferByTrackingNumbers, {
      orderNumbers,
    });
    if (result.id) {
      yield put({ type: 'UPDATE_ID_PROCESS_WITHDRAW_TASKS', processId: result.id });
      yield put({ type: 'CHECK_ID_PROCESS_WITHDRAW_TASKS' });
    }
  } catch (err) {
    yield put({
      type: 'PROCESS_WITHDRAW_TASKS_ERROR',
      error: err.message,
    });
  }
}
