import { delay, put } from '@redux-saga/core/effects';
import * as _ from 'lodash-es';
import { sortBy } from 'lodash-es';
import get from 'lodash-es/get';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { call, select, takeLatest } from 'redux-saga/effects';

import { fetchJWT } from '@yojee/api/helpers/JwtHelper';
import { hubsService } from '@yojee/api/hubsService';
import { serviceTimeService } from '@yojee/api/serviceTimeService';
import { solverService } from '@yojee/api/solverService';
import { tasksSearchService } from '@yojee/api/tasksSearchService';
import { tasksService } from '@yojee/api/tasksService';
import { vehicleTypesService } from '@yojee/api/vehicleTypesService';
import { workersService } from '@yojee/api/workersService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { fetchServiceTimes } from '@yojee/data/fetch-services/tasks';
import { getValue } from '@yojee/helpers/access-helper';
import { getMapOfArray } from '@yojee/helpers/ArrayHelper';
import { getDefaultVolumeUnitSelector, getDefaultWeightUnitSelector } from '@yojee/helpers/CommonSelectors';
import { ACTION_MODE, AVAILABLE_FILTERS, STATUSES } from '@yojee/helpers/constants';
import { sendGA4EventSync } from '@yojee/helpers/GAHelper';
import { logData } from '@yojee/helpers/log-helper';
import {
  mapRequest,
  mapVehicleTypes,
  mapWorkers,
  routePlannerAssignedTasksSelector,
  transformTasks,
  transformTasksToTour,
  transformWorkersSchedule,
} from '@yojee/helpers/route-planning';
import { getErrorMessage, mapResponseData } from '@yojee/helpers/RoutePlanningHelper/ResponseResolver';
import { getSequences } from '@yojee/helpers/search-helper';
import { FEATURES_MAP, isFeatureEnabledSelector } from '@yojee/helpers/SettingResolver';
import { capitalizeFirstLetter } from '@yojee/helpers/string-helper';
import { getPrecedencesMap, isDescendantTaskSelected } from '@yojee/helpers/tasks-helper';
import TimelineHelper from '@yojee/helpers/TimelineHelper';
import { uuidv4 } from '@yojee/helpers/uuidv4';
import VehicleCapacity from '@yojee/helpers/VehicleCapacity';
import { getNormalizedTask } from '@yojee/ui/main/saga/saga';
import { assignedDroppedTasksSelector } from '@yojee/ui/optimise';
import {
  ROUTE_PLANNER_DATA_LISTS,
  ROUTE_PLANNER_STATUSES,
  ROUTE_PLANNER_SUBMENUS,
} from '@yojee/ui/route-planner/reducers/reducer';
import { fetchLegsFromES } from '@yojee/ui/tasks/saga';

const moment = extendMoment(Moment);

function getEntityTypeBaseOnSubmenu(submenu) {
  if (ROUTE_PLANNER_SUBMENUS.DISPLAY_TASKS_LIST === submenu) {
    return 'tasks';
  }

  if (ROUTE_PLANNER_SUBMENUS.DISPLAY_WORKERS_LIST === submenu) {
    return 'workers';
  }

  if (ROUTE_PLANNER_SUBMENUS.DISPLAY_VEHICLE_TYPES_LIST === submenu) {
    return 'vehicles';
  }

  if (ROUTE_PLANNER_SUBMENUS.DISPLAY_SERVICE_TIME_CONDITIONS === submenu) {
    return 'serviceTimeConditions';
  }

  return null;
}

const settingsEventsMap = {
  enforceEpoch: 'used enforce epoch',
  subsequentPickupAndDropoff: 'used subsequenct pickup and dropoff',
};

function* startOptimisationFlow() {
  const id = uuidv4();
  const { settings } = yield select((state) => state.planner);
  const authData = yield select(AuthSelectors.getData);
  settings.serviceTime = getValue(authData, 'dispatcher_info.data.company.settings.company.task_service_time');

  yield put({ type: 'START_NEW_PLAN', id: id, planningType: 'solver', settings });
  yield put({ type: 'SET_OPTIMISATION_CONFIG', optimisation: { instanceToDisplay: id, onlyTimeline: false } });
  yield put({ type: 'INIT_DATA_BASED_ON_SELECTION', id });
}

function* initDataBasedOnSelection({ id }) {
  const planData = yield select((state) => state.planner);
  const selectedWorkersIds = planData.selectedWorkers;
  const selectedTasksIds = planData.selectedTasks;
  yield put({ type: 'SET_PLAN_DATA', id, selectedTasksIds, selectedWorkersIds });
  yield call(fetchTasks, id, selectedTasksIds, true);
  const { usedVehicleTypeIds } = yield call(fetchWorkers, id, selectedWorkersIds);
  yield call(fetchAssignedTasks, {
    id,
    selectedWorkersId: selectedWorkersIds,
    calculateServiceTimes: true,
    onlyAccepted: false,
  });
  yield call(fetchVehicleTypes, id, usedVehicleTypeIds);
  yield call(fetchHubs, id, selectedWorkersIds);
}

function* fetchSequenceData(id, workerIds, force) {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id: companyId },
        },
      },
    } = yield select(AuthSelectors.getData);
    const workers = yield select((state) => getValue(state, `optimisation.requests.${id}.result.routes`, []));
    const existingWorkers = {};
    workers.forEach((w) => (existingWorkers[parseInt(w.assignee.id)] = true));
    const newWorkers = force ? workerIds : workerIds.filter((id) => !existingWorkers[parseInt(id)]);
    if (newWorkers.length > 0) {
      yield call(fetchWorkers, id, newWorkers, true);

      let moreResultsExists = true;
      let afterKey = null;
      const limit = 1000;
      const buckets = [];
      while (moreResultsExists) {
        const request = getSequences({ companyId, afterKey, limit, workerIds: newWorkers });
        const resultData = yield call(tasksSearchService.search, request, {});
        buckets.push(...resultData.data.data.aggregations.stops_list.buckets);
        if (
          resultData.data.data.aggregations.stops_list.buckets.length === 0 ||
          resultData.data.data.aggregations.stops_list.buckets.length < limit
        ) {
          moreResultsExists = false;
        }

        afterKey = resultData.data.data.aggregations.stops_list.after_key;
      }
      const transformedTasks = [].concat(
        ...buckets.map((b) => {
          return b.tasks_data.buckets.map((taskBucket) => {
            const [task_id, order_item_id, weight, quantity, volume] = taskBucket.key
              .replaceAll('[', '')
              .replaceAll(']', '')
              .split('_');
            return {
              // eslint-disable-next-line no-underscore-dangle
              ...b.data.hits.hits[0]._source,
              id: parseInt(task_id),
              task_id: task_id,
              order_item_id: order_item_id,
              type: b.key.type,
              item: {
                quantity: quantity ? parseInt(quantity) : 0,
                volume: volume ? parseFloat(volume) : 0,
                weight: weight ? parseFloat(weight) : 0,
              },
              stop_id: `${b.key.eta}_${b.key.sequence_id}_${b.key.type}`,
            };
          });
        })
      );

      const currentTasks = yield select((state) => state.routePlanner[id].assignedTasks.data);
      yield put({
        type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
        id,
        requestType: 'assignedTasks',
        data: {
          status: STATUSES.COMPLETED,
          data: transformedTasks.concat(currentTasks),
        },
      });

      return transformedTasks;
    }
  } catch (error) {
    console.error(error);
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
    return [];
  }
}

function* initDataForTimeline({ id }) {
  const workers = yield select((state) => state.planner.workerData.data);
  yield put({ type: 'START_NEW_PLAN', id: id, planningType: ACTION_MODE.MANUAL });
  yield call(
    fetchSequenceData,
    id,
    workers.slice(0, 10).map((w) => w.id)
  );
  yield call(createRequest, id);
  yield call(fetchVehicleTypes, id);
  yield put({ type: 'SET_INSTANCE_ID', params: { instanceId: id } });
}

function* startManualPlanFlow() {
  const instanceId = uuidv4();
  yield call(prepareManualPlanDataset, instanceId);
  yield call(mapManualPlanDataset, instanceId);
}

function* prepareManualPlanDataset(id) {
  const workerId = yield select((state) => state.planner?.selectedWorkers);
  const selectedTasksIds = yield select((state) => state.planner?.selectedTasks);
  yield put({ type: 'START_NEW_PLAN', id, planningType: ACTION_MODE.MANUAL });
  yield put({ type: 'SET_PLANNER_STATUS', id, status: ROUTE_PLANNER_STATUSES.DATA_FETCH });
  yield put({ type: 'SET_OPTIMISATION_CONFIG', optimisation: { instanceToDisplay: id, onlyTimeline: false } });
  yield put({ type: 'SET_STOPS_LIST_CONFIG', stopsList: { filter: AVAILABLE_FILTERS.LAST_PLANNED } });
  yield put({ type: 'SET_INSTANCE_ID', params: { instanceId: id } });
  yield call(fetchTasks, id, selectedTasksIds, true);
  yield call(fetchWorkers, id, workerId, false);
  yield call(fetchAssignedTasks, { id, selectedWorkersId: workerId, onlyAccepted: false });
  yield call(fetchVehicleTypes, id);
}

/**
 * Returns selected tasks and their order item ids sorted by their order in an object.
 *
 * @param {Object} tasksGroupedByOrderItem - an object grouping tasks by order item id
 * @param {Object} selectedTasksOrderMap - an object mapping task ids to their order
 * @return {Object} an object containing an array of selected tasks and their order item ids in an object
 */
export const getSelectedTasksAndOrderItemIdsOrder = (tasksGroupedByOrderItem, selectedTasksOrderMap) => {
  return _.flatMap(tasksGroupedByOrderItem).reduce(
    ({ tasks, orderItemIdsOrder }, t) => {
      if (selectedTasksOrderMap[parseInt(t.id)] !== undefined) {
        if (!orderItemIdsOrder[t['order_item_id']]) {
          orderItemIdsOrder[t['order_item_id']] = selectedTasksOrderMap[parseInt(t.id)];
        }
        tasks[selectedTasksOrderMap[parseInt(t.id)]] = t;
      }

      return { tasks, orderItemIdsOrder };
    },
    { tasks: [], orderItemIdsOrder: {} }
  );
};

function* mapManualPlanDataset(instanceId) {
  const workerId = yield select((state) => state.planner?.selectedWorkers);
  const tasksGroupedByOrderItem = yield select((state) => state.routePlanner[instanceId]?.tasks?.data);
  const selectedTasksIds = yield select((state) => state.planner?.selectedTasks);
  const selectedTasksOrderMap = selectedTasksIds.reduce((acc, id, index) => {
    acc[id] = index;
    return acc;
  }, {});
  const { tasks: selectedTasks, orderItemIdsOrder } = getSelectedTasksAndOrderItemIdsOrder(
    tasksGroupedByOrderItem,
    selectedTasksOrderMap
  );
  const nonEmptySelectedTasks = selectedTasks.filter((t) => t);
  const additionalTasks = []
    .concat(
      ...Object.keys(orderItemIdsOrder).reduce((acc, orderItemId) => {
        acc[orderItemIdsOrder[orderItemId]] = tasksGroupedByOrderItem[orderItemId].filter(
          (t) => selectedTasksOrderMap[t.id] === undefined
        );
        return acc;
      }, [])
    )
    .filter((t) => t);

  const combinedTasks = nonEmptySelectedTasks.concat(...additionalTasks);

  logData({ module: 'routePlanner', message: 'selected tasks order', data: selectedTasksIds });
  logData({
    module: 'routePlanner',
    message: 'selected tasks order after fetch',
    data: nonEmptySelectedTasks,
    callback: (data) => data.map((t) => t.id),
  });
  logData({
    module: 'routePlanner',
    message: 'not selected tasks',
    data: additionalTasks,
    callback: (data) => data.map((t) => t.id),
  });
  logData({
    module: 'routePlanner',
    message: 'selected + not selected tasks',
    data: combinedTasks,
    callback: (data) => data.map((t) => t.id),
  });

  yield call(createRequest, instanceId, { [workerId]: transformTasksToTour(combinedTasks) }, [workerId], true, false);
  yield put({ type: 'SET_LATEST_REQUEST_ID', latestRequestId: instanceId });
  yield put({ type: 'SET_STOPS_LIST_CONFIG', stopsList: { filter: AVAILABLE_FILTERS.LAST_PLANNED } });
  yield put({ type: 'SET_ROUTES_CONFIG', routes: { visiblePlanned: workerId } });
  yield put({ type: 'SET_PLANNER_STATUS', id: instanceId, status: ROUTE_PLANNER_STATUSES.DISPLAY_RESULTS });
  yield transformTasksData({ id: instanceId });
}

function* fetchTasks(id, selectedTasksIds, calculateServiceTimes = false) {
  const { data } = yield select(getNormalizedTask);
  // TODO: need to ensure that all required tasks exists
  yield call(fetchLegsFromES, {
    tasks: selectedTasksIds.map((id) => data[id]),
    routePlanningId: id,
    calculateServiceTimes,
  });
}

function* fetchWorkers(id, selectedWorkersId, append = false) {
  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'workers',
    data: {
      status: STATUSES.IN_PROGRESS,
    },
  });

  let workers = append ? yield select((state) => state.routePlanner[id].workers.data) : [];
  try {
    const chunks = _.chunk(selectedWorkersId, 1000);
    let chunkIndex = 0;
    // TODO: try to reuse current drivers
    while (chunkIndex < chunks.length) {
      const pageData = yield call(workersService.fetchWorkersById, {
        token,
        partnerJwt,
        slug,
        ids: chunks[chunkIndex],
        pageSize: 1000,
      });
      workers = workers.concat(pageData.data);
      chunkIndex++;
    }
  } catch (e) {
    console.error('Error while fetching workers by ids', e);
  }

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'workersSchedule',
    data: {
      status: STATUSES.IN_PROGRESS,
    },
  });

  const workersSechedule = yield call(workersService.fetchWorkersSchedule, {
    workerIds: workers.map((worker) => worker.id),
  });

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'workersSchedule',
    data: {
      status: STATUSES.COMPLETED,
      data: workersSechedule,
    },
  });

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'workers',
    data: {
      status: STATUSES.COMPLETED,
      data: workers,
    },
  });

  const usedVehicleTypeIds = workers.reduce((acc, w) => {
    acc[w.current_vehicle_type_id] = true;
    return acc;
  }, {});

  return { workers, usedVehicleTypeIds };
}

function* fetchAssignedTasks({
  id,
  selectedWorkersId,
  startDate,
  endDate,
  calculateServiceTimes,
  onlyAccepted = true,
}) {
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'assignedTasks',
    data: {
      status: STATUSES.IN_PROGRESS,
    },
  });

  const PAGE_SIZE = 500;
  const params = {
    cancelled: 'false',
    include_transfer_properties: false,
    task_group_states: ['assigned'],
    task_states: ['created'],
    task_query_type: 'assigned',
    query_version: 2,
    limit: PAGE_SIZE,
    include_count: true,
  };

  if (onlyAccepted) {
    params.accepted = true;
  }

  if (selectedWorkersId) {
    params.worker_ids = selectedWorkersId;
  }

  if (startDate) {
    params.range_type = true;
    params.from = startDate;
  }

  if (endDate) {
    params.range_type = true;
    params.to = endDate;
  }

  const {
    data: { data: fetchedWorkerTasks, count },
  } = yield call(tasksService.fetchTasksPost, params, { token, slug });

  let workerTasks = fetchedWorkerTasks;

  if (fetchedWorkerTasks.length < count) {
    let page = 2;
    while (page <= Math.ceil(count / PAGE_SIZE)) {
      const {
        data: { data: fetchedWorkerTasks },
      } = yield call(
        tasksService.fetchTasksPost,
        {
          ...params,
          order_id_before: workerTasks?.[workerTasks.length - 1]?.order_item.id,
          step_sequence_after: workerTasks?.[workerTasks.length - 1]?.step_sequence,
          include_count: false,
        },
        { token, slug }
      );
      workerTasks = workerTasks.concat(fetchedWorkerTasks);
      page++;
    }
  }

  workerTasks.sort((a, b) => a.stop_id - b.stop_id);

  if (calculateServiceTimes) {
    try {
      const { tasks: tasksWithServiceTimes, serviceTimes } = yield fetchServiceTimes(workerTasks);
      workerTasks = tasksWithServiceTimes;
      yield put({
        type: 'UPDATE_SERVICE_TIME_CONDITIONS',
        id,
        data: {
          data: serviceTimes,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'assignedTasks',
    data: {
      status: STATUSES.COMPLETED,
      data: workerTasks,
    },
  });

  return workerTasks;
}

function* fetchServiceTimesData(vehicleTypes) {
  if (!vehicleTypes) {
    return {
      vehicleTypes: [],
      serviceTimes: [],
    };
  }

  const chunks = _.chunk(
    vehicleTypes.map((v) => v.id),
    1000
  );
  let serviceTimes = {};
  for (let i = 0; i < chunks.length; i++) {
    const { data: fetchedServiceTimes } = yield call(serviceTimeService.calculateForVehicles, {
      vehicleTypeIds: chunks[i],
      locationTypes: ['port', 'office', 'residential', 'warehouse'],
    });
    serviceTimes = { ...serviceTimes, ...fetchedServiceTimes };
  }

  const mappedServiceTimes = {};
  Object.values(serviceTimes).forEach((vehicleServiceTime) => {
    vehicleServiceTime.forEach((t) => {
      if (t?.condition?.id) {
        if (!mappedServiceTimes[t.condition.id]) {
          mappedServiceTimes[t.condition.id] = t.condition;
        }
      }
    });
  });

  return {
    vehicleTypes: vehicleTypes.map((v) => ({
      ...v,
      service_time_matrix: serviceTimes[v.id] || [],
    })),
    serviceTimes: Object.values(mappedServiceTimes),
  };
}

function* fetchVehicleTypes(id, usedVehicleTypeIds) {
  // TODO: try to reuse types
  const isServiceTimesEnabled = yield select((state) =>
    isFeatureEnabledSelector(state, FEATURES_MAP.SERVICE_TIMES_MANAGEMENT)
  );
  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'vehicleTypes',
    data: {
      status: STATUSES.IN_PROGRESS,
    },
  });

  const { data: vehicleData, pagination } = yield call(vehicleTypesService.fetchVehicleTypes);
  let mergedData = vehicleData;
  const maxPages = Math.min(pagination.total_pages, 20);
  if (pagination.total_pages > 1) {
    for (let i = 2; i <= maxPages; i++) {
      const { data: _vehicleData } = yield call(vehicleTypesService.fetchVehicleTypes, i);
      mergedData = mergedData.concat(_vehicleData);
    }
  }

  let vehiclesData = !usedVehicleTypeIds ? mergedData : mergedData.filter((v) => usedVehicleTypeIds[parseInt(v.id)]);
  if (isServiceTimesEnabled) {
    const { vehicleTypes: vehiclesDataWithServiceTimes, serviceTimes } = yield call(
      fetchServiceTimesData,
      vehiclesData
    );
    vehiclesData = vehiclesDataWithServiceTimes;
    yield put({
      type: 'UPDATE_SERVICE_TIME_CONDITIONS',
      id,
      data: {
        data: serviceTimes,
      },
    });
  }

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'vehicleTypes',
    data: {
      status: STATUSES.COMPLETED,
      data: vehiclesData,
    },
  });
}

function* fetchHubs(id) {
  // TODO: try to reuse hubs
  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'hubs',
    data: {
      status: STATUSES.IN_PROGRESS,
    },
  });

  const { data: hubsData } = yield call(hubsService.fetchHubs, { token, partnerJwt, slug });

  yield put({
    type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
    id,
    requestType: 'hubs',
    data: {
      status: STATUSES.COMPLETED,
      data: hubsData,
    },
  });
}

function* transformTasksAfterDataFetch({ id }) {
  const actionMode = yield select((state) => state.planner.actionMode);
  if (actionMode === ACTION_MODE.MANUAL) {
    return;
  }
  const groupedTasksStatus = yield select((state) => state.routePlanner[id]?.tasks?.status);
  const assignedTasksStatus = yield select((state) => state.routePlanner[id]?.assignedTasks?.status);
  const hubsStatus = yield select((state) => state.routePlanner[id]?.hubs?.status);
  const workersStatus = yield select((state) => state.routePlanner[id]?.workers?.status);
  const vehicleTypesStatus = yield select((state) => state.routePlanner[id]?.vehicleTypes?.status);
  if (groupedTasksStatus && assignedTasksStatus && hubsStatus && workersStatus && vehicleTypesStatus) {
    if (
      groupedTasksStatus === STATUSES.COMPLETED &&
      assignedTasksStatus === STATUSES.COMPLETED &&
      hubsStatus === STATUSES.COMPLETED &&
      workersStatus === STATUSES.COMPLETED &&
      vehicleTypesStatus === STATUSES.COMPLETED
    ) {
      yield transformTasksData({ id });
    }
  }
}

function* transformTasksData({ id }) {
  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_STATUS', id, status: STATUSES.IN_PROGRESS });
  const groupedTasks = yield select((state) => state.routePlanner[id].tasks.data);
  const assignedTasks = yield select((state) => state.routePlanner[id].assignedTasks.data);
  const hubs = yield select((state) => state.routePlanner[id].hubs.data);
  const workers = yield select((state) => state.routePlanner[id].workers.data);
  const workersSchedule = yield select((state) => state.routePlanner[id].workersSchedule.data);
  const vehicleTypes = yield select((state) => state.routePlanner[id].vehicleTypes.data);
  const planData = yield select((state) => state.planner);
  const defaultVolumeUnit = yield select(getDefaultVolumeUnitSelector);
  const defaultWeightUnit = yield select(getDefaultWeightUnitSelector);
  const { dispatcher_info: dispatcherInfo } = yield select(AuthSelectors.getData);
  const {
    data: {
      company: {
        settings: {
          company: { timezone },
        },
      },
    },
  } = dispatcherInfo;

  // TODO: improve logic of selecting tasks for multileg
  const tasks = [];
  const assignedTasksIds = assignedTasks.reduce((acc, task) => {
    acc[task.id] = true;
    return acc;
  }, {});
  Object.keys(groupedTasks).forEach((key) => {
    groupedTasks[key].forEach((task) => {
      if (!assignedTasksIds[task.id]) {
        tasks.push(task);
      }
    });
  });

  const optimisationSettings = dispatcherInfo?.data?.company?.settings?.applications?.optimisation ?? {};
  const singleDayPlanning = optimisationSettings?.['single_day_planning'] ?? false;
  const useWorkerSchedule = optimisationSettings?.['use_worker_schedule'] ?? false;
  const mapProvider = optimisationSettings?.['map_provider'] ?? null;
  const clusterPickups = optimisationSettings?.['cluster_pickups'] ?? false;

  const settings = {
    ...planData.settings,
    singleDayPlanning,
    useWorkerSchedule,
    mapProvider,
    timezone,
    clusterPickups,
    sameLocationServiceTime: 0,
    serviceTime: 0,
  };

  settings.epochDate = (
    settings.epochDate ? moment(settings.epochDate).utc().tz(settings.timezone) : moment()
  ).toDate();

  const allTasks = tasks.concat(assignedTasks);
  const mappedVehicles = mapVehicleTypes({
    workers,
    vehicleTypes,
    profiles: planData.assetTypes,
    volumeUnit: defaultVolumeUnit,
    weightUnit: defaultWeightUnit,
  });
  const mappedWorkers = mapWorkers(workers, mappedVehicles, settings, allTasks);
  const mappedTasks = transformTasks(allTasks, {
    hubs,
    settings,
    workers,
    vehicleTypes: mappedVehicles,
  });

  const mappedWorkersSchedule = transformWorkersSchedule(workersSchedule.data);

  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_DATA', id, dataType: 'tasks', data: mappedTasks });
  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_DATA', id, dataType: 'workers', data: mappedWorkers });
  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_DATA', id, dataType: 'vehicles', data: mappedVehicles });
  yield put({
    type: 'SET_OPTIMISATION_TRANSFORMATION_DATA',
    id,
    dataType: 'workersSchedule',
    data: mappedWorkersSchedule,
  });
  yield put({ type: 'UPDATE_PLAN_SETTINGS', id, settings });
  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_STATUS', id, status: STATUSES.COMPLETED });
  yield put({ type: 'MOVE_TO_NEXT_STEP', id });
}

function* applyActionOnStepChange({ id }) {
  yield put({ type: 'MOVE_TO_NEXT_STEP', id });
}

function sendTrackingParameters(metadata) {
  Object.keys(settingsEventsMap).forEach((key) => {
    if (metadata?.[key]) {
      sendGA4EventSync({ feature: 'optimisation', event: settingsEventsMap[key] });
    }
  });
}

function* runOptimisation({ id, mode, request }) {
  const jwt = yield fetchJWT();
  yield call(solverService.planRoute, jwt, request, mode);

  let optimisationCanceled = false;
  let optimisationSucceeded = false;
  let optimisationStatusData = false;

  while (true) {
    const optimisation = yield select((state) => state.optimisation);
    if (optimisation?.requests?.[id]?.status === 'cancelled') {
      optimisationCanceled = true;
      break;
    }

    const jwt = yield fetchJWT();
    const { data: statusData } = yield call(solverService.optimisationStatus, id, jwt, mode);
    if (statusData.status !== 'running') {
      optimisationSucceeded = statusData.status === 'completed';
      optimisationStatusData = statusData;
      break;
    }

    yield put({ requestId: id, type: 'OPTIMISATION_STATUS', statusData });
    yield delay(2000);
  }

  return { optimisationCanceled, optimisationSucceeded, optimisationStatusData };
}

function* planRoute({ params: { id, explore } }) {
  yield put({ type: 'MOVE_TO_NEXT_STEP', id });

  const { tasks, vehicles, workers, workersSchedule } = yield select((state) => state.routePlanner[id].transformedData);
  const hubs = yield select((state) => state.routePlanner[id].hubs.data);
  const tasksData = yield select((state) => state.routePlanner[id]?.tasks?.data);
  const assignedTasks = yield select((state) => state.routePlanner[id].assignedTasks.data);
  const settings = yield select((state) => state.routePlanner[id].settings);

  const transformedTasks = tasks.map((task) => {
    let serviceTime = {
      setup: task.fixedServiceTime,
    };
    if (task.isServiceTimeMultiplyByQuantity) {
      serviceTime = {
        ...serviceTime,
        servicePerTask: 0,
        servicePerUnit: task.variableServiceTime,
      };
    } else {
      serviceTime = {
        ...serviceTime,
        servicePerTask: task.variableServiceTime,
        servicePerUnit: 0,
      };
    }
    return {
      ...task,
      serviceTime,
    };
  });

  const tasksDataObj = {};

  Object.keys(tasksData).forEach((orderItemId) => {
    tasksData[orderItemId].forEach((task) => {
      tasksDataObj[task.id] = task;
    });
  });
  assignedTasks.forEach((t) => {
    tasksDataObj[t.id] = t;
  });

  const request = mapRequest({
    requestId: id,
    vehicleTypes: vehicles,
    tasks: transformedTasks,
    workers,
    workersSchedule,
    hubs,
    settings,
  });
  sendTrackingParameters(request.metadata);

  const mode = explore ? 'explorer' : 'solver';
  try {
    const { optimisationCanceled, optimisationSucceeded, optimisationStatusData } = yield call(runOptimisation, {
      id,
      mode,
      request,
    });

    if (optimisationSucceeded) {
      const jwt = yield fetchJWT();
      // Result
      const { data } = yield call(solverService.optimisationResult, {
        requestId: id,
        jwt,
        statusData: optimisationStatusData,
        mode,
      });

      const resultData = mapResponseData({ resultData: explore ? data?.[0] : data, tasksDataObj, settings });

      yield put({
        type: 'OPTIMISATION_SUCCEEDED',
        requestId: id,
        data: resultData,
        requestedAt: new Date(),
        completed: true,
        newPlannerUsed: true,
        alternativeResults: explore
          ? data.map((d) => mapResponseData({ resultData: d, tasksDataObj, settings }))
          : null,
      });
      yield put({
        type: 'DISPLAY_OPTIMISATION_RESULT',
        resultData,
        requestId: id,
      });

      yield put({ type: 'MOVE_TO_NEXT_STEP', id });
    } else {
      if (!optimisationCanceled) {
        yield put({ type: 'SET_LATEST_REQUEST_ID', latestRequestId: id });
        yield put({ type: 'OPTIMISATION_FAILED', requestId: id, error: getErrorMessage(optimisationStatusData) });
        yield put({ type: 'MOVE_TO_NEXT_STEP', id });
      }
    }
  } catch (error) {
    console.error(error, { extra: request });
    yield put({ type: 'OPTIMISATION_FAILED', requestId: id, error });
    yield put({ type: 'MOVE_TO_NEXT_STEP', id });
    // Save request history to local storage
    // saveRequestHistoryToLocal(dispatcherId, requestId, "failed");
  }
}

function* updateSubmenu({ params }) {
  const fields = {
    [ROUTE_PLANNER_SUBMENUS.DISPLAY_TASKS_LIST]: [
      'task',
      'location',
      'dropPenalty',
      'duration',
      'capacityDemand',
      'timeWindows',
      'fixedServiceTime',
      'variableServiceTime',
    ],
    [ROUTE_PLANNER_SUBMENUS.DISPLAY_WORKERS_LIST]: [
      'startLocationType',
      'startLocations',
      'startLocation',
      'startHub',
      'endLocationType',
      'endLocations',
      'endLocation',
      'endHub',
      'timeSchedule',
      'vehicleType',
    ],
    [ROUTE_PLANNER_SUBMENUS.DISPLAY_VEHICLE_TYPES_LIST]: ['cost'],
    [ROUTE_PLANNER_SUBMENUS.DISPLAY_SERVICE_TIME_CONDITIONS]: ['value', 'location_service_time_value'],
  };

  const menu = yield select((state) => state.routePlanner[params.id].submenu);
  if (ROUTE_PLANNER_DATA_LISTS.includes(params.submenu) && menu !== ROUTE_PLANNER_SUBMENUS.DISPLAY_ADDRESS_LOCATOR) {
    const dataType = getEntityTypeBaseOnSubmenu(params.submenu);
    const data = yield select((state) => state.routePlanner[params.id].transformedData[dataType]);
    yield put({
      type: 'SET_OPTIMISATION_TRANSFORMATION_DATA',
      id: params.id,
      dataType,
      data: data.map((d) => {
        d.latestVersion = fields[params.submenu].reduce((acc, field) => {
          acc[field] = _.cloneDeep(d[field]);
          return acc;
        }, {});
        d.changed = false;
        return d;
      }),
    });
  }

  if (ROUTE_PLANNER_DATA_LISTS.includes(menu) && params.submenu !== menu) {
    // 3 options available, save - no action needed, reset - reset to initial value, default - discard latest values
    if (params.action === 'reset') {
      yield resetChanges(params.id, getEntityTypeBaseOnSubmenu(menu));
    } else if (params.action === 'revert') {
      yield revertChanges(params.id, getEntityTypeBaseOnSubmenu(menu));
    } else if (params.action !== 'save' && params.submenu !== ROUTE_PLANNER_SUBMENUS.DISPLAY_ADDRESS_LOCATOR) {
      const data = yield select(
        (state) => state.routePlanner[params.id].transformedData[getEntityTypeBaseOnSubmenu(menu)]
      );
      if (data.find((d) => d.changed)) {
        yield put({
          type: 'SHOW_CONFIRMATION_DIALOG',
          params: { id: params.id, show: true, nextSubmenu: params.submenu },
        });
        return;
      }
    } else if (params.action === 'save') {
      if (menu === ROUTE_PLANNER_SUBMENUS.DISPLAY_VEHICLE_TYPES_LIST) {
        yield updateWorkersBasedOnVehicleChanges(params.id);
      }
      if (menu === ROUTE_PLANNER_SUBMENUS.DISPLAY_SERVICE_TIME_CONDITIONS) {
        yield updateTasksBasedOnServiceTimeConditionsChanges(params.id);
      }
      yield put({ type: 'SHOW_CONFIRMATION_DIALOG', params: { id: params.id, show: false } });
    }
  }

  yield put({ type: 'CHANGE_SUBMENU', params });
}

function* updateWorkersBasedOnVehicleChanges(id) {
  const vehicles = yield select((state) => state.routePlanner[id].transformedData.vehicles);
  const workers = yield select((state) => state.routePlanner[id].transformedData.workers);
  const vehiclesMap = vehicles.reduce((map, v) => {
    map[v.id] = v;
    return map;
  }, {});
  const updatedWorkers = workers.map((w) => {
    if (w.vehicleType.id && vehiclesMap[w.vehicleType.id]) {
      w.vehicleType = vehiclesMap[w.vehicleType.id];
    }

    return w;
  });
  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_DATA', id, dataType: 'workers', data: updatedWorkers });
}

function* updateTasksBasedOnServiceTimeConditionsChanges(id) {
  const tasks = yield select((state) => state.routePlanner[id].transformedData.tasks);
  const serviceTimeConditions = yield select((state) => state.routePlanner[id].transformedData.serviceTimeConditions);

  const conditionsMap = serviceTimeConditions.reduce((map, v) => {
    map[v.id] = v;
    return map;
  }, {});

  const updatedTasks =
    tasks &&
    tasks.map((t) => {
      if (t.serviceTimeConditionId && conditionsMap[t.serviceTimeConditionId]) {
        if (conditionsMap[t.serviceTimeConditionId]['multiply_by_quantity']) {
          t.duration = conditionsMap[t.serviceTimeConditionId].value * Math.abs(t.capacityDemand.unit);
        } else {
          t.duration = conditionsMap[t.serviceTimeConditionId].value;
        }

        t.duration += conditionsMap[t.serviceTimeConditionId]['location_service_time_value'];
      }

      return t;
    });

  yield put({ type: 'SET_OPTIMISATION_TRANSFORMATION_DATA', id, dataType: 'tasks', data: updatedTasks });
}

function* rollbackToVersion(id, dataType, versionType) {
  const data = yield select((state) => state.routePlanner[id].transformedData[dataType]);
  yield put({
    type: 'SET_OPTIMISATION_TRANSFORMATION_DATA',
    id,
    dataType,
    data: data.map((d) => {
      return { ...d, ...d[versionType], changed: false };
    }),
  });
}

function* revertChanges(id, dataType) {
  yield rollbackToVersion(id, dataType, 'latestVersion');
}

function* resetChanges(id, dataType) {
  yield rollbackToVersion(id, dataType, 'initial');
}

function* hideConfirmationDialog({ params: { id, action } }) {
  const currentSubmenu = yield select((state) => state.routePlanner[id].submenu);
  const submenu = yield select((state) => state.routePlanner[id].confirmationDialog.nextSubmenu);
  if (action === 'discard') {
    yield revertChanges(id, getEntityTypeBaseOnSubmenu(currentSubmenu));
  }

  if (action !== 'back') {
    yield put({ type: 'CHANGE_SUBMENU', params: { id, submenu } });
  }

  if (action === 'save' && currentSubmenu === ROUTE_PLANNER_SUBMENUS.DISPLAY_VEHICLE_TYPES_LIST) {
    yield updateWorkersBasedOnVehicleChanges(id);
  }

  yield put({ type: 'SHOW_CONFIRMATION_DIALOG', params: { id, show: false } });
}

function* createRequest(
  instanceId,
  predefinedTasks = {},
  predefinedWorkersId = [],
  fetchDirections = false,
  sort = true
) {
  const assignedTasks = yield select((state) => routePlannerAssignedTasksSelector(state, instanceId));
  const precedence = getPrecedencesMap(assignedTasks);
  const groupedTasks = _.groupBy(assignedTasks, 'task_group.worker_id');
  const workersIds = Object.keys(groupedTasks).concat(...predefinedWorkersId.filter((id) => !groupedTasks[id]));
  const routes = workersIds.map((workerId) => {
    const predefinedTasksIds = (predefinedTasks?.[workerId] ?? []).reduce((acc, t) => {
      acc[t.id] = true;
      return acc;
    }, {});

    const tourTasks = (groupedTasks?.[workerId] ?? [])
      .filter((t) => !predefinedTasksIds[t.id])
      .map((t) => ({
        id: t.id,
        stop_id: t.stop_id || t.id,
        task: { ...t, stop_id: t.stop_id || t.id },
        arrivalEarliest: t.task.eta ? moment.utc(t.task.eta).toISOString() : null,
        location: {
          latitude: t.location.lat,
          longitude: t.location.lon || t.location.lng,
        },
        precedenceTaskId: get(precedence, [`${t['order_item_id']}`, `${t['step_group']}`, `${t['step_sequence'] - 1}`]),
      }));

    const tour = (sort ? TimelineHelper.sortTourRoute(tourTasks) : tourTasks).concat(
      ...(predefinedTasks?.[workerId] ?? [])
    );

    return {
      optimised: false,
      assignee: {
        id: workerId,
      },
      tour,
    };
  });

  const generatedOptimisationData = {
    type: 'OPTIMISATION_SUCCEEDED',
    requestId: instanceId,
    data: { routes },
    requestedAt: null,
    completed: false,
    newPlannerUsed: true,
  };

  yield put(generatedOptimisationData);
  yield put({ type: 'SET_OPTIMISATION_CONFIG', optimisation: { instanceToDisplay: instanceId, onlyTimeline: true } });
  if (fetchDirections) {
    yield put({
      type: 'FETCH_DIRECTIONS_ON_MOVE',
      params: { effectedRouteKeys: [0], data: { result: { routes } }, requestId: instanceId },
    });
  }
}

function* sendTaskToDriver({ params: { tasks, workerId, droppedTask, manualInit = false } }) {
  const authData = yield select(AuthSelectors.getData);
  const serviceTime = getValue(authData, 'dispatcher_info.data.company.settings.company.task_service_time');

  yield put({ type: 'SET_SHOW_TASK_MOVE_OVERLAY', params: { showTaskMoveOverlay: true } });
  let instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);

  if (!instanceId) {
    instanceId = uuidv4();
    yield call(initDataForTimeline, { id: instanceId });
    const workers = yield select((state) => state.planner.workerData.data);
    yield put({
      type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
      id: instanceId,
      requestType: 'workers',
      data: {
        status: STATUSES.COMPLETED,
        data: workers,
      },
    });
  }

  // TODO: append data later
  const fetchedTasks = yield call(fetchLegsFromES, { routePlanningId: droppedTask ? null : instanceId, tasks });
  const request = yield select((state) => state.optimisation.requests[instanceId]);
  if (!request) {
    yield call(createRequest, instanceId);
  }

  let latestTaskDate = null;
  const precedence = getPrecedencesMap(fetchedTasks);
  const tasksToMove = sortBy(fetchedTasks, ['step_group', 'step_sequence']).map((t) => {
    let date = moment.utc(t.task.eta || t.from);
    if (latestTaskDate && moment.duration(date.diff(latestTaskDate)).asSeconds() < serviceTime) {
      date = latestTaskDate.add(serviceTime, 'seconds');
    }
    latestTaskDate = date;
    const mappedTask = {
      id: t.id,
      stop_id: t.id,
      task: { ...t, stop_id: t.id },
      arrivalEarliest: date.toISOString(),
      precedenceTaskId: get(precedence, [`${t['order_item_id']}`, `${t['step_group']}`, `${t['step_sequence'] - 1}`]),
      location: {
        latitude: t.location.lat,
        longitude: t.location.lng,
      },
      capacityDemand: {
        weight: t.item.weight,
        volume: t.item.volume,
        unit: t.item.quantity,
      },
    };

    const stopIdParts = [t.location.lat, t.location.lng, mappedTask.arrivalEarliest, t.order_step.contact_name];
    mappedTask.stop_id = stopIdParts.join('_');
    mappedTask.task.stop_id = stopIdParts.join('_');

    return mappedTask;
  });

  yield put({
    type: 'ADD_TASKS_TO_TIMELINE',
    params: { instanceId, tasks: tasksToMove, workerId: workerId, manualInit },
  });
}

export function* fetchWorkersTasksForRoutePlanner({ workerIds, routePlannerInstanceId }) {
  if (routePlannerInstanceId) {
    const tasksData = yield call(fetchSequenceData, routePlannerInstanceId, workerIds);
    const workers = yield select((state) => state.routePlanner[routePlannerInstanceId].workers.data);
    const vehicleTypes = yield select((state) => state.routePlanner[routePlannerInstanceId].vehicleTypes.data);
    const optimisationResultData = yield select((state) => state.optimisation.requests[routePlannerInstanceId]);
    const groupedTasks = _.groupBy(tasksData, 'task_group.worker_id');
    Object.values(groupedTasks).forEach((tour) => {
      const worker = workers.find((w) => parseInt(w.id) === parseInt(tour[0].task_group.worker_id));
      const capacity = VehicleCapacity.getWorkerVehicleCapacity(worker, vehicleTypes);
      optimisationResultData.result.routes.push({
        assignee: {
          id: tour[0].task_group.worker_id,
          vehicleType: capacity
            ? {
                capacity: {
                  volume: capacity.capacityInfo.vMaxLoadSpaceVolume,
                  unit: capacity.capacityInfo.vCarryUnit,
                  weight: capacity.capacityInfo.vMaxCarryWeight,
                },
              }
            : {},
        },
        tour: TimelineHelper.sortTourRoute(
          tour.map((t) => ({
            id: t.id,
            stop_id: t.stop_id || t.id,
            task: { ...t, stop_id: t.stop_id || t.id },
            arrivalEarliest: moment.utc(t.task.eta || t.from).toISOString(),
            location: {
              latitude: t.location.lat,
              longitude: t.location.lon,
            },
          }))
        ),
      });
    });
    yield put({
      type: 'OPTIMISATION_UPDATE',
      params: { requestId: routePlannerInstanceId, data: optimisationResultData },
    });
  }
}

export function* createWorkerTour({ workerIds, routePlannerInstanceId, addTasksParams, manualInit = false }) {
  if (routePlannerInstanceId) {
    const tasks = yield call(fetchSequenceData, routePlannerInstanceId, workerIds, true);
    const precedence = getPrecedencesMap(tasks);
    const mappedTasks =
      tasks &&
      tasks.map((t) => {
        return {
          id: parseInt(t.id),
          stop_id: t.stop_id || t.id,
          task: { ...t, stop_id: t.stop_id || t.id, tasks: [t.task] },
          arrivalEarliest: t.task.eta ? moment.utc(t.task.eta).toISOString() : null,
          location: {
            latitude: t.location.lat,
            longitude: t.location.lon || t.location.lng,
          },
          precedenceTaskId: get(precedence, [
            `${t['order_item_id']}`,
            `${t['step_group']}`,
            `${t['step_sequence'] - 1}`,
          ]),
        };
      });
    const newTourData = manualInit ? mappedTasks : TimelineHelper.sortTourRoute(mappedTasks);
    yield put({
      type: 'ADD_TASKS_TO_TIMELINE',
      params: {
        ...addTasksParams,
        instanceId: routePlannerInstanceId,
        newTourData,
        manualInit,
      },
    });
  }
}

function* toggleSelectedTaskInSortableGrid({ params: { requestId, tasks, position, onlySelect } }) {
  const selectedTasks = yield select((state) => state.routePlanner[requestId].sortableGrid.selectedTasks);
  const workerId = yield select((state) => state.routePlanner[requestId].sortableGrid.workerId);
  const { id: taskId, type, step_sequence: stepSequence, order_item_id: orderItemId } = tasks?.[0] ?? {};

  const routes = yield select((state) => state.optimisation.requests[requestId]?.result?.routes);
  const workerRoute = routes.find((route) => parseInt(route.assignee.id) === parseInt(workerId));
  const assignedDroppedTasks = yield select((state) => assignedDroppedTasksSelector(state, workerId));

  if (!selectedTasks.includes(taskId)) {
    const tour = workerRoute?.tour.concat(
      assignedDroppedTasks.map((t) => ({
        ...t,
        task: {
          ...(t?.task ?? {}),
          step_sequence: t['step_sequence'],
          order_item_id: t['order_item_id'],
          type: t['type'],
        },
      }))
    );
    const firstSelectedDescendantTask = isDescendantTaskSelected({
      orderItemId,
      stepSequence,
      tour,
      selectedTasks,
      returnFirstSelectedDescendantTask: true,
    });
    if (firstSelectedDescendantTask) {
      const taskPosition = workerRoute?.tour
        ?.filter((t) => `${t.id}` !== 'startFromDepot' && `${t.id}` !== 'EndDepot')
        .findIndex((t) => parseInt(t.id) === parseInt(firstSelectedDescendantTask.id));

      const descendantType =
        firstSelectedDescendantTask?.taskType === 'Delivery' || firstSelectedDescendantTask?.task?.type === 'dropoff'
          ? 'Dropoff'
          : 'Pickup';
      const positionText = taskPosition > -1 ? `#${taskPosition + 1}` : '';

      yield put({
        type: 'DISPLAY_MESSAGE',
        message: `${capitalizeFirstLetter(type)} #${
          position + 1
        } cannot be selected after ${descendantType} ${positionText}`,
        variant: 'error',
      });
    } else {
      yield put({
        type: 'SELECT_TASK_IN_SORTABLE_GRID',
        params: { requestId, taskId, position },
      });
    }
  } else if (!onlySelect) {
    // TODO: For multileg orders we need make sure that tasks sequence after unselection doesnt have gaps
    yield put({
      type: 'UNSELECT_TASK_IN_SORTABLE_GRID',
      params: { requestId, taskId, position },
    });
  }
}

function* recalculateETA({ params: { requestId, workerId } }) {
  const request = yield select((state) => state.optimisation.requests[requestId]);
  const routes = request?.result?.routes;
  const workerRoute = routes.find((route) => parseInt(route.assignee.id) === parseInt(workerId));

  const tasksIds = (workerRoute?.tour ?? []).map((t) => parseInt(t.id)).filter((id) => id);

  const optimisationResultData = _.clone(request);
  const index = optimisationResultData.result.routes.findIndex(
    (route) => parseInt(route.assignee.id) === parseInt(workerId)
  );
  optimisationResultData.result.routes[index] = {
    ...optimisationResultData.result.routes[index],
    etaCalculateInProgress: true,
  };
  yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId, data: optimisationResultData } });

  try {
    const {
      data: {
        data: { directions },
      },
    } = yield call(tasksService.computeEta, { tasksIds, workerId });
    const etaMap = getMapOfArray(directions, 'task_id', 'eta');

    optimisationResultData.result.routes[index] = {
      ...optimisationResultData.result.routes[index],
      etaCalculateInProgress: false,
      tour: optimisationResultData.result.routes[index].tour.map((t) => ({
        ...t,
        arrivalEarliest: etaMap?.[parseInt(t.id)],
      })),
    };
    yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId, data: optimisationResultData } });
    yield put({ type: 'DISPLAY_MESSAGE', message: 'ETA calculation successful', variant: 'success' });
  } catch (e) {
    optimisationResultData.result.routes[index] = {
      ...optimisationResultData.result.routes[index],
      etaCalculateInProgress: false,
    };
    yield put({ type: 'OPTIMISATION_UPDATE', params: { requestId, data: optimisationResultData } });
    yield put({ type: 'DISPLAY_MESSAGE', message: 'ETA calculation failed', variant: 'error' });
    console.error(e);
  }
}

export default function* sagas() {
  yield takeLatest('START_OPTIMISATION_FLOW', startOptimisationFlow);
  yield takeLatest('INIT_DATA_BASED_ON_SELECTION', initDataBasedOnSelection);
  yield takeLatest('INIT_DATA_FOR_TIMELINE', initDataForTimeline);
  yield takeLatest('SET_OPTIMISATION_DATA_FETCH_DATA', transformTasksAfterDataFetch);
  yield takeLatest('MOVE_TO_NEXT_ROUTE_PLANNING_STEP', applyActionOnStepChange);
  yield takeLatest('SET_SUBMENU', updateSubmenu);
  yield takeLatest('PLAN_ROUTE', planRoute);
  yield takeLatest('HIDE_CONFIRMATION_DIALOG', hideConfirmationDialog);
  yield takeLatest('SEND_TASK_TO_DRIVER', sendTaskToDriver);
  yield takeLatest('START_MANUAL_PLAN', startManualPlanFlow);
  yield takeLatest('FETCH_WORKERS_TASKS_FOR_ROUTE_PLANNER', fetchWorkersTasksForRoutePlanner);
  yield takeLatest('CREATE_WORKER_TOUR', createWorkerTour);
  yield takeLatest('TOGGLE_SELECTED_TASK_IN_SORTABLE_GRID', toggleSelectedTaskInSortableGrid);
  yield takeLatest('RECALCULATE_ETA', recalculateETA);
}
