import _ from 'lodash-es';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { tasksSearchService } from '@yojee/api/tasksSearchService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { SENDER_TYPES } from '@yojee/helpers/constants';
import { getSendersSearchQuery, transformSuggestionSearch } from '@yojee/helpers/search-helper';

export default function* sagas() {
  yield takeLatest('SEARCH_SENDERS', searchSenders);
}

function* searchSenders({ searchKey }) {
  let searchFilters;
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'SENDER_AUTOCOMPLETE_START', searchKey: searchKey });
    searchFilters = getSendersSearchQuery(id, searchKey);
    const { data } = yield call(tasksSearchService.search, searchFilters, {});
    const transformedData = transformSuggestionSearch(data).map((v) => {
      let id = v.data.sender.id ? v.data.sender.id : `external_${v.data.sender.external_id}`;
      let name =
        v.data.sender.type === SENDER_TYPES.organisation ? v.data.sender.organisation_name : v.data.sender.name;
      if (v.type === 'order_step.contact_name') {
        id = `contact_name_${v.data.order_step.contact_name}`;
        name = v.data.order_step.contact_name;
      }

      return { id, name };
    });

    yield put({ type: 'SENDER_AUTOCOMPLETE_FETCH_SUCCESSFUL', data: _.uniqBy(transformedData, 'id') });
  } catch (error) {
    console.error(error, { extra: searchFilters });
    yield put({ type: 'SENDER_AUTOCOMPLETE_FETCH_FAILED', error });
  }
}
