import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { CONNECTION_TYPES, webSocketService } from '@yojee/api/webSocketService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { SOCKET_EVENTS } from '@yojee/helpers/constants';

import * as Api from '../../Api/chatApi';
import { store } from '../../index';

export default function* sagas() {
  yield takeLatest('REQUEST_CHANNELS_LIST_INFO', requestChannelsList);
  yield takeLatest('REQUEST_CHANNEL_INFO', requestChannelInfo);
  yield takeLatest('REQUEST_DRIVER_CHANNEL_INFO', requestDriverChannelInfo);
  yield takeLatest('UPDATE_CHANNEL', updateChannel);
  yield takeEvery('SEND_MESSAGE', sendMessage);
  yield takeLatest('INIT_CHAT_SOCKET', initChatSocket);
}

function* requestChannelsList() {
  yield put({ type: 'CHAT_LOADING', key: 'requestChannelsList' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.fetchChannels, { token, slug, partnerJwt });
    yield put({ type: 'REQUEST_CHANNELS_LIST_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'REQUEST_CHANNELS_LIST_FAILED', error });
  }
}

function* requestChannelInfo({ payload: { channelId } }) {
  yield put({ type: 'CHAT_LOADING', key: 'requestChannelInfo' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.fetchChannelInfo, { token, slug, partnerJwt, channelId });
    yield put({ type: 'REQUEST_CHANNEL_INFO_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'REQUEST_CHANNEL_INFO_FAILED', error });
  }
}

function* requestDriverChannelInfo({ payload: { driverId } }) {
  yield put({ type: 'CHAT_LOADING', key: 'requestDriverChannelInfo' });
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.fetchDriverChannelInfo, { token, slug, partnerJwt, driverId });
    yield call(requestChannelInfo, { payload: { channelId: data.channel.id } });
  } catch (error) {
    yield put({ type: 'REQUEST_DRIVER_CHANNEL_INFO_FAILED', error });
  }
}

function* sendMessage({ payload: { channelId, message, attachment, optimisticResponse } }) {
  yield put({
    type: 'CHAT_LOADING',
    key: 'sendMessage',
    channelId: channelId,
    optimisticResponse: optimisticResponse,
  });

  if (attachment) {
    yield put({ type: 'CHAT_LOADING', key: 'sendAttachmentMessage' });
  }

  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);

    const { data } = yield call(Api.sendMessage, { token, slug, partnerJwt, channelId, message, attachment });

    yield put({
      type: 'SEND_MESSAGE_SUCCESS',
      data,
      channelId: channelId,
      optimisticResponse: optimisticResponse,
    });
  } catch (error) {
    yield put({
      type: 'SEND_MESSAGE_FAILED',
      error,
      channelId: channelId,
      optimisticResponse: optimisticResponse,
    });
  }
}

function* updateChannel({ payload: { channelId, lastReadAt } }) {
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield call(Api.updateChannel, { token, slug, partnerJwt, channelId, lastReadAt });
    yield put({ type: 'UPDATE_CHANNEL_SUCCESS', data: { channelId } });
  } catch (error) {
    yield put({ type: 'UPDATE_CHANNEL_FAILED', error });
  }
}

function* initChatSocket() {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id: companyId, slug },
          id,
        },
      },
      token: jwt,
    } = yield select(AuthSelectors.getData);

    if (jwt && companyId) {
      const onError = () => {
        store.dispatch({ type: 'INIT_CHAT_SOCKET_FAILED' });
      };
      const onTimeout = () => {
        store.dispatch({ type: 'INIT_CHAT_SOCKET_FAILED' });
      };
      const channel = webSocketService
        .getSocket(CONNECTION_TYPES.NORMAL, { jwt, company_slug: slug, userId: id })
        .getChannelByTopic({ topic: `dispatcher:${id}`, onError, onTimeout });

      channel.on('notification', (msg) => {
        store.dispatch({ type: 'INIT_CHAT_SOCKET_SUCCESS' });
        if (msg) {
          const { event_type, payload } = msg;
          if (event_type === SOCKET_EVENTS.CHAT_CHANNEL_MESSAGE_SENT && payload && payload.message) {
            store.dispatch({ type: 'GET_NEW_MESSAGE_NOTIFICATION', message: payload.message });
          }
        }
      });
    }
  } catch (e) {
    console.error(e);
  }
}
