import { documentsService } from '@yojee/api/documentsService';
import { orderApiV4 } from '@yojee/api/v4/orderApi';

const serviceLocator = {
  documentsService,
  orderApiV4,
};

export function updateServiceLocator(serviceName, value) {
  serviceLocator[serviceName] = value;
}

export default serviceLocator;
