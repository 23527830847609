import moment from 'moment';

import { getValue } from '@yojee/helpers/access-helper';
import { transformSingleTaskToTour } from '@yojee/helpers/route-planning';
import { fixSequence } from '@yojee/helpers/TasksHelper';

class TimelineHelper {
  static mapTasks(plannedRouteData, optimisationResultData, onlyRoutes = null, onlyDrivers = null) {
    const newTaskData = {};
    const usedStopIds = {};
    const orderItemLinks = {};
    const stopLinksPairs = {};
    getValue(optimisationResultData, 'result.routes', []).forEach((route, index) => {
      if (
        (!Array.isArray(onlyRoutes) || onlyRoutes.includes(index)) &&
        (!Array.isArray(onlyDrivers) || onlyDrivers.includes(parseInt(route.assignee.id)))
      ) {
        let position = 1;
        route?.tour
          ?.filter((t) => t && !isNaN(parseInt(t.id)))
          .forEach((t) => {
            const stopId = parseInt(route.assignee.id) + '_' + (t.task.stop_id || t.id);
            const taskOrderId = t?.task?.order_item_id || t?.task?.order_item?.id;
            if (!newTaskData[usedStopIds[stopId]] && usedStopIds[stopId]) {
              newTaskData[t.id] = null;
              newTaskData[usedStopIds[stopId]].orderItemIds.push(taskOrderId);
              newTaskData[usedStopIds[stopId]].orderItemStepGroups.push(`${taskOrderId}_${t.task['step_group']}`);
              newTaskData[usedStopIds[stopId]].volume += parseFloat(t.task.item.volume);
              newTaskData[usedStopIds[stopId]].weight += parseFloat(t.task.item.weight);
              newTaskData[usedStopIds[stopId]].quantity += parseInt(t.task.item.quantity);
            } else {
              newTaskData[t.id] = {
                eta: t.arrivalEarliest,
                workerId: parseInt(route.assignee.id),
                position: position,
                stopId,
                orderItemIds: [taskOrderId],
                orderItemStepGroups: [`${taskOrderId}_${t.task['step_group']}`],
                volume: parseFloat(t.task.item.volume),
                weight: parseFloat(t.task.item.weight),
                quantity: parseInt(t.task.item.quantity),
                vehicleType: route.assignee.vehicleType,
              };

              usedStopIds[stopId] = t.id;
              position++;
            }

            if (orderItemLinks[taskOrderId]) {
              orderItemLinks[taskOrderId].push({
                stopId,
                task: { ...t, task_group: { worker_id: parseInt(route.assignee.id) }, from: t.arrivalEarliest },
              });
            } else {
              orderItemLinks[taskOrderId] = [
                {
                  stopId,
                  task: { ...t, task_group: { worker_id: parseInt(route.assignee.id) }, from: t.arrivalEarliest },
                },
              ];
            }
          });
      }
    });

    Object.values(orderItemLinks).forEach((list) => {
      for (let i = 0; i < list.length - 1; i++) {
        for (let j = i + 1; j < list.length; j++) {
          const startStopId = list[i].stopId;
          const endStopId = list[j].stopId;
          if (!stopLinksPairs[startStopId]) {
            stopLinksPairs[startStopId] = { [endStopId]: [list[i], list[j]] };
          } else if (!stopLinksPairs[startStopId][endStopId]) {
            stopLinksPairs[startStopId][endStopId] = [list[i], list[j]];
          } else {
            let taskA = list[i];
            let taskB = list[j];
            if (list[i].task.from > stopLinksPairs[startStopId][endStopId][0].task.from) {
              taskA = stopLinksPairs[startStopId][endStopId][0];
            }
            if (list[j].task.from > stopLinksPairs[startStopId][endStopId][1].task.from) {
              taskB = stopLinksPairs[startStopId][endStopId][1];
            }
            stopLinksPairs[startStopId][endStopId] = [taskA, taskB];
          }
        }
      }
    });

    let tasksData;
    if (Array.isArray(onlyRoutes) || Array.isArray(onlyDrivers)) {
      tasksData = plannedRouteData.filter((t) => newTaskData[t.id]);
    } else {
      tasksData = plannedRouteData;
    }

    return tasksData
      .map((t) => {
        const newData = newTaskData[t.id];
        if (newData === null) {
          return null;
        }

        if (newData) {
          t.task_group = { ...t.task_group, worker_id: newData.workerId };
          t.position = newData.position;
          t.orderItemIds = newData.orderItemIds;
          t.orderItemStepGroups = newData.orderItemStepGroups;
          t.linkedStops = stopLinksPairs[newData.stopId] ? Object.values(stopLinksPairs[newData.stopId]) : [];
          t.vehicleType = newData.vehicleType;

          if (!t['stop_id']) {
            t['stop_id'] = newData.stopId;
          }

          if (t.task && newData.eta) {
            t.task.eta = newData.eta;
          }

          if (t.item) {
            t.item.volume = newData.volume;
            t.item.weight = newData.weight;
            t.item.quantity = newData.quantity;
          }
          newTaskData[t.id] = null;
        } else {
          t.task_group.worker_id = null;
        }

        return { ...t };
      })
      .filter((t) => t);
  }

  static sortTourRoute = (tour) => {
    const startFromDepotIndex = tour.findIndex((t) => t && t.id === 'startFromDepot');
    const endDepotIndex = tour.findIndex((t) => t && t.id === 'EndDepot');

    const sorted = tour
      .filter((t) => t && parseInt(t.id))
      .sort((a, b) => {
        if (b.arrivalEarliest?.valueOf() === a.arrivalEarliest?.valueOf()) {
          return b.task?.type !== a?.type && b.task?.type === 'dropoff' ? -1 : 1;
        }
        return b.arrivalEarliest?.valueOf() < a.arrivalEarliest?.valueOf() ? 1 : -1;
      });

    if (endDepotIndex > -1) {
      sorted.push(tour[endDepotIndex]);
      return sorted;
    }

    if (startFromDepotIndex > -1) {
      return [tour[startFromDepotIndex], ...sorted];
    }

    return sorted;
  };

  static moveTask = (movedTask, optimisationResultData) => {
    const effectedRouteKeys = [];
    const effectedTasks = [];
    const optimisationResult = optimisationResultData;
    optimisationResult?.result?.routes?.forEach((route, routeKey) => {
      route.tour.forEach((t, taskKey) => {
        if (parseInt(movedTask.id) === parseInt(t.id)) {
          if (parseInt(route.assignee.id) === parseInt(movedTask.group)) {
            effectedRouteKeys.push(routeKey);
            const movedTaskStopId = optimisationResult.result.routes[routeKey].tour[taskKey].task['stop_id'];
            optimisationResult.result.routes[routeKey].tour = [
              ...this.sortTourRoute(
                optimisationResult.result.routes[routeKey].tour.map((t) => {
                  if (movedTaskStopId && t.task && t.task['stop_id'] === movedTaskStopId) {
                    effectedTasks.push({ workerId: route.assignee.id, taskId: t.id });
                    if (movedTask.start) {
                      const updatedTask = {
                        ...t,
                        arrivalEarliest: moment(movedTask.start).toISOString(),
                      };
                      const stopIdParts = [
                        updatedTask?.location?.latitude || updatedTask?.task?.location?.lat,
                        updatedTask?.location?.longitude || updatedTask?.task?.location?.lng,
                        updatedTask.arrivalEarliest,
                        updatedTask.task.order_step.contact_name,
                      ];
                      updatedTask.stop_id = stopIdParts.join('_');
                      updatedTask.task.stop_id = stopIdParts.join('_');

                      return updatedTask;
                    } else {
                      return t;
                    }
                  }

                  return t;
                })
              ),
            ];

            optimisationResult.result.routes[routeKey].directions = [];
            optimisationResult.result.routes[routeKey].updatedRoute = true;
            optimisationResult.result.routes[routeKey].optimised = false;
          } else {
            const startTime = movedTask.start ? moment(movedTask.start).toISOString() : t.arrivalEarliest;
            const movedTourTasks = [{ ...t, arrivalEarliest: startTime }];
            const movedTaskStopId = optimisationResult.result.routes[routeKey].tour[taskKey].task['stop_id'];
            const movedTaskOrderItemIds = optimisationResult.result.routes[routeKey].tour
              .filter((t) => t.task && t.task['stop_id'] === movedTaskStopId)
              .map((t) => `${t.task['order_item_id']}_${t.task['step_group']}`);

            delete optimisationResult.result.routes[routeKey].tour[taskKey];
            optimisationResult.result.routes[routeKey].tour.forEach((tourTask, tourTaskKey) => {
              if (
                tourTask &&
                tourTask.task &&
                (movedTaskOrderItemIds.includes(`${tourTask.task['order_item_id']}_${tourTask.task['step_group']}`) ||
                  tourTask.task['stop_id'] === movedTaskStopId)
              ) {
                if (tourTask.task['stop_id'] === movedTaskStopId) {
                  tourTask.arrivalEarliest = startTime;
                }

                movedTourTasks.push(tourTask);
                delete optimisationResult.result.routes[routeKey].tour[tourTaskKey];
              }
            });

            optimisationResult.result.routes[routeKey].tour = this.sortTourRoute(
              optimisationResult.result.routes[routeKey].tour
            );
            optimisationResult.result.routes[routeKey].directions = [];
            optimisationResult.result.routes[routeKey].updatedRoute = true;
            optimisationResult.result.routes[routeKey].optimised = false;

            effectedRouteKeys.push(routeKey);

            const newRouteIndex = optimisationResult.result.routes.findIndex(
              (r) => parseInt(r.assignee.id) === parseInt(movedTask.group)
            );

            if (newRouteIndex > -1) {
              effectedRouteKeys.push(newRouteIndex);
              optimisationResult.result.routes[newRouteIndex].tour.push(...movedTourTasks);
              optimisationResult.result.routes[newRouteIndex].tour = this.sortTourRoute(
                optimisationResult.result.routes[newRouteIndex].tour
              );
              optimisationResult.result.routes[newRouteIndex].directions = [];
              optimisationResult.result.routes[newRouteIndex].updatedRoute = true;
              optimisationResult.result.routes[newRouteIndex].optimised = false;
            } else {
              optimisationResult.result.routes.push({
                assignee: {
                  id: movedTask.group,
                },
                updatedRoute: true,
                optimised: false,
                tour: this.sortTourRoute(movedTourTasks),
                directions: [],
              });
              effectedRouteKeys.push(optimisationResult.result.routes.length - 1);
            }

            movedTourTasks.forEach((t) => effectedTasks.push({ workerId: movedTask.group, taskId: t.id }));
          }
        }
      });
    });

    optimisationResult.updatedAt = moment().toISOString();

    return { data: optimisationResult, effectedRouteKeys, effectedTasks };
  };

  static changeTaskPosition = (
    { id, group, start, taskBefore, singleTaskMove },
    optimisationData,
    movedTasksOrder = []
  ) => {
    const optimisationResult = optimisationData;
    const effectedRouteKeys = [];
    const effectedTasks = [];

    if (!Array.isArray(movedTasksOrder) || movedTasksOrder.length === 0) {
      movedTasksOrder = [id];
    }

    const hasDroppedTask = (optimisationResult?.result?.droppedTaskData || []).some(
      (task) => parseInt(task.id) === parseInt(id) || movedTasksOrder.indexOf(task.id) > -1
    );
    optimisationResult?.result?.routes?.forEach((route, routeKey) => {
      route.tour.forEach((tourTask, taskIndex) => {
        if (parseInt(id) === parseInt(tourTask.id) || (hasDroppedTask && taskIndex === 0)) {
          if (parseInt(route.assignee.id) === parseInt(group)) {
            const movedDroppedTasks = [];
            (optimisationResult?.result?.droppedTaskData || []).forEach((task) => {
              const index = movedTasksOrder.indexOf(task.id);
              if (index > -1) {
                movedDroppedTasks[index] = transformSingleTaskToTour(
                  task,
                  optimisationResult.result.routes[routeKey].tour
                    .filter((t) => t?.task?.['order_item_id'] === task['order_item_id'])
                    .map((t) => t.task)
                    .concat(
                      ...(optimisationResult?.result?.droppedTaskData || []).filter(
                        (t) => t?.['order_item_id'] === task['order_item_id']
                      )
                    )
                );

                effectedTasks.push({ workerId: parseInt(route.assignee.id), taskId: task.id });
              }
            });

            effectedRouteKeys.push(routeKey);
            const { movedTasksStopsMap, movedTasks: movedTasksData } = optimisationResult.result.routes[
              routeKey
            ].tour.reduce(
              ({ movedTasksStopsMap, movedTasks, previousStopId, previousFoundIndex }, t) => {
                if (`${t.id}` === 'startFromDepot' || `${t.id}` === 'EndDepot') {
                  return {
                    movedTasksStopsMap,
                    movedTasks,
                    previousStopId,
                    previousFoundIndex,
                  };
                }

                const index = movedTasksOrder.indexOf(parseInt(t.id));

                let newPreviousStopId = t.task['stop_id'];
                let newPreviousFoundIndex = index;
                if (index > -1) {
                  if (!movedTasks[index]) {
                    movedTasks[index] = [];
                  }
                  movedTasks[index] = [t];
                  movedTasksStopsMap[t.task.id] = true;
                } else if (!singleTaskMove && previousStopId && previousStopId === t.task['stop_id']) {
                  movedTasks[previousFoundIndex].push(t);
                  movedTasksStopsMap[t.task.id] = true;
                  newPreviousFoundIndex = previousFoundIndex;
                } else {
                  newPreviousStopId = null;
                  newPreviousFoundIndex = null;
                }

                return {
                  movedTasksStopsMap,
                  movedTasks,
                  previousStopId: newPreviousStopId,
                  previousFoundIndex: newPreviousFoundIndex,
                };
              },
              { movedTasksStopsMap: {}, movedTasks: movedDroppedTasks, previousStopId: null }
            );

            const movedTasks = [].concat(...movedTasksData);

            const { tasksBefore, tasksAfter } = optimisationResult.result.routes[routeKey].tour.reduce(
              ({ tasksBefore, tasksAfter, breakpointFound }, t) => {
                if (t.task && movedTasksStopsMap[t.task.id]) {
                  effectedTasks.push({ workerId: parseInt(route.assignee.id), taskId: t.id });
                  return { tasksBefore, tasksAfter, breakpointFound };
                }

                if (breakpointFound) {
                  tasksAfter.push(t);
                } else {
                  tasksBefore.push(t);
                  if (parseInt(taskBefore.id) === parseInt(t.id)) {
                    breakpointFound = true;
                  }
                }

                return { tasksBefore, tasksAfter, breakpointFound };
              },
              { tasksBefore: [], tasksAfter: [], breakpointFound: !taskBefore }
            );

            const mappedMovedTasks = movedTasks.map((t) => {
              if (start) {
                const updatedTask = {
                  ...t,
                  arrivalEarliest: moment(start).toISOString(),
                };
                const stopIdParts = [
                  updatedTask.location.latitude || updatedTask.task.location.lat,
                  updatedTask.location.longitude || updatedTask.task.location.lng,
                  updatedTask.arrivalEarliest,
                  updatedTask.task.order_step.contact_name,
                ];
                updatedTask.stop_id = stopIdParts.join('_');
                updatedTask.task.stop_id = stopIdParts.join('_');

                return updatedTask;
              } else {
                return t;
              }
            });

            optimisationResult.result.routes[routeKey].tour = [...tasksBefore, ...mappedMovedTasks, ...tasksAfter];

            optimisationResult.result.routes[routeKey].directions = [];
            optimisationResult.result.routes[routeKey].updatedRoute = true;
            optimisationResult.result.routes[routeKey].optimised = false;
            if (hasDroppedTask) {
              optimisationResult.droppedTaskData = optimisationResult.result.droppedTaskData.filter(
                (t) => movedTasksOrder.indexOf(t.id) === -1
              );
              optimisationResult.result.droppedTaskData = optimisationResult.droppedTaskData;
            }
          } else if (!hasDroppedTask) {
            console.error('Drag and drop to different driver is not implemented', {
              extra: { id, group, start, taskBefore, optimisationData },
            });
          }
        }
      });
    });

    optimisationResult.updatedAt = moment().toISOString();

    return { data: optimisationData, effectedRouteKeys, effectedTasks };
  };

  static addUnSequencedTasksToDriver({ tasks, workerId, position, optimisationData }) {
    const fixedTasks = fixSequence(tasks);
    const optimisationResult = optimisationData;
    const effectedRouteKeys = [];
    const effectedTasks = [];

    const ungroup = false;
    optimisationResult?.result?.routes?.forEach((route, routeKey) => {
      if (parseInt(route?.assignee?.id) === parseInt(workerId)) {
        effectedRouteKeys.push(routeKey);

        const addedDroppedTasks = fixedTasks.map((task) => {
          effectedTasks.push({ workerId: parseInt(route.assignee.id), taskId: task.id });
          return transformSingleTaskToTour(
            task,
            optimisationResult.result.routes[routeKey].tour
              .filter((t) => t?.task?.['order_item_id'] === task['order_item_id'])
              .map((t) => t.task)
              .concat(
                ...(optimisationResult?.result?.droppedTaskData || []).filter(
                  (t) => t?.['order_item_id'] === task['order_item_id']
                )
              )
          );
        });

        let tasksBefore = [];
        let tasksAfter = [];
        if (position === 'top') {
          tasksBefore = optimisationResult.result.routes[routeKey].tour.filter((t) => `${t.id}` === 'startFromDepot');
          tasksAfter = optimisationResult.result.routes[routeKey].tour.filter((t) => `${t.id}` !== 'startFromDepot');
        } else {
          tasksBefore = optimisationResult.result.routes[routeKey].tour.filter((t) => `${t.id}` !== 'EndDepot');
          tasksAfter = optimisationResult.result.routes[routeKey].tour.filter((t) => `${t.id}` === 'EndDepot');
        }

        optimisationResult.result.routes[routeKey].tour = [...tasksBefore, ...addedDroppedTasks, ...tasksAfter];

        optimisationResult.result.routes[routeKey].directions = [];
        optimisationResult.result.routes[routeKey].updatedRoute = true;
        optimisationResult.result.routes[routeKey].optimised = false;

        const addedDroppedTasksMap = addedDroppedTasks.reduce((acc, t) => {
          acc[t.id] = true;
          return acc;
        }, {});

        optimisationResult.droppedTaskData = optimisationResult.result.droppedTaskData.filter(
          (t) => !addedDroppedTasksMap[t.id]
        );
        optimisationResult.result.droppedTaskData = optimisationResult.droppedTaskData;
        optimisationResult.result.droppedTasks = optimisationResult.result.droppedTasks.filter(
          (id) => !addedDroppedTasksMap[parseInt(id)]
        );
      }
    });

    optimisationResult.updatedAt = moment().toISOString();

    return { data: optimisationData, effectedRouteKeys, effectedTasks, ungroup };
  }

  static removeUnSequencedTasks({ optimisationResult, workerId }) {
    const { remainingTasks: remaining, removedTasks: removed } = optimisationResult.result.droppedTaskData.reduce(
      ({ remainingTasks, removedTasks }, t) => {
        if (parseInt(t?.['task_group']?.['worker_id']) === parseInt(workerId)) {
          removedTasks.push(t);
        } else {
          remainingTasks.push(t);
        }

        return { remainingTasks, removedTasks };
      },
      { remainingTasks: [], removedTasks: [] }
    );

    optimisationResult.droppedTaskData = remaining;
    optimisationResult.result.droppedTaskData = optimisationResult.droppedTaskData;
    optimisationResult.result.droppedTasks = optimisationResult.result.droppedTaskData.map((t) => t.id);
    optimisationResult.removedUnsequencedTasksData = (optimisationResult.removedUnsequencedTasksData || []).concat(
      ...removed
    );
    optimisationResult.result.removedUnsequencedTasksData = optimisationResult.removedUnsequencedTasksData;
    optimisationResult.result.removedUnsequencedTasks = optimisationResult.result.removedUnsequencedTasksData.map(
      (t) => t.id
    );

    return optimisationResult;
  }

  static validStepGroupSequence({ updatedTaskData, tourData, updatedTaskIndex }) {
    return (
      updatedTaskData &&
      tourData.find(({ task }, index) => {
        if (task && parseInt(task['order_item_id']) === parseInt(updatedTaskData['order_item_id'])) {
          if (index > updatedTaskIndex && parseInt(updatedTaskData?.['step_group']) > task?.['step_group']) {
            return true;
          }

          if (index < updatedTaskIndex && parseInt(updatedTaskData?.['step_group']) < task?.['step_group']) {
            return true;
          }
        }

        return false;
      })
    );
  }
}

export default TimelineHelper;
