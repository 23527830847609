import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class OrderItemsService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  cancelMultiple = ({ ids, forceCancellation, cancelled_notes, reason_code }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/order_items/cancel_multiple'), {
        ids,
        cancelled_notes,
        reason_code,
        ...(forceCancellation ? { force_cancellation: forceCancellation } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  fetchHierarchy = ({ orderItemId }) => {
    return this.authService.get(this.getUmbrellaApiUrl(`dispatcher/order_items/${orderItemId}/hierarchy`), {});
  };

  deconsolidate = ({ params }) => {
    return this.authService.post(this.getUmbrellaApiUrl(`dispatcher/order_items/deconsolidate`), params);
  };

  consolidate = ({ params }) => {
    return this.authService.post(this.getUmbrellaApiUrl(`dispatcher/order_items/consolidate`), params);
  };

  fetchOrderItemJobs = ({ orderItemId }) => {
    return this.authService.get(this.getUmbrellaApiUrl(`dispatcher/order_items/${orderItemId}/jobs`), {});
  };

  updateJobs = ({ params: { orderItemId, jobs } }) => {
    return this.authService.put(this.getUmbrellaApiUrl(`dispatcher/order_items/${orderItemId}/jobs/update_multi`), {
      jobs,
    });
  };

  generatePODs = ({ orderNumbers }) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/orders/documents/pods/bulk_export`), { order_numbers: orderNumbers })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const orderItemsService = new OrderItemsService({ authService });
