const defaultState = {
  status: 'idle',
  formaDataList: [],
  formaDataListStatus: 'idle', // idle || running || failed || success
  formaDataDownloadStatus: 'idle', // idle || running || failed || success
  successMessage: undefined,
  errorMessage: undefined,
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  CLEAR_MESSAGE: (state) => {
    return {
      ...state,
      successMessage: undefined,
      errorMessage: undefined,
    };
  },
  SET_STATUS: (state, action) => {
    return {
      ...state,
      [action.payload.status]: action.payload.data,
    };
  },
  FETCH_FORMA_LIST_SUCCESS: (state, action) => {
    return {
      ...state,
      formaDataList: action.payload.data,
      pagination: action.payload.pagination,
    };
  },
  FETCH_FORMA_LIST_FAILED: (state, action) => {
    return {
      ...state,
      errorMessage: `Fetch Pro Forma list failed ${action.payload.data}`,
    };
  },
  DOWNLOAD_INVOICE_STARTED: (state) => {
    return {
      ...state,
      successMessage: `Preparing ...`,
    };
  },
  DOWNLOAD_INVOICE_FAILED: (state, action) => {
    return {
      ...state,
      errorMessage: `Download failed ${action.payload.data}!`,
    };
  },
};

const reducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
