import { parseJSONWithDefaultValue } from '@yojee/helpers/JSONHelper';

// 10 mininutes
export const DEFAULT_SEARCH_SEARCH_TIME_LIMIT_MS = 600000;

/**
 * Checks the validation of the plan data before optimization.
 * @param requestId
 * @param planData
 * @returns {boolean}
 */
export const checkValidation = (requestId, planData) => {
  const tasksDic = {};
  planData.taskData.data.forEach((t) => (tasksDic[t.id] = t));

  const workers = planData.workerData.data.filter(
    (w) => planData.selectedWorkers && planData.selectedWorkers.includes(w.id)
  );
  const tasks = planData.selectedTasks
    .filter((taskId) => Object.hasOwn(tasksDic, taskId))
    .map((taskId) => tasksDic[taskId]);

  if (planData.selectedTasks.length > tasks.length) {
    const msg = 'The number of selected tasks is not equal to the task filtered from the task data!';
    const error = {
      code: 401,
      message: msg,
      info: {
        selected_task_count: planData.selectedTasks.length,
        found_task_count: tasks.length,
      },
    };
    // console.error(msg, error);
    throw error;
  }

  const filteredTasks = tasks.filter((t) => t.state !== 'completed');

  const unselectedVehicleIds = new Set();
  const unselectedVehicleNames = new Set();
  const unselectedRecommendedVehicleIds = new Set();
  const unselectedRecommendedVehicleNames = new Set();
  const unselectedVehicleTypeIds = new Set();
  const unselectedVehicleTypeNames = new Set();

  filteredTasks.forEach((t) => {
    let fixedVehicleId = null;
    let assignableVehicleIdList = null;

    // Validates if the task has the already predefined rule from the `order_item.info` field.
    if (t.order_item && t.order_item.info) {
      try {
        const infoField = parseJSONWithDefaultValue(t.order_item.info, {});
        // Predefined assigned vehicle
        if (workers && infoField && infoField.yojee_vehicle_id) {
          const fixedVehicle = workers.find((w) => w.id === infoField.yojee_vehicle_id);
          if (fixedVehicle) {
            fixedVehicleId = fixedVehicle.id;
          } else {
            unselectedVehicleIds.add(infoField.yojee_vehicle_id);
            if (infoField.yojee_vehicle_name || infoField.vehicle_id) {
              unselectedVehicleNames.add(infoField.yojee_vehicle_name || infoField.vehicle_id);
            }
          }
        }
        // Recommended vehicles
        if (
          !fixedVehicleId &&
          workers &&
          infoField &&
          infoField.yojee_vehicle_ids &&
          infoField.yojee_vehicle_ids.length > 0
        ) {
          assignableVehicleIdList = [];
          const selectedWorkerIdList = workers.map((w) => w.id);
          infoField.yojee_vehicle_ids.forEach(function (id, i) {
            if (!selectedWorkerIdList.includes(id)) {
              unselectedRecommendedVehicleIds.add(id);
              try {
                unselectedRecommendedVehicleNames.add(infoField.yojee_vehicle_names[i]);
              } catch (e) {
                console.warn(e);
              }
            } else {
              assignableVehicleIdList.push(id);
            }
          });
        }
        // Make the predefined assignable vehicle id list from the predefined assignable vehicle type
        // Ignore if there is the fixed assigned vehicle already!!!
        if (
          !fixedVehicleId &&
          workers &&
          infoField &&
          (!assignableVehicleIdList || (assignableVehicleIdList && assignableVehicleIdList.length === 0)) &&
          infoField.yojee_vehicle_type_ids &&
          infoField.yojee_vehicle_type_ids.length > 0
        ) {
          // assignableVehicleIdList = workers.filter(w => w.current_vehicle_type_id === infoField.yojee_vehicle_type_id).map(w => w.id.toString());
          assignableVehicleIdList = workers
            .filter((w) => infoField.yojee_vehicle_type_ids.includes(w.current_vehicle_type_id))
            .map((w) => w.id.toString());
          if (!assignableVehicleIdList || assignableVehicleIdList.length === 0) {
            // unselectedVehicleTypeIds.add(infoField.yojee_vehicle_type_id);
            infoField.yojee_vehicle_type_ids.forEach((id) => {
              unselectedVehicleTypeIds.add(id);
            });

            if (infoField.yojee_vehicle_type_names && infoField.yojee_vehicle_type_names.length > 0) {
              infoField.yojee_vehicle_type_names.forEach((name) => {
                unselectedVehicleTypeNames.add(name);
              });
            }
          }
        }
      } catch (error) {
        console.error('order_item.info=', t.order_item.info, error);
      }
    }
  });

  if (unselectedVehicleIds.size > 0 || unselectedVehicleTypeIds.size > 0) {
    const msg = 'Should select the predefined vehicles or vehicle types';
    const error = {
      code: 402,
      message: msg,
      info: {
        unselected_vehicle_ids: unselectedVehicleIds,
        unselected_vehicle_names: unselectedVehicleNames,
        unselected_recommended_vehicle_ids: unselectedRecommendedVehicleIds,
        unselected_recommended_vehicle_names: unselectedRecommendedVehicleNames,
        unselected_vehicle_type_ids: unselectedVehicleTypeIds,
        unselected_vehicle_type_names: unselectedVehicleTypeNames,
      },
    };
    // console.error(msg, error);
    throw error;
  }

  return true;
};
