import { call, put, select, takeLatest } from 'redux-saga/effects';

import { partnerWorkersService } from '@yojee/api/partnerWorkersService';
import { tasksSearchService } from '@yojee/api/tasksSearchService';
import { CONNECTION_TYPES, webSocketService } from '@yojee/api/webSocketService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { initTasks } from '@yojee/data/fetch-services';
import { getValue } from '@yojee/helpers/AccessHelper';
import { SOCKET_EVENTS, SOCKET_TOPIC } from '@yojee/helpers/constants';
import { getPartnerWorkerTasksQueryPayload, transformResults } from '@yojee/helpers/SearchHelper';
import { MapController } from '@yojee/ui/map';
import { setSelectedPartnerWorker } from '@yojee/ui/partnerWorker';

export const getSelectedPartnerWorker = (state) => state.partnerWorker.selectedPartnerWorker;

export default function* sagas() {
  yield takeLatest('GET_PARTNER_WORKERS', getPartnerWorkers);
  yield takeLatest('GET_PARTNER_WORKER_TASKS', getPartnerWorkerTasks);
  yield takeLatest('INIT_PARTNER_WORKER_SOCKET', initSocket);
}

function* initSocket() {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id: companyId, slug },
        },
      },
      token: jwt,
    } = yield select(AuthSelectors.getData);

    if (jwt && companyId && slug) {
      const topic = SOCKET_TOPIC.PARTNER_WORKER;

      // Subcribe channel.
      const channel = webSocketService
        .getSocket(CONNECTION_TYPES.NORMAL, { company_slug: slug, jwt })
        .getChannelByTopic({
          topic,
          onError: ({ reason }) => {
            console.error('Failed join', reason, topic, slug);
          },
          onTimeout: () => {
            console.error('Networking issue. Still waiting...');
          },
        });

      channel.on('notification', (msg) => {
        if (msg) {
          const { event_type: eventType, payload: { worker } = {} } = msg;

          if (eventType === SOCKET_EVENTS.LOCATION_UPDATE && worker) {
            MapController.updateWorkersData({
              ...worker,
              location_updated_at: new Date().toISOString(),
            });

            MapController.driverMove(worker);
          }
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPartnerWorkers() {
  try {
    const { data } = yield call(partnerWorkersService.fetchPartnerWorkers);
    yield put({ type: 'GET_PARTNER_WORKERS_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GET_PARTNER_WORKERS_ERROR', error });
  }
}

function* getPartnerWorkerTasks({ worker }) {
  try {
    const workerId = worker.id;
    const selectedPartnerWorker = yield select(getSelectedPartnerWorker);
    const isParnerWorkerSelected = selectedPartnerWorker && workerId === selectedPartnerWorker.id;

    if (isParnerWorkerSelected) {
      yield put(setSelectedPartnerWorker(null));
      yield initTasks({});
    } else {
      yield put(setSelectedPartnerWorker(worker));
      yield put({ type: 'FETCH_TASKS_START' });

      const companyId = yield select((state) => getValue(state, 'auth.dispatcher_info.data.company.id'));
      const payload = getPartnerWorkerTasksQueryPayload(companyId, workerId);
      const { data } = yield call(tasksSearchService.search, payload);
      const taskData = transformResults(data);

      yield put({ type: 'ZOOM_SPECIFIC', data: taskData.data });
      yield put({ type: 'FETCH_TASKS_SUCCEEDED', tasks: taskData });
      yield put({ type: 'FETCH_TASKS_COMPLETE' });
    }
  } catch (error) {
    yield put({ type: 'FETCH_TASKS_ERROR', error });
    yield put({ type: 'FETCH_TASKS_COMPLETE' });
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
  }
}
