import { t } from 'i18next';
import _ from 'lodash-es';
import moment from 'moment';
import hash from 'object-hash';

import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { DEFAULT_TIME_FRAME_KEY } from '@yojee/helpers/timeFrame';

import { FEATURES_MAP, isFeatureEnabled } from '../SettingResolver';

export const defaultFilter = {
  taskType: [],
  status: [],
  dateOf: ['commit_at'],
  timeFrame: {
    key: DEFAULT_TIME_FRAME_KEY,
  },
  dateType: ['range'],
  dateValue: { from: moment().startOf('day'), to: moment().endOf('day').add(1, 'day') }, // should be object : from, to,
  geo_circles: [],
  region_ids: [],
  hubs: [],
  senderIds: [],
  serviceTypeIds: [],
  driverIds: [],
  partnerCompanyIds: [],
  addressItemIds: [],
  driverIdsQueryByTags: [],
  itemTypeIds: [],
  idKey: 'order_id',
  idValue: '',
  ignoreRange: false,
  selectedAddressOptions: [],
  radius: 0,
  reportInfoWithReason: [],
  vesselValue: '',
  vesselIdKey: '',
  legTypes: [],
  isHideRelatedTasks: false,
  shipper: '',
  shipperIdKey: '',
  selectedAddressTagIds: [],
  selectedDriverTagIds: [],
};

export const getDefaultHash = (excludeProps) => {
  return getHash(defaultFilter, excludeProps);
};

export const getJSON = (objectInput, excludeProps) => {
  const replacer = function (key, value) {
    if (moment.isMoment(this[key])) {
      return this[key].format('DD/MM/YYYY hh:mm:ss a');
    }

    return value;
  };

  if (excludeProps && excludeProps.length > 0) {
    const tempObj = Object.assign({}, objectInput);
    excludeProps.forEach((prop) => {
      delete tempObj[prop];
    });

    return JSON.stringify(tempObj, replacer);
  }

  return JSON.stringify(objectInput, replacer);
};

export const getHash = (objectInput, excludeProps) => {
  const json = getJSON(objectInput, excludeProps);

  return hash(json);
};

export const handleControlByOuterAndSubStatus = (outerStatus, subStatus, currentFilterState) => {
  const newStateByOuterStatus = handleControlByFilterChange(outerStatus, currentFilterState);
  if (subStatus && subStatus.length > 0) {
    return handleControlBySubStatusChange(outerStatus, subStatus, newStateByOuterStatus);
  }
  return newStateByOuterStatus;
};

export const handleControlByFilterChange = (statusValue, currentMasterFilterState, previouStatusValue) => {
  const taskStatusControl = {
    ...currentMasterFilterState.taskStatusControl,
    ...(Array.isArray(statusValue)
      ? {
          availableSource: statusValue.reduce((acc, status) => {
            return acc.concat(Status[status].availableSource);
          }, []),
        }
      : Status[statusValue]),
  };

  const sortCriteria = {
    ...currentMasterFilterState.sortCriteria,
    ...(Array.isArray(statusValue) ? SortCriteria[statusValue[statusValue.length - 1]] : SortCriteria[statusValue]),
  };

  const dateOfControl = {
    ...currentMasterFilterState.dateOfControl,
    availableSource: Array.isArray(statusValue)
      ? statusValue.reduce((acc, status) => {
          return acc.concat(DateOf[status].availableSource);
        }, [])
      : DateOf[statusValue].availableSource,
  };

  //INFO: Deep clone to not allow mutations on original Redux data
  const currentFilter = _.cloneDeep(currentMasterFilterState.filter);
  if (previouStatusValue && previouStatusValue === statusValue) {
    currentFilter.dateOf =
      dateOfControl.availableSource &&
      Array.isArray(currentFilter.dateOf) &&
      dateOfControl.availableSource.includes(currentFilter.dateOf[0])
        ? currentFilter.dateOf
        : Array.isArray(statusValue)
        ? statusValue.reduce((acc, status) => {
            return acc.concat(DateOf[status].defaultSelected);
          }, [])
        : DateOf[statusValue].defaultSelected;
  } else {
    currentFilter.dateOf = Array.isArray(statusValue)
      ? statusValue.reduce((acc, status) => {
          return acc.concat(DateOf[status].defaultSelected);
        }, [])
      : DateOf[statusValue].defaultSelected;
  }

  if (currentFilter.status && currentFilter.status.length > 0) {
    const availableSubStatus = Array.isArray(statusValue)
      ? statusValue.reduce((acc, status) => {
          return acc.concat(Status[status].availableSource);
        }, [])
      : Status[statusValue].availableSource;
    currentFilter.status = currentFilter.status.filter((s) => availableSubStatus.includes(s));
  }
  const disabledDriver = isDriverDisabled(statusValue);
  const driverControl = { ...currentMasterFilterState.driverControl, disabled: disabledDriver };

  const visiblePartner = isPartnerVisible(statusValue);
  const partnerControl = { ...currentMasterFilterState.partnerControl, visible: visiblePartner };
  return {
    ...currentMasterFilterState,
    filter: currentFilter,
    taskStatusControl,
    sortCriteria,
    dateOfControl,
    driverControl,
    partnerControl,
  };
};

export const handleControlBySubStatusChange = (outerStatus, subStatus, currentFilterState) => {
  const currentFilter = currentFilterState.filter;

  let availableDateOfSource = [];
  let defaultSelected = [];
  let disabledDriver = true;

  subStatus?.length > 0 &&
    subStatus.forEach((s) => {
      const source = DateOf?.[`${outerStatus}-${s}`];

      availableDateOfSource = availableDateOfSource.concat(source?.availableSource || []);
      defaultSelected = defaultSelected.concat(source?.defaultSelected || []);
      disabledDriver = disabledDriver && isDriverDisabled(`${outerStatus}-${s}`);
    });

  if (!subStatus || subStatus.length < 1) {
    const outerStatusAvailableSource = Array.isArray(outerStatus)
      ? outerStatus.reduce((acc, status) => {
          return acc.concat(DateOf[`${status}`].availableSource);
        }, [])
      : DateOf[`${outerStatus}`].availableSource;

    availableDateOfSource = availableDateOfSource.concat(outerStatusAvailableSource);

    const outerStatusDefaultSelected = Array.isArray(outerStatus)
      ? outerStatus.reduce((acc, status) => {
          return acc.concat(DateOf[`${status}`].defaultSelected);
        }, [])
      : DateOf[`${outerStatus}`].defaultSelected;

    defaultSelected = defaultSelected.concat(outerStatusDefaultSelected);
    disabledDriver = disabledDriver && isDriverDisabled(`${outerStatus}`);
  }

  availableDateOfSource = [...new Set(availableDateOfSource)];

  if (currentFilter?.dateOf?.length > 0) {
    currentFilter.dateOf = availableDateOfSource.includes(currentFilter.dateOf[0])
      ? currentFilter.dateOf
      : [defaultSelected[0]];
  }
  const driverControl = { ...currentFilterState.driverControl, disabled: disabledDriver };

  return {
    ...currentFilterState,
    filter: currentFilter,
    dateOfControl: { availableSource: availableDateOfSource },
    driverControl,
  };
};

export const getAvailableIdsFilter = (adminCopy = {}, adminAccess = {}, featureToggles = {}, customSource = []) => {
  const availableSource = [
    { id: 'order_id', display_name: t('Order TN') },
    { id: 'order_item_tracking_number', display_name: t('Item TN') },
    { id: 'global_tracking_number', display_name: t('Global Item TN') },
    { id: 'sender_name', display_name: t('Sender'), type: 'senders' },
    { id: 'sender_organisation_name', display_name: t('Sender Organisation'), type: 'senders' },
    { id: 'contact_name', display_name: t('Contact Name'), type: 'contact_name' },
    { id: 'description', display_name: t('Item Description'), type: 'description' },
    { id: 'transferred_order_number', display_name: t('Transfer Order TN'), type: 'transferred_order_number' },
    ...customSource,
  ];

  if (isFeatureEnabled(featureToggles, FEATURES_MAP.USE_BATCH_ID)) {
    availableSource.unshift({ id: 'order_group_number', display_name: t('Batch ID') });
  }

  const orderExternalIdTitle = adminCopy['orders.show.external_id_title'] || t('Order External Id');
  availableSource.push({ id: 'order_external_id', display_name: orderExternalIdTitle });

  const showExternalId = !!adminAccess['items.show.external_customer_id'];
  const showExternalId2 = !!adminAccess['items.show.external_customer_id2'];
  const showExternalId3 = !!adminAccess['items.show.external_customer_id3'];
  if (showExternalId) {
    const externalIdTitle = adminCopy['items.show.external_customer_id_title'] || t('External Id');
    availableSource.push({ id: 'external_id', display_name: externalIdTitle });
  }
  if (showExternalId2) {
    const externalIdTitle = adminCopy['items.show.external_customer_id2_title'] || t('External Id2');
    availableSource.push({ id: 'external_id2', display_name: externalIdTitle });
  }
  if (showExternalId3) {
    const externalIdTitle = adminCopy['items.show.external_customer_id3_title'] || t('External Id3');
    availableSource.push({ id: 'external_id3', display_name: externalIdTitle });
  }
  if (isFeatureEnabled(featureToggles, FEATURES_MAP.USING_CONTAINER)) {
    availableSource.push({ id: 'item_container_no', display_name: t('Container Number') });
  }

  availableSource.push({
    id: 'external_carrier_reference',
    display_name: t('Carrier Reference'),
  });

  return availableSource;
};

export const Status = {
  [AVAILABLE_FILTERS.UNASSIGNED]: {
    availableSource: ['missing_info', 'reported', 'unassigned'],
  },
  [AVAILABLE_FILTERS.REPORTED]: {
    availableSource: [],
  },
  [AVAILABLE_FILTERS.ASSIGNED]: {
    availableSource: ['accepted', 'pending'],
  },
  [AVAILABLE_FILTERS.ALL]: {
    availableSource: [
      'missing_info',
      'reported',
      'accepted',
      'pending',
      'cancelled',
      'unassigned',
      'transferred_completed',
      'transferred_unassigned',
      'transferred_assigned',
      'transferred_pending',
      'completed',
    ],
  },
  [AVAILABLE_FILTERS.COMPLETED]: {
    availableSource: [],
  },
  [AVAILABLE_FILTERS.TRANSFERRED]: {
    availableSource: [
      'transferred_completed',
      'transferred_unassigned',
      'transferred_assigned',
      'transferred_reported',
      'transferred_pending',
    ],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_PENDING]: {
    availableSource: ['transferred_pending'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_UNASSIGNED]: {
    availableSource: ['transferred_unassigned'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_ASSIGNED]: {
    availableSource: ['transferred_assigned'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_COMPLETED]: {
    availableSource: ['transferred_completed'],
  },
};

export const DATEOF_TYPE = {
  COMMIT: 'commit_at',
  ASSIGNED: 'task_group.assigned_time',
  PARTNER_ASSIGNED: 'transfer_task_metadata.task_group_info.assigned_time',
  COMPLETION: 'task.completion_time',
  PARTNER_COMPLETION: 'transfer_task_metadata.task_info.completion_time',
  ETA: 'task.eta',
  PARTNER_ETA: 'transfer_task_metadata.task_info.eta',
  CREATION: 'inserted_at',
  REPORTED: 'reported',
  CANCELLATION: 'task.cancelled_time',
  VESSEL_RECEIVING_START_DATE: 'voyage_info.receiving_start_datetime',
  VESSEL_RECEIVING_END_DATE: 'voyage_info.receiving_end_datetime',
  VESSEL_DEPARTURE_DATE: 'voyage_info.departure_datetime',
  VESSEL_ARRIVAL_DATE: 'voyage_info.arrival_datetime',
  FCL_AVAILABLE_DATE: 'voyage_info.fcl_available_datetime',
  FCL_STORAGE_DATE: 'voyage_info.fcl_storage_datetime',
};

export const DATEOF_LIST = (t) => {
  return [
    { id: DATEOF_TYPE.CREATION, display_name: t('Creation Date') },
    { id: DATEOF_TYPE.COMMIT, display_name: t('Commit Date') },
    { id: DATEOF_TYPE.COMPLETION, display_name: t('Completion Date') },
    { id: DATEOF_TYPE.PARTNER_COMPLETION, display_name: t('Completion Date') },
    { id: DATEOF_TYPE.ETA, display_name: t('ETA') },
    { id: DATEOF_TYPE.PARTNER_ETA, display_name: t('ETA') },
    { id: DATEOF_TYPE.ASSIGNED, display_name: t('Assigned Date') },
    { id: DATEOF_TYPE.PARTNER_ASSIGNED, display_name: t('Assigned Date') },
    { id: DATEOF_TYPE.REPORTED, display_name: t('Reported Date') },
    { id: DATEOF_TYPE.CANCELLATION, display_name: t('Cancelled Date') },
    { id: DATEOF_TYPE.VESSEL_RECEIVING_START_DATE, display_name: t('Vessel Receiving Start Date') },
    { id: DATEOF_TYPE.VESSEL_RECEIVING_END_DATE, display_name: t('Vessel Receiving End Date') },
    { id: DATEOF_TYPE.VESSEL_DEPARTURE_DATE, display_name: t('Vessel Departure Date') },
    { id: DATEOF_TYPE.VESSEL_ARRIVAL_DATE, display_name: t('Vessel Arrival Date') },
    { id: DATEOF_TYPE.FCL_AVAILABLE_DATE, display_name: t('FCL Available Date') },
    { id: DATEOF_TYPE.FCL_STORAGE_DATE, display_name: t('FCL Storage Date') },
  ];
};

export const SortCriteria = {
  [AVAILABLE_FILTERS.TRANSFERRED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_PENDING]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_ASSIGNED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_UNASSIGNED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.TRANSFERRED_COMPLETED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.ALL]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.UNASSIGNED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.REPORTED]: {
    sortBy: ['commit_at_from'],
  },
  [AVAILABLE_FILTERS.ASSIGNED]: {
    sortBy: ['task.sequence_id', 'task.position'],
  },
  [AVAILABLE_FILTERS.COMPLETED]: {
    sortBy: ['task.completion_time'],
  },
};

const DisabledDriver = {
  [`${AVAILABLE_FILTERS.UNASSIGNED}`]: {
    disabledDriver: true,
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}-reported`]: {
    disabledDriver: true,
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}-missing_info`]: {
    disabledDriver: true,
  },
  [`${AVAILABLE_FILTERS.REPORTED}`]: {
    disabledDriver: true,
  },
  [`${AVAILABLE_FILTERS.ALL}-cancelled`]: {
    disabledDriver: true,
  },
};

export function isDriverDisabled(status) {
  const availableDisabledDriverOption = DisabledDriver[status];
  if (availableDisabledDriverOption) {
    return availableDisabledDriverOption.disabledDriver;
  }
  return false;
}

export const VisiblePartner = {
  [`${AVAILABLE_FILTERS.TRANSFERRED}`]: {
    visiblePartner: true,
  },
  [`${AVAILABLE_FILTERS.ALL}`]: {
    visiblePartner: true,
  },
};

export function isPartnerVisible(status) {
  const availableVisiblePartnerOption = VisiblePartner[status];
  if (availableVisiblePartnerOption) {
    return availableVisiblePartnerOption.visiblePartner;
  }
  return false;
}

const VesselDates = [
  DATEOF_TYPE.VESSEL_RECEIVING_START_DATE,
  DATEOF_TYPE.VESSEL_RECEIVING_END_DATE,
  DATEOF_TYPE.VESSEL_DEPARTURE_DATE,
  DATEOF_TYPE.VESSEL_ARRIVAL_DATE,
  DATEOF_TYPE.FCL_AVAILABLE_DATE,
  DATEOF_TYPE.FCL_STORAGE_DATE,
];

export const DateOf = {
  [`${AVAILABLE_FILTERS.TRANSFERRED}`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      DATEOF_TYPE.PARTNER_COMPLETION,
      DATEOF_TYPE.REPORTED,
      DATEOF_TYPE.CANCELLATION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },

  [`${AVAILABLE_FILTERS.TRANSFERRED_PENDING}`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED}-transferred_pending`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED_UNASSIGNED}`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED}-transferred_unassigned`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED_ASSIGNED}`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED}-transferred_assigned`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED}-transferred_reported`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED_COMPLETED}`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      DATEOF_TYPE.PARTNER_COMPLETION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.TRANSFERRED}-transferred_completed`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      DATEOF_TYPE.PARTNER_COMPLETION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}`]: {
    availableSource: [
      DATEOF_TYPE.ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.ETA,
      DATEOF_TYPE.COMPLETION,
      DATEOF_TYPE.REPORTED,
      DATEOF_TYPE.CANCELLATION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-unassigned`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-reported`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-missing_info`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-accepted`]: {
    availableSource: [DATEOF_TYPE.ASSIGNED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.ASSIGNED],
  },
  [`${AVAILABLE_FILTERS.ALL}-pending`]: {
    availableSource: [DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-cancelled`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.CANCELLATION, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.CANCELLATION],
  },
  [`${AVAILABLE_FILTERS.ALL}-completed`]: {
    availableSource: [
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.ASSIGNED,
      DATEOF_TYPE.ETA,
      DATEOF_TYPE.COMPLETION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-transferred_pending`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-transferred_unassigned`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-transferred_assigned`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ALL}-transferred_completed`]: {
    availableSource: [
      DATEOF_TYPE.PARTNER_ASSIGNED,
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.PARTNER_ETA,
      DATEOF_TYPE.PARTNER_COMPLETION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}-reported`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}-missing_info`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.UNASSIGNED}-unassigned`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },

  [`${AVAILABLE_FILTERS.REPORTED}`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.REPORTED, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },

  [`${AVAILABLE_FILTERS.ASSIGNED}`]: {
    availableSource: [DATEOF_TYPE.CREATION, DATEOF_TYPE.COMMIT, DATEOF_TYPE.ASSIGNED, DATEOF_TYPE.ETA, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },

  [`${AVAILABLE_FILTERS.ASSIGNED}-accepted`]: {
    availableSource: [DATEOF_TYPE.COMMIT, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
  [`${AVAILABLE_FILTERS.ASSIGNED}-pending`]: {
    availableSource: [DATEOF_TYPE.ASSIGNED, DATEOF_TYPE.ETA, ...VesselDates],
    defaultSelected: [DATEOF_TYPE.ASSIGNED],
  },
  [`${AVAILABLE_FILTERS.COMPLETED}`]: {
    availableSource: [
      DATEOF_TYPE.CREATION,
      DATEOF_TYPE.COMMIT,
      DATEOF_TYPE.ASSIGNED,
      DATEOF_TYPE.ETA,
      DATEOF_TYPE.COMPLETION,
      ...VesselDates,
    ],
    defaultSelected: [DATEOF_TYPE.COMMIT],
  },
};
