import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

const PAGINATION_HEIGHT_SIZE = '24px';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black.B60,
    overflow: 'hidden',
  },
  toolbar: {
    maxHeight: PAGINATION_HEIGHT_SIZE,
    minHeight: PAGINATION_HEIGHT_SIZE,
  },
  actions: {
    marginLeft: theme.spacing(1),
    '& button': {
      padding: 0,
    },
  },
  selectRoot: {
    border: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1),
    maxHeight: PAGINATION_HEIGHT_SIZE,
  },
}));

const Pagination = ({ pagination, dataCy, onChangePage, onChangeRowsPerPage, rowsPerPageOptions, ...restProps }) => {
  const classes = useStyles();
  const {
    total_count = 0,
    limit_value = 25,
    current_page = 1,
    previous = 'pagination-previous',
    next = 'pagination-next',
    select = 'pagination-select',
  } = pagination ?? {};

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={total_count}
      rowsPerPage={limit_value}
      page={current_page - 1}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
        'data-cy': previous,
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
        'data-cy': next,
      }}
      SelectProps={{
        'data-cy': select,
      }}
      onChangePage={(_, newPage) => onChangePage({ page: newPage + 1 })}
      onChangeRowsPerPage={(event) => onChangeRowsPerPage({ page: 1, page_size: event.target.value })}
      classes={{
        root: classes.root,
        actions: classes.actions,
        selectRoot: classes.selectRoot,
        toolbar: classes.toolbar,
      }}
      labelRowsPerPage={<Trans>Show</Trans>}
      {...restProps}
    />
  );
};

Pagination.propTypes = {
  pagination: PropTypes.objectOf({
    total_count: PropTypes.number,
    limit_value: PropTypes.number,
    current_page: PropTypes.number,
    previous: PropTypes.string,
    next: PropTypes.string,
    select: PropTypes.string,
  }),
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

Pagination.defaultProps = {
  pagination: {
    total_count: 0,
    limit_value: 25,
    current_page: 1,
    previous: 'pagination-previous',
    next: 'pagination-next',
    select: 'pagination-select',
  },
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  rowsPerPageOptions: [25, 50, 100, 200, 500],
};

export default Pagination;
