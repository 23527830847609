import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import moment from 'moment';

import { YojeeLegacyTheme } from '@yojee/ui/base/theme';

const useStyles = makeStyles((theme: YojeeLegacyTheme) => ({
  contentRoot: {
    width: '100%',
    display: 'flex',
  },
  contentLeft: {},
  contentRight: {
    flex: 1,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  notificationMessage: {
    color: theme.palette.black.B3,
    fontSize: '0.6875rem',
  },
  identificationNumber: {
    color: theme.palette.blue.B3,
    fontSize: '0.6875rem',
  },
  notificationTimestamp: {
    display: 'block',
    color: '#6e6e6e' /* hard-coded because it's not defined in theme */,
    fontSize: '0.625rem',
  },
  externalId: {
    color: theme.palette.black.B3,
    fontSize: '0.625rem',
  },
  contentLeftMaxWidth: {
    maxWidth: theme.spacing(30),
  },
}));

type NotificationContentProps = {
  identification_number?: string;
  published_at: moment.MomentInput;
  external_id?: string;
  message: string;
};

const NotificationContent = ({
  identification_number,
  published_at,
  external_id,
  message,
}: NotificationContentProps) => {
  const classes = useStyles();
  const hasRightContent = !!identification_number;

  return (
    <div className={classes.contentRoot}>
      <div
        className={clsx(classes.contentLeft, {
          [classes.contentLeftMaxWidth]: hasRightContent,
        })}
      >
        <Typography variant="body2" className={classes.notificationMessage}>
          {message}
        </Typography>
        <Typography variant="caption" className={classes.notificationTimestamp}>
          {moment(published_at).fromNow()}
        </Typography>
      </div>
      {hasRightContent && (
        <div className={classes.contentRight}>
          <Typography variant="body2" className={classes.identificationNumber}>
            {identification_number}
          </Typography>
          {identification_number !== external_id && (
            <Typography variant="body2" className={classes.externalId}>
              {external_id}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationContent;
