import { call, put, takeLatest } from 'redux-saga/effects';

import { itemTypesApi } from '@yojee/api/itemTypesApi';

export default function* sagas() {
  yield takeLatest(['FETCH_ITEM_TYPES'], fetchItemsTypes);
}

function* fetchItemsTypes() {
  try {
    const result = yield call(itemTypesApi.fetchItemTypes);
    yield put({ type: 'FETCH_ITEM_TYPES_SUCCESS', itemTypes: { data: result } });
  } catch (err) {
    yield put({ type: 'FETCH_ITEM_TYPES_FAILED', err });
  }
}
