import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

const moment = extendMoment(Moment);

export const VALIDATION_ERRORS_TYPES = {
  NO_DRIVER_AVAILABLE_FOR_TASK_TIME_WINDOW: 'no_driver_available_for_task_time_window',
  UNIT_DEMAND_CAPACITY_TO_BIG: 'unit_demand_capacity_to_big',
  WEIGHT_DEMAND_CAPACITY_TO_BIG: 'weight_demand_capacity_to_big',
  VOLUME_DEMAND_CAPACITY_TO_BIG: 'volume_demand_capacity_to_big',
  MULTILEG_ORDER_ALREADY_ASSIGNED_TO_DRIVER: 'multileg_order_already_assigned_to_driver',
};

export const validateTasks = ({ tasks, workers }) => {
  const errors = {};

  validateTasksTimeWindows(tasks, workers, errors);

  validateTasksCapacity(tasks, workers, errors);

  validateMultilegOrder(tasks, workers, errors);

  return { errors };
};

const validateTasksCapacity = (tasks, workers, errors) => {
  const maxWorkerUnitCapacity = Math.max(...workers.map((worker) => worker.vehicleType?.capacity?.unit ?? 0));
  const maxWorkerWeightCapacity = Math.max(...workers.map((worker) => worker.vehicleType?.capacity?.weight ?? 0));
  const maxWorkerVolumeCapacity = Math.max(...workers.map((worker) => worker.vehicleType?.capacity?.volume ?? 0));

  tasks.forEach((task, index) => {
    if (task.capacityDemand.unit > maxWorkerUnitCapacity) {
      addError(errors, index, VALIDATION_ERRORS_TYPES.UNIT_DEMAND_CAPACITY_TO_BIG);
    }
    if (task.capacityDemand.weight > maxWorkerWeightCapacity) {
      addError(errors, index, VALIDATION_ERRORS_TYPES.WEIGHT_DEMAND_CAPACITY_TO_BIG);
    }
    if (task.capacityDemand.volume > maxWorkerVolumeCapacity * 1000000) {
      addError(errors, index, VALIDATION_ERRORS_TYPES.VOLUME_DEMAND_CAPACITY_TO_BIG);
    }
  });
};

const validateTasksTimeWindows = (tasks, workers, errors) => {
  const workersRanges = workers.map((worker) => moment.range(worker.timeSchedule.start, worker.timeSchedule.end));
  const taskRanges = tasks.map((task) => moment.range(task.timeWindows[0].start, task.timeWindows[0].end));
  tasks
    .map((task, index) => {
      return {
        index: index,
        timeWindowValid: atLeastOneDriverCanComplete(taskRanges[index], workersRanges),
      };
    })
    .forEach((validation) => {
      if (!validation.timeWindowValid) {
        addError(errors, validation.index, VALIDATION_ERRORS_TYPES.NO_DRIVER_AVAILABLE_FOR_TASK_TIME_WINDOW);
      }
    });
};

const validateMultilegOrder = (tasks, workers, errors) => {
  const lastStepGroupOfOrderItem = {};
  const isMultilegOrderSelected = tasks.find((t) => {
    const lastStepGroup = lastStepGroupOfOrderItem[t?.task?.['order_item_id']];
    if (lastStepGroup !== undefined && lastStepGroup !== t?.task?.['step_group']) {
      return true;
    }

    lastStepGroupOfOrderItem[t?.task?.['order_item_id']] = t?.task?.['step_group'];
    return false;
  });

  if (isMultilegOrderSelected) {
    addError(errors, null, VALIDATION_ERRORS_TYPES.MULTILEG_ORDER_ALREADY_ASSIGNED_TO_DRIVER);
  }
};

const atLeastOneDriverCanComplete = (taskRange, workersRanges) => {
  return !!workersRanges.find((workerRange) => canWorkerCompleteTask(taskRange, workerRange));
};

const canWorkerCompleteTask = (taskRange, workerRange) => {
  return workerRange.overlaps(taskRange);
};

const addError = (errors, taskIndex, error) => {
  if (!errors[taskIndex]) {
    errors[taskIndex] = [];
  }

  errors[taskIndex].push(error);
};
