import Cookies from 'js-cookie';
import { call, put, takeLatest } from 'redux-saga/effects';

import { decodeJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import Utilities from '@yojee/helpers/utilities';

import * as Api from '../../Api/api';
import { partnerCompanySignup, validatePartnerInvitationCode } from '../../Api/partnerSignUpApi';

export default function* sagas() {
  yield takeLatest('VALIDATE_INVITATION_CODE', validateInvitationCode);
  yield takeLatest('PARTNER_COMPANY_SIGN_UP', partnerCompanySignUp);
  yield takeLatest('START_PROCESS_PARTNER_LOGIN', partnerCompanyLogin);
}

function* partnerCompanyLogin({ email, password, slug }) {
  try {
    yield put({ type: 'PARTNER_COMPANY_SIGN_UP_START_LOGIN' });
    const {
      data: {
        data: { jwt_tokens, dispatcher_companies },
      },
    } = yield call(Api.login, { email, password }, { slug });
    const jwtData = decodeJwtData(jwt_tokens);
    yield call(saveJwtData, jwtData);
    Cookies.set('jwt_data', JSON.stringify(jwtData), { domain: `.${Utilities.domain()}` });
    Cookies.set('company_slug', slug, { domain: `.${Utilities.domain()}` });
    Cookies.set('dispatcher_companies', JSON.stringify(dispatcher_companies), { domain: `.${Utilities.domain()}` });
    let arrayPath = window.location.host.split('.');
    if (arrayPath.length > 3) {
      arrayPath[0] = slug;
    } else {
      arrayPath = [slug].concat(arrayPath);
    }
    yield put({ type: 'PARTNER_COMPANY_LOGIN_SUCCESS' });
    window.location.href = `${window.location.protocol}//${arrayPath.join('.')}/explore?from=signup`;
  } catch (err) {
    yield put({ type: 'PARTNER_COMPANY_LOGIN_ERROR', error: err.message });
  }
}

function* partnerCompanySignUp({ invitationCode, company, dispatcher, options }) {
  try {
    const result = yield call(partnerCompanySignup, {
      invitationCode,
      signupInfo: {
        company,
        dispatcher,
        options: {
          ...options,
          create_individual_sender: false,
          create_corp_sender: false,
          create_worker: false,
          create_custom_service_type: false,
          create_example_tasks: false,
        },
      },
    });
    yield put({ type: 'PARTNER_COMPANY_SIGN_UP_SUCCESS', result });
    yield put({
      type: 'START_PROCESS_PARTNER_LOGIN',
      email: dispatcher.email,
      password: dispatcher.password,
      slug: company.slug,
    });
  } catch (err) {
    yield put({ type: 'PARTNER_COMPANY_SIGN_UP_ERROR', error: err });
  }
}

function* validateInvitationCode({ invitationCode }) {
  try {
    const result = yield call(validatePartnerInvitationCode, invitationCode);
    yield put({ type: 'VALIDATE_INVITATION_CODE_SUCCESS', invitationInfo: result.data });
  } catch (err) {
    yield put({ type: 'VALIDATE_INVITATION_CODE_ERROR', error: err.message });
  }
}
