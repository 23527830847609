import { normalize, schema } from 'normalizr';
const defaultState = {
  data: {},
  ids: [],
  errorMessage: undefined,
  inProgress: false,
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  FETCH_SERVICE_TYPES_SUCCESS: (state, { serviceTypes }) => {
    const serviceTypeArr = new schema.Entity('data');
    const mySchema = { data: [serviceTypeArr] };
    const normalizedData = normalize(serviceTypes, mySchema);
    const { entities, result } = normalizedData;
    const dataNormalized = entities.data;
    const idArray = result.data;
    return { ...state, data: dataNormalized, ids: idArray, errorMessage: undefined, inProgress: false };
  },
  FETCH_SERVICE_TYPES_ERROR: (state, { err }) => {
    return { ...state, errorMessage: err, inProgress: false };
  },
  FETCH_SERVICE_TYPES_IN_PROGRESS: (state, { err }) => {
    return { ...state, errorMessage: err, inProgress: true };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
