import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

import { mapClusterToRoute } from '@yojee/helpers/ClusterizationHelper';
import { generateColor } from '@yojee/helpers/ColorGenerator';

const moment = extendMoment(Moment);

const resolveClusteredCapacityDemand = (clusteredTask) => {
  return {
    weight: clusteredTask.item.weight * (clusteredTask.type === 'pickup' ? 1 : -1),
    volume: clusteredTask.item.volume * (clusteredTask.type === 'pickup' ? 1 : -1),
    unit: clusteredTask.item.quantity * (clusteredTask.type === 'pickup' ? 1 : -1),
  };
};

const mapTour = ({ route, i, tasksDataObj, settings }) => {
  route.color = generateColor(i);

  if (settings.clusterPickups) {
    return { ...route, ...mapClusterToRoute(route, tasksDataObj) };
  }

  return {
    ...route,
    tour: route.tour.map((t) => {
      const taskData = tasksDataObj[parseInt(t.id)];
      return { ...t, task: taskData ? { ...taskData, stop_id: taskData.stop_id || taskData.id } : taskData };
    }),
  };
};

const mapDroppedTasks = ({ settings, resultData, tasksDataObj }) => {
  if (settings.clusterPickups) {
    return [].concat(
      ...resultData.droppedTasks.map((tid) => {
        return tasksDataObj.tasksProperties[tid].clusteredTasks.map((clusteredTaskId) => {
          const clusteredTask = tasksDataObj[parseInt(clusteredTaskId)];
          const t = tasksDataObj[tid];

          return {
            ...t,
            ...clusteredTask,
            capacityDemand: resolveClusteredCapacityDemand(clusteredTask),
            task: { ...clusteredTask.task, order_step_id: t.order_step_id, stop_id: `dropped_${tid}` },
            order_step_id: t.order_step_id,
            id: parseInt(clusteredTaskId),
            location: clusteredTask
              ? {
                  lat: clusteredTask.location.lat,
                  lng: clusteredTask.location.lng,
                }
              : {},
          };
        });
      })
    );
  }

  // Set dropped task information from taskData
  return resultData.droppedTasks.map((tid) => tasksDataObj[tid]);
};

export const mapResponseData = ({ resultData, tasksDataObj, settings }) => {
  if (resultData?.routes) {
    return {
      ...resultData,
      routes: resultData.routes.map((route, i) => mapTour({ route, i, tasksDataObj, settings })),
      droppedTaskData: mapDroppedTasks({ settings, tasksDataObj, resultData }),
      epoch: settings.epochDate ? moment(settings.epochDate).utc().tz(settings.timezone) : moment(),
    };
  }

  return resultData;
};

export const getErrorMessage = (optimisationStatusData) => {
  if (optimisationStatusData.message && optimisationStatusData.status === 'failed') {
    return optimisationStatusData.message;
  }

  return 'Optimisation failed: something is wrong with your capacity configuration and tasks provided';
};
