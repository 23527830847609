import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { rrulestr } from 'rrule';

import { getDurationInSeconds } from '@yojee/helpers/planner-helper';
import { getAvailableRangesInPeriod } from '@yojee/helpers/workerShedule';

const moment = extendMoment(Moment);

export const getSingleDayTimeSchedule = (worker, startDate) => {
  const workStartDate = moment(startDate) || moment();
  const from = moment(workStartDate).set({ hour: 0, minute: 0, second: 0 });
  const to = moment(workStartDate).set({ hour: 23, minute: 59, second: 59 });
  if (worker?.schedules) {
    const hasAnySchedule = worker.schedules.some(
      (schedule) => schedule.type === 'worker_on' && schedule.conflict_schedule_id === null
    );
    if (hasAnySchedule) {
      return getAvailableRangesInPeriod(worker.schedules, from, to).map((window) => ({
        start: window.start.toISOString(),
        end: window.end.toISOString(),
      }));
    }

    return [
      {
        start: from.hour(0).minute(0).second(0).toISOString(),
        end: to.hour(23).minute(59).second(59).toISOString(),
      },
    ];
  }

  return [
    {
      start: from.hour(0).minute(0).second(0).toISOString(),
      end: to.toISOString(),
    },
  ];
};

export const compareRanges = (_driverSchedule, _from, _to, _range, method = 'overlaps') => {
  const _rule = rrulestr(_driverSchedule.recurrence_rule);

  const driverEventDuration = getDurationInSeconds(
    moment(_driverSchedule['start_event_datetime']).toDate(),
    moment(_driverSchedule['end_event_datetime']).toDate()
  );
  return _rule
    .between(_from.clone().subtract(2, 'weeks').toDate(), _to.clone().add(2, 'weeks').toDate())
    .some((driverScheduleDate) => {
      const _driverScheduleFrom = moment(driverScheduleDate);
      const _driverScheduleTo = moment(driverScheduleDate).add(driverEventDuration, 'seconds');
      const _driverScheduleRange = moment.range(_driverScheduleFrom, _driverScheduleTo);

      return method === 'overlaps' ? _driverScheduleRange.overlaps(_range) : _driverScheduleRange.contains(_range);
    });
};
