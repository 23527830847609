export const PROCESS_STATUS = {
  NORMAL: 'NORMAL',
  CREATING_USERS: 'CREATING_USERS',
  CREATING_USERS_SUCCESS: 'CREATING_USERS_SUCCESS',
  CREATING_USERS_ERROR: 'CREATING_USERS_ERROR',

  CREATING_DRIVERS: 'CREATING_DRIVERS',
  CREATING_DRIVERS_SUCCESS: 'CREATING_DRIVERS_SUCCESS',
  CREATING_DRIVERS_ERROR: 'CREATING_DRIVERS_ERROR',

  CREATING_SENDERS: 'CREATING_SENDERS',
  CREATING_SENDERS_SUCCESS: 'CREATING_SENDERS_SUCCESS',
  CREATING_SENDERS_ERROR: 'CREATING_SENDERS_ERROR',
};

const defaultState = {
  showOnBoardingDialog: false,
  status: PROCESS_STATUS.NORMAL,
  error: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  CREATING_USERS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_USERS };
  },
  CREATING_DRIVERS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_DRIVERS };
  },
  CREATING_SENDERS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_SENDERS };
  },
  CREATING_USERS_ERROR: (state, { error }) => {
    return { ...state, status: PROCESS_STATUS.CREATING_USERS_ERROR, error };
  },
  CREATING_DRIVERS_ERROR: (state, { error }) => {
    return { ...state, status: PROCESS_STATUS.CREATING_DRIVERS_ERROR, error };
  },
  CREATING_SENDERS_ERROR: (state, { error }) => {
    return { ...state, status: PROCESS_STATUS.CREATING_SENDERS_ERROR, error };
  },
  CREATING_USERS_SUCCESS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_USERS_SUCCESS, error: undefined };
  },
  CREATING_DRIVERS_SUCCESS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_DRIVERS_SUCCESS, error: undefined };
  },
  CREATING_SENDERS_SUCCESS: (state) => {
    return { ...state, status: PROCESS_STATUS.CREATING_SENDERS_SUCCESS, error: undefined };
  },
  RESET_STATUS: (state) => {
    return { ...state, status: PROCESS_STATUS.NORMAL, error: undefined };
  },
  CLOSE_ON_BOARDING_DIALOG: () => {
    return defaultState;
  },
  SHOW_ON_BOARDING_DIALOG: () => {
    return { ...defaultState, showOnBoardingDialog: true };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
