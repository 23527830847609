import _ from 'lodash-es';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { fetchJWT } from '@yojee/api/helpers/JwtHelper';
import AuthSelectors from '@yojee/auth/store/selectors';
import { fetchAssignedTasks } from '@yojee/data/fetch-services/assignedTasks';
import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { filterSelectedTasksAndIds } from '@yojee/helpers/LegsHelper';
import { fixSequence } from '@yojee/helpers/tasks-helper';
import { normalizedTaskToStop_byView } from '@yojee/helpers/tasksToStops';
import { uuidv4 } from '@yojee/helpers/uuidv4';

import * as Api from '../../Api/api';

export const getOptimisation = (state) => state.optimisation;
export const getAssignment = (state) => state.assignment;
export const getAssigmentCommision = (state) => state.assignment && state.assignment.commissionJson;
export const getEnableDriverCommission = (state) =>
  getValue(state, 'auth.dispatcher_info.data.company.enable_driver_commission', false);
export const getNormalizeData = (state) => state.planner && state.planner.taskData;
export const getPlannerWorkerData = (state) => state.planner && state.planner.workerData;
export const getPlannerData = (state) => state.planner;
export const getWorkerData = (state) => state.worker;
export const getTasks = (state) => state.tasks;
export const getSelectedWorkers = (state) => state.planner && state.planner.selectedWorkers;
export const getAuth = (state) => state.planner && state.auth;

const groupAndSortTasksBasedOnLocation = (tasks) => {
  const stops = Object.values(
    normalizedTaskToStop_byView(
      tasks.reduce((acc, task) => {
        acc[task.id] = task;
        return acc;
      }, {}),
      tasks.map((t) => t.id),
      'OPTIMISATION_CLUSTERING'
    ).stops
  );

  return []
    .concat(...stops.filter((s) => s.type === 'pickup').map((s) => s.tasks))
    .concat(...stops.filter((s) => s.type === 'dropoff').map((s) => s.tasks));
};

function* constructParamsForReassign({
  assignedTask,
  token,
  slug,
  workerData,
  workers,
  vehicleNumber,
  isContactBasedAllocation,
  commissionJson,
}) {
  let taskRelated = assignedTask;

  const existingTasksIds = assignedTask.reduce((acc, t) => {
    acc[t.id] = true;
    return acc;
  }, {});

  // getAllTask related with selected WorkerId
  const currentSelectedWorkerIds = yield select(getSelectedWorkers);
  const tasks = yield call(fetchAssignedTasks, currentSelectedWorkerIds);
  taskRelated = taskRelated.concat(tasks.filter((t) => !existingTasksIds[t.id]));
  const jwt = yield call(fetchJWT);
  const theWorker = currentSelectedWorkerIds.map((workerId) =>
    workerData.data.find((worker) => worker.id === workerId)
  )[0];

  taskRelated = isContactBasedAllocation
    ? groupAndSortTasksBasedOnLocation(taskRelated)
    : _.orderBy(taskRelated, ['stop_id', 'task.sequence_id', 'step_sequence']);

  const tasksSequences = fixSequence(taskRelated);

  const authData = yield select(getAuth);
  const taskServiceTime = authData?.['dispatcher_info']?.data?.company?.settings?.company?.['task_service_time'];

  const { data: directionsData } = yield call(Api.fetchDirections, {
    jwt,
    worker: theWorker,
    tasks: tasksSequences,
    settings: taskServiceTime ? { serviceTime: taskServiceTime } : null,
  });
  return {
    requestRoutes: directionsData,
    tasks: tasksSequences,
    workers,
    vehicleNumber,
    commissionJson: commissionJson ? commissionJson : {},
    auth: { token, slug },
    isContactBasedAllocation,
  };
}

function* assign({
  params: {
    requestId,
    worker,
    quickAssign,
    whitelistedWorkers,
    vehicleNumber,
    selectedTasks,
    tasks,
    keepLegs,
    isContactBasedAllocation,
    unassignDroppedTasks,
  },
}) {
  whitelistedWorkers = Array.isArray(whitelistedWorkers)
    ? whitelistedWorkers.map((id) => parseInt(id, 10))
    : whitelistedWorkers;
  const { requests, latestRequestId } = yield select(getOptimisation);
  const instanceId = yield select((state) => state.main.optimisation.instanceToDisplay);
  const { requests: assignmentRequests, latestRequestId: latestAssignmentRequestId } = yield select(getAssignment);
  const planner = yield select(getPlannerData);
  const {
    token,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const workerData = yield select(getWorkerData);
  const plannerWorkerData = yield select(getPlannerWorkerData);
  const { legs } = yield select(getTasks);
  const routePlanner = yield select((state) => state.routePlanner);

  if (!requestId) {
    requestId = latestRequestId || instanceId;
  }

  const newPlannerUsed = getValue(requests[requestId], 'newPlannerUsed', false);

  if (getValue(assignmentRequests, `${latestAssignmentRequestId}.status`) === 'running') {
    return;
  }

  let selectedTasksIdsList;
  let tasksDataObj;
  let tasksData;
  let data;
  let ids;
  if (newPlannerUsed) {
    selectedTasksIdsList = routePlanner[requestId].selectedTasksIds;
    selectedTasks = selectedTasksIdsList;
    tasksDataObj = {};
    Object.keys(routePlanner[requestId].tasks.data).forEach((orderItemId) => {
      routePlanner[requestId].tasks.data[orderItemId].forEach((task) => {
        tasksDataObj[task.id] = task;
      });
    });
    routePlanner[requestId].assignedTasks.data.forEach((t) => {
      tasksDataObj[t.id] = t;
    });
    tasksData = Object.values(tasksDataObj);
    const legsList = _.groupBy(tasksData, 'order_item_id');
    Object.keys(legsList).forEach((legKey) => {
      legs[legKey] = { tasks: legsList[legKey] };
    });
  } else {
    selectedTasksIdsList = selectedTasks ? selectedTasks : planner?.selectedTasks;
    const normalizedData = yield select(getNormalizeData);
    data = normalizedData.data;
    ids = normalizedData.ids;
    tasksData = tasks ? tasks : ids.map((id) => data[id]);
  }

  if (!worker) {
    worker =
      quickAssign || getValue(requests, `${requestId}.status`) !== 'completed'
        ? workerData.data.find((worker) => worker.id === planner.selectedWorkers[0]) ||
          plannerWorkerData.data.find((worker) => worker.id === planner.selectedWorkers[0])
        : null;
  }

  let params = {};

  try {
    if (quickAssign && !requestId) {
      requestId = uuidv4();
    }
    const requestRoutes = requestId && requests[requestId]?.result?.routes;
    const droppedTaskData = requests?.[requestId]?.result?.droppedTaskData;
    const tasksToUnassign = requests?.[requestId]?.result?.removedUnsequencedTasksData ?? [];
    yield put({ type: 'ASSIGNMENT_REQUESTED', requestId });

    const enableDriverCommision = yield select(getEnableDriverCommission);
    const commissionJson = enableDriverCommision ? yield select(getAssigmentCommision) : {};
    const tasksDic = {};
    tasksData.forEach((t) => (tasksDic[t.id] = t));

    let workers;
    let selectedTasks;
    if (whitelistedWorkers !== null) {
      const selectedWorkers = newPlannerUsed ? routePlanner[requestId].selectedWorkersIds : planner.selectedWorkers;
      const workerDataList = newPlannerUsed ? routePlanner[requestId].workers.data : workerData.data;
      workers = worker
        ? [worker]
        : selectedWorkers.map((workerId) => workerDataList.find((worker) => worker.id === workerId));
      selectedTasks = selectedTasksIdsList;
      selectedTasks = quickAssign
        ? selectedTasks
        : selectedTasks.concat(
            ...requestRoutes.map((route) => {
              return route.tour
                .filter((t) => `${t.id}` !== 'startFromDepot' && `${t.id}` !== 'EndDepot')
                .map((t) => parseInt(t.id, 10));
            })
          );
    } else {
      workers = Array.isArray(whitelistedWorkers)
        ? whitelistedWorkers.map((workerId) => workerData.data.find((worker) => worker.id === workerId))
        : [worker];
      if (quickAssign) {
        selectedTasks = selectedTasksIdsList;
      } else {
        selectedTasks = [].concat(
          ...requestRoutes.map((route) => {
            return route.tour
              .filter((t) => `${t.id}` !== 'startFromDepot' && `${t.id}` !== 'EndDepot')
              .map((t) => parseInt(t.id, 10));
          })
        );
      }
    }

    const actualSelectedTasks = tasksData.filter((t) => selectedTasks.includes(t.id));
    const { selectedTasks: tasks } = filterSelectedTasksAndIds(actualSelectedTasks, legs);
    const unassignedTask = tasks.filter((t) => t.task_group && t.task_group.state === 'unassigned');
    const assignedTask = tasks.filter((t) => t.task_group && t.task_group.state === 'assigned');
    let backgroundJob = null;

    const droppedTasksToBeUnassigned = (
      unassignDroppedTasks ? droppedTaskData.filter((t) => t.task_group.worker_id) : []
    ).concat(...tasksToUnassign);

    const epochDate = routePlanner?.[requestId]?.settings?.enforceEpoch
      ? requests?.[requestId]?.result?.epoch ?? null
      : null;

    if (unassignedTask && unassignedTask.length > 0) {
      params = {
        tasks: unassignedTask.concat(assignedTask),
        workers,
        vehicleNumber,
        commissionJson: commissionJson, // json - key: driverId, commission: amount
        auth: { token, slug },
        droppedTasks: droppedTasksToBeUnassigned,
        epochDate,
      };
      if (!quickAssign) {
        params.requestRoutes = whitelistedWorkers
          ? requestRoutes.filter((route) => whitelistedWorkers.includes(parseInt(route.assignee.id, 10)))
          : requestRoutes;
      }

      if (quickAssign) {
        if (isContactBasedAllocation) {
          params = yield call(constructParamsForReassign, {
            assignedTask: params.tasks,
            token,
            slug,
            workerData,
            workers,
            vehicleNumber,
            isContactBasedAllocation,
            commissionJson,
          });
        } else {
          params.tasks = fixSequence(params.tasks);
        }
        params.optimised = false;
      }

      backgroundJob = yield call(
        // use quick assign if not planned
        quickAssign && !isContactBasedAllocation && assignedTask.length === 0 ? Api.assignBg : Api.allocateBg,
        params
      );
    } else if (assignedTask && assignedTask.length > 0) {
      if (quickAssign) {
        params = yield call(constructParamsForReassign, {
          assignedTask,
          token,
          slug,
          workerData,
          workers,
          vehicleNumber,
          isContactBasedAllocation,
          commissionJson,
        });
        params.optimised = false;
      } else {
        params = {
          requestRoutes: whitelistedWorkers
            ? requestRoutes.filter((route) => whitelistedWorkers.includes(parseInt(route.assignee.id, 10)))
            : requestRoutes,
          tasks: unassignedTask,
          workers,
          vehicleNumber,
          commissionJson: commissionJson, // json - key: driverId, commission: amount
          auth: { token, slug },
          droppedTasks: droppedTasksToBeUnassigned,
          epochDate,
        };
      }
      backgroundJob = yield call(quickAssign ? Api.reassignBg : Api.allocateBg, params);
    }
    const assignmentRequestId = backgroundJob?.data?.id;
    if (!assignmentRequestId) {
      const error = new Error('Assignment not exists');
      console.error(error, { extra: { assignmentRequestId, backgroundJob, params } });
      throw new Error('Internal server error. Please try again');
    }

    let i = 0;
    while (true) {
      const { data: statusData } = yield call(Api.getAllocateStatus, assignmentRequestId, { auth: { token, slug } });
      if (statusData.completed_at) {
        yield put({
          type: 'ASSIGNMENT_SUCCEEDED',
          requestId,
          unassignment:
            params?.tasks?.length === 0 &&
            params?.droppedTasks?.length > 0 &&
            (params?.requestRoutes?.length ?? 0) === 0,
        });

        if (window.location.pathname.includes('explore-listview')) {
          yield put({ type: 'TOGGLE_EXPLORE_LIST', show: true });
        }
        // refresh worker to reflect change in capacity
        yield put({ type: 'INIT_PLANNER_WORKER' });
        yield put({ type: 'UPDATE_FILTERS_AFTER_ASSIGNMENT' });
        break;
      }
      if (i >= 1000) {
        throw new Error('Timed out!');
      } else {
        i++;
      }
      yield delay(2000);
    }
  } catch (e) {
    console.error(e, { extra: params });
    yield put({ type: 'TOGGLE_EXPLORE_LIST', show: false });
    yield put({ type: 'CHANGE_SELECTION_MODE', selectionMode: false });
    yield put({ type: 'TOGGLE_ACTION_MODE', mode: 'quick' });
    yield put({ type: 'HANDLE_STOP_FILTER_CHANGE', value: AVAILABLE_FILTERS.UNASSIGNED, isLoadTasks: true });
    yield put({ type: 'TOGGLE_TEAM_SELECTION' });
    yield put({ type: 'UPDATE_VISIBLE_ROUTES', workerId: null, visible: false, source: 'unassigned' });
    yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'collapse' });
    yield put({ type: 'CLOSE_OPTIMISATION' });
    yield delay(500);
    yield put({ type: 'ASSIGNMENT_FAILED', requestId, error: e });
  } finally {
    if (!keepLegs) {
      yield put({ type: 'RESET_LEGS' });
    }
    yield put({ type: 'CLEAR_OPTIMISATION_ROUTES', requestId });
  }
}

function* assignRegion({ region }) {
  yield put({ type: 'SET_REGION_OPTIMISATION_STATUS', data: { status: 5 }, regionId: region.id });
  yield assign({
    params: {
      requestId: region.requestId,
      worker: null,
      quickAssign: false,
      whitelistedWorkers: region.workers,
      selectedTasks: region.tasksIds,
      tasks: region.tasks,
      keepLegs: true,
    },
  });
}

function* setStep() {
  yield delay(2000);

  yield put({ type: 'SET_STEP', step: 2 });
}

function* handleAssignButtonClick({
  params: { confirmed, quickAssign, whitelistedWorkers, vehicleNumber, isContactBasedAllocation, unassignDroppedTasks },
}) {
  if (confirmed) {
    yield put({ type: 'SET_ASSIGNMENT_CONFIG', assignment: { showConfirmationDialog: false, timelineSave: false } });
    yield put({
      type: 'REQUEST_ASSIGNMENT',
      params: {
        quickAssign,
        whitelistedWorkers,
        vehicleNumber,
        isContactBasedAllocation,
        unassignDroppedTasks,
      },
    });
  } else {
    yield put({ type: 'OPEN_ASSIGNMENT_CONFIRMATION', whitelistedWorkers });
  }
}
export default function* sagas() {
  yield takeLatest('REQUEST_ASSIGNMENT', assign);
  yield takeLatest('REQUEST_ASSIGNMENT_REGION', assignRegion);
  yield takeLatest('SELECT_STOP', setStep);
  yield takeLatest('HANDLE_ASSIGN_BUTTON_CLICK', handleAssignButtonClick);
}
