const defaultState = {
  isCreateAddressDialogOpen: false,
  loading: {
    openCreateAddressDialog: false,
  },
  errorMessage: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  OPEN_CREATE_ADDRESS_DIALOG: (state) => {
    return {
      ...state,
      isCreateAddressDialogOpen: true,
      loading: {
        ...state.loading,
        openCreateAddressDialog: true,
      },
    };
  },
  CLOSE_CREATE_ADDRESS_DIALOG: (state) => {
    return {
      ...state,
      isCreateAddressDialogOpen: false,
    };
  },
  CLEAR_LOADING_STATE: (state) => {
    return {
      ...state,
      loading: defaultState.loading,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
