const defaultState = {
  currentPath: '/explore',
};
export const init = () => {
  return defaultState;
};
const ACTION_HANDLERS = {
  UPDATE_PAGE: (state, { payload }) => {
    return { currentPath: payload.path };
  },
};
const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
