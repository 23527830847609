import actionTypes from '../sagas/actionTypes';

export const uploadingStatus = Object.freeze({
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  ASSIGNMENT_FAILED: 'assignment_failed',
  API_ERROR: 'api_error',
});

const defaultState = {
  open: false,
  uploadingState: {},
};

const ACTION_HANDLERS = {
  [actionTypes.reset()]: () => {
    return defaultState;
  },
  [actionTypes.updateState()]: (state, { newState }) => {
    return {
      ...state,
      ...newState,
    };
  },
  [actionTypes.updateUploadingState()]: (state, { newState }) => {
    return {
      ...state,
      uploadingState: {
        ...state.uploadingState,
        ...newState,
      },
    };
  },
  [actionTypes.refreshUpload()]: (state) => {
    return {
      ...state,
      uploadingState: {},
    };
  },
};

export const batchAssignmentDialogReducer = {
  batchAssignmentDialog: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
