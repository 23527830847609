import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
}));

const Drawer = ({ children, ...restProps }) => {
  const { open } = restProps;
  const classes = useStyles(restProps);

  return (
    <Collapse in={open} direction="down" mountOnEnter unmountOnExit>
      <div className={clsx(classes.container)}>{children}</div>
    </Collapse>
  );
};

Drawer.defaultProps = {
  open: true,
};

Drawer.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

export default Drawer;
