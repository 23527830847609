import _ from 'lodash-es';

import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_VIEWS } from '@yojee/helpers/constants';

const groupByKey = ({
  task,
  inputStops,
  taskIds,
  groupByStop,
  keys,
  fallback_keys,
  normalized,
  sequencetial,
  idx,
  keepOrder,
}) => {
  const { type, from, to, location, order, order_item, order_step, order_step_id, stop_id, task: inner_task } = task;
  let json_key = keys
    .map((k) => {
      if (k === 'location.lat' || k === 'location.lng') {
        const value = getValue(task, k);
        if (value) {
          return value.toFixed(4);
        }

        return value;
      }
      return getValue(task, k);
    })
    .filter((v) => !!v)
    .join('_');

  json_key = json_key.replace(/ /g, '');
  if (!json_key) {
    json_key = fallback_keys
      .map((k) => getValue(task, k))
      .filter((v) => !!v)
      .join('_');
  }
  if (!groupByStop) {
    json_key = task['id'];
  }
  if (sequencetial) {
    // getAll keys  related current
    const allKeysRelated = Object.keys(inputStops)
      .filter((k) => k.startsWith(json_key))
      .filter((k) => {
        const inputStopByK = inputStops[k];
        if (inputStopByK.sequencetialIdx && inputStopByK.sequencetialIdx.length > 0) {
          const lastIdx = inputStopByK.sequencetialIdx[inputStopByK.sequencetialIdx.length - 1];
          return idx - lastIdx === 1;
        }
        return false;
      });
    if (allKeysRelated.length > 0) {
      json_key = allKeysRelated[0];
    } else {
      json_key = `${json_key}_${idx}`;
    }
  }

  if (keepOrder) {
    const previousKey = `${Object.keys(inputStops).length - 1}|${json_key}`;
    if (inputStops[previousKey]) {
      json_key = `${Object.keys(inputStops).length - 1}|${json_key}`;
    } else {
      json_key = `${Object.keys(inputStops).length}|${json_key}`;
    }
  }

  if (inputStops[json_key]) {
    const stop = { ...inputStops[json_key] };
    stop['tasks'] = stop['tasks'] || [];
    stop['tasks'] = [...new Set(stop['tasks'].concat([normalized ? task.id : task]))];
    stop.count = stop.count + 1;
    stop.sequencetialIdx.push(idx);
    inputStops[json_key] = stop;
  } else {
    inputStops[json_key] = normalized
      ? { id: json_key, tasks: [task.id], sequencetialIdx: [idx !== null ? idx : -1] }
      : {
          sequencetialIdx: [idx !== null ? idx : -1],
          id: json_key,
          completion_time: inner_task?.completion_time,
          sequence_id: inner_task?.sequence_id,
          position: inner_task?.position,
          worker_id: task?.task_group?.worker_id,
          address: order_step && order_step?.address,
          type,
          from,
          to,
          eta: inner_task?.eta,
          order_step_id,
          stop_id,
          key: json_key,
          order,
          order_item,
          order_step,
          location,
          tasks: [task],
          count: 1,
        };
  }
  if (!groupByStop) {
    const keysInputStop = Object.keys(inputStops);
    keysInputStop &&
      keysInputStop.length > 0 &&
      keysInputStop.forEach((k) => {
        const { tasks } = inputStops[k];
        if (tasks && tasks.length > 1) {
          inputStops[k].tasks = _.sortBy(tasks, (t) => {
            return taskIds.indexOf(t);
          });
        }
      });
  }
  return inputStops;
};

const groupTaskToStop = (task, inputStops, taskIds, groupByStop, normalized = false, keepOrder = false) => {
  const fallback_keys = ['order_step_id', 'type', 'eta', 'task.eta'];
  const keys = ['stop_id'];
  return groupByKey({ task, inputStops, taskIds: taskIds, groupByStop, keys, fallback_keys, normalized, keepOrder });
};

export const normalizedTaskToStop_byView = (tasks, ids, viewBy) => {
  if (!ids || !ids.length) return { stopKeys: [], stops: {} };
  let fallback_keys = ['order_step_id', 'type', 'eta', 'task.eta'];
  let keys = ['stop_id'];
  switch (viewBy) {
    case AVAILABLE_VIEWS.STOPS: {
      fallback_keys = ['order_step_id', 'type', 'eta', 'task.eta'];
      keys = ['stop_id'];
      break;
    }
    case AVAILABLE_VIEWS.ITEMS: {
      fallback_keys = undefined;
      keys = ['order_item_id', 'step_group'];
      break;
    }
    case AVAILABLE_VIEWS.TASKS: {
      fallback_keys = undefined;
      keys = ['id'];
      break;
    }
    case AVAILABLE_VIEWS.LOCATION: {
      fallback_keys = ['sender.id', 'type'];
      keys = ['location.lat', 'location.lng', 'type', 'order_step.contact_name', 'task_group.worker_id'];
      break;
    }
    case 'OPTIMISATION_CLUSTERING': {
      fallback_keys = ['sender.id', 'type'];
      keys = ['location.lat', 'location.lng', 'type', 'order_step.contact_name'];
      break;
    }
    default:
  }

  const stopsJson = ids.reduce((oldStops, taskId, idx) => {
    const task = tasks[taskId];
    return groupByKey({
      task,
      inputStops: oldStops,
      taskIds: ids,
      groupByStop: viewBy !== AVAILABLE_VIEWS.TASKS,
      keys,
      fallback_keys,
      normalized: false,
      sequencetial: task.task_group.worker_id && viewBy === AVAILABLE_VIEWS.LOCATION,
      idx,
    });
  }, {});
  let stopKeys = _.keys(stopsJson);
  if (keys.length > 0) {
    stopKeys =
      viewBy === AVAILABLE_VIEWS.TASKS
        ? ids
        : _.sortBy(stopKeys, (key) => {
            return ids.indexOf(stopsJson[key].tasks[0].id);
          });
  }
  const result = { stopKeys: stopKeys ? stopKeys : [], stops: stopsJson ? stopsJson : {} };
  return result;
};

export const normalizedTaskToStop = (tasks, ids, groupByStop = true) => {
  return normalizedTaskToStop_byView(tasks, ids, AVAILABLE_VIEWS.STOPS);
};

export const normalizedTasksToStop_ListView_byView = (tasks, ids, viewBy) => {
  if (!ids || !ids.length) return { stopKeys: [], stops: {} };
  let fallback_keys = ['order_step_id', 'type', 'eta', 'task.eta'];
  let keys = ['stop_id'];
  switch (viewBy) {
    case AVAILABLE_VIEWS.STOPS: {
      fallback_keys = ['order_step_id', 'type', 'eta', 'task.eta'];
      keys = ['stop_id'];
      break;
    }
    case AVAILABLE_VIEWS.ITEMS: {
      fallback_keys = undefined;
      keys = ['order_item_id', 'step_group'];
      break;
    }
    case AVAILABLE_VIEWS.TASKS: {
      fallback_keys = undefined;
      keys = ['id'];
      break;
    }
    default:
  }
  const stopsJson = ids.reduce((oldStops, taskId) => {
    const task = tasks[taskId];
    return groupByKey({
      task,
      inputStops: oldStops,
      taskIds: ids,
      groupByStop: viewBy !== AVAILABLE_VIEWS.TASKS,
      keys,
      fallback_keys,
      normalized: true,
    });
  }, {});
  let stopKeys = _.keys(stopsJson);

  // stop view: preserve order from input
  if (keys.length > 0) {
    stopKeys =
      viewBy === AVAILABLE_VIEWS.TASKS
        ? ids
        : _.sortBy(stopKeys, (key) => {
            return ids.indexOf(stopsJson[key].tasks[0]);
          });
  }
  return { stopKeys: stopKeys, stops: stopsJson };
};

export const tasksToStops = (tasks = [], groupByStop = true, keepOrder = false) => {
  if (!tasks || !tasks.length) return [];
  const ids = tasks.map((t) => t.id);
  const stopsJson = tasks.reduce((oldStops, task) => {
    return groupTaskToStop(task, oldStops, ids, groupByStop, false, keepOrder);
  }, {});
  const result = json2array(stopsJson);
  // stop view: preserve order from input
  if (groupByStop) {
    return _.sortBy(result, (stop) => {
      return ids.indexOf(stop.tasks[0].id);
    });
  }
  return result;
};

const json2array = (j) =>
  Object.keys(j).map(function (_) {
    return j[_];
  });

export const taskTitle = ({
  task,
  isUsingContainer = false,
  isShowExternalCustomerId = false,
  isShowExternalCustomerId2 = false,
  isShowExternalCustomerId3 = false,
}) => {
  let title = '';

  if (isUsingContainer && task?.item?.item_container?.container_no) {
    title = task?.item?.item_container?.container_no;
  } else if (isShowExternalCustomerId && task?.order_item?.external_customer_id) {
    title = task?.order_item?.external_customer_id;
  } else if (isShowExternalCustomerId2 && task?.order_item?.external_customer_id2) {
    title = task?.order_item?.external_customer_id2;
  } else if (isShowExternalCustomerId3 && task?.order_item?.external_customer_id3) {
    title = task?.order_item?.external_customer_id3;
  } else {
    title = task?.order_item?.tracking_number;
  }

  return title;
};
