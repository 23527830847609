import * as _ from 'lodash-es';

import { AVAILABLE_VIEWS } from '@yojee/helpers/constants';
import { normalizedTaskToStop_byView } from '@yojee/helpers/tasksToStops';

const mapDropoffTasks = ({ normalizedItems, task }) => {
  return (
    normalizedItems.stops[`${task.order_item_id}_${task.step_group}`]?.tasks
      ?.filter((t) => t.type === 'dropoff')
      ?.map((t) => {
        const clusteredTask = _.cloneDeep(t);
        clusteredTask.item.volume =
          (clusteredTask.item.volume ? Number(clusteredTask.item.volume) : 0) * clusteredTask.task.quantity;
        clusteredTask.item.weight =
          (clusteredTask.item.weight ? Number(clusteredTask.item.weight) : 0) * clusteredTask.task.quantity;
        return clusteredTask;
      }) || []
  );
};

const createEmptyCluster = ({ task, filteredDropoffTasks }) => {
  return [
    {
      ...task,
      clusteredTasksIds: [task.id],
      clusteredTasks: [task],
      dropOffTasks: filteredDropoffTasks,
      item: {
        ...task.item,
        volumetric_weight: task.item.volumetric_weight || 0,
        volume: task.item.volume ? Number(task.item.volume) : 0,
        weight: task.item.weight ? Number(task.item.weight) : 0,
      },
    },
  ];
};

const addTaskToCluster = ({ task: t, lastTask, filteredDropoffTasks }) => {
  return {
    ...lastTask,
    clusteredTasksIds: [...lastTask.clusteredTasksIds, t.id],
    dropOffTasks: [...lastTask.dropOffTasks, ...filteredDropoffTasks],
    task: {
      quantity: (lastTask.task.quantity || 1) + (t.task.quantity || 1),
    },
    item: {
      ...lastTask.item,
      volumetric_weight: (lastTask.item.volumetric_weight || 0) + (t.item.volumetric_weight || 0),
      volume: (Number(lastTask.item.volume) || 0) + (t.item.volume ? Number(t.item.volume) : 0),
      weight: (Number(lastTask.item.weight) || 0) + (t.item.weight ? Number(t.item.weight) : 0),
    },
    clusteredTasks: [...lastTask.clusteredTasks, t],
  };
};

const removeClusteredDropoffs = ({ task, normalizedItems }) => {
  return (
    task.type !== 'dropoff' ||
    normalizedItems.stops[`${task.order_item_id}_${task.step_group}`]?.tasks?.every((t2) => t2.type !== 'pickup')
  );
};

const applyQuantity = ({ task, quantity }) => {
  task.task.quantity = quantity;
  task.item.volume = (task.item.volume ? Number(task.item.volume) : 0) * quantity;
  task.item.weight = (task.item.weight ? Number(task.item.weight) : 0) * quantity;

  return task;
};

export const clusterTasks = (tasksData, vehicleTypes) => {
  const maxCapacityOfSmallestVehicle = 1; //1 = 100%

  const tasksMap = tasksData.reduce((acc, t) => {
    acc[t.id] = t;
    return acc;
  }, {});
  const tasksIds = tasksData.map((t) => t.id);

  const normalizedTasks = normalizedTaskToStop_byView(tasksMap, tasksIds, 'OPTIMISATION_CLUSTERING');
  const normalizedItems = normalizedTaskToStop_byView(tasksMap, tasksIds, AVAILABLE_VIEWS.ITEMS);

  const minUnit = Math.min(...vehicleTypes.map((v) => parseInt(v.capacity.unit)));
  const minVolume = Math.min(...vehicleTypes.map((v) => parseInt(v.capacity.volume))) * maxCapacityOfSmallestVehicle;
  const minWeight = Math.min(...vehicleTypes.map((v) => parseInt(v.capacity.weight))) * maxCapacityOfSmallestVehicle;

  const clusteredTasks = [].concat(
    ...normalizedTasks.stopKeys.map((key) => {
      const remainingTasks = normalizedTasks.stops[key].tasks.filter((task) =>
        removeClusteredDropoffs({ task, normalizedItems })
      );
      return remainingTasks.reduce((tasks, task) => {
        let t = _.cloneDeep(task);

        const filteredDropoffTasks = mapDropoffTasks({ normalizedItems, task: t });
        const quantity = Math.max(
          ...[1, t.task.quantity || 1, ...filteredDropoffTasks.map((dt) => dt.task.quantity || 1)]
        );

        t = applyQuantity({ task: t, quantity });

        if (tasks.length === 0) {
          return createEmptyCluster({ task: t, filteredDropoffTasks });
        }

        const lastTask = tasks[tasks.length - 1];

        const shouldEndCluster =
          lastTask.task.quantity + t.task.quantity > minUnit ||
          lastTask.item.volume + (t.item.volume ? Number(t.item.volume) : 0) > minVolume ||
          lastTask.item.weight + (t.item.volume ? Number(t.item.weight) : 0) > minWeight;

        if (shouldEndCluster) {
          tasks.push({
            ...t,
            clusteredTasksIds: [t.id],
            clusteredTasks: [t],
            dropOffTasks: filteredDropoffTasks,
          });
        } else {
          tasks[tasks.length - 1] = addTaskToCluster({ lastTask, filteredDropoffTasks, task: t });
        }

        return tasks;
      }, []);
    })
  );

  return [].concat(
    ...clusteredTasks.map((t) => {
      if (t.dropOffTasks.length > 0) {
        const dropOffCluster = {
          ...t.dropOffTasks[0],
          clusteredTasks: t.dropOffTasks,
          clusteredTasksIds: t.dropOffTasks.map((dropOffTask) => dropOffTask.id),
          task: {
            quantity: t.task.quantity,
          },
          item: {
            ...t.dropOffTasks[0].item,
            volumetric_weight: t.item.volumetric_weight,
            volume: t.item.volume,
            weight: t.item.weight,
          },
          pickupTaskId: t.type === 'dropoff' ? null : t.id,
        };

        delete t.dropOffTasks;
        if (t.type === 'dropoff') {
          return [dropOffCluster];
        }

        return [t, dropOffCluster];
      } else {
        console.error('PU without DO', t);
        delete t.dropOffTasks;
        return [t];
      }
    })
  );
};
