import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import i18n from '@yojee/helpers/i18nLoader';
import { updateMomentLocaleForRelativeTime } from '@yojee/helpers/TimeHelper';

import App from './App';
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';

export const { store, history } = configureStore();

updateMomentLocaleForRelativeTime();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({ scope: '/assign/scan' });
