import getEnv from '@yojee/helpers/env/getEnv';

import { default as axios } from './common/axios';
import { getHeaders } from './common/common';

const API_URL = getEnv('REACT_APP_API_URL');

export function fetchJWT({ token, slug, partnerJwt }) {
  return axios.post(`${API_URL}/dispatcher/jwt`, {}, { headers: getHeaders({ token, slug, partnerJwt }) });
}
