import React from 'react';
import { Trans } from 'react-i18next';
import { createSelector } from 'reselect';

import NotificationContent from './NotificationContent';
const TRANSFER_FAILED_CODE_TYPE = {
  notMapServiceType: 'not_map_service_type',
};

const NOTIFICATION_TYPES = {
  DRIVER_REPORTED_TASK: 'driver_reported_task',
  DRIVER_REJECTED_TASK_GROUP: 'driver_rejected_task_group',
  PARTNER_REPORTED_ITEM: 'partner_reported_item',
  PARTNER_REJECTED_TRANSFER: 'partner_rejected_transfer',
  PARTNER_ACCEPTED_TRANSFER: 'partner_accepted_transfer',
  COMPANY_CREATED_TRANSFER: 'company_created_transfer',
  COMPANY_WITHDREW_TRANSFER: 'company_withdrew_transfer',
  COMPANY_SENT_PARTNERSHIP_INVITATION: 'company_sent_partnership_invitation',
  COMPANY_ACCEPTED_PARTNERSHIP_INVITATION: 'company_accepted_partnership_invitation',
  COMPANY_REJECTED_PARTNERSHIP_INVITATION: 'company_rejected_partnership_invitation',
  BROADCAST_TIMEOUT: 'broadcast_timeout',
  ORDER_CREATED: 'order_created',
  ORDER_ACCEPTED: 'order_accepted',
  BULK_ORDER_STEPS_UPDATED: 'bulk_order_steps_updated',
  ACCEPTED_AUTOMATICALLY_TRANSFERRED_ORDERS: 'accepted_automatically_transferred_orders',
  ORDER_GROUP_CREATED: 'order_group_created',
  COMPANY_CREATED_TRANSFER_FOR_BATCH: 'company_created_transfer_for_batch',
  SERVICE_TYPE_MAPPING_CREATED: 'service_type_mapping_created',
  SERVICE_TYPE_MAPPING_UPDATED: 'service_type_mapping_updated',
  SERVICE_TYPE_MAPPING_DELETED: 'service_type_mapping_deleted',
  SERVICE_TYPE_MAPPING_INVALIDATED: 'service_type_mapping_invalidated',
  SERVICE_TYPE_UPDATED: 'service_type_updated',
  BULK_EXPORT_PODS_COMPLETED: 'bulk_export_pods_completed',
  BULK_EXPORT_PODS_FAILED: 'bulk_export_pods_failed',
  BULK_EXPORT_PODS_TRIGGERED: 'bulk_export_pods_triggered',
  TRANSFER_VIA_EXT_PARTNER_REF_FAILED: 'transfer_via_ext_partner_ref_failed',
  TRANSFER_FAILED: 'transfer_failed',
  ORDER_CANCELLED: 'order_cancelled',
  ORDER_PARTIALLY_CANCELLED: 'order_partially_cancelled',
  ORDER_COMPLETED_BY_UPSTREAM: 'orders_completed_by_upstream',
};
// selector
const getState = (state) => state.notification;

export const getNotificationState = createSelector([getState], (notificationState) => {
  notificationState.data.sort(function (a, b) {
    return new Date(b.published_at) - new Date(a.published_at);
  });
  return {
    ...notificationState,
    data: notificationState.data.map(transformNotification),
    pagination: {
      ...notificationState.pagination,
      start_id: notificationState.data.length > 0 ? notificationState.data[notificationState.data.length - 1].id : null,
      hasMore: notificationState.pagination.total_count >= notificationState.pagination.limit_value,
    },
  };
});

export const uniqueNotification = (array) => {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id) a.splice(j--, 1);
    }
  }
  return a;
};

const transformNotification = (data) => {
  return { ...genDisplayItem(data), noti_action_payload: genNotiActionPayLoad(data) };
};

const genNotiActionPayLoad = (notification) => {
  switch (notification.type) {
    case NOTIFICATION_TYPES.DRIVER_REPORTED_TASK: {
      return {
        notification_type: notification.type,
        payload: {
          order_item_ids: notification.object.id, // object.type = order_item
        },
      };
    }
    case NOTIFICATION_TYPES.DRIVER_REJECTED_TASK_GROUP: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          associated_task_group_ids: notification.object.id, // object.type = task_group
        },
      };
    }
    case NOTIFICATION_TYPES.PARTNER_REPORTED_ITEM: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_item_tracking_number: notification.object.id, // object.type = pick_up_item
        },
      };
    }
    case NOTIFICATION_TYPES.BROADCAST_TIMEOUT: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_item_ids: notification.object.order_item_ids, // object.type = taskgroup
        },
      };
    }
    case NOTIFICATION_TYPES.COMPANY_CREATED_TRANSFER:
    case NOTIFICATION_TYPES.COMPANY_WITHDREW_TRANSFER:
    case NOTIFICATION_TYPES.PARTNER_ACCEPTED_TRANSFER:
    case NOTIFICATION_TYPES.ORDER_CREATED:
    case NOTIFICATION_TYPES.ORDER_ACCEPTED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_number: notification.object.id,
        },
      };
    }
    case NOTIFICATION_TYPES.PARTNER_REJECTED_TRANSFER: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_number: notification.object.id,
          cancellation_notes: notification.cancellation_notes,
        },
      };
    }

    case NOTIFICATION_TYPES.ORDER_CANCELLED:
    case NOTIFICATION_TYPES.ORDER_PARTIALLY_CANCELLED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_number: notification.object.id,
          cancellation_notes: notification.cancellation_notes,
        },
      };
    }
    case NOTIFICATION_TYPES.COMPANY_SENT_PARTNERSHIP_INVITATION:
    case NOTIFICATION_TYPES.COMPANY_REJECTED_PARTNERSHIP_INVITATION:
    case NOTIFICATION_TYPES.COMPANY_ACCEPTED_PARTNERSHIP_INVITATION: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
        },
      };
    }
    case NOTIFICATION_TYPES.BULK_ORDER_STEPS_UPDATED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
        },
      };
    }
    case NOTIFICATION_TYPES.ACCEPTED_AUTOMATICALLY_TRANSFERRED_ORDERS: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_numbers: notification.object.order_numbers,
        },
      };
    }
    case NOTIFICATION_TYPES.ORDER_GROUP_CREATED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          batch_id: notification.object.id,
        },
      };
    }
    case NOTIFICATION_TYPES.COMPANY_CREATED_TRANSFER_FOR_BATCH: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_numbers: notification.object.order_numbers,
          batch_id: notification.object.id,
        },
      };
    }

    case NOTIFICATION_TYPES.TRANSFER_VIA_EXT_PARTNER_REF_FAILED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_number: notification.object.order_number,
          ext_partner_ref: notification.object.ext_partner_ref,
        },
      };
    }

    case NOTIFICATION_TYPES.TRANSFER_FAILED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          partner_company_id: notification.object.partner_company_id,
        },
      };
    }
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_CREATED:
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_UPDATED:
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_DELETED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          partner_company_id: notification.object.upstream_company_id,
          isDefaultMapping: !notification.object.upstream_service_type_name,
        },
      };
    }
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_INVALIDATED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          partner_company_id: notification.object.downstream_company_id,
        },
      };
    }
    case NOTIFICATION_TYPES.SERVICE_TYPE_UPDATED: {
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          partner_company_id: notification.object.partner_company_id,
        },
      };
    }

    case NOTIFICATION_TYPES.BULK_EXPORT_PODS_COMPLETED:
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          download_url: notification.object.download_url,
        },
      };
    case NOTIFICATION_TYPES.ORDER_COMPLETED_BY_UPSTREAM:
      return {
        notification_type: notification.type,
        payload: {
          id: notification.id,
          order_number: notification.object.id,
        },
      };

    default:
      return undefined;
  }
};

const genDisplayItem = (data) => {
  if (!data) {
    return {};
  }
  switch (data.type) {
    case NOTIFICATION_TYPES.DRIVER_REPORTED_TASK:
      return transformDriverReportedTask(data);
    case NOTIFICATION_TYPES.DRIVER_REJECTED_TASK_GROUP:
      return transformDriverRejectedTaskGroup(data);
    case NOTIFICATION_TYPES.PARTNER_REPORTED_ITEM:
      return transformPartnerReportedItem(data);
    case NOTIFICATION_TYPES.PARTNER_REJECTED_TRANSFER:
      return transformPartnerRejectedTransfer(data);
    case NOTIFICATION_TYPES.PARTNER_ACCEPTED_TRANSFER:
      return transformPartnerAcceptedTransfer(data);
    case NOTIFICATION_TYPES.COMPANY_CREATED_TRANSFER:
      return transformCompanyTransferResult(data, 'transferred');
    case NOTIFICATION_TYPES.COMPANY_WITHDREW_TRANSFER:
      return transformCompanyTransferResult(data, 'withdrew');
    case NOTIFICATION_TYPES.ORDER_CANCELLED:
      return transformCancelledOrderResult(data, 'cancelled');
    case NOTIFICATION_TYPES.ORDER_PARTIALLY_CANCELLED:
      return transformCancelledOrderResult(data, 'cancelled');
    case NOTIFICATION_TYPES.COMPANY_SENT_PARTNERSHIP_INVITATION:
      return transformCompanySentPartnershipInvitation(data);
    case NOTIFICATION_TYPES.COMPANY_ACCEPTED_PARTNERSHIP_INVITATION:
      return transformCompanyAcceptedPartnershipInvitation(data);
    case NOTIFICATION_TYPES.COMPANY_REJECTED_PARTNERSHIP_INVITATION:
      return transformCompanyRejectedPartnershipInvitation(data);
    case NOTIFICATION_TYPES.BROADCAST_TIMEOUT:
      return transformBroadcastTimeout(data);
    case NOTIFICATION_TYPES.ORDER_CREATED:
    case NOTIFICATION_TYPES.ORDER_ACCEPTED:
      return transformOrderCreated(data);
    case NOTIFICATION_TYPES.BULK_ORDER_STEPS_UPDATED:
      return transformBulkOrderStepsUpdated(data);
    case NOTIFICATION_TYPES.ACCEPTED_AUTOMATICALLY_TRANSFERRED_ORDERS:
      return transformAcceptedAutomaticallyTransferredOrders(data);
    case NOTIFICATION_TYPES.ORDER_GROUP_CREATED:
      return transformBatchFileCreated(data);
    case NOTIFICATION_TYPES.COMPANY_CREATED_TRANSFER_FOR_BATCH:
      return transformCompanyCreatedTransferForBatch(data);
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_CREATED:
      return transformServiceTypeMappingCreated(data);
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_UPDATED:
      return transformServiceTypeMappingUpdated(data);
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_DELETED:
      return transformServiceTypeMappingDeleted(data);
    case NOTIFICATION_TYPES.SERVICE_TYPE_MAPPING_INVALIDATED:
      return transformServiceTypeMappingInvalidated(data);
    case NOTIFICATION_TYPES.SERVICE_TYPE_UPDATED:
      return transformServiceTypeUpdated(data);
    case NOTIFICATION_TYPES.BULK_EXPORT_PODS_COMPLETED:
      return bulkExportPodsCompleted(data);
    case NOTIFICATION_TYPES.BULK_EXPORT_PODS_FAILED:
      return bulkExportPodsFailed(data);
    case NOTIFICATION_TYPES.BULK_EXPORT_PODS_TRIGGERED:
      return bulkExportPodsTriggered(data);
    case NOTIFICATION_TYPES.TRANSFER_VIA_EXT_PARTNER_REF_FAILED:
      return transformTransferViaExtPartnerRefFailed(data);
    case NOTIFICATION_TYPES.TRANSFER_FAILED:
      return transformTransferFailed(data);
    case NOTIFICATION_TYPES.ORDER_COMPLETED_BY_UPSTREAM:
      return transformOrderCompletedByUpstream(data);
    default:
      return {};
  }
};

const removeSpecialCharacterFromBeginning = (str, charToRemove) => {
  if (str.charAt(0) === charToRemove) {
    return str.substring(1);
  }
  return str;
};

const transformDriverReportedTask = (data) => {
  const { id, actor, object, unread, published_at } = data;
  const name = removeSpecialCharacterFromBeginning(object.name, '#');
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="driverReported" values={{ driverName: actor.name }} />}
        identification_number={name}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformDriverRejectedTaskGroup = (data) => {
  const { id, actor, object, unread, published_at } = data;
  const name = removeSpecialCharacterFromBeginning(object.name, '#');
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="driverRejectedTaskGroup" values={{ driverName: actor.name }} />}
        identification_number={name}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformPartnerReportedItem = (data) => {
  const { id, actor, object, unread, published_at } = data;
  const name = removeSpecialCharacterFromBeginning(object.name, '#');
  let _taskType;
  switch (object.type) {
    case 'pick_up_item':
      _taskType = `Pickup of Item`;
      break;
    case 'drop_off_item':
      _taskType = `Dropoff of Item`;
      break;
    default:
  }

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="partnerReported" values={{ partnerName: actor.name, taskType: _taskType }} />}
        identification_number={name}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const renderMessageForRejection = (partnerName, cancellationNotes) => {
  if (cancellationNotes) {
    return (
      <Trans
        i18nKey="partnerRejectedWithReason"
        values={{ partnerName: partnerName, cancellationNote: cancellationNotes }}
      />
    );
  } else {
    return <Trans i18nKey="partnerRejected" values={{ partnerName: partnerName }} />;
  }
};

const transformPartnerRejectedTransfer = (data) => {
  const {
    id,
    actor: { name },
    object: { id: externalId, name: orderNumber, cancellation_notes: cancellationNotes },
    unread,
    published_at,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(orderNumber, '#');
  const message = renderMessageForRejection(name, cancellationNotes);
  return {
    id,
    message: (
      <NotificationContent
        message={message}
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};

const transformPartnerAcceptedTransfer = (data) => {
  const {
    id,
    actor: { name },
    object: { id: externalId, name: orderNumber },
    unread,
    published_at,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(orderNumber, '#');
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="partnerAccepted" values={{ partnerName: name }} />}
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};

const transformCompanyTransferResult = (data, type) => {
  const {
    id,
    actor,
    object: { id: externalId, name: orderNumber, type: objectType },
    unread,
    published_at,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(orderNumber, '#');
  const transferType = objectType === 'order' ? 'Order' : undefined;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="companyTransferResult"
            values={{ partnerName: actor.name, transferType: transferType, type: type }}
          />
        }
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};

const renderMessageForCompanyCancellationWithReason = (cancellationNote) => {
  return <Trans i18nKey="companyCancelledWithReason" values={{ cancellationNote: cancellationNote }} />;
};

const renderMessageForCompanyPartialCancellationWithReason = (cancellationNote) => {
  return <Trans i18nKey="companyPartiallyCancelledWithReason" values={{ cancellationNote: cancellationNote }} />;
};

const renderMessageForCompanyCancellationWithoutReason = () => {
  return <Trans i18nKey="companyCancelledWithoutReason" />;
};

const renderMessageForCompanyPartialCancellationWithoutReason = () => {
  return <Trans i18nKey="companyPartiallyCancelledWithoutReason" />;
};

const renderMessageForPartnerCancellationWithReason = (partnerName, cancellationNote) => {
  return (
    <Trans
      i18nKey="partnerCancelledWithReason"
      values={{ partnerName: partnerName, cancellationNote: cancellationNote }}
    />
  );
};

const renderMessageForPartnerPartialCancellationWithReason = (partnerName, cancellationNote) => {
  return (
    <Trans
      i18nKey="partnerPartiallyCancelledWithReason"
      values={{ partnerName: partnerName, cancellationNote: cancellationNote }}
    />
  );
};

const renderMessageForPartnerCancellationWithOutReason = (partnerName) => {
  return <Trans i18nKey="partnerCancelledWithoutReason" values={{ partnerName: partnerName }} />;
};

const renderMessageForPartnerPartialCancellationWithOutReason = (partnerName) => {
  return <Trans i18nKey="partnerPartiallyCancelledWithoutReason" values={{ partnerName: partnerName }} />;
};

const renderMessage = (partnerName, orderNumber, cancellationNotes, actor, action) => {
  if (action === 'order_cancelled' && actor === 'company' && cancellationNotes) {
    return renderMessageForCompanyCancellationWithReason(cancellationNotes);
  } else if (action === 'order_cancelled' && actor === 'company' && !cancellationNotes) {
    return renderMessageForCompanyCancellationWithoutReason(cancellationNotes);
  } else if (action === 'order_cancelled' && actor === 'partner' && cancellationNotes) {
    return renderMessageForPartnerCancellationWithReason(partnerName, cancellationNotes);
  } else if (action === 'order_cancelled' && actor === 'partner' && !cancellationNotes) {
    return renderMessageForPartnerCancellationWithOutReason(partnerName);
  } else if (action === 'order_partially_cancelled' && actor === 'company' && cancellationNotes) {
    return renderMessageForCompanyPartialCancellationWithReason(cancellationNotes);
  } else if (action === 'order_partially_cancelled' && actor === 'company' && !cancellationNotes) {
    return renderMessageForCompanyPartialCancellationWithoutReason();
  } else if (action === 'order_partially_cancelled' && actor === 'partner' && cancellationNotes) {
    return renderMessageForPartnerPartialCancellationWithReason(partnerName, cancellationNotes);
  } else if (action === 'order_partially_cancelled' && actor === 'partner' && !cancellationNotes) {
    return renderMessageForPartnerPartialCancellationWithOutReason(partnerName);
  }
};

const transformCancelledOrderResult = (data) => {
  const {
    id,
    actor: { name, type },
    object: { id: externalId, name: orderNumber, cancellation_notes: cancellationNotes },
    unread,
    published_at,
    type: action,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(orderNumber, '#');
  const message = renderMessage(name, orderNumber, cancellationNotes, type, action);

  return {
    id,
    message: (
      <NotificationContent
        message={message}
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};

const transformCompanySentPartnershipInvitation = (data) => {
  const { id, actor, unread, published_at } = data;
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="companySentPartnershipInvitation" values={{ companyName: actor.name }} />}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformCompanyAcceptedPartnershipInvitation = (data) => {
  const {
    id,
    actor: { name },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="companyAcceptedPartnershipInvitation" values={{ companyName: name }} />}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformCompanyRejectedPartnershipInvitation = (data) => {
  const {
    id,
    actor: { name },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="companyRejectedPartnershipInvitation" values={{ companyName: name }} />}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformBroadcastTimeout = (data) => {
  const {
    id,
    object: { name },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="broadcastTimeout" values={{ name: name }} />}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformOrderCreated = (data) => {
  const {
    id,
    object: { name, id: externalId },
    unread,
    published_at,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(name, '#');
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="createdOrder" />}
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};

const transformBulkOrderStepsUpdated = (data) => {
  const {
    id,
    object: { order_step_ids = [] },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="bulkOrderStepsUpdated" values={{ numberOfItems: order_step_ids.length }} />}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformAcceptedAutomaticallyTransferredOrders = (data) => {
  const {
    id,
    object: { upstream_company_name, order_numbers = [] },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="acceptedTransferAutomatically"
            values={{ numberOfOrders: order_numbers.length, upstreamCompanyName: upstream_company_name }}
          />
        }
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformBatchFileCreated = (data) => {
  const {
    id,
    object: { id: batchId },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="batchOrderCreated" />}
        identification_number={batchId}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformCompanyCreatedTransferForBatch = (data) => {
  const {
    id,
    object: { id: batchId, order_numbers = [] },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="transferredOrders"
            values={{ partnerName: partnerName, numberOfOrders: order_numbers.length }}
          />
        }
        identification_number={batchId}
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformServiceTypeMappingCreated = (data) => {
  const {
    id,
    object: { upstream_service_type_name, downstream_service_type_name },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;

  let message = '';
  if (upstream_service_type_name) {
    message = (
      <NotificationContent
        message={
          <Trans
            i18nKey="serviceTypeMapping"
            values={{
              partnerName: partnerName,
              usServiceType: upstream_service_type_name,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    );
  } else {
    message = (
      <NotificationContent
        message={
          <Trans
            i18nKey="defaultServiceTypeMapping"
            values={{
              partnerName: partnerName,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    );
  }

  return {
    id,
    message,
    unread,
    published_at,
  };
};

const transformServiceTypeMappingUpdated = (data) => {
  const {
    id,
    object: { upstream_service_type_name, downstream_service_type_name },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;
  let message = '';
  if (upstream_service_type_name) {
    message = (
      <NotificationContent
        message={
          <Trans
            i18nKey="updatedServiceTypeMapping"
            values={{
              partnerName: partnerName,
              usServiceType: upstream_service_type_name,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    );
  } else {
    message = (
      <NotificationContent
        message={
          <Trans
            i18nKey="defaultServiceTypeMappingUpdate"
            values={{
              partnerName: partnerName,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    );
  }

  return {
    id,
    message,
    unread,
    published_at,
  };
};

const transformServiceTypeMappingDeleted = (data) => {
  const {
    id,
    object: { upstream_service_type_name, downstream_service_type_name },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="serviceTypeMappingDelete"
            values={{
              partnerName: partnerName,
              usServiceType: upstream_service_type_name,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformServiceTypeMappingInvalidated = (data) => {
  const {
    id,
    object: { downstream_service_type_name },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="removedServiceType"
            values={{
              partnerName: partnerName,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformServiceTypeUpdated = (data) => {
  const {
    id,
    object: { upstream_service_type_name, downstream_service_type_name },
    actor: { name: partnerName },
    unread,
    published_at,
  } = data;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="updatedServiceType"
            values={{
              partnerName: partnerName,
              usServiceType: upstream_service_type_name,
              dsServiceType: downstream_service_type_name,
            }}
          />
        }
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const bulkExportPodsCompleted = (data) => {
  const { id, unread, published_at } = data;

  const message = <Trans>Multiple POD archive available</Trans>;

  return {
    id,
    message: <NotificationContent message={message} published_at={published_at} />,
    unread,
    published_at,
  };
};

const bulkExportPodsFailed = (data) => {
  const { id, unread, published_at } = data;

  const message = <Trans>Multiple POD archive failed</Trans>;

  return {
    id,
    message: <NotificationContent message={message} published_at={published_at} />,
    unread,
    published_at,
  };
};

const bulkExportPodsTriggered = (data) => {
  const { id, unread, published_at } = data;

  const message = <Trans>POD generation started</Trans>;

  return {
    id,
    message: <NotificationContent message={message} published_at={published_at} />,
    unread,
    published_at,
  };
};

const transformTransferViaExtPartnerRefFailed = (data) => {
  const {
    id,
    object: { ext_partner_ref: extPartnerRef, reason },
    unread,
    published_at,
  } = data;

  let message = null;

  switch (reason) {
    case 'ext_partner_ref_not_found':
      message = (
        <Trans i18nKey="transferViaExtPartnerRef_RefNotFoundError" values={{ extPartnerRef }}>
          {`Transfer failed. Partner reference ${extPartnerRef} not found`}
        </Trans>
      );
      break;
    default:
      message = (
        <Trans i18nKey="transferViaExtPartnerRef_UnidentifiedError" values={{ extPartnerRef }}>
          {`Transfer to ${extPartnerRef} failed`}
        </Trans>
      );
  }

  return {
    id,
    message: <NotificationContent message={message} published_at={published_at} />,
    unread,
    published_at,
  };
};

const transformTransferFailed = (data) => {
  const {
    id,
    object: { order_number, external_id, partner_company_name, reason = {} },
    unread,
    published_at,
  } = data;

  let reasonText = <span />;
  const { code, metadata = {} } = reason;
  if (code === TRANSFER_FAILED_CODE_TYPE.notMapServiceType) {
    if (metadata.service_type_name) {
      reasonText = (
        <span>
          service type <strong>{metadata.service_type_name}</strong> has not been mapped
        </span>
      );
    } else {
      reasonText = <span>this order doesn't have a service type</span>;
    }
  }
  const orderId = external_id || order_number;

  return {
    id,
    message: (
      <NotificationContent
        message={
          <Trans
            i18nKey="transferFailed"
            values={{
              partnerName: partner_company_name,
              orderId: orderId,
              reasonText: reasonText,
            }}
          />
        }
        published_at={published_at}
      />
    ),
    unread,
    published_at,
  };
};

const transformOrderCompletedByUpstream = (data) => {
  const {
    id,
    actor: { name },
    object: { id: externalId, name: orderNumber },
    unread,
    published_at,
  } = data;
  const idNumber = removeSpecialCharacterFromBeginning(orderNumber, '#');
  return {
    id,
    message: (
      <NotificationContent
        message={<Trans i18nKey="orderCompletedByUpstream" values={{ partnerName: name }} />}
        identification_number={externalId}
        published_at={published_at}
        external_id={idNumber}
      />
    ),
    unread,
    published_at,
  };
};
