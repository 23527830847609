import { call, put, select, takeLatest } from 'redux-saga/effects';

import transferAPI from '../../Api/transferAPI';

const getTransferData = (state) => state.transferTasks;

export default function* sagas() {
  yield takeLatest('TRANSFER_TASKS_TO_PARTNER', transferTasksToPartner);
}

function* transferTasksToPartner() {
  try {
    const { partner, selectedTasks, serviceTypeId } = yield select(getTransferData);
    if (selectedTasks?.length < 1) {
      return;
    }
    const uniqueSelectedTrackingNumbers = [...new Set(selectedTasks.map((task) => task?.order_item?.tracking_number))];

    yield put({ type: 'TRANSFER_TASKS_TO_PARTNER_START' });
    const requestData = { partner_cip: partner.cip, tracking_numbers: uniqueSelectedTrackingNumbers };
    if (serviceTypeId) {
      requestData['service_type_mapping'] = uniqueSelectedTrackingNumbers.reduce((prev, curr) => {
        prev[curr] = serviceTypeId;
        return prev;
      }, {});
    }

    yield call(transferAPI.transferByTrackingNumbers, requestData);
    yield put({ type: 'TRANSFER_TASKS_TO_PARTNER_SUCCESS' });
    yield put({ type: 'DESELECT_ALL_STOPS' });
    yield put({ type: 'UPDATE_CURRENT_RIGHT_VIEW', newView: 'DRIVERS' });
    yield put({ type: 'DISPLAY_MESSAGE', message: 'Selected tasks has been transferred', variant: 'success' });
    yield put({ type: 'REFRESH' });
  } catch (ex) {
    yield put({ type: 'TRANSFER_TASKS_TO_PARTNER_ERROR', message: ex.message });
  }
}
