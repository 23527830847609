export interface ItemTypeLabel {
  id: number;
  is_default: boolean;
  name: string;
}

export interface Organisation {
  id: number;
  name: string;
}

export interface ItemType {
  billing_name?: string;
  id: number;
  label: ItemTypeLabel;
  name: string;
  organisations: Organisation[];
  type: string;
}

export const ITEM_TYPE_CATEGORY = {
  container: 'container',
};
