const defaultState = {
  // Not used at the moment
  instanceId: null,
  workerId: null,
  startTaskId: null,
  endTaskId: null,
  etaZoom: null,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  HIGHLIGHT_TIMELINE_ROUTE: (state, { params: { instanceId, workerId, startTaskId, endTaskId } }) => {
    return { ...state, instanceId, workerId, startTaskId, endTaskId };
  },
  SET_INSTANCE_ID: (state, { params: { instanceId } }) => {
    return { ...state, instanceId };
  },
  REVERT_HIGHLIGHT: (state) => {
    return {
      ...state,
      instanceId: null,
      workerId: null,
      startTaskId: null,
      endTaskId: null,
    };
  },
  TIMELINE_ETA_ZOOM_SPECIFIC: (state, { params: etaZoom }) => {
    return { ...state, etaZoom };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
