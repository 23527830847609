const CountryBounds = {
  AW: { sw: { lat: 12.1702998, lng: -70.2809842 }, ne: { lat: 12.8102998, lng: -69.6409842 } },
  AF: { sw: { lat: 29.3772, lng: 60.5176034 }, ne: { lat: 38.4910682, lng: 74.889862 } },
  AO: { sw: { lat: -18.038945, lng: 11.4609793 }, ne: { lat: -4.3880634, lng: 24.0878856 } },
  AI: { sw: { lat: 18.0615454, lng: -63.6391992 }, ne: { lat: 18.7951194, lng: -62.7125449 } },
  AX: { sw: { lat: 59.4541578, lng: 19.0832098 }, ne: { lat: 60.87665, lng: 21.3456556 } },
  AL: { sw: { lat: 39.6448625, lng: 19.1246095 }, ne: { lat: 42.6610848, lng: 21.0574335 } },
  AD: { sw: { lat: 42.4288238, lng: 1.4135781 }, ne: { lat: 42.6559357, lng: 1.7863837 } },
  AN: { sw: { lat: 12.1544542, lng: -68.940593 }, ne: { lat: 12.1547472, lng: -68.9403518 } },
  AE: { sw: { lat: 22.6444, lng: 51.498 }, ne: { lat: 26.2822, lng: 56.3834 } },
  AR: { sw: { lat: -55.1850761, lng: -73.5600329 }, ne: { lat: -21.781168, lng: -53.6374515 } },
  AM: { sw: { lat: 38.8404775, lng: 43.4471395 }, ne: { lat: 41.300712, lng: 46.6333087 } },
  AS: { sw: { lat: -14.7608358, lng: -171.2951296 }, ne: { lat: -10.8449746, lng: -167.9322899 } },
  AQ: { sw: { lat: -85.0511287, lng: -180.0 }, ne: { lat: -60.0, lng: 180.0 } },
  TF: { sw: { lat: -50.2187169, lng: 39.4138676 }, ne: { lat: -11.3139928, lng: 77.8494974 } },
  AG: { sw: { lat: 16.7573901, lng: -62.5536517 }, ne: { lat: 17.929, lng: -61.447857 } },
  AU: { sw: { lat: -55.3228175, lng: 72.2460938 }, ne: { lat: -9.0882278, lng: 168.2249543 } },
  AT: { sw: { lat: 46.3722761, lng: 9.5307487 }, ne: { lat: 49.0205305, lng: 17.160776 } },
  AZ: { sw: { lat: 38.3929551, lng: 44.7633701 }, ne: { lat: 41.9502947, lng: 51.0090302 } },
  BI: { sw: { lat: -4.4693155, lng: 29.0007401 }, ne: { lat: -2.3096796, lng: 30.8498462 } },
  BE: { sw: { lat: 49.4969821, lng: 2.3889137 }, ne: { lat: 51.5516667, lng: 6.408097 } },
  BJ: { sw: { lat: 6.0398696, lng: 0.776667 }, ne: { lat: 12.4092447, lng: 3.843343 } },
  BF: { sw: { lat: 9.4104718, lng: -5.5132416 }, ne: { lat: 15.084, lng: 2.4089717 } },
  BD: { sw: { lat: 20.3756582, lng: 88.0075306 }, ne: { lat: 26.6382534, lng: 92.6804979 } },
  BG: { sw: { lat: 41.2353929, lng: 22.3571459 }, ne: { lat: 44.2167064, lng: 28.8875409 } },
  BH: { sw: { lat: 25.535, lng: 50.2697989 }, ne: { lat: 26.6872444, lng: 50.9233693 } },
  BS: { sw: { lat: 20.7059846, lng: -80.7001941 }, ne: { lat: 27.4734551, lng: -72.4477521 } },
  BA: { sw: { lat: 42.5553114, lng: 15.7287433 }, ne: { lat: 45.2764135, lng: 19.6237311 } },
  BL: { sw: { lat: 17.670931, lng: -63.06639 }, ne: { lat: 18.1375569, lng: -62.5844019 } },
  BY: { sw: { lat: 51.2575982, lng: 23.1783344 }, ne: { lat: 56.17218, lng: 32.7627809 } },
  BZ: { sw: { lat: 15.8857286, lng: -89.2262083 }, ne: { lat: 18.496001, lng: -87.3098494 } },
  BM: { sw: { lat: 32.0469651, lng: -65.1232222 }, ne: { lat: 32.5913693, lng: -64.4109842 } },
  BO: { sw: { lat: -22.8982742, lng: -69.6450073 }, ne: { lat: -9.6689438, lng: -57.453 } },
  BR: { sw: { lat: -33.8689056, lng: -73.9830625 }, ne: { lat: 5.2842873, lng: -28.6341164 } },
  BB: { sw: { lat: 12.845, lng: -59.8562115 }, ne: { lat: 13.535, lng: -59.2147175 } },
  BN: { sw: { lat: 4.002508, lng: 114.0758734 }, ne: { lat: 5.1011857, lng: 115.3635623 } },
  BT: { sw: { lat: 26.702016, lng: 88.7464724 }, ne: { lat: 28.246987, lng: 92.1252321 } },
  BV: { sw: { lat: -54.654, lng: 2.9345531 }, ne: { lat: -54.187, lng: 3.7791099 } },
  BW: { sw: { lat: -26.9059669, lng: 19.9986474 }, ne: { lat: -17.778137, lng: 29.375304 } },
  CF: { sw: { lat: 2.2156553, lng: 14.4155426 }, ne: { lat: 11.001389, lng: 27.4540764 } },
  CA: { sw: { lat: 41.6765556, lng: -141.00275 }, ne: { lat: 83.3362128, lng: -52.3231981 } },
  CC: { sw: { lat: -12.4055983, lng: 96.612524 }, ne: { lat: -11.6213132, lng: 97.1357343 } },
  CH: { sw: { lat: 45.817995, lng: 5.9559113 }, ne: { lat: 47.8084648, lng: 10.4922941 } },
  CL: { sw: { lat: -56.725, lng: -109.6795789 }, ne: { lat: -17.4983998, lng: -66.0753474 } },
  CN: { sw: { lat: 8.8383436, lng: 73.4997347 }, ne: { lat: 53.5608154, lng: 134.7754563 } },
  CI: { sw: { lat: 4.1621205, lng: -8.601725 }, ne: { lat: 10.740197, lng: -2.493031 } },
  CM: { sw: { lat: 1.6546659, lng: 8.3822176 }, ne: { lat: 13.083333, lng: 16.1921476 } },
  CD: { sw: { lat: -13.459035, lng: 12.039074 }, ne: { lat: 5.3920026, lng: 31.3056758 } },
  CG: { sw: { lat: -5.149089, lng: 11.0048205 }, ne: { lat: 3.713056, lng: 18.643611 } },
  CK: { sw: { lat: -22.15807, lng: -166.0856468 }, ne: { lat: -8.7168792, lng: -157.1089329 } },
  CO: { sw: { lat: -4.2316872, lng: -82.1243666 }, ne: { lat: 16.0571269, lng: -66.8511907 } },
  KM: { sw: { lat: -12.621, lng: 43.025305 }, ne: { lat: -11.165, lng: 44.7451922 } },
  CV: { sw: { lat: 14.8031546, lng: -25.3609478 }, ne: { lat: 17.2053108, lng: -22.6673416 } },
  CR: { sw: { lat: 5.3329698, lng: -87.2722647 }, ne: { lat: 11.2195684, lng: -82.5060208 } },
  CU: { sw: { lat: 19.6275294, lng: -85.1679702 }, ne: { lat: 23.4816972, lng: -73.9190004 } },
  CX: { sw: { lat: -10.5698515, lng: 105.5336422 }, ne: { lat: -10.4123553, lng: 105.7130159 } },
  KY: { sw: { lat: 19.0620619, lng: -81.6313748 }, ne: { lat: 19.9573759, lng: -79.5110954 } },
  CY: { sw: { lat: 34.4383706, lng: 32.0227581 }, ne: { lat: 35.913252, lng: 34.8553182 } },
  CZ: { sw: { lat: 48.5518083, lng: 12.0905901 }, ne: { lat: 51.0557036, lng: 18.859216 } },
  DE: { sw: { lat: 47.2701114, lng: 5.8663153 }, ne: { lat: 55.099161, lng: 15.0419319 } },
  DJ: { sw: { lat: 10.9149547, lng: 41.7713139 }, ne: { lat: 12.7923081, lng: 43.6579046 } },
  DM: { sw: { lat: 15.0074207, lng: -61.6869184 }, ne: { lat: 15.7872222, lng: -61.0329895 } },
  DK: { sw: { lat: 54.4516667, lng: 7.7153255 }, ne: { lat: 57.9524297, lng: 15.5530641 } },
  DO: { sw: { lat: 17.2701708, lng: -72.0574706 }, ne: { lat: 21.303433, lng: -68.1101463 } },
  DZ: { sw: { lat: 18.968147, lng: -8.668908 }, ne: { lat: 37.2962055, lng: 11.997337 } },
  EC: { sw: { lat: -5.0159314, lng: -92.2072392 }, ne: { lat: 1.8835964, lng: -75.192504 } },
  EG: { sw: { lat: 22.0, lng: 24.6499112 }, ne: { lat: 31.8330854, lng: 37.1153517 } },
  ER: { sw: { lat: 12.3548219, lng: 36.4333653 }, ne: { lat: 18.0709917, lng: 43.3001714 } },
  EH: { sw: { lat: 20.556883, lng: -17.3494721 }, ne: { lat: 27.6666834, lng: -8.666389 } },
  ES: { sw: { lat: 27.4335426, lng: -18.3936845 }, ne: { lat: 43.9933088, lng: 4.5918885 } },
  EE: { sw: { lat: 57.5092997, lng: 21.3826069 }, ne: { lat: 59.9383754, lng: 28.2100175 } },
  ET: { sw: { lat: 3.397448, lng: 32.9975838 }, ne: { lat: 14.8940537, lng: 47.9823797 } },
  FI: { sw: { lat: 59.4541578, lng: 19.0832098 }, ne: { lat: 70.0922939, lng: 31.5867071 } },
  FJ: { sw: { lat: -21.9434274, lng: 172.0 }, ne: { lat: -12.2613866, lng: -178.5 } },
  FK: { sw: { lat: -53.1186766, lng: -61.7726772 }, ne: { lat: -50.7973007, lng: -57.3662367 } },
  FR: { sw: { lat: 41.2632185, lng: -5.4534286 }, ne: { lat: 51.268318, lng: 9.8678344 } },
  FO: { sw: { lat: 61.3915553, lng: -7.6882939 }, ne: { lat: 62.3942991, lng: -6.2565525 } },
  FM: { sw: { lat: 0.827, lng: 137.2234512 }, ne: { lat: 10.291, lng: 163.2364054 } },
  GA: { sw: { lat: -4.1012261, lng: 8.5002246 }, ne: { lat: 2.3182171, lng: 14.539444 } },
  GB: { sw: { lat: 49.674, lng: -14.015517 }, ne: { lat: 61.061, lng: 2.0919117 } },
  GE: { sw: { lat: 41.0552922, lng: 39.8844803 }, ne: { lat: 43.5864294, lng: 46.7365373 } },
  GG: { sw: { lat: 49.4155331, lng: -2.6751703 }, ne: { lat: 49.5090776, lng: -2.501814 } },
  GH: { sw: { lat: 4.5392525, lng: -3.260786 }, ne: { lat: 11.1748562, lng: 1.2732942 } },
  GI: { sw: { lat: 36.100807, lng: -5.3941295 }, ne: { lat: 36.180807, lng: -5.3141295 } },
  GN: { sw: { lat: 7.1906045, lng: -15.5680508 }, ne: { lat: 12.67563, lng: -7.6381993 } },
  GP: { sw: { lat: 15.8320085, lng: -61.809764 }, ne: { lat: 16.5144664, lng: -61.0003663 } },
  GM: { sw: { lat: 13.061, lng: -17.0288254 }, ne: { lat: 13.8253137, lng: -13.797778 } },
  GW: { sw: { lat: 10.6514215, lng: -16.894523 }, ne: { lat: 12.6862384, lng: -13.6348777 } },
  GQ: { sw: { lat: -1.6732196, lng: 5.4172943 }, ne: { lat: 3.989, lng: 11.3598628 } },
  GR: { sw: { lat: 34.7006096, lng: 19.2477876 }, ne: { lat: 41.7488862, lng: 29.7296986 } },
  GD: { sw: { lat: 11.786, lng: -62.0065868 }, ne: { lat: 12.5966532, lng: -61.1732143 } },
  GL: { sw: { lat: 59.515387, lng: -74.1250416 }, ne: { lat: 83.875172, lng: -10.0288759 } },
  GT: { sw: { lat: 13.6345804, lng: -92.3105242 }, ne: { lat: 17.8165947, lng: -88.1755849 } },
  GF: { sw: { lat: 2.112222, lng: -54.60278 }, ne: { lat: 5.7507111, lng: -51.6346139 } },
  GU: { sw: { lat: 13.182335, lng: 144.563426 }, ne: { lat: 13.706179, lng: 145.009167 } },
  GY: { sw: { lat: 1.1710017, lng: -61.414905 }, ne: { lat: 8.6038842, lng: -56.4689543 } },
  HK: { sw: { lat: 22.1193278, lng: 114.0028131 }, ne: { lat: 22.4393278, lng: 114.3228131 } },
  HM: { sw: { lat: -53.394741, lng: 72.2460938 }, ne: { lat: -52.7030677, lng: 74.1988754 } },
  HN: { sw: { lat: 12.9808485, lng: -89.3568207 }, ne: { lat: 17.619526, lng: -82.1729621 } },
  HR: { sw: { lat: 42.1765993, lng: 13.2104814 }, ne: { lat: 46.555029, lng: 19.4470842 } },
  HT: { sw: { lat: 17.9099291, lng: -75.2384618 }, ne: { lat: 20.2181368, lng: -71.6217461 } },
  HU: { sw: { lat: 45.737128, lng: 16.1138867 }, ne: { lat: 48.585257, lng: 22.8977094 } },
  ID: { sw: { lat: -11.2085669, lng: 94.7717124 }, ne: { lat: 6.2744496, lng: 141.0194444 } },
  IM: { sw: { lat: 54.0539576, lng: -4.7946845 }, ne: { lat: 54.4178705, lng: -4.3076853 } },
  IN: { sw: { lat: 6.5546079, lng: 68.1113787 }, ne: { lat: 35.6745457, lng: 97.395561 } },
  IO: { sw: { lat: -7.6454079, lng: 71.036504 }, ne: { lat: -5.037066, lng: 72.7020157 } },
  IE: { sw: { lat: 51.222, lng: -11.0133788 }, ne: { lat: 55.636, lng: -5.6582363 } },
  IR: { sw: { lat: 24.8465103, lng: 44.0318908 }, ne: { lat: 39.7816502, lng: 63.3332704 } },
  IQ: { sw: { lat: 29.0585661, lng: 38.7936719 }, ne: { lat: 37.380932, lng: 48.8412702 } },
  IS: { sw: { lat: 63.0859177, lng: -25.0135069 }, ne: { lat: 67.353, lng: -12.8046162 } },
  IL: { sw: { lat: 29.4533796, lng: 34.2674994 }, ne: { lat: 33.3356317, lng: 35.8950234 } },
  IT: { sw: { lat: 35.2889616, lng: 6.6272658 }, ne: { lat: 47.0921462, lng: 18.7844746 } },
  JM: { sw: { lat: 16.5899443, lng: -78.5782366 }, ne: { lat: 18.7256394, lng: -75.7541143 } },
  JE: { sw: { lat: 49.1625179, lng: -2.254512 }, ne: { lat: 49.2621288, lng: -2.0104193 } },
  JO: { sw: { lat: 29.183401, lng: 34.8844372 }, ne: { lat: 33.3750617, lng: 39.3012981 } },
  JP: { sw: { lat: 20.2145811, lng: 122.7141754 }, ne: { lat: 45.7112046, lng: 154.205541 } },
  KZ: { sw: { lat: 40.5686476, lng: 46.4932179 }, ne: { lat: 55.4421701, lng: 87.3156316 } },
  KE: { sw: { lat: -4.8995204, lng: 33.9098987 }, ne: { lat: 4.62, lng: 41.899578 } },
  KG: { sw: { lat: 39.1728437, lng: 69.2649523 }, ne: { lat: 43.2667971, lng: 80.2295793 } },
  KH: { sw: { lat: 9.4752639, lng: 102.3338282 }, ne: { lat: 14.6904224, lng: 107.6276788 } },
  KI: { sw: { lat: -7.0516717, lng: -179.1645388 }, ne: { lat: 7.9483283, lng: -164.1645388 } },
  KN: { sw: { lat: 16.895, lng: -63.051129 }, ne: { lat: 17.6158146, lng: -62.3303519 } },
  KR: { sw: { lat: 32.9104556, lng: 124.354847 }, ne: { lat: 38.623477, lng: 132.1467806 } },
  KW: { sw: { lat: 28.5243622, lng: 46.5526837 }, ne: { lat: 30.1038082, lng: 49.0046809 } },
  LA: { sw: { lat: 13.9096752, lng: 100.0843247 }, ne: { lat: 22.5086717, lng: 107.6349989 } },
  LB: { sw: { lat: 33.0479858, lng: 34.8825667 }, ne: { lat: 34.6923543, lng: 36.625 } },
  LR: { sw: { lat: 4.1555907, lng: -11.6080764 }, ne: { lat: 8.5519861, lng: -7.367323 } },
  LY: { sw: { lat: 19.5008138, lng: 9.391081 }, ne: { lat: 33.3545898, lng: 25.3770629 } },
  LC: { sw: { lat: 13.508, lng: -61.2853867 }, ne: { lat: 14.2725, lng: -60.6669363 } },
  LI: { sw: { lat: 47.0484291, lng: 9.4716736 }, ne: { lat: 47.270581, lng: 9.6357143 } },
  LK: { sw: { lat: 5.719, lng: 79.3959205 }, ne: { lat: 10.035, lng: 82.0810141 } },
  LS: { sw: { lat: -30.6772773, lng: 27.0114632 }, ne: { lat: -28.570615, lng: 29.4557099 } },
  LT: { sw: { lat: 53.8967893, lng: 20.653783 }, ne: { lat: 56.4504213, lng: 26.8355198 } },
  LU: { sw: { lat: 49.4969821, lng: 4.9684415 }, ne: { lat: 50.430377, lng: 6.0344254 } },
  LV: { sw: { lat: 55.6746505, lng: 20.6715407 }, ne: { lat: 58.0855688, lng: 28.2414904 } },
  MO: { sw: { lat: 22.0766667, lng: 113.5281666 }, ne: { lat: 22.2170361, lng: 113.6301389 } },
  MF: { sw: { lat: 17.8963535, lng: -63.3605643 }, ne: { lat: 18.1902778, lng: -62.7644063 } },
  MA: { sw: { lat: 21.3365321, lng: -17.2551456 }, ne: { lat: 36.0505269, lng: -0.998429 } },
  MC: { sw: { lat: 43.7247599, lng: 7.4090279 }, ne: { lat: 43.7519311, lng: 7.4398704 } },
  MD: { sw: { lat: 45.4674139, lng: 26.6162189 }, ne: { lat: 48.4918695, lng: 30.1636756 } },
  MG: { sw: { lat: -25.6071002, lng: 43.2202072 }, ne: { lat: -11.9519693, lng: 50.4862553 } },
  MV: { sw: { lat: -0.9074935, lng: 72.3554187 }, ne: { lat: 7.3106246, lng: 73.9700962 } },
  MX: { sw: { lat: 14.3886243, lng: -118.59919 }, ne: { lat: 32.7186553, lng: -86.493266 } },
  MH: { sw: { lat: -0.5481258, lng: 163.4985095 }, ne: { lat: 14.4518742, lng: 178.4985095 } },
  MK: { sw: { lat: 40.8536596, lng: 20.4529023 }, ne: { lat: 42.3735359, lng: 23.034051 } },
  ML: { sw: { lat: 10.147811, lng: -12.2402835 }, ne: { lat: 25.001084, lng: 4.2673828 } },
  MT: { sw: { lat: 35.6029696, lng: 13.9324226 }, ne: { lat: 36.2852706, lng: 14.8267966 } },
  MM: { sw: { lat: 9.4399432, lng: 92.1719423 }, ne: { lat: 28.547835, lng: 101.1700796 } },
  ME: { sw: { lat: 41.7495999, lng: 18.4195781 }, ne: { lat: 43.5585061, lng: 20.3561641 } },
  MN: { sw: { lat: 41.5800276, lng: 87.73762 }, ne: { lat: 52.1496, lng: 119.931949 } },
  MP: { sw: { lat: 14.036565, lng: 144.813338 }, ne: { lat: 20.616556, lng: 146.154418 } },
  MZ: { sw: { lat: -26.9209427, lng: 30.2138197 }, ne: { lat: -10.3252149, lng: 41.0545908 } },
  MR: { sw: { lat: 14.7209909, lng: -17.068081 }, ne: { lat: 27.314942, lng: -4.8333344 } },
  MS: { sw: { lat: 16.475, lng: -62.450667 }, ne: { lat: 17.0152978, lng: -61.9353818 } },
  MQ: { sw: { lat: 14.3948596, lng: -61.2290815 }, ne: { lat: 14.8787029, lng: -60.8095833 } },
  MU: { sw: { lat: -20.725, lng: 56.3825151 }, ne: { lat: -10.138, lng: 63.7151319 } },
  MW: { sw: { lat: -17.1296031, lng: 32.6703616 }, ne: { lat: -9.3683261, lng: 35.9185731 } },
  MY: { sw: { lat: -5.1076241, lng: 105.3471939 }, ne: { lat: 9.8923759, lng: 120.3471939 } },
  YT: { sw: { lat: -13.0210119, lng: 45.0183298 }, ne: { lat: -12.6365902, lng: 45.2999917 } },
  NA: { sw: { lat: -28.96945, lng: 11.5280384 }, ne: { lat: -16.9634855, lng: 25.2617671 } },
  NC: { sw: { lat: -23.2217509, lng: 162.6034343 }, ne: { lat: -17.6868616, lng: 167.8109827 } },
  NE: { sw: { lat: 11.693756, lng: 0.1689653 }, ne: { lat: 23.517178, lng: 15.996667 } },
  NF: { sw: { lat: -29.333, lng: 167.6873878 }, ne: { lat: -28.796, lng: 168.2249543 } },
  NG: { sw: { lat: 4.0690959, lng: 2.676932 }, ne: { lat: 13.885645, lng: 14.678014 } },
  NI: { sw: { lat: 10.7076565, lng: -87.901532 }, ne: { lat: 15.0331183, lng: -82.6227023 } },
  NU: { sw: { lat: -19.3548665, lng: -170.1595029 }, ne: { lat: -18.7534559, lng: -169.5647229 } },
  NL: { sw: { lat: 50.7295671, lng: 1.9193492 }, ne: { lat: 53.7253321, lng: 7.2274985 } },
  NO: { sw: { lat: 57.7590052, lng: 4.0875274 }, ne: { lat: 71.3848787, lng: 31.7614911 } },
  NP: { sw: { lat: 26.3477581, lng: 80.0586226 }, ne: { lat: 30.446945, lng: 88.2015257 } },
  NR: { sw: { lat: -0.5541334, lng: 166.9091794 }, ne: { lat: -0.5025906, lng: 166.9589235 } },
  NZ: { sw: { lat: -52.8213687, lng: -179.059153 }, ne: { lat: -29.0303303, lng: 179.3643594 } },
  OM: { sw: { lat: 16.4649608, lng: 52 }, ne: { lat: 26.7026737, lng: 60.054577 } },
  PK: { sw: { lat: 23.5393916, lng: 60.872855 }, ne: { lat: 37.084107, lng: 77.1203914 } },
  PA: { sw: { lat: 7.0338679, lng: -83.0517245 }, ne: { lat: 9.8701757, lng: -77.1393779 } },
  PN: { sw: { lat: -25.1306736, lng: -130.8049862 }, ne: { lat: -23.8655769, lng: -124.717534 } },
  PE: { sw: { lat: -20.1984472, lng: -84.6356535 }, ne: { lat: -0.0392818, lng: -68.6519906 } },
  PH: { sw: { lat: 4.2158064, lng: 114.0952145 }, ne: { lat: 21.3217806, lng: 126.8072562 } },
  PW: { sw: { lat: 2.748, lng: 131.0685462 }, ne: { lat: 8.222, lng: 134.7714735 } },
  PG: { sw: { lat: -13.1816069, lng: 136.7489081 }, ne: { lat: 1.8183931, lng: 151.7489081 } },
  PL: { sw: { lat: 49.0020468, lng: 14.1229707 }, ne: { lat: 55.0336963, lng: 24.145783 } },
  PR: { sw: { lat: 17.9268695, lng: -67.271492 }, ne: { lat: 18.5159789, lng: -65.5897525 } },
  KP: { sw: { lat: 37.5867855, lng: 124.0913902 }, ne: { lat: 43.0089642, lng: 130.924647 } },
  PT: { sw: { lat: 29.8288021, lng: -31.5575303 }, ne: { lat: 42.1543112, lng: -6.1891593 } },
  PY: { sw: { lat: -27.6063935, lng: -62.6442036 }, ne: { lat: -19.2876472, lng: -54.258 } },
  PS: { sw: { lat: 31.2201289, lng: 34.0689732 }, ne: { lat: 32.5521479, lng: 35.5739235 } },
  PF: { sw: { lat: -28.0990232, lng: -154.9360599 }, ne: { lat: -7.6592173, lng: -134.244799 } },
  QA: { sw: { lat: 24.4707534, lng: 50.5675 }, ne: { lat: 26.3830212, lng: 52.638011 } },
  RE: { sw: { lat: -21.3897308, lng: 55.2164268 }, ne: { lat: -20.8717136, lng: 55.8366924 } },
  RO: { sw: { lat: 43.618682, lng: 20.2619773 }, ne: { lat: 48.2653964, lng: 30.0454257 } },
  RU: { sw: { lat: 41.1850968, lng: 19.6389 }, ne: { lat: 82.0586232, lng: 180 } },
  RW: { sw: { lat: -2.8389804, lng: 28.8617546 }, ne: { lat: -1.0474083, lng: 30.8990738 } },
  SA: { sw: { lat: 16.29, lng: 34.4571718 }, ne: { lat: 32.1543377, lng: 55.6666851 } },
  SD: { sw: { lat: 8.685278, lng: 21.8145046 }, ne: { lat: 22.224918, lng: 39.0576252 } },
  SN: { sw: { lat: 12.2372838, lng: -17.7862419 }, ne: { lat: 16.6919712, lng: -11.3458996 } },
  SG: { sw: { lat: 1.1304753, lng: 103.6920359 }, ne: { lat: 1.4504753, lng: 104.0120359 } },
  GS: { sw: { lat: -59.684, lng: -42.354739 }, ne: { lat: -53.3500755, lng: -25.8468303 } },
  SH: { sw: { lat: -16.23, lng: -5.9973424 }, ne: { lat: -15.704, lng: -5.4234153 } },
  SJ: { sw: { lat: 70.6260825, lng: -9.6848146 }, ne: { lat: 81.028076, lng: 34.6891253 } },
  SB: { sw: { lat: -13.2424298, lng: 155.3190556 }, ne: { lat: -4.81085, lng: 170.3964667 } },
  SL: { sw: { lat: 6.755, lng: -13.5003389 }, ne: { lat: 9.999973, lng: -10.271683 } },
  SV: { sw: { lat: 12.976046, lng: -90.1790975 }, ne: { lat: 14.4510488, lng: -87.6351394 } },
  SM: { sw: { lat: 43.8937002, lng: 12.4033246 }, ne: { lat: 43.992093, lng: 12.5160665 } },
  SO: { sw: { lat: -1.8031969, lng: 40.98918 }, ne: { lat: 12.1889121, lng: 51.6177696 } },
  PM: { sw: { lat: 46.5507173, lng: -56.6972961 }, ne: { lat: 47.365, lng: -55.9033333 } },
  RS: { sw: { lat: 42.2322435, lng: 18.8142875 }, ne: { lat: 46.1900524, lng: 23.006309 } },
  ST: { sw: { lat: -0.2135137, lng: 6.260642 }, ne: { lat: 1.9257601, lng: 7.6704783 } },
  SR: { sw: { lat: 1.8312802, lng: -58.070833 }, ne: { lat: 6.225, lng: -53.8433358 } },
  SK: { sw: { lat: 47.7314286, lng: 16.8331891 }, ne: { lat: 49.6138162, lng: 22.56571 } },
  SI: { sw: { lat: 45.4214242, lng: 13.3754696 }, ne: { lat: 46.8766816, lng: 16.5967702 } },
  SE: { sw: { lat: 55.1331192, lng: 10.5930952 }, ne: { lat: 69.0599699, lng: 24.1776819 } },
  SZ: { sw: { lat: -27.3175201, lng: 30.7908 }, ne: { lat: -25.71876, lng: 32.1349923 } },
  SC: { sw: { lat: -10.4649258, lng: 45.9988759 }, ne: { lat: -3.512, lng: 56.4979396 } },
  SY: { sw: { lat: 32.311354, lng: 35.4714427 }, ne: { lat: 37.3184589, lng: 42.3745687 } },
  TC: { sw: { lat: 20.9553418, lng: -72.6799046 }, ne: { lat: 22.1630989, lng: -70.8643591 } },
  TD: { sw: { lat: 7.44107, lng: 13.47348 }, ne: { lat: 23.4975, lng: 24.0 } },
  TG: { sw: { lat: 5.926547, lng: -0.1439746 }, ne: { lat: 11.1395102, lng: 1.8087605 } },
  TH: { sw: { lat: 5.612851, lng: 97.3438072 }, ne: { lat: 20.4648337, lng: 105.636812 } },
  TJ: { sw: { lat: 36.6711153, lng: 67.3332775 }, ne: { lat: 41.0450935, lng: 75.1539563 } },
  TK: { sw: { lat: -9.6442499, lng: -172.7213673 }, ne: { lat: -8.3328631, lng: -170.9797586 } },
  TM: { sw: { lat: 35.129093, lng: 52.335076 }, ne: { lat: 42.7975571, lng: 66.6895177 } },
  TL: { sw: { lat: -9.5642775, lng: 124.0415703 }, ne: { lat: -8.0895459, lng: 127.5335392 } },
  TO: { sw: { lat: -24.1034499, lng: -179.3866055 }, ne: { lat: -15.3655722, lng: -173.5295458 } },
  TT: { sw: { lat: 9.8732106, lng: -62.083056 }, ne: { lat: 11.5628372, lng: -60.2895848 } },
  TN: { sw: { lat: 30.230236, lng: 7.5219807 }, ne: { lat: 37.7612052, lng: 11.8801133 } },
  TR: { sw: { lat: 35.8076804, lng: 25.6212891 }, ne: { lat: 42.297, lng: 44.8176638 } },
  TV: { sw: { lat: -9.9939389, lng: 175.1590468 }, ne: { lat: -5.4369611, lng: 178.7344938 } },
  TW: { sw: { lat: 10.374269, lng: 114.3599058 }, ne: { lat: 26.4372222, lng: 122.297 } },
  TZ: { sw: { lat: -11.761254, lng: 29.3269773 }, ne: { lat: -0.9854812, lng: 40.6584071 } },
  UG: { sw: { lat: -1.4823179, lng: 29.573433 }, ne: { lat: 4.2340766, lng: 35.000308 } },
  UA: { sw: { lat: 44.184598, lng: 22.137059 }, ne: { lat: 52.3791473, lng: 40.2275801 } },
  UM: { sw: { lat: 6.1779744, lng: -162.6816297 }, ne: { lat: 6.6514388, lng: -162.1339885 } },
  UY: { sw: { lat: -35.7824481, lng: -58.4948438 }, ne: { lat: -30.0853962, lng: -53.0755833 } },
  US: { sw: { lat: 24.9493, lng: -125.0011 }, ne: { lat: 49.5904, lng: -66.9326 } },
  UZ: { sw: { lat: 37.1821164, lng: 55.9977865 }, ne: { lat: 45.590118, lng: 73.1397362 } },
  VA: { sw: { lat: 41.9002044, lng: 12.4457442 }, ne: { lat: 41.9073912, lng: 12.4583653 } },
  VC: { sw: { lat: 12.5166548, lng: -61.6657471 }, ne: { lat: 13.583, lng: -60.9094146 } },
  VE: { sw: { lat: 0.647529, lng: -73.3529632 }, ne: { lat: 15.9158431, lng: -59.5427079 } },
  VG: { sw: { lat: 17.623468, lng: -65.159094 }, ne: { lat: 18.464984, lng: -64.512674 } },
  VI: { sw: { lat: 17.623468, lng: -65.159094 }, ne: { lat: 18.464984, lng: -64.512674 } },
  VN: { sw: { lat: 8.1790665, lng: 102.14441 }, ne: { lat: 23.393395, lng: 114.3337595 } },
  VU: { sw: { lat: -20.4627425, lng: 166.3355255 }, ne: { lat: -12.8713777, lng: 170.449982 } },
  WF: { sw: { lat: -14.5630748, lng: -178.3873749 }, ne: { lat: -12.9827961, lng: -175.9190391 } },
  WS: { sw: { lat: -14.2770916, lng: -173.0091864 }, ne: { lat: -13.2381892, lng: -171.1929229 } },
  YE: { sw: { lat: 11.9084802, lng: 41.60825 }, ne: { lat: 19.0, lng: 54.7389375 } },
  ZA: { sw: { lat: -47.1788335, lng: 16.3335213 }, ne: { lat: -22.1250301, lng: 38.2898954 } },
  ZM: { sw: { lat: -18.0765945, lng: 21.9993509 }, ne: { lat: -8.2712822, lng: 33.701111 } },
  ZW: { sw: { lat: -22.4241096, lng: 25.2373 }, ne: { lat: -15.6097033, lng: 33.0683413 } },
};
export default CountryBounds;
