/* eslint-disable no-mixed-operators */
// State Access Functions
export const getMapData = (state, mapKey = null) => {
  return mapKey ? state.map?.maps?.[mapKey] ?? {} : state.map;
};

// Helper functions
/**
 * @param {number|string} id
 * @param {string} type - 'tracking'|'explore'
 */
export const getMapKeyName = (id, type) => {
  return `${type}_${id}`;
};

export const isPointInsideNonOperationalZone = (location, nonOperationalZone) => {
  //  use ray-casting algorithm to check if the coordinates fall within one of the NON-operating zones
  //  note: ray-casting algorithm doesn't work reliably with points on an edge or vertex of a polygon
  const x = location.lat,
    y = location.lng;
  if (!nonOperationalZone || nonOperationalZone.length < 1) {
    return false;
  }

  let insideNonOperatingZone = false;
  for (let i = 0, j = nonOperationalZone.length - 1; i < nonOperationalZone.length; j = i++) {
    const xi = nonOperationalZone[i].lat,
      yi = nonOperationalZone[i].lng;
    const xj = nonOperationalZone[j].lat,
      yj = nonOperationalZone[j].lng;
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      insideNonOperatingZone = !insideNonOperatingZone;
    }
  }

  return insideNonOperatingZone;
};
