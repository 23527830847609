import BaseActionTypes from '@yojee/helpers/BaseActionTypes';

class ActionTypes extends BaseActionTypes {
  getDocuments = () => this.customWithSubType('GET_DOCUMENTS');
  downloadDocument = () => this.customWithSubType('DOWNLOAD_DOCUMENTS');
  toggleDocumentFormDialog = () => this.custom('TOGGLE_DOCUMENT_FORM_DIALOG');
  toggleDeleteDocumentDialog = () => this.custom('TOGGLE_DELETE_DOCUMENT_DIALOG');
  addSuccessMessage = () => this.custom('ADD_SUCCESS_MESSAGE');
  addErrorMessage = () => this.custom('ADD_ERROR_MESSAGE');
  clearMessage = () => this.custom('CLEAR_MESSAGE');
  startProgress = () => this.custom('START_PROGRESS');
  endProgress = () => this.custom('END_PROGRESS');
  updateState = () => this.custom('UPDATE_STATE');
  resetState = () => this.custom('RESET_STATE');
}

export default new ActionTypes({ prefix: 'DOCUMENT_PORTAL' });
