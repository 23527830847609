import { AVAILABLE_VIEWS } from '@yojee/helpers/constants';
const defaultState = {
  items: {},
  itemsKeys: [],
  totalTasksInput: 0,
  currentView: 'ITEMS',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  UPDATE_ITEMS: (state, { itemsKeys, items, totalTasksInput }) => {
    return { ...state, itemsKeys, items, totalTasksInput };
  },
  APPLY_TASK_FILTER: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  REFRESH_TASKS: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  REFRESH_ALL: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  CHANGE_DATE: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  UPDATE_SEARCH_TEXT: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  CLEAR_MASTER_FILTER: (state) => {
    return { ...defaultState, currentView: state.currentView };
  },
  UPDATE_CURRENT_VIEW: (state, { selectedView }) => {
    if (![AVAILABLE_VIEWS.ITEMS, AVAILABLE_VIEWS.TASKS].includes(selectedView)) {
      return state;
    }
    return { ...state, currentView: selectedView };
  },
  CHANGE_VIEW_BY_MASTER_FILTER: (state, { newView }) => {
    if (![AVAILABLE_VIEWS.ITEMS, AVAILABLE_VIEWS.TASKS].includes(newView)) {
      return state;
    }
    return { ...state, currentView: newView };
  },
  CHANGE_VIEW_BY_URL_PARAM: (state, { newView }) => {
    if (![AVAILABLE_VIEWS.ITEMS, AVAILABLE_VIEWS.TASKS].includes(newView)) {
      return state;
    }
    return { ...state, currentView: newView };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
