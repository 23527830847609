import { geocodeByAddress } from 'react-places-autocomplete';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { AVAILABLE_VIEWS } from '@yojee/helpers/constants';
import { handleControlByFilterChange, handleControlBySubStatusChange } from '@yojee/helpers/MasterFilterHelper';
import { FEATURES_MAP, isFeatureEnabledSelector } from '@yojee/helpers/SettingResolver';
import { isPairTaskIncluded } from '@yojee/helpers/TasksHelper';

export const getPlannerTaskFilterData = (state) =>
  state.planner && state.planner.filters && state.planner.filters.taskFilter;
export const getFilterState = (state) => state.masterFilter;
export const getCurrentMasterFilter = (state) => state.masterFilter.filter;
export const getBasicMasterDateFilter = (state) => state.masterFilter.filter.dateValue;
export const fetchingLocationStatus = (state) => state.masterFilter.requests?.fetchingLocation;

export default function* sagas() {
  yield takeLatest('APPLY_MASTER_FILTER', masterFilterWatcher);
  yield takeLatest('CLEAR_MASTER_FILTER', clearMasterFilter);
  yield takeLatest('MANIPULATE_MASTER_FILTER_BY_OUTER_STATUS', manipulateMasterFilterByOuterStatus);
  yield takeLatest('UPDATE_FILTER_TASK_STATUS', handleUpdateFilterTaskStatus);
  yield takeLatest('FILTER_MISSING_INFO_TASKS', filterMissingInfo);
  yield takeLatest('UPDATE_SELECTED_ADDRESS_OPTION', getPositions);
}

function* manipulateMasterFilterByOuterStatus({ status, previousTaskFilter, refreshUrlQuery }) {
  const currentFilterState = yield select(getFilterState);
  const newState = handleControlByFilterChange(status, currentFilterState, previousTaskFilter);

  if (refreshUrlQuery) {
    newState.urlQuery = '';
  }

  yield put({ type: 'UPDATE_FILTER_SOURCE', newState });
}

function* handleUpdateFilterTaskStatus({ status }) {
  const plannerFilter = yield select(getPlannerTaskFilterData);
  const currentFilterState = yield select(getFilterState);
  const newState = handleControlBySubStatusChange(plannerFilter, status, currentFilterState);
  yield put({ type: 'UPDATE_FILTER_SOURCE', newState });
}

function* masterFilterWatcher() {
  const isFetching = yield select(fetchingLocationStatus);
  if (isFetching) {
    yield take(['UPDATE_SELECTED_ADDRESS_OPTION_SUCCESS', 'UPDATE_SELECTED_ADDRESS_OPTION_FAILED']);
  }
  yield call(applyMasterFilter);
}

function* applyMasterFilter() {
  const currentPlannerFilter = yield select(getPlannerTaskFilterData);
  const currentMasterFilter = yield select(getCurrentMasterFilter);

  if (currentMasterFilter.taskType.length > 0) {
    const pairTaskIncluded = isPairTaskIncluded(currentMasterFilter.taskType);

    yield put({
      type: 'CHANGE_VIEW_BY_MASTER_FILTER',
      newView: pairTaskIncluded ? AVAILABLE_VIEWS.ITEMS : AVAILABLE_VIEWS.TASKS,
    });
  }

  if (currentMasterFilter.driverIds) {
    yield put({
      type: 'UPDATE_WORKER_FILTER_BY_MASTER_FILTER',
      workerIds: currentMasterFilter.driverIds,
    });
  }
  yield put({ type: 'UPDATE_QUERY_STRING' });
  yield put({ type: 'APPLY_TASK_FILTER', taskFilter: currentPlannerFilter });
  yield put({ type: 'INIT_TASKS' });

  // Update planner from/to date filter to load drivers and vehicles by the right range
  const { from, to } = yield select(getBasicMasterDateFilter);
  yield put({ type: 'RESET_DATE', startDate: from, endDate: to, open: false });
}

function* clearMasterFilter() {
  yield put({ type: 'CLEAR_FILTER_QUERY_STRING' });
  const plannerFilter = yield select(getPlannerTaskFilterData);
  yield manipulateMasterFilterByOuterStatus({ status: plannerFilter, refreshUrlQuery: true });
  yield put({ type: 'REFRESH' });
}

function* filterMissingInfo({ task }) {
  const currentPlannerFilter = yield select(getPlannerTaskFilterData);
  const useSenderNameInMissingInfoFix = yield select((state) =>
    isFeatureEnabledSelector(state, FEATURES_MAP.USE_SENDER_NAME_IN_MISSING_INFO_FIX)
  );
  yield put({ type: 'CHANGE_VIEW_BY_MASTER_FILTER', newView: AVAILABLE_VIEWS.TASKS });
  yield put({ type: 'UPDATE_QUERY_STRING' });
  yield put({ type: 'APPLY_TASK_FILTER', taskFilter: currentPlannerFilter });
  if (useSenderNameInMissingInfoFix) {
    yield put({ type: 'UPDATE_SENDER_IDS', sendersId: [`contact_name_${task['order_step']['contact_name']}`] });
  } else {
    yield put({ type: 'UPDATE_SENDER_IDS', sendersId: [task?.sender?.id] });
  }
  yield put({ type: 'UPDATE_SELECTED_ADDRESS_OPTION', options: [{ name: task['order_step']['address'] ?? '' }] });
  yield put({ type: 'UPDATE_FILTER_TASK_STATUS', status: ['missing_info'] });
  yield put({ type: 'SET_MISSING_INFO_FIX_MODE', enabled: true });
  yield put({ type: 'INIT_TASKS' });
}

function* getPositions({ options }) {
  try {
    if (options.length === 0) {
      yield put({ type: 'UPDATE_SELECTED_ADDRESS_OPTION_SUCCESS', options });
      return;
    }

    const allGoogleLocation = yield Promise.all(
      options.map((item) => {
        if (item.type === 'google' && !!item.lat === false && !!item.lng === false) {
          return onGoogleAddressSelect(item.value, item.id);
        }
        return item;
      })
    );

    const result = allGoogleLocation.filter((g) => !!g);
    yield put({ type: 'UPDATE_SELECTED_ADDRESS_OPTION_SUCCESS', options: result });
    yield call(applyMasterFilter);
  } catch (error) {
    yield put({ type: 'UPDATE_SELECTED_ADDRESS_OPTION_FAILED' });
    console.error(error);
  }
}

const onGoogleAddressSelect = (address, id) => {
  return geocodeByAddress(address)
    .then((results) => results[0])
    .then((place) => {
      const details = { id: id, value: address, name: address, type: 'google' };
      const location = place.geometry.location;
      if (location.lat()) {
        details.lat = location.lat();
      }
      if (location.lng()) {
        details.lng = location.lng();
      }
      return details;
    });
};
