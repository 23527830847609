import { Socket } from 'phoenix';

import getEnv from '@yojee/helpers/env/getEnv';

const SOCKET_URL = getEnv('REACT_APP_SOCKET_URL');

class WebSocketInstance {
  phoenixWebsocketInstance = null;
  currentChannel = {};

  closeChannel(topic) {
    if (!this.phoenixWebsocketInstance) {
      return;
    }

    const channelByTopic = this.currentChannel && this.currentChannel[topic];
    if (channelByTopic) {
      channelByTopic.leave();
    }
  }

  closeSocket() {
    if (!this.phoenixWebsocketInstance) {
      return;
    }

    this.phoenixWebsocketInstance.close();
    this.phoenixWebsocketInstance = undefined;
  }

  currentSocket = (params, onOpen, onMessage) => {
    if (this.phoenixWebsocketInstance) {
      return;
    }

    this.phoenixWebsocketInstance = new Socket(SOCKET_URL, { params });

    onOpen && this.phoenixWebsocketInstance.onOpen(onOpen);
    this.phoenixWebsocketInstance.onMessage(onMessage);
    this.phoenixWebsocketInstance.connect();
  };

  getChannelByTopic = ({ topic, onError, onTimeout }) => {
    if (this.currentChannel && this.currentChannel[topic]) {
      return this.currentChannel[topic];
    }

    const channel = this.phoenixWebsocketInstance.channel(topic);
    channel.onError(() => {
      console.error('==== Channel OnError');
    });
    channel.onClose(() => {
      console.info('==== Channel onClose');
    });
    channel
      .join()
      .receive('ok', () => {
        console.info('====== joined successfully');
      })
      .receive('error', (error) => {
        console.error('====== joined error!!!');
        onError && onError(error);
      })
      .receive('timeout', (error) => {
        console.log(topic);
        console.error('====== joined timeout!!!');
        onTimeout && onTimeout(error);
      });
    this.currentChannel[topic] = channel;

    return channel;
  };
}

export default WebSocketInstance;
