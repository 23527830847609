import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class TransferAPI extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  transferByTrackingNumbers = (transferData) => {
    transferData = {
      ...transferData,
      source: 'transfer-manual',
    };
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/sender/create_order'), { ...transferData })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

const transferAPI = new TransferAPI({ authService });
export default transferAPI;
