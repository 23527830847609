import { createSelector } from 'reselect';

export const optimisationRequestSelector = createSelector(
  [(state) => state && state.optimisation.requests, (_, id) => id],
  (requests, requestId) => (requestId ? requests[requestId] : null)
);

export const isAnyAssignedTaskDropped = createSelector(
  [(state) => state.optimisation?.latestRequest?.droppedTaskData, (_, workerId) => workerId],
  (droppedTasks, workerId) =>
    droppedTasks && droppedTasks.some((t) => parseInt(t['task_group']['worker_id']) === workerId)
);

export const droppedAssignedTasksSelector = createSelector(
  [(state) => state.optimisation?.latestRequest?.droppedTaskData, (_, workerId) => workerId],
  (droppedTasks, workerId) =>
    droppedTasks && droppedTasks.filter((t) => parseInt(t['task_group']['worker_id']) === workerId)
);

export const droppedAllAssignedTasksSelector = createSelector(
  [(state) => state.optimisation?.latestRequest?.droppedTaskData],
  (droppedTasks) => droppedTasks && droppedTasks.filter((t) => parseInt(t['task_group']['worker_id']))
);

export const droppedTasksSelector = createSelector(
  [
    (state) => state.optimisation?.latestRequest?.droppedTaskData || [],
    (state) => state.optimisation?.latestRequest?.routes || [],
    (state) =>
      state.optimisation?.requests?.[state.main.optimisation.instanceToDisplay]?.result?.removedUnsequencedTasksData,
  ],
  (droppedTasks, routes, removedTasks) => {
    const workersIdsMap = routes.reduce((acc, { assignee: { id } }) => {
      acc[parseInt(id)] = true;
      return acc;
    }, {});

    return droppedTasks
      .filter((t) => !workersIdsMap[parseInt(t['task_group']['worker_id'])])
      .concat(removedTasks || []);
  }
);

export const isAnyTaskUnSequencedSelector = createSelector(
  [
    (state) => state.optimisation?.latestRequest?.droppedTaskData || [],
    (state) => state.optimisation?.latestRequest?.routes || [],
  ],
  (droppedTasks, routes) => {
    const workersIdsMap = routes.reduce((acc, { assignee: { id } }) => {
      acc[parseInt(id)] = true;
      return acc;
    }, {});

    return droppedTasks.some(
      (t) => t?.['task_group']?.['worker_id'] && workersIdsMap[parseInt(t['task_group']['worker_id'])]
    );
  }
);

export const isAnyAssignedTaskDroppedSelector = createSelector(
  [(state) => state.optimisation?.latestRequest?.droppedTaskData || []],
  (droppedTasks) => droppedTasks.some((t) => t?.['task_group']?.['worker_id'])
);

export const assignedDroppedTasksSelector = createSelector(
  [(state) => state.optimisation?.latestRequest?.droppedTaskData, (_, workerId) => workerId],
  (droppedTasks, workerId) =>
    (droppedTasks || []).filter((t) => parseInt(t['task_group']['worker_id']) === parseInt(workerId))
);
