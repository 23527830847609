import BaseActionTypes from '@yojee/helpers/BaseActionTypes';

class ActionTypes extends BaseActionTypes {
  downloadSample = () => this.custom('DOWNLOAD_SAMPLE');
  uploadBatchAssignment = () => this.custom('UPLOAD_BATCH_ASSIGNMENT');
  updateUploadingState = () => this.custom('UPDATE_UPLOADING_STATE');
  refreshUpload = () => this.custom('REFRESH_UPLOAD');
}

export default new ActionTypes({ prefix: 'BACTH_ASSIGN_DIALOG' });
