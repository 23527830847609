import { fitBounds } from 'google-map-react';
import { isEqual } from 'lodash-es';
import Geocode from 'react-geocode';
import { put, select, takeLatest } from 'redux-saga/effects';

import AuthSelectors from '@yojee/auth/store/selectors';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { getMapData } from '@yojee/helpers/map-helper';
import * as zoomHelper from '@yojee/helpers/zoom-helper';
import { getNormalizedTask } from '@yojee/ui/main/saga/saga';
import { getHighlightedPolyline } from '@yojee/ui/plan-timeline/selectors/selectors';

// State Access Functions
export const getPlannerData = (state) => state.planner;
export const getWorkerData = (state) => state.worker;
export const getOptimisationData = (state) => state.optimisation;
export const getMainData = (state) => state.main;

export default function* sagas() {
  yield takeLatest('CALCULATE_ZOOM', calculateZoom);
  yield takeLatest('ZOOM_SPECIFIC', zoomSpecific);

  yield takeLatest('ZOOM_SPECIFIC_ROUTE', zoomSpecificRoute);
  yield takeLatest('ZOOM_CLEAR', zoom);
  yield takeLatest('FETCH_PLANNER_WORKERS_SUCCEEDED', zoom);
  yield takeLatest('FETCH_HUBS_SUCCEEDED', zoom);
  yield takeLatest('UI_AUTH_UPDATE_COMPANY_SETTINGS', getCountryBound);
}

function* calculateZoom({ data: newMapData, mapKey, forceZoom = false }) {
  const fieldsToCompares = ['size', 'zoom', 'bounds'];
  const currentMapData = yield select((state) => getMapData(state, mapKey));
  const isDataChanged = fieldsToCompares.some((field) => !isEqual(currentMapData[field], newMapData[field]));

  if (isDataChanged) {
    yield put({ type: 'ZOOM_CALCULATED', data: newMapData, mapKey, forceZoom });
  }
}

function* zoomSpecific({ data: tasks, mapKey }) {
  try {
    const { size } = yield select((state) => getMapData(state, mapKey));
    if (size && tasks && tasks.length > 0) {
      const _bounds = zoomHelper.calculateBounds({
        tasks: tasks,
        hubs: [],
        workers: [],
        countryBounds: null,
        size: size,
      });
      if (_bounds) {
        yield put({
          type: 'ZOOM_CALCULATED',
          data: {
            center: _bounds.center,
            zoom: Number(_bounds.zoom) + Number((Math.random() * 0.02).toFixed(2)),
            bounds: _bounds.newBounds,
          },
          mapKey,
        });
      }
    }
  } catch (error) {
    console.log('zoomSpecific-error', error);
  }
}

function* zoomSpecificRoute({ data: { workerId, tasks } }) {
  const { size } = yield select(getMapData);
  const locationsList = yield select(getHighlightedPolyline, {
    workerId,
    startTaskId: tasks[0].id,
    endTaskId: tasks[1].id,
  });
  const locations = locationsList.map((l) => ({ lng: l[0], lat: l[1] }));
  if (size && locations.length > 0) {
    const _bounds = zoomHelper.calculateBounds({
      tasks: [],
      hubs: [],
      workers: [],
      locations,
      countryBounds: null,
      size: size,
    });
    if (_bounds) {
      yield put({
        type: 'ZOOM_CALCULATED',
        data: {
          center: _bounds.center,
          zoom: Number(_bounds.zoom) + Number((Math.random() * 0.02).toFixed(2)),
          bounds: _bounds.newBounds,
        },
      });
    }
  }
}

/**
 * zoomProps will be sent if map explicitly zoomed
 */
function* zoom() {
  // wait 200 seconds before zooming in
  yield delay(200);
  const { size } = yield select(getMapData);
  const country = yield select((state) => state.auth?.dispatcher_info?.data?.company?.country);

  if (size) {
    const { latestRequestId, requests } = yield select(getOptimisationData);
    const {
      hubs: { data: hubsData },
    } = yield select(getPlannerData);
    const workerData = yield select(getWorkerData);
    const {
      stopsList: { filter },
    } = yield select(getMainData);

    const optimisationResult = requests?.[latestRequestId]?.result;
    let tasks = [];

    if (optimisationResult && filter === AVAILABLE_FILTERS.LAST_PLANNED) {
      tasks = tasks.concat(optimisationResult.droppedTaskData ?? []);
      if (optimisationResult.routes?.[0]?.tour) {
        tasks = tasks.concat(
          optimisationResult.routes[0].tour.map((point) => ({
            location: { lat: point.location.latitude, lng: point.location.longitude },
          }))
        );
      }
    } else {
      const { data, ids: taskIds } = yield select(getNormalizedTask);
      tasks = taskIds.map((taskId) => data[taskId]);
    }

    const bounds = zoomHelper.calculateBounds({
      tasks,
      hubs: hubsData.map((hub) => ({ ...hub.location })),
      workers: workerData.data.map((workers) => ({ ...workers.location })).filter((w) => w.lat || w.lng),
      countryBounds: null,
      size: size,
      country,
    });

    if (bounds) {
      yield put({
        type: 'ZOOM_CALCULATED',
        data: {
          center: bounds.center,
          zoom: bounds.zoom,
          bounds: bounds.newBounds,
        },
      });
    }
  }
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

function* getCountryBound() {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { country },
        },
      },
    } = yield select(AuthSelectors.getData);
    const { country: mapCountry } = yield select(getMapData);
    const {
      hubs: { data: hubsData },
      taskData: { ids: taskIds },
    } = yield select(getPlannerData);
    if (hubsData && hubsData.length === 0 && taskIds.length === 0 && !mapCountry) {
      Geocode.setApiKey(process.env.REACT_APP_SCOPED_GOOGLE_API_KEY);
      const { results } = yield Geocode.fromAddress(country);
      if (results && results.length > 0) {
        const bounds = JSON.parse(JSON.stringify(results[0].geometry.bounds));
        const { width, height } = window.visualViewport;
        const { center, zoom } = fitBounds(
          {
            ne: bounds.northeast,
            sw: bounds.southwest,
          },
          { width: width - 600, height }
        );
        yield put({
          type: 'ZOOM_CALCULATED',
          data: {
            center: center,
            zoom: zoom,
            bounds: bounds,
            country: {
              center: center,
              zoom: zoom,
              bounds: bounds,
            },
          },
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}
