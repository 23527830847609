import _ from 'lodash-es';
import queryString from 'query-string';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';
import { initTasks } from '@yojee/data/fetch-services/tasks';
import { AVAILABLE_VIEWS } from '@yojee/helpers/constants';
import {
  defaultFilter,
  getCurrentFilter,
  getCurrentFilterPresetId,
  getHash,
  handleControlByOuterAndSubStatus,
} from '@yojee/helpers/MasterFilterHelper';

import {
  parseBasicFilterToQueryString,
  parsedQueryStringToFilterObject,
  parseTimeFrameQueryStringToObject,
} from '../../helpers/filterQueryStringHelper';

let isFirstTimeRender = true;

export const getFilterState = (state) => state.masterFilter;
const getCurrentTaskFilter = (state) => state.planner && state.planner.filters && state.planner.filters.taskFilter;
const getCurrentStopView = (state) => state.main && state.main.stopsList.currentView;
const getCurrentListView = (state) => state.itemsTable && state.itemsTable.currentView;
const getShowList = (state) => state.main && state.main.showList;
const getDefaultViewBy = (state) =>
  _.get(state, 'auth.dispatcher_info.data.company.settings.worker.tasks_group_by_value');
const getSearchTask = (state) => state.planner && state.planner.searchTasks && state.planner.searchTasks;
const getSelectedAddressTagIds = (state) => {
  const selectedIds = state.masterFilter.filter.selectedAddressTagIds;
  const addressTags = state.addressTagAutoComplete.addressTags;
  return addressTags.filter((addressTag) => selectedIds.includes(addressTag.id)).map((addressTag) => addressTag.id);
};
const getSelectedDriverTagIds = (state) => {
  const selectedIds = state.masterFilter.filter.selectedDriverTagIds;
  const driverTags = state.driverTagAutoComplete.driverTags;
  return driverTags.filter((driverTag) => selectedIds.includes(driverTag.id)).map((driverTag) => driverTag.id);
};

const extractCurrentAddressItemIds = (addressTags, selectedIds) => {
  const addressItemIds = addressTags
    .filter((addressTag) => selectedIds.includes(addressTag.id))
    .reduce((acc, v) => acc.concat(v?.address_item_tags?.map((addressItem) => addressItem.address_item_id)), []);

  return [...new Set(addressItemIds)];
};

const extractCurrentDriverIdsQueryByTags = (driverTags, selectedIds) => {
  const driverIds = driverTags
    .filter((driverTag) => selectedIds.includes(driverTag.id))
    .reduce((acc, v) => acc.concat(v?.worker_tags?.map((driver) => driver.worker_id)), []);

  return [...new Set(driverIds)];
};

const setting = {
  skipEmptyString: true,
  skipNull: true,
  arrayFormat: 'index',
};

export default function* sagas() {
  yield takeLatest(['CLEAR_WORKER_FILTER', 'UPDATE_QUERY_STRING'], updateQueryString);
  yield takeLatest('UPDATE_FILTER_BY_QUERY_STRING', updateFilterByQueryString);
  yield takeLatest('CLEAR_FILTER_QUERY_STRING', clearQueryString);
}

function* clearQueryString() {
  const parseQuery = queryString.parse(window.location.search, setting);

  if (parseQuery.partner_jwt) {
    const querryStringObj = {};
    querryStringObj['partner_jwt'] = parseQuery.partner_jwt;

    const querryString = queryString.stringifyUrl({ url: window.location.pathname, query: querryStringObj }, setting);
    window.history.pushState('', '', querryString);
    return;
  }
  yield;

  const queryStringObj = {
    mainFilter: parseQuery.mainFilter,
  };
  const query = queryString.stringifyUrl({ url: window.location.pathname, query: queryStringObj }, setting);
  yield put({ type: 'UPDATE_HASH_BY_QUERY_STRING_URL', query });
  window.history.pushState('', '', query);
}

function* updateFilterByQueryString(action) {
  const { callbackAction } = action;

  try {
    const currentMasterFilter = yield select(getFilterState);
    const parseQuery = queryString.parse(window.location.search, setting);

    const defaultViewBy = yield select(getDefaultViewBy);
    if (!window.location.pathname.includes('/explore-listview') && defaultViewBy === 'address') {
      yield put({ type: 'CHANGE_VIEW_BY_URL_PARAM', newView: AVAILABLE_VIEWS.LOCATION });
    }

    if ((parseQuery.transferred_order_number || parseQuery.order_number) && !parseQuery.mainFilter) {
      parseQuery.mainFilter = 'ALL';
    }
    if (parseQuery.order_item_ids) {
      parseQuery.mainFilter = 'ALL';
    }

    const newState = parseQuery.mainFilter
      ? handleControlByOuterAndSubStatus(parseQuery.mainFilter, parseQuery.status, currentMasterFilter)
      : currentMasterFilter;

    newState.filter = parsedQueryStringToFilterObject(parseQuery, defaultFilter);
    if (parseQuery.direction && newState.sortCriteria) {
      newState.sortCriteria.direction = parseQuery.direction;
    }
    if (parseQuery.sortBy && newState.sortCriteria) {
      newState.sortCriteria.sortBy = parseQuery.sortBy;
    }

    if (parseQuery.currentFilterPresetId) {
      newState.currentFilterPresetId = Number(parseQuery.currentFilterPresetId);
    } else {
      newState.currentFilterPresetId = undefined;
    }

    // handle ViewBy
    if (parseQuery.viewBy) {
      yield put({ type: 'CHANGE_VIEW_BY_URL_PARAM', newView: parseQuery.viewBy });
    }
    if (newState.filter.driverIds) {
      yield put({ type: 'UPDATE_WORKER_FILTER_BY_QUERY_STRING', workerIds: newState.filter.driverIds });
    }

    if (parseQuery.serviceTypeIds && parseQuery.serviceTypeIds !== '[]') {
      yield put({ type: 'FETCH_SERVICE_TYPES' });
    }

    if (parseQuery.selectedAddressTagIds && parseQuery.selectedAddressTagIds !== '[]') {
      const selectedAddressTagIds = JSON.parse(parseQuery.selectedAddressTagIds);
      newState.filter.selectedAddressTagIds = selectedAddressTagIds;

      const addressTags = yield call(companyService.getCompanyTags, { type: 'address_item' });
      yield put({ type: 'SEARCH_ADDRESS_TAG_SUCCESS', addressTags });

      newState.filter.addressItemIds = extractCurrentAddressItemIds(addressTags, selectedAddressTagIds);
    } else {
      newState.filter.selectedAddressTagIds = [];
    }

    if (parseQuery.selectedDriverTagIds && parseQuery.selectedDriverTagIds !== '[]') {
      const selectedDriverTagIds = JSON.parse(parseQuery.selectedDriverTagIds);
      newState.filter.selectedDriverTagIds = selectedDriverTagIds;

      const driverTags = yield call(companyService.getCompanyTags, { type: 'worker' });
      yield put({ type: 'SEARCH_DRIVER_TAG_SUCCESS', driverTags });

      newState.filter.driverIdsQueryByTags = extractCurrentDriverIdsQueryByTags(driverTags, selectedDriverTagIds);
    } else {
      newState.filter.selectedDriverTagIds = [];
    }

    // handleSearchState
    if (parseQuery.searchState) {
      yield put({ type: 'UPDATE_SEARCH_STATE_BY_QUERY_STRING_URL', searchState: JSON.parse(parseQuery.searchState) });
    } else {
      yield put({ type: 'CLEAR_SEARCH_TEXT' });
    }
    if (parseQuery.order_item_ids) {
      newState.filter.ignoreRange = true;
      yield put({ type: 'UPDATE_SEARCH_PARAM_BY_QUERY_STRING_URL', order_item_ids: Number(parseQuery.order_item_ids) });
    }
    // handle mainFilter
    if (parseQuery.mainFilter) {
      yield put({ type: 'APPLY_TASK_FILTER', taskFilter: parseQuery.mainFilter });
    }

    if (Array.isArray(parseQuery.senderIds) && parseQuery.senderIds.length > 0) {
      const sendersByType = parseQuery.senderIds
        .filter((v) => `${v}`.startsWith('contact_name_'))
        .map((v) => {
          const contactName = `${v}`.replace('contact_name_', '');
          return { id: v, value: contactName, type: 'contact_name', name: contactName };
        });

      if (sendersByType.length > 0) {
        yield put({ type: 'ADD_SENDERS', newSenders: sendersByType });
      }
    }

    const currentHash = getHash(newState.filter);

    if (parseQuery.ignoreRange) {
      newState.filter.ignoreRange = parseQuery.ignoreRange === 'true';
    }

    if (parseQuery.isHideRelatedTasks) {
      newState.filter.isHideRelatedTasks = parseQuery.isHideRelatedTasks === 'true';
    }

    if (parseQuery.idKey) {
      newState.filter.idKey = parseQuery.idKey;
    }

    if (parseQuery.idValue) {
      newState.filter.idValue = parseQuery.idValue;
    }

    if (parseQuery.timeFrame) {
      newState.filter.timeFrame = parseTimeFrameQueryStringToObject(parseQuery);
    }

    if (parseQuery.taskType) {
      newState.filter.taskType = parseQuery.taskType;
    }

    newState.previousHash = currentHash;

    yield put({ type: 'UPDATE_FILTER_SOURCE', newState: { ...newState } });

    if (isFirstTimeRender) {
      yield initTasks({});
      isFirstTimeRender = false;
    }

    if (callbackAction) {
      yield put(callbackAction);
    }
  } catch (err) {
    console.error(err);
    yield put({ type: 'DISPLAY_MESSAGE', message: `Construct querryString err ${err.message}`, variant: 'error' });
  }
}

function* updateQueryString() {
  if (!window.location.pathname.includes('/explore-listview') && !window.location.pathname.includes('/explore')) {
    return;
  }

  try {
    const currentMasterFilter = yield select(getFilterState);
    const currentFilter = yield select(getCurrentFilter);
    const mainFilter = yield select(getCurrentTaskFilter);
    const currentFilterPresetId = yield select(getCurrentFilterPresetId);
    const showList = yield select(getShowList);
    const currentStopView = yield select(getCurrentStopView);
    const currentListView = yield select(getCurrentListView);
    const currentSearchState = yield select(getSearchTask);
    const selectedAddressTagIds = yield select(getSelectedAddressTagIds);
    const selectedDriverTagIds = yield select(getSelectedDriverTagIds);
    const currentView = showList ? currentListView : currentStopView;
    const parsedQueryString = parseBasicFilterToQueryString(currentFilter);

    const queryStringObj = {
      ...currentMasterFilter.sortCriteria,
      ...parsedQueryString,
      currentFilterPresetId,
      selectedAddressTagIds: JSON.stringify(selectedAddressTagIds),
      selectedDriverTagIds: JSON.stringify(selectedDriverTagIds),
      mainFilter,
      viewBy: currentView,
      searchState: JSON.stringify(currentSearchState),
    };

    const params = new URLSearchParams(window.location.search);
    const partnerJwt = params && params.get('partner_jwt');
    if (partnerJwt) {
      queryStringObj['partner_jwt'] = partnerJwt;
    }

    const query = queryString.stringifyUrl({ url: window.location.pathname, query: queryStringObj }, setting);

    yield put({ type: 'UPDATE_HASH_BY_QUERY_STRING_URL', query });
    window.history.pushState('', '', query);
  } catch (err) {
    yield put({
      type: 'DISPLAY_MESSAGE',
      message: `Parsed filter from queryString err ${err.message}`,
      variant: 'error',
    });
  }
}
