export const getLocation = (location) => {
  if (!location) {
    return {
      id: null,
      latitude: null,
      longitude: null,
    };
  }
  return {
    id: `${location.lat}_${location.lng}`,
    latitude: location.lat,
    longitude: location.lng,
  };
};
