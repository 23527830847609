import { Trans } from 'react-i18next';

export const ZERO = '0.00';

export const BASED_ON_VALUES = {
  ORDER_CREATED: 'order_created',
  ORDER_DELIVERED: 'order_delivered',
  CHARGE_CREATED: 'charge_created',
};

export const BASED_ON_OPTIONS = [
  { value: BASED_ON_VALUES.ORDER_CREATED, label: <Trans>Order Created Date</Trans> },
  { value: BASED_ON_VALUES.ORDER_DELIVERED, label: <Trans>Order Delivered Date</Trans> },
  { value: BASED_ON_VALUES.CHARGE_CREATED, label: <Trans>Charge Created Date</Trans> },
];

export const CALCULATION_STATUS = {
  COMPLETED: 'completed',
  INPROGRESS: 'in_progress',
  NOT_SCHEDULED: 'not_yet_scheduled',
};

export const CALCULATION_TAB = {
  TAB_PREPARE: 0,
  TAB_COMPLETE: 0,
  TAB_WARNING: 1,
  TAB_MANUAL: 2,
};

export const MANUAL_CHARGE_KEYS = {
  GROUP: 'manualChargesGroup',
  NEW_CHARGE: 'new_manual_charges',
};
