import { getValue } from '@yojee/helpers/access-helper';

const SETTINGS_PATH = 'data.company.settings.applications.warehouse-scanner.';

const FIELD_PATH = {
  idField: SETTINGS_PATH + 'id_field',
  searchField: SETTINGS_PATH + 'search_field',
  searchFieldType: SETTINGS_PATH + 'search_field_type',
};

const DEFAULT_SETTINGS = {
  idField: 'tracking_number',
  searchField: 'order_item_tracking_number',
  searchFieldType: 'string',
};

export const getScannerIdField = (dispatcherInfo) => {
  return getValue(dispatcherInfo, FIELD_PATH.idField, DEFAULT_SETTINGS.idField);
};

export const getScannerSearchField = (dispatcherInfo) => {
  return getValue(dispatcherInfo, FIELD_PATH.searchField, DEFAULT_SETTINGS.searchField);
};

export const getScannerSearchFieldType = (dispatcherInfo) => {
  return getValue(dispatcherInfo, FIELD_PATH.searchFieldType, DEFAULT_SETTINGS.searchFieldType);
};
