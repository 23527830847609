// Library components
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import ManageHeader from '../../corners/ManageHeader';
// Custom components
import ManagePageContainer from '../../corners/ManagePageContainer';
import ManageTable from '../../rooms/ManageTable';
import ListManageTemplateSkeleton from './ListManageTemplateSkeleton';

const ListManageTemplate = ({
  title,
  loadingConfig,
  backBtn,
  primaryBtns,
  searchInput,
  actionBtnsShow,
  actionBtns,
  tableConfig,
  tableComponent,
  pagination,
  titleBtnShow = false,
  titleBtnOnClick = undefined,
  component = {},
}) => {
  const { tableLoading, pageLoading } = loadingConfig;
  const overallLoading = tableLoading;
  if (pageLoading) {
    return <ListManageTemplateSkeleton />;
  }

  return (
    <ManagePageContainer>
      <Grid container>
        {/* Header section */}
        <ManageHeader
          title={title}
          backBtnShow={backBtn?.show}
          backBtnOnClick={backBtn?.onClick}
          primaryBtns={primaryBtns}
          primaryBtnsDisable={overallLoading}
          containerStyle={{ marginBottom: '8px' }}
          titleBtnShow={titleBtnShow}
          titleBtnOnClick={titleBtnOnClick}
          component={component}
        />
        {/* Table Section */}
        <ManageTable
          searchInput={searchInput}
          actionBtnsShow={actionBtnsShow}
          actionBtns={actionBtns}
          tableLoading={tableLoading}
          tableConfig={tableConfig}
          pagination={pagination}
          component={tableComponent}
        />
      </Grid>
    </ManagePageContainer>
  );
};

ListManageTemplate.defaultProps = {
  backBtn: {
    show: false,
    onClick: () => ({}),
  },
};

ListManageTemplate.propTypes = {
  tableConfig: PropTypes.object.isRequired,
  tableComponent: PropTypes.node,
  pagination: PropTypes.object,
};

export default ListManageTemplate;
