import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import isEmpty from 'lodash-es/isEmpty';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isValidEmail } from '@yojee/auth/utils/AuthUtils';
import PhoneNumber from '@yojee/ui/base/corners/PhoneNumber';
import Button from '@yojee/ui/base/furnitures/Button';
import TextBox from '@yojee/ui/base/furnitures/Input';
import ReactSelect from '@yojee/ui/base/furnitures/Select/ReactSelect';
import Tooltip from '@yojee/ui/base/furnitures/Tooltip';

import { UIUserManagementActions } from '../../../sagas/userManagement/actions';

const companyDefault = {
  company_id: undefined,
  roles: [],
  filter_id: undefined,
  default: true,
};

const AddEditUserContent = ({ user = undefined, onClose }, ref) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(
    user
      ? user
      : {
          name: undefined,
          email: undefined,
          phone: undefined,
        }
  );
  const [error, setError] = useState({
    name: '',
    email: '',
    company: [''],
    roles: [''],
  });

  const [isInProgress, setIsInProgress] = useState(false);
  const [companies, setCompanies] = useState([]);

  const isActionInProgress = useSelector(
    (state) => state?.users?.inProgress?.requestCreateUser || state?.users?.inProgress?.requestUpdateUser
  );
  const errorMessage = useSelector((state) => state.users?.errorMessage);
  const successMessage = useSelector((state) => state.users?.successMessage);
  const roles = useSelector((state) => state.users?.roles?.list || []);
  const relatedCompanies = useSelector((state) => state.auth?.relatedCompanies || []);
  const currentCompanyId = useSelector((state) => state.auth?.dispatcher_info?.data?.company.id);
  const currentUserProfileId = useSelector((state) => state.auth?.dispatcher_info?.data?.user_profile_id);
  const relatedCompanyIds = useSelector((state) => state.auth?.dispatcher_info?.data?.related_company_ids);
  const isTheUserCurrent = currentUserProfileId === (user && user.user_profile_id);

  useEffect(() => {
    if (isActionInProgress && !isInProgress) {
      setIsInProgress(true);
    } else if (isInProgress) {
      if (successMessage) {
        handleClose();
        setIsInProgress(false);
      } else if (errorMessage) {
        setIsInProgress(false);
      }
    }
  }, [isActionInProgress]);

  useEffect(() => {
    if (user && user.user_profile_id) {
      setData({
        name: user.name,
        email: user.email,
        phone: user.phone,
      });
      setCompanies(
        user.companies
          ? Object.values(user.companies).map((c) => {
              return {
                company_id: c.company_id,
                roles: c?.roles?.map((r) => r.id) || [],
                filter_id: c?.saved_filters?.[0]?.id,
              };
            })
          : [companyDefault]
      );
    } else {
      setCompanies([
        {
          ...companyDefault,
          company_id: relatedCompanyIds?.[0],
        },
      ]);
    }
  }, [user]);

  const handleClose = () => {
    onClose();
  };

  const _handleSetError = (key, value) => {
    setError((prevState) => ({ ...prevState, [key]: value }));
  };

  const _handleSetErrorArray = (key, index, value) => {
    setError((prevState) => {
      const copiedArr = [...prevState[key]];
      copiedArr[index] = value;
      return {
        ...prevState,
        [key]: copiedArr,
      };
    });
  };

  const _validate = () => {
    let isValid = true;

    if (!!data.name === false) {
      _handleSetError('name', 'Field is required');
      isValid = false;
    }

    if (!!data.email === false) {
      _handleSetError('email', 'Field is required');
      isValid = false;
    } else if (!isValidEmail(data.email)) {
      _handleSetError('email', 'Email is not in correct format');
      isValid = false;
    }

    if (isEmpty(companies)) {
      _handleSetError('company', ['Field is required']);
    } else {
      companies.forEach((company, index) => {
        if (!!company.company_id === false) {
          _handleSetErrorArray('company', index, 'Field is required');
          isValid = false;
        }

        if (isEmpty(company.roles)) {
          _handleSetErrorArray('roles', index, 'Field is required');
          isValid = false;
        }
      });
    }

    return isValid;
  };

  const handleSave = useCallback(() => {
    if (_validate() === false) return;

    const _companies = {};
    companies.forEach((c) => {
      const _company = relatedCompanies.find((_c) => _c.company_id === c.company_id);
      _companies[_company.company_slug] = {
        role_ids: c.roles,
        saved_filter_ids: !c.filter_id || c.filter_id === 'all' ? [] : [c.filter_id],
      };
    });
    const submitData = {
      ...data,
      companies: _companies,
    };

    if (user && user.user_profile_id) {
      dispatch(
        UIUserManagementActions.requestUpdateUser({
          userId: user.user_profile_id,
          data: submitData,
        })
      );
    } else {
      dispatch(UIUserManagementActions.requestCreateUser(submitData));
    }
  }, [companies, data, relatedCompanies, user]);

  useImperativeHandle(
    ref,
    () => ({
      saveRef() {
        handleSave();
      },
    }),
    [handleSave]
  );

  const _addCompany = () => {
    setCompanies([...companies, companyDefault]);
  };

  const handleCloseCompanySection = (index) => {
    setCompanies(companies.filter((_, i) => i !== index));
  };

  const _areCompanySectionsValid = () => {
    return companies ? !companies.find((c) => !c.company_id || c.roles.length === 0) : false;
  };

  const _addCompanyButton = (disabled = false) => {
    return (
      <Button variant="contained" size="small" color="default" disabled={disabled} onClick={!disabled && _addCompany}>
        <Trans>Add New Company</Trans>
        <AddIcon fontSize="small" />
      </Button>
    );
  };

  const _getCompanyOptions = (targetCompany, companyId) => {
    return targetCompany
      .filter((c) => !companies.find((c1) => c1.company_id === c.company_id && c1.company_id !== companyId))
      .map((c) => {
        return {
          value: c.company_id,
          label: `${c.company_name} - ${c.company_id}`,
        };
      });
  };

  const _getRelatedCompanyOptions = (targetCompany, companyId) => {
    return targetCompany
      .filter(
        (c) =>
          !companies.find((c1) => c1.company_id === c.company_id && c1.company_id !== companyId) &&
          relatedCompanyIds.includes(c.company_id)
      )
      .map((c) => {
        return {
          value: c.company_id,
          label: `${c.company_name} - ${c.company_id}`,
        };
      });
  };

  const _getSelectedCompanyOptions = (optionList, companyId) => {
    return optionList.filter((option) => option.value === companyId);
  };

  const _getSelectedRoleOptions = (optionList, rolesArr) => {
    return optionList.filter((option) => rolesArr.includes(option.value));
  };

  const _getRoleOptions = (tagetRoles, companyId) => {
    return tagetRoles
      .filter((role) => role.company_id === companyId)
      .map((role) => {
        return {
          value: role.id,
          label: role.name,
        };
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextBox
          label={<Trans>Name</Trans>}
          error={!!error.name}
          helperText={error.name}
          fullWidth
          required={true}
          disabled={false}
          value={(data && data.name) || ''}
          onChange={(event) => {
            setData({ ...data, name: event.target.value });
            _handleSetError('name', '');
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextBox
          label={<Trans>Email</Trans>}
          fullWidth
          required={true}
          error={!!error.email}
          helperText={error.email}
          disabled={false}
          value={(data && data.email) || ''}
          onChange={(event) => {
            setData({ ...data, email: event.target.value });
            _handleSetError('email', '');
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <PhoneNumber
          label={<Trans>Phone Number</Trans>}
          disabled={false}
          type="phone"
          value={(data && data.phone) || ''}
          onChange={(phoneNumber, countryData) => {
            setData({ ...data, phone: `+${countryData?.dialCode}` === phoneNumber ? '' : phoneNumber });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {companies &&
            companies.map((company, index) => {
              const isCurrentUserInTheCompanyOrDefault =
                relatedCompanyIds.includes(company.company_id) || company.default;
              return (
                <Grid item xs={12} key={`company-row-${index}-${company.company_id}`}>
                  {companies.length > 1 && !(currentCompanyId === company.company_id && isTheUserCurrent) && (
                    <IconButton onClick={() => handleCloseCompanySection(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <ReactSelect
                        label={<Trans i18nKey="companyWorkspace">Company workspace</Trans>}
                        required
                        error={!!error.company[index]}
                        helperText={error.company[index]}
                        hideSelectedOptions={false}
                        disabled={
                          (currentCompanyId === company.company_id && isTheUserCurrent) ||
                          !isCurrentUserInTheCompanyOrDefault
                        }
                        value={_getSelectedCompanyOptions(
                          _getCompanyOptions(relatedCompanies, company.company_id),
                          company.company_id
                        )}
                        onChange={({ value }) => {
                          _handleSetErrorArray('company', index, '');
                          setCompanies(
                            companies.map((c) => {
                              return c.company_id === company.company_id
                                ? {
                                    ...c,
                                    company_id: value,
                                  }
                                : c;
                            })
                          );
                        }}
                        options={_getRelatedCompanyOptions(relatedCompanies, company.company_id)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReactSelect
                        label={<Trans>Roles</Trans>}
                        required
                        error={!!error.roles[index]}
                        helperText={error.roles[index]}
                        isMulti
                        disabled={!company.company_id || !isCurrentUserInTheCompanyOrDefault}
                        value={_getSelectedRoleOptions(_getRoleOptions(roles, company.company_id), company.roles)}
                        onChange={(dataArr) => {
                          _handleSetErrorArray('roles', index, '');
                          setCompanies(
                            companies.map((c) => {
                              return c.company_id === company.company_id
                                ? {
                                    ...c,
                                    roles: dataArr ? dataArr.map(({ value }) => value) : [],
                                  }
                                : c;
                            })
                          );
                        }}
                        options={_getRoleOptions(roles, company.company_id)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {companies.length !== relatedCompanies.length &&
          (_areCompanySectionsValid() ? (
            _addCompanyButton(false)
          ) : (
            <Tooltip title={<Trans>Please fill current sections before adding a new one</Trans>} placement="right">
              <span>{_addCompanyButton(true)}</span>
            </Tooltip>
          ))}
      </Grid>
    </Grid>
  );
};

export default forwardRef(AddEditUserContent);
