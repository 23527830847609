import { denormalize, schema } from 'normalizr';

import { normalizeResponse } from './NormalizedUtils';

export const normalizeTaskResponse = (tasksResponse) => {
  return normalizeResponse(tasksResponse);
};
export const deNormalizeTaskResponse = ({ data, ids }) => {
  const task = new schema.Entity('tasks');
  const mySchema = { tasks: [task] };
  const entities = { tasks: data };
  const denormalizedData = denormalize({ tasks: ids }, mySchema, entities);
  return denormalizedData.tasks;
};
