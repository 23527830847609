const defaultState = {
  status: 'idle',
  latestRequestId: null,
  latestShownRequestId: null,
  latestRequest: null,
  latestRequestRoutes: [],
  requests: {},
  showOverlay: false,
  newPlannerUsed: false,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  RESET_OPTIMISATION: () => {
    return defaultState;
  },
  OPTIMISATION_INIT: (state, { requestId, requestedAt }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'running', requestedAt: requestedAt };
    return { ...state, requests: currentRequests, latestRequestId: requestId, showOverlay: true };
  },
  OPTIMISATION_REQUESTED: (state, { requestId, requestedAt, selectedWorkers }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'running', requestedAt, selectedWorkers };
    return { ...state, requests: currentRequests, latestRequestId: requestId, showOverlay: true };
  },
  OPTIMISATION_SUBMITTED: (state, { requestId }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { ...currentRequests[requestId] };
    return { ...state, requests: currentRequests, showOverlay: true };
  },
  OPTIMISATION_UPDATE: (state, { params: { requestId, data } }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { ...currentRequests[requestId], ...data };
    return {
      ...state,
      requests: currentRequests,
      latestRequestRoutes: [...(data?.result?.routes ?? [])],
      latestRequest: { ...data?.result },
    };
  },
  OPTIMISATION_FAILED: (state, { requestId, error }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'failed', error: error && error.message ? error.message : error };
    return { ...state, requests: currentRequests, showOverlay: false };
  },
  OPTIMISATION_CANCELLED: (state, { requestId }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { status: 'cancelled' };
    return { ...state, requests: currentRequests, showOverlay: false };
  },
  OPTIMISATION_STATUS: (state, { requestId, statusData: { statusMessage } }) => {
    const currentRequests = { ...state.requests };
    currentRequests[requestId] = { ...currentRequests[requestId], message: statusMessage };
    return { ...state, requests: currentRequests, showOverlay: false };
  },
  OPTIMISATION_SUCCEEDED: (state, { requestId, data, requestedAt, completed, newPlannerUsed, alternativeResults }) => {
    const currentRequests = { ...state.requests };
    const currentRequest = {
      ...(currentRequests[requestId] || {}),
      result: data,
      status: 'completed',
      requestedAt,
      completed,
      newPlannerUsed,
      alternativeResults,
    };
    currentRequests[requestId] = currentRequest;

    return {
      ...state,
      requests: currentRequests,
      showOverlay: false,
      latestRequestRoutes: data.routes ? data.routes : [],
      latestRequest: { ...data, selectedWorkers: currentRequest.selectedWorkers, newPlannerUsed },
    };
  },
  CLOSE_OPTIMISATION: (state) => {
    return { ...state, latestRequestRoutes: [], latestRequestId: null };
  },
  CLEAR_OPTIMISATION_ROUTES: (state, { requestId }) => {
    const requests = state.requests;
    delete requests[requestId];
    return {
      ...state,
      latestRequestRoutes: [],
      requests,
    };
  },
  ADD_TASKS_TO_TOUR: (state, { requestId, assigneeId, tasks }) => {
    const currentRequests = state.requests;
    const {
      result: { routes },
    } = currentRequests[requestId];
    if (routes) {
      routes.map((route) => {
        if (route.assignee.id === assigneeId.toString()) {
          route.tour = [...route.tour, ...tasks];
        }
        return route;
      });
    }

    return {
      ...state,
      requests: {
        ...currentRequests,
        [requestId]: {
          ...currentRequests[requestId],
          result: {
            routes,
          },
        },
      },
    };
  },
  SET_LATEST_REQUEST_ID: (state, { latestRequestId }) => {
    return { ...state, latestRequestId };
  },
  REFRESH_ALL_INIT: (state) => {
    return { ...state, regionOptimisation: defaultState.regionOptimisation };
  },
  SET_LATEST_SHOWN_REQUEST_ID: (state, { latestShownRequestId }) => {
    return { ...state, latestShownRequestId };
  },
};

const reducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
