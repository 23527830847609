import { flatten, keyBy } from 'lodash-es';
import moment from 'moment';

import { generateColor } from '@yojee/helpers/ColorGenerator';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { tasksToStops } from '@yojee/helpers/tasksToStops';

// converts optimisation data into task data structure
export const getOptimisationTasks = (taskData = [], optimizationData) => {
  const taskMap = keyBy(taskData, 'id');
  return flatten(
    optimizationData.routes.map((r) => {
      const workerId = r.assignee && r.assignee.id ? Number(r.assignee.id) : -1;
      return r.tour.map((tPlan) => {
        const { arrivalEarliest, id } = tPlan;
        const task = taskMap[id];
        if (task) {
          const task_group = task.task_group || {};
          const inner_task = task.task;
          return {
            ...task,
            eta: arrivalEarliest,
            task: { ...inner_task, eta: arrivalEarliest },
            task_group: { ...task_group, worker_id: workerId },
          };
        }
        return null;
      });
    })
  ).filter((t) => t);
};

export const getOptimisationTasks_normalized = (taskData, optimizationData) => {
  const { data } = taskData;

  return flatten(
    optimizationData.routes.map((r) => {
      const workerId = r.assignee && r.assignee.id ? Number(r.assignee.id) : -1;
      return r.tour.map((tPlan) => {
        const { arrivalEarliest, id } = tPlan;
        const task = data[id];
        if (task) {
          const task_group = task.task_group || {};
          const inner_task = task.task;
          return {
            ...task,
            eta: arrivalEarliest,
            task: { ...inner_task, eta: arrivalEarliest },
            task_group: { ...task_group, worker_id: workerId },
          };
        }
        return null;
      });
    })
  ).filter((t) => t);
};

export const getTimelineItems = (taskData = [], workers, startDate, endDate) => {
  const assignedWorkerIds = [...new Set(taskData.map((t) => t.task_group.worker_id).filter((t) => !!t))];
  const groups = workers
    .filter((w) => assignedWorkerIds.includes(w.id))
    .map((w) => {
      return {
        id: w.id,
        title: w.name,
        rightTitle: w.name,
        root: true,
        parent: null,
        driverId: w.id,
        color: generateColor(w.id),
      };
    });
  //TODO: Get company service time setting
  const serviceTime = 5; // in minutes
  const { stops } = tasksToStops(taskData)
    .filter((s) => s.worker_id)
    .map((s) => {
      // set execution time
      s.execution_time = moment.utc(s.completion_time || s.eta || s.from).local();
      return s;
    })
    .sort((a, b) => {
      // sort based on worker_id and then execution time
      return a.worker_id - b.worker_id || a.execution_time.valueOf() - b.execution_time.valueOf();
    })
    .reduce(
      ({ prevStop, stops }, stop) => {
        // sets worker position
        const worker_position = prevStop && prevStop.worker_id === stop.worker_id ? prevStop.worker_position + 1 : 1;
        const newStop = { ...stop, worker_position: worker_position };
        return { prevStop: newStop, stops: stops.concat([newStop]) };
      },
      { prevStop: null, stops: [] }
    );

  const stopNodes = getStopNodes(stops, { serviceTime });
  let itemDic = {};

  stopNodes.forEach((item, idx) => {
    itemDic[item.id] = idx;
  });
  const { links: linkNodes } = getLinkNodes(stops, stopNodes, itemDic, { serviceTime });
  const cloneStartDate = startDate.clone();
  const cloneEndDate = endDate.clone();
  const { timeStart, timeEnd } = getTimeRange(stops, {
    startDate: cloneStartDate.local(),
    endDate: cloneEndDate.local(),
  });
  const items = stopNodes.concat(linkNodes);

  itemDic = {};
  items.forEach((item, idx) => {
    itemDic[item.id] = idx;
  });

  return { groups, items, itemDic, timeStart, timeEnd };
};

const getTimeRange = (stops, { startDate, endDate }) => {
  if (stops && stops.length) {
    let { timeStart, timeEnd } = stops.reduce(
      ({ timeStart, timeEnd }, s) => {
        return {
          timeStart: moment.min(s.execution_time, timeStart || endDate),
          timeEnd: moment.max(s.execution_time, timeEnd || startDate),
        };
      },
      { timeStart: null, timeEnd: null }
    );

    if (moment.duration(timeStart.diff(timeEnd)).asDays() < 1) {
      // If less than a day
      timeStart = timeStart.subtract(2, 'hour');
      timeEnd = timeEnd.add(2, 'hour');
    }
    return { timeStart, timeEnd };
  } else return { timeStart: startDate, timeEnd: startDate.add(20, 'hour') };
};
const getStopNodes = (stops, { serviceTime }) => {
  return stops.map((s) => {
    const from = moment.utc(s.execution_time);
    return {
      bgColor: generateColor(s.worker_id),
      borderColor: generateColor(s.worker_id),
      className: '',
      color: 'white',
      title: s.worker_position,
      description: `${s.count}${s.type[0]}`,
      driverId: s.worker_id,
      start_time: from.valueOf(),
      end_time: from.add(serviceTime, 'minutes').valueOf(),
      group: s.worker_id,
      id: s.id,
      itemProps: {
        // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
        'aria-hidden': true,
        'data-tip': s,
        onDoubleClick: () => {
          console.debug('You clicked double! task=');
          console.debug(s);
        },
      },
      selectedBgColor: 'white',
      type: 'node',
    };
  });
};

const getLinkNodes = (stops, stopNodes, itemDic, { serviceTime }) => {
  return stops.reduce(
    ({ links, prevStop }, stop) => {
      if (!prevStop) {
        // first element
        return { links: [], prevStop: stop };
      } else {
        const newLinks = [...links];
        if (
          prevStop.worker_id === stop.worker_id &&
          prevStop.sequence_id === stop.sequence_id &&
          prevStop.execution_time
        ) {
          const group_color = generateColor(stop.worker_id);
          newLinks.push({
            id: stop.id + '_link_' + stop.worker_id,
            group: stop.worker_id,
            type: 'link',
            driverId: stop.worker_id,
            title: '',
            start_time: prevStop.execution_time.add(serviceTime, 'minutes').valueOf(),
            end_time: stop.execution_time.valueOf(),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            className:
              prevStop.execution_time && (prevStop.execution_time.day() === 6 || prevStop.execution_time.day() === 0)
                ? 'item-weekend'
                : '',
            bgColor: convertHex(group_color, 40),
            selectedBgColor: group_color, //bgColorSelected,
            color: group_color,
            itemProps: {
              // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
              'aria-hidden': true,
              'data-tip': {
                from: stopNodes[itemDic[prevStop.id]],
                to: stopNodes[itemDic[stop.id]],
              },
            },
          });
        }
        return { links: newLinks, prevStop: stop };
      }
    },
    { links: [], prevStop: null }
  );
};
const convertHex = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  return result;
};

export const isOptimisationMode = (stopFilter) => {
  //filter = props.stopFilter.toUpperCase();
  const filter = stopFilter.toUpperCase();
  //only need to send this data to use ETA based timeline
  return filter === AVAILABLE_FILTERS.LAST_PLANNED;
};
