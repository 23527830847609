import 'moment-timezone';

import { t } from 'i18next';
import moment from 'moment';

import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { allAvailableSelectTasks } from '@yojee/helpers/stop-helper';
import { normalizeTaskResponse } from '@yojee/helpers/task-normalize-helper';

const defaultState = {
  taskData: {
    isFetchingTasks: false,
    loadingState: 0,
    data: {},
    ids: [],
    count: 0,
    copiedItemIds: [],
    markOnId: '',
  },
  hubs: { data: [], inProgress: false },
  regionsData: [],
  filters: {
    selectedRegions: [],
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day').add(1, 'day'),
    taskFilter: AVAILABLE_FILTERS.UNASSIGNED, //all, unassigned, assigned, completed
    workers: [],
    selectedRegionNames: [],
    selectedHubs: [],
    orderItemIds: [],
  },
  searchTasks: {
    searchText: '',
    order_item_ids: [],
  },
  searchDriverTeam: undefined,
  selectedTasks: [],
  selectedItemsByStepGroup: {},
  selectedAssignedTask: false,
  selectedWorkers: [],
  selectedBoundsConfirm: {
    active: false,
    workers: [],
    stops: [],
  },
  optimizable: true,
  selectedHubs: [],
  selectedZones: [],
  selectionMode: false,
  actionMode: 'quick',
  dispatcherInfo: {
    data: {
      company: {
        country: 'Singapore',
      },
    },
  },
  settings: {},
  maxLoad: 2000,
  vehicleTypes: [],
  inProgress: {
    getWorker: false,
    getWorkers: false,
    searchWorkers: false,
    getVehicles: false,
    getVehicleSchedules: false,
    searchVehicles: false,
    assignVehicleToDriver: false,
    completeTasks: false,
  },
  trackingMapInfo: {
    dropOffCompleted: false,
    workerId: false,
  },
  assignVehicleToDriver: {
    showConfirmationDialog: false,
    vehicle: null,
    driver: null,
    status: undefined,
    message: undefined,
  },
  vehiclesData: {
    list: [],
    pagination: {
      current_page: 1,
      limit_value: 50,
      total_count: null,
      total_pages: 1,
    },
    filters: {
      show: false,
      sortBy: 'high_to_low',
    },
    searchTerm: '',
  },
  workerData: {
    data: [],
    filters: {
      current: 'last_seen',
      distanceType: 'all',
      showFilter: false,
      showSort: false,
      filterType: 'all',
    },
  },
  vehiclesSearch: {
    data: [],
    filterType: 'all',
  },
  workersFromSearch: [],
  isShownConfirmCompleteTasksDialog: false,
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  '@@router/LOCATION_CHANGE': (state, { payload }) => {
    const { location } = payload;
    return {
      ...state,
      selectedTasks: location.pathname.includes('/explore') ? state.selectedTasks : [],
      selectedWorkers: location.pathname.includes('/explore') ? state.selectedWorkers : [],
      selectedItemsByStepGroup: location.pathname.includes('/explore') ? state.selectedItemsByStepGroup : {},
    };
  },
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SEARCH_DRIVER_TEAM: (state, { searchText }) => {
    return { ...state, searchDriverTeam: searchText };
  },
  CHANGE_DATE: (state, { startDate, endDate }) => {
    const filters = state.filters;
    return {
      ...state,
      filters: { ...filters, startDate, endDate },
      taskData: defaultState.taskData,
    };
  },
  REFRESH_TASKS: (state) => {
    return { ...state, selectedAssignedTask: false, taskData: defaultState.taskData };
  },
  REFRESH_ALL_INIT: (state) => {
    return {
      ...defaultState,
      filters: {
        ...state.filters,
      },
      workerData: {
        ...defaultState.workerData,
        filters: {
          ...state.workerData.filters,
        },
      },
      vehiclesData: {
        ...defaultState.vehiclesData,
        filters: {
          ...state.vehiclesData.filters,
        },
      },
    };
  },
  APPLY_TASK_FILTER: (state, { taskFilter }) => {
    const filters = state.filters;
    return { ...state, selectedTasks: [], selectedItemsByStepGroup: {}, filters: { ...filters, taskFilter } };
  },
  SET_TASK_FILTER: (state, { taskFilter }) => {
    const filters = state.filters;

    return { ...state, filters: { ...filters, taskFilter } };
  },
  CHANGE_ORDER_ITEM_IDS_FILTER_SUCCESSFUL: (state, { orderItemIds }) => {
    const filters = state.filters;
    return {
      ...state,
      selectedTasks: [],
      selectedItemsByStepGroup: {},
      filters: { ...filters, orderItemIds },
      taskData: defaultState.taskData,
    };
  },
  UPDATE_TASK_FILTER: (state) => {
    return { ...state, taskData: defaultState.taskData };
  },
  UPDATE_WORKER_FILTER_BY_QUERY_STRING: (state, { workerIds }) => {
    return { ...state, filters: { ...state.filters, workers: workerIds } };
  },
  UPDATE_WORKER: (state, { updatedWorker }) => {
    const { worker } = updatedWorker;
    worker.id = parseInt(worker.id);

    const lstWorker = state.workerData.data;
    const idxWorker = lstWorker && lstWorker.findIndex((d) => d.id === worker.id);

    if (idxWorker !== undefined && idxWorker > -1) {
      const workerById = lstWorker[idxWorker];
      lstWorker[idxWorker] = { ...workerById, ...worker };
      return {
        ...state,
        workerData: {
          ...state.workerData,
          data: lstWorker.concat([]),
        },
      };
    }
    return { ...state };
  },
  UPDATE_WORKERS: (state, { updatedWorkersMap = {} }) => {
    const newWorkers = [...(state.workerData.data || [])];

    newWorkers.forEach((worker, index) => {
      if (updatedWorkersMap[worker.id]) {
        newWorkers[index] = { ...worker, ...updatedWorkersMap[worker.id] };
      }
    });

    return {
      ...state,
      workerData: {
        ...state.workerData,
        data: newWorkers,
      },
    };
  },
  UPDATE_WORKER_FILTER_BY_MASTER_FILTER: (state, { workerIds }) => {
    return { ...state, filters: { ...state.filters, workers: workerIds } };
  },
  APPLY_WORKER_FILTER: (state, { workerId, updateTasks }) => {
    let workersToView = state.filters.workers ? state.filters.workers.slice() : [];
    if (workersToView.includes(workerId)) {
      workersToView = workersToView.filter((w) => w !== workerId).concat([]);
    } else {
      workersToView = workersToView.concat(workerId);
    }
    const filters = state.filters;
    let taskFilter = filters.taskFilter;
    // If selecting a driver from unassigned/reported view only
    if (workersToView.length > 0 && [AVAILABLE_FILTERS.UNASSIGNED, AVAILABLE_FILTERS.REPORTED].includes(taskFilter)) {
      taskFilter = AVAILABLE_FILTERS.ASSIGNED;
    }

    return {
      ...state,
      filters: { ...filters, taskFilter, workers: workersToView },
      ...(updateTasks ? { taskData: defaultState.taskData } : {}),
    };
  },
  CLEAR_WORKER_FILTER: (state) => {
    return {
      ...state,
      filters: { ...state.filters, workers: [] },
      taskData: defaultState.taskData,
    };
  },
  CLEAR_MASTER_FILTER: (state) => {
    return {
      ...state,
      filters: { ...state.filters, workers: [] },
      searchTasks: defaultState.searchTasks,
      taskData: defaultState.taskData,
    };
  },
  FETCH_SETTINGS_SUCCEEDED: (state, { data: json }) => {
    const settings = json.settings;
    return {
      ...state,
      settings,
      assetTypes: json.assetTypes,
      defaultAssetTypes: json.assetTypes,
    };
  },
  FETCH_DISPATCHER_INFO: (state, { data }) => {
    return { ...state, dispatcherInfo: data };
  },
  FETCH_HUBS_STARTED: (state) => {
    return { ...state, hubs: { ...state.hubs, inProgress: true } };
  },
  FETCH_HUBS_SUCCEEDED: (state, { data: hubsData }) => {
    return { ...state, hubs: { data: hubsData.data && hubsData.data.filter((h) => h.location), inProgress: false } };
  },
  FETCH_REGIONS_SUCCEEDED: (state, { data: regionsData }) => {
    return { ...state, regionsData: regionsData.data };
  },
  FETCH_PLANNER_WORKERS_SUCCEEDED: (state, { data: workerData }) => {
    return {
      ...state,
      workerData: {
        ...state.workerData,
        data: workerData.data.map((w) => {
          w.profile = 'van';
          return w;
        }),
      },
      inProgress: {
        ...state.inProgress,
        getWorkers: false,
      },
    };
  },
  FETCH_WORKER_SUCCEEDED: (state, { data }) => {
    return {
      ...state,
      workerData: {
        ...state.workerData,
        data: [data].map((w) => {
          w.profile = 'van';
          return w;
        }),
      },
      inProgress: {
        ...state.inProgress,
        getWorkers: false,
      },
    };
  },
  SEARCH_WORKERS_SUCCEEDED: (state, { data }) => {
    return {
      ...state,
      workersFromSearch: data,
      inProgress: {
        ...state.inProgress,
        searchWorkers: false,
      },
    };
  },
  FETCH_VEHICLES_SUCCEEDED: (state, { data, pagination }) => {
    return {
      ...state,
      vehiclesData: {
        ...state.vehiclesData,
        list: !pagination || pagination['current_page'] === 1 ? data : [...state.vehiclesData.list, ...data],
        pagination,
      },
      inProgress: {
        ...state.inProgress,
        getVehicles: false,
      },
    };
  },
  FETCH_VEHICLE_SCHEDULES_SUCCEEDED: (state, { data, schedulesOverlap }) => {
    return {
      ...state,
      assignVehicleToDriver: {
        ...state.assignVehicleToDriver,
        vehicle: {
          ...state.assignVehicleToDriver.vehicle,
          schedules: data,
          schedulesOverlap,
        },
      },
      inProgress: {
        ...state.inProgress,
        getVehicleSchedules: false,
      },
    };
  },
  UPDATE_VEHICLES_SEARCH_TERM: (state, { searchTerm }) => {
    return {
      ...state,
      vehiclesData: {
        ...state.vehiclesData,
        searchTerm,
        pagination: defaultState.vehiclesData.pagination,
      },
    };
  },
  TOGGLE_SHOW_VEHICLE_FILTERS: (state) => {
    return {
      ...state,
      vehiclesData: {
        ...state.vehiclesData,
        filters: {
          ...state.vehiclesData.filters,
          show: !state.vehiclesData.filters.show,
        },
      },
    };
  },
  UPDATE_VEHICLE_SORT_FILTER: (state, { filter }) => {
    return {
      ...state,
      vehiclesData: {
        ...state.vehiclesData,
        filters: {
          ...state.vehiclesData.filters,
          sortBy: filter,
        },
      },
    };
  },
  SEARCH_VEHICLES_SUCCEEDED: (state, { data }) => {
    return {
      ...state,
      vehiclesSearch: {
        ...state.vehiclesSearch,
        data,
      },
      inProgress: {
        ...state.inProgress,
        searchVehicles: false,
      },
    };
  },
  FETCH_TASKS_COMPLETE: (state) => {
    return { ...state, taskData: { ...state.taskData, loadingState: 2, isFetchingTasks: false } };
  },
  SET_TASK_COUNT_WITH_TYPE: (state, { count, countType }) => {
    const { taskData } = state;
    return { ...state, taskData: { ...taskData, count, countType } };
  },
  FETCH_TASKS_SUCCEEDED: (state, { tasks }) => {
    const { taskData } = state;
    const { count, countType } = tasks;
    const { data, ids } = normalizeTaskResponse(tasks);
    return { ...state, taskData: { ...taskData, data: data, ids: ids, count, countType } };
  },
  LOAD_MORE_TASK: (state) => {
    const { taskData } = state;
    return { ...state, taskData: { ...taskData, loadingState: 1 } };
  },
  FETCH_TASKS_START: (state) => {
    const { taskData } = state;
    return { ...state, taskData: { ...taskData, isFetchingTasks: true } };
  },
  SAVE_COPIED_ITEM_IDS: (state, { ids }) => {
    return {
      ...state,
      taskData: {
        ...state.taskData,
        copiedItemIds: ids,
      },
    };
  },
  REMOVE_COPIED_ITEM_ID: (state, { id }) => {
    return {
      ...state,
      taskData: {
        ...state.taskData,
        copiedItemIds: state.taskData.copiedItemIds.filter((i) => i !== id),
      },
    };
  },
  LOAD_MORE_TASK_SUCCESS: (state, { tasks }) => {
    const { taskData } = state;
    const { data, ids } = normalizeTaskResponse(tasks);
    const currentData = taskData.data || {};
    const newData = Object.assign(currentData, data);
    const currentIds = taskData.ids;
    return {
      ...state,
      taskData: { ...taskData, data: newData, ids: Array.from(new Set(currentIds.concat(ids))), loadingState: 2 },
    };
  },
  CHECK_FOR_MORE_TASKS: (state, { tasks }) => {
    const { taskData } = state;
    const { ids } = normalizeTaskResponse(tasks);

    return {
      ...state,
      taskData: { ...taskData, moreTasks: ids.length > 0 },
    };
  },
  CHANGE_SELECTION_MODE: (state, { selectionMode }) => {
    const { selectedTasks, selectedWorkers } = state;
    const newState = { ...state, selectionMode };
    if (selectedTasks.length > 0) {
      newState.selectedTasks = [];
      newState.selectedItemsByStepGroup = {};
      newState.selectedAssignedTask = false;
    }
    if (selectedWorkers.length > 0) {
      newState.selectedWorkers = [];
    }
    return newState;
  },
  TOGGLE_SELECTION: (state, { hideSelection }) => {
    const { selectionMode, selectedWorkers, actionMode } = state;
    const newActionMode = !selectionMode ? actionMode : 'quick';
    const newState = {
      ...state,
      selectionMode: hideSelection ? !hideSelection : !selectionMode,
      actionMode: newActionMode,
    };
    if (selectedWorkers.length > 0) {
      newState.selectedWorkers = [];
    }
    return newState;
  },
  HIDE_SELECTION_ON_PAGE_CHANGE: (state) => {
    const { selectedTasks, selectedWorkers } = state;
    if (selectedWorkers.length === 0 && selectedTasks.length === 0) {
      state.selectionMode = false;
    }
    return state;
  },
  TOGGLE_ACTION_MODE: (state, { mode }) => {
    if (mode) {
      return { ...state, actionMode: mode };
    } else {
      return state;
    }
  },
  SET_SELECT_STOP: (state, { newState }) => {
    return { ...state, ...newState };
  },
  SELECT_TASK_BY_MAP_SELECTION: (state, { tasks }) => {
    const selectedTasks = [];
    tasks.forEach((t) => {
      if (t?.task?.state !== 'completed') {
        selectedTasks.push(t?.id);
      }
    });
    return { ...state, selectionMode: true, selectedTasks };
  },
  SET_SELECT_TASKS: (
    state,
    { selectionMode, selectedTasks, selectedWorkers, selectedAssignedTask, selectedItemsByStepGroup }
  ) => {
    return { ...state, selectionMode, selectedTasks, selectedWorkers, selectedAssignedTask, selectedItemsByStepGroup };
  },
  DESELECT_ALL_STOPS: (state) => {
    return deselectAllStop(state);
  },
  SELECT_ALL_STOPS: (state) => {
    return selectAllStop(state);
  },
  UPDATE_SELECTED_WORKER: (state, { selectedWorkers }) => {
    return {
      ...state,
      selectedWorkers,
    };
  },
  SELECT_BOUNDS: (state, { bounds }) => {
    // google bounds object has contains method
    const { workerData } = state;
    const { ids, data } = state.taskData;
    const workers = workerData.data;
    const selectedWorkers = workers
      .filter((w) => {
        return bounds.contains(w.location);
      })
      .map((w) => w.id);
    const selectedTasks = ids.filter((id) => bounds.contains(data[id].location));
    return { ...state, selectedWorkers, selectedTasks };
  },
  REGION_SELECTED: (state, { regionId }) => {
    // TODO: Need to be improved
    const { regionsData } = state;
    const selectedRegionNames = regionsData.filter((r) => regionId.includes(r.id)).map((r) => r.tag);
    return {
      ...state,
      filters: { ...state.filters, selectedRegions: regionId, selectedRegionNames: selectedRegionNames },
      taskData: { ...state.taskData, data: [], loadingState: 0 },
    };
  },
  CLEAR_ZONE_FILTER_SUCCESSFUL: (state) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        selectedRegions: [],
        selectedRegionNames: [],
      },
      selectedZones: [],
      taskData: defaultState.taskData,
    };
  },
  UPDATE_SELECTED_HUBS: (state, { selectedHubs }) => {
    return {
      ...state,
      selectedHubs: selectedHubs,
      filters: { ...state.filters, selectedHubs: selectedHubs.map((h) => h.id) },
    };
  },
  CLEAR_HUB_FILTER_SUCCESSFUL: (state) => {
    return {
      ...state,
      selectedHubs: [],
      filters: {
        ...state.filters,
        selectedHubs: [],
      },
    };
  },
  UPDATE_SELECTED_ZONES: (state, { selectedZones }) => {
    return {
      ...state,
      selectedZones: selectedZones,
    };
  },
  DROP_OFF_COMPLETED: (state, { workerId }) => {
    return {
      ...state,
      trackingMapInfo: {
        dropOffCompleted: true,
        workerId: workerId,
      },
    };
  },
  UPDATE_SEARCH_PARAM: (state, data) => {
    const { order_item_ids } = data;
    return {
      ...state,
      searchTasks: {
        ...state.searchTasks,
        order_item_ids,
      },
    };
  },
  UPDATE_SEARCH_STATE_BY_QUERY_STRING_URL: (state, { searchState }) => {
    return {
      ...state,
      searchTasks: searchState,
    };
  },
  CLEAR_SEARCH_TEXT: (state) => {
    return {
      ...state,
      searchTasks: {
        ...state.searchTasks,
        searchText: '',
        order_item_ids: [],
      },
    };
  },

  UPDATE_SEARCH_PARAM_BY_QUERY_STRING_URL: (state, data) => {
    const { order_item_ids } = data;
    return {
      ...state,
      searchTasks: {
        ...state.searchTasks,
        order_item_ids: [].concat(order_item_ids),
      },
    };
  },
  UPDATE_SEARCH_TEXT: (state, data) => {
    const { searchText } = data;
    return {
      ...state,
      searchTasks: {
        ...state.searchTasks,
        searchText,
      },
      taskData: defaultState.taskData,
    };
  },
  UPDATE_OPTIMIZABLE: (state, { optimizable }) => {
    return {
      ...state,
      optimizable,
    };
  },
  FETCH_VEHICLE_TYPES_SUCCEEDED: (state, { vehicleTypes }) => {
    return { ...state, vehicleTypes };
  },
  TOGGLE_ASSIGN_VEHICLE_TO_DRIVER_DIALOG: (state, { payload: { driver = null, vehicle = null, type = null } }) => {
    return {
      ...state,
      assignVehicleToDriver: {
        ...state.assignVehicleToDriver,
        showConfirmationDialog: !state.assignVehicleToDriver.showConfirmationDialog,
        driver,
        vehicle,
        type,
      },
    };
  },
  CLEAR_ASSIGN_VEHICLE_TO_DRIVER_MESSAGE: (state) => {
    return {
      ...state,
      assignVehicleToDriver: {
        ...state.assignVehicleToDriver,
        message: undefined,
        status: undefined,
      },
    };
  },
  ASSIGN_VEHICLE_TO_DRIVER_SUCCEEDED: (state) => {
    return {
      ...state,
      assignVehicleToDriver: {
        ...state.assignVehicleToDriver,
        message: t('Vehicle assignment updated'),
        status: 'success',
      },
      inProgress: {
        ...state.inProgress,
        assignVehicleToDriver: false,
      },
      vehiclesSearch: {
        ...state.vehiclesSearch,
        data: [],
      },
      workersFromSearch: [],
    };
  },
  ASSIGN_VEHICLE_TO_DRIVER_FAILED: (state, { message }) => {
    return {
      ...state,
      assignVehicleToDriver: {
        ...state.assignVehicleToDriver,
        message: message || "Vehicle's assignment was failed.",
        status: 'error',
      },
      inProgress: {
        ...state.inProgress,
        assignVehicleToDriver: false,
      },
    };
  },
  TOGGLE_SHOW_WORKER_FILTER: (state) => {
    return {
      ...state,
      workerData: {
        ...state.workerData,
        filters: {
          ...state.workerData.filters,
          showSort: false,
          showFilter: !state.workerData?.filters?.showFilter,
        },
      },
    };
  },
  TOGGLE_SHOW_WORKER_SORT: (state) => {
    return {
      ...state,
      workerData: {
        ...state.workerData,
        filters: {
          ...state.workerData.filters,
          showFilter: false,
          showSort: !state.workerData.filters.showSort,
        },
      },
    };
  },
  TOGGLE_CONFIRM_COMPLETE_TASKS: (state) => {
    return {
      ...state,
      isShownConfirmCompleteTasksDialog: !state.isShownConfirmCompleteTasksDialog,
    };
  },
  UPDATE_WORKER_FILTER: (state, { filter }) => {
    return {
      ...state,
      workerData: {
        ...state.workerData,
        filters: {
          ...state.workerData.filters,
          ...filter,
        },
      },
      selectedWorkers: [],
    };
  },
  UPDATE_SEARCH_VEHICLES_FILTER: (state, { filterType }) => {
    return {
      ...state,
      vehiclesSearch: {
        ...state.vehiclesSearch,
        data: [],
        filterType,
      },
    };
  },
  RESET_VEHICLES: (state) => {
    return {
      ...state,
      vehiclesSearch: {
        ...state.vehiclesSearch,
        data: [],
      },
    };
  },
  PLANNER_LOADING: (state, { key }) => {
    return { ...state, inProgress: { ...state.inProgress, [key]: true } };
  },
  PROCESS_CANCEL_TASKS_SUCCESS: (state) => {
    return { ...state, selectedTasks: [] };
  },
  SHOW_MARK_ON: (state, { id }) => {
    return {
      ...state,
      taskData: {
        ...state.taskData,
        markOnId: id,
      },
    };
  },
  SHOW_MARK_OFF: (state) => {
    return {
      ...state,
      taskData: {
        ...state.taskData,
        markOnId: '',
      },
    };
  },
};
const deselectAllStop = (state) => {
  return { ...state, selectedTasks: [], selectedItemsByStepGroup: {}, selectedWorkers: [], selectionMode: false };
};
const selectAllStop = (state) => {
  const { selectedWorkers } = state;
  const { data, ids } = state.taskData;
  const selectedTasks = allAvailableSelectTasks({ data, ids });
  // Select or unselect workers automatically
  const assignedWorkers = findAssignedWorkers({ ids: ids.filter((id) => selectedTasks.includes(id)), data });
  assignedWorkers.forEach((workerId) => {
    if (!selectedWorkers.includes(workerId)) {
      selectedWorkers.push(workerId);
    }
  });
  return {
    ...state,
    selectionMode: true,
    selectedTasks,
    selectedWorkers,
    selectedAssignedTask: assignedWorkers.length > 0,
  };
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export const findAssignedWorkers = ({ ids, data }) => {
  return ids
    .filter((id) => {
      if (data[id].state !== 'completed') {
        // Need to check `failed` state as well?
        if (data[id].task_group && data[id].task_group.worker_id) {
          return true;
        } else if (data[id].worker_id) {
          return true;
        }
      }
      return false;
    })
    .map((id) => {
      if (data[id].task_group && data[id].task_group.worker_id) {
        return data[id].task_group.worker_id;
      } else if (data[id].worker_id) {
        return data[id].worker_id;
      }

      return null;
    }); // Unique list
};

export default reducer;
