import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import React from 'react';

// TODO: change #e8e8e8 to use theme color's constant
const tabsStyles = makeStyles((theme) => ({
  root: (props) => ({
    borderRight: props.orientation === 'vertical' ? `1px solid ${theme.palette.black.B10}` : 'inherit',
    borderBottom: props.orientation !== 'vertical' ? `1px solid ${theme.palette.black.B10}` : 'inherit',
    border: props.noBorder ? 'none !important' : 'inherit',
  }),
  indicator: (props) => ({
    backgroundColor: props.indicatorBackgroundColor ?? theme.palette.primary.main,
    height: '3px',
    width: props.orientation === 'vertical' ? '3px' : 'inherit',
  }),
}));

export const YojeeTabs = (props) => {
  const classes = tabsStyles(props);

  return (
    <Tabs
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      {...props}
    />
  );
};

export const YojeeTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 32,
    marginRight: theme.spacing(4),
    padding: 0,
    fontWeight: 600,
    color: theme.palette?.black?.B32,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
      fontWeight: 600,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&:focus': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const YojeeTabPanel = withStyles(() => ({
  root: {
    maxHeight: '100%',
  },
  hide: {
    display: 'none',
  },
}))((props) => {
  const { children, value, classes, className, index, keepComponentIfNotDisplay = false } = props;

  if (keepComponentIfNotDisplay) {
    const combineClassName = clsx([classes.root, className].concat(value !== index ? [classes.hide] : []));
    return <div className={combineClassName}>{children}</div>;
  }

  return value === index && <div className={clsx([classes.root, className])}>{children}</div>;
});
