import configureMeasurements, {
  length as lengthMeasurement,
  mass as weightMeasurement,
  volume as volumeMeasurement,
} from 'convert-units';

/**
 * Standard units used in elasticsearch, fields which postfix with '_si' are in SI units
 */
export const STANDARD_UNITS = {
  volume: 'm3',
  dimension: 'm',
  weight: 'kg',
};

export const DIMENSION_UNIT_NOTIONS = {
  millimeter: 'mm',
  centimeter: 'cm',
  meter: 'm',
  kilometer: 'km',
  foot: 'ft',
};

export const CUBIC_UNIT_NOTIONS = {
  cubic_meter: 'm3',
  cubic_centimeter: 'cm3',
  cubic_inch: 'in3',
  cubic_foot: 'ft3',
};

export const WEIGHT_UNIT_NOTIONS = {
  gram: 'g',
  kilogram: 'kg',
  pound: 'lb',
};

export const ALL_UNIT_NOTIONS = {
  ...DIMENSION_UNIT_NOTIONS,
  ...CUBIC_UNIT_NOTIONS,
  ...WEIGHT_UNIT_NOTIONS,
};

const allConverter = configureMeasurements({
  mass: weightMeasurement,
  length: lengthMeasurement,
  volume: volumeMeasurement,
});

function defaultGetShortFormOfUnit(unit) {
  return ALL_UNIT_NOTIONS[unit] || unit;
}

export function fromUnitAToUnitB({
  converter = allConverter,
  value,
  unitA,
  unitB,
  getShortFormOfUnit = defaultGetShortFormOfUnit,
}) {
  if (!Number(value)) return null;

  let convertedValue = parseFloat(value);

  try {
    convertedValue = converter(convertedValue).from(getShortFormOfUnit(unitA)).to(getShortFormOfUnit(unitB));
  } catch (e) {
    console.error(e);
  }

  return convertedValue;
}
