import { call, put, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';

export default function* sagas() {
  yield takeLatest('SEARCH_ADDRESS_BOOK', searchAddressBook);
}
const googleAutocompleteService = window.google ? new window.google.maps.places.AutocompleteService() : null;
function* searchAddressBook({ searchKey }) {
  try {
    if (!searchKey) {
      yield put({ type: 'CLEAR_ADDRESS_OPTIONS' });
      return;
    }
    yield put({ type: 'SEARCH_ADDRESS_BOOK_START', searchKey: searchKey });
    const addressBookData = yield call(companyService.getCompanyAddressBookEntries, { query: searchKey });
    const transformedAddressBookData =
      addressBookData && addressBookData.length > 0 ? addressBookData.map(transformAddressToOption) : [];
    const googleResult = yield call(getGooglePredictions, { searchKey });
    const transformedGoogleData = Array.isArray(googleResult)
      ? googleResult.map((result) => transformGooglePredictionToOption(result))
      : [];
    yield put({ type: 'SEARCH_GOOGLE_ADDRESS_SUCCESS', items: transformedGoogleData });
    yield put({ type: 'SEARCH_ADDRESS_BOOK_SUCCESS', items: transformedAddressBookData });
  } catch (error) {
    yield put({ type: 'SEARCH_ADDRESS_BOOK_FAILED', error });
  }
}

const getGooglePredictions = async ({ searchKey }) => {
  if (!googleAutocompleteService) {
    return [];
  }
  const googleResult = await new Promise((resolve) => {
    googleAutocompleteService.getPlacePredictions({ input: searchKey }, (result) => {
      resolve(result);
    });
  });
  if (!googleResult) return [];

  return googleResult;
};

const transformAddressToOption = (item) => {
  return { id: item.id, type: 'address', value: item, name: item.address1, ...item.location };
};

const transformGooglePredictionToOption = (item) => {
  return { id: item.place_id, type: 'google', value: item.description, name: item.description };
};
