import { call, put, select, takeLatest } from 'redux-saga/effects';

import AuthSelectors from '@yojee/auth/store/selectors';

import Api from '../../Api/onboardingApi';

export const getPlannerData = (state) => state.planner;
export const getOptimisationData = (state) => state.optimisation;
export const getTasks = (state) => state.tasks;
export const getTeams = (state) => state.team;

function* createMultipleUsers({ users }) {
  try {
    yield put({ type: 'CREATING_USERS' });
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield call(Api.createMultipleUsers, { users }, { token, slug });
    yield put({ type: 'CREATING_USERS_SUCCESS' });
  } catch (err) {
    const response = err.message;
    yield put({
      type: 'CREATING_USERS_ERROR',
      error: Array.isArray(response) ? response : `Status ${err.statusCode} : ${err.message}`,
    });
  }
}

function* createMultipleDrivers({ drivers }) {
  try {
    yield put({ type: 'CREATING_DRIVERS' });
    const {
      token,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield call(Api.createMultipleDrivers, { users: drivers }, { token, slug });
    yield put({ type: 'CREATING_DRIVERS_SUCCESS' });
  } catch (err) {
    const response = err.message;
    yield put({
      type: 'CREATING_DRIVERS_ERROR',
      error: Array.isArray(response) ? response : `Status ${err.statusCode} : ${err.message}`,
    });
  }
}

function handleCloseOnBoarding() {
  window.history.pushState('', '', window.location.origin + window.location.pathname);
}

export default function* sagas() {
  yield takeLatest('CREATE_MULTILE_USERS', createMultipleUsers);
  yield takeLatest('CREATE_MULTILE_DRIVERS', createMultipleDrivers);

  yield takeLatest('CLOSE_ON_BOARDING_DIALOG', handleCloseOnBoarding);
}
