import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { BaseService } from '@yojee/api/baseService';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';

export class AuthGeodisService extends BaseService {
  get = (url, params, options) => {
    return APIAxiosInstance.get(url, { params, ...this.createAuthRequestConfig(options) });
  };
  post = (url, body, options) => {
    return APIAxiosInstance.post(url, body, this.createAuthRequestConfig(options));
  };
  put = (url, body, options) => {
    return APIAxiosInstance.put(url, body, this.createAuthRequestConfig(options));
  };
  delete = (url, options) => {
    const params = options && options.params;
    return APIAxiosInstance.delete(url, { ...this.createAuthRequestConfig(options), params });
  };

  createAuthRequestConfig = ({ headers }) => {
    if (!headers) {
      headers = {};
    }
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    headers['company_slug'] = getSlugFromUrl();

    return { headers };
  };
}

export const authGeodisService = new AuthGeodisService();
