const defaultState = {
  nodes: { flattenedTree: [], tree: [] },
  normalizeTasks: { ids: [], data: {}, totalCount: 0 },
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  APPLY_TASK_FILTER: () => {
    return defaultState;
  },
  UPDATE_STOP_NODES: (state, { nodes }) => {
    return { ...state, nodes: nodes };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
