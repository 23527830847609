const defaultState = {
  showBanner: false,
  showFeatureDialog: false,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SHOW_BANNER: (state) => {
    return { ...state, showBanner: true };
  },
  HIDE_BANNER: (state) => {
    return { ...state, showBanner: false };
  },
  SHOW_FEATURE_DIALOG: (state) => {
    return { ...state, showFeatureDialog: true };
  },
  HIDE_FEATURE_DIALOG: (state) => {
    return { ...state, showFeatureDialog: false };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
