// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import useNotistack from '@yojee/ui/base/customHooks/useNotistack';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UIUserManagementActions } from '../../../sagas/userManagement/actions';

const UserSnackBar = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector((state) => state.users.successMessage, shallowEqual);
  const errorMessage = useSelector((state) => state.users.errorMessage, shallowEqual);

  useNotistack({ successMessage, errorMessage, onClose: () => dispatch(UIUserManagementActions.clearMessages()) });

  return null;
};

export default UserSnackBar;
