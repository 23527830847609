// Library components
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

// Custom components
import ManagePageContainer from '../../corners/ManagePageContainer';
import Skeleton from '../../furnitures/SkeletonLoading/Skeleton';
import TableSkeleton from '../../furnitures/SkeletonLoading/TableSkeleton';

const useStyles = makeStyles((theme) => ({
  tableSearchField: {
    marginBottom: theme.spacing(5),
  },
}));

const ListManageTemplateSkeleton = ({ tableSkeletonProps = {} }) => {
  const classes = useStyles();

  return (
    <ManagePageContainer>
      <Grid container>
        <Grid item xs={6}>
          <Skeleton variant="rect" width={280} height="40px" animation="wave" />
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Skeleton variant="circle" width={40} height="40px" animation="wave" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12} className={classes.tableSearchField}>
          <Skeleton variant="rect" width={200} height={30} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <TableSkeleton {...tableSkeletonProps} />
        </Grid>
      </Grid>
    </ManagePageContainer>
  );
};

export default ListManageTemplateSkeleton;
