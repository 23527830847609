import { makeStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => theme.palette[props.variant]?.dark,
    padding: '8px',
    color: 'white',
    borderRadius: '5px',
    fontWeight: '700',
  },
  arrow: {
    color: (props) => theme.palette[props.variant]?.dark,
  },
  defaultIcon: {
    width: '20px',
    height: '20px',
  },
}));

/**
 *
 *
 * @param {Omit<import('react').ComponentProps<typeof MuiTooltip>, 'children'> & {children?: import('react').ReactElement | null}} { children, ...restProps }
 * @return {import('react').ReactElement}
 */
const Tooltip = ({ children, ...restProps }) => {
  const classes = useStyles(restProps);

  return (
    <MuiTooltip arrow classes={{ tooltip: classes.root, arrow: classes.arrow }} {...restProps}>
      {children ? children : <InfoOutlinedIcon className={classes.defaultIcon} />}
    </MuiTooltip>
  );
};

Tooltip.defaultProps = {
  variant: 'primary', // color props will override the inner component color
};

Tooltip.propTypes = {
  variant: PropTypes.string,
};

export default Tooltip;
