const defaultState = {
  isLoading: false,
  addressBookItems: [],
  errorMessage: undefined,
  searchKey: '',
  newAddressId: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_ADDRESSES_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.addressData;
    return {
      ...state,
      loading: false,
      addressData: data?.data,
      pagination: data?.pagination,
      filter: sagaData.params,
    };
  },
  CREATE_ADDRESS_SUCCESSFUL: (state, sagaData) => {
    const { addressData } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, createAddress: false },
      newAddressId: addressData && addressData.length > 0 && addressData[0].id,
    };
  },
  CREATE_ADDRESS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createAddress: false },
    };
  },
  CLEAR_NEW_ADDRESS_ID: (state) => {
    return {
      ...state,
      newAddressId: undefined,
    };
  },
};

export const AddressBookInputReducer = {
  addressBookInput: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
