import * as _ from 'lodash-es';
import { isEmpty, sumBy } from 'lodash-es';
import { groupBy } from 'lodash-es/collection';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchServiceTimes, searchTasksByOrderItemIds } from '@yojee/data/fetch-services/tasks';
import { getValue } from '@yojee/helpers/access-helper';
import { getDefaultVolumeUnitSelector } from '@yojee/helpers/CommonSelectors';
import { SOLVER_VOLUME_UNIT, STATUSES } from '@yojee/helpers/constants';
import { filterSelectedTasksGroups } from '@yojee/helpers/LegsHelper';
import { convertVolume } from '@yojee/helpers/TasksHelper';
import VehicleCapacity from '@yojee/helpers/VehicleCapacity';

function* updateSelectedTasksAggregate() {
  const selectedTasks = yield select((state) => state.planner && state.planner.selectedTasks);
  if (selectedTasks.length === 0) {
    yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'selectedTasks', aggregatedData: null });
    return;
  }

  const tasks = yield select((state) => state.planner && state.planner.taskData && state.planner.taskData.data);

  const selectedOrderItemsValues = {};
  selectedTasks.forEach((id) => {
    if (tasks[id]) {
      const orderItemId = tasks[id]['order_item_id'];
      const quantity = tasks[id].task.quantity ? parseInt(tasks[id].task.quantity) : 1;
      const item = tasks[id].item;
      selectedOrderItemsValues[orderItemId] = {
        quantity,
        volume: item.volume_si ? parseFloat(item.volume_si) * quantity : 0,
        weight: item.weight_si ? parseFloat(item.weight_si) * quantity : 0,
      };
    }
  });

  const values = Object.values(selectedOrderItemsValues);
  const aggregatedData = {
    quantity: sumBy(values, 'quantity'),
    weight: sumBy(values, 'weight'),
    volume: sumBy(values, 'volume'),
  };
  yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'selectedTasks', aggregatedData });
}

function* updateLoadedTasksAggregate() {
  let tasks = yield select((state) => state.planner && state.planner.taskData && state.planner.taskData.data);

  // TODO(@linhyojee): pin down the root cause why `tasks` could be `undefined`
  // when there is no tasks (*empty list*) returned by OpenSearch.
  tasks = Object.values(tasks || {});

  if (isEmpty(tasks)) {
    yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'loadedTasks', aggregatedData: null });
    yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'loadedTotal', aggregatedData: null });
    return;
  }

  const selectedOrderItemsValues = {};
  tasks.forEach((task) => {
    const orderItemId = task.order_item_id;
    const quantity = task.task.quantity ? parseInt(task.task.quantity) : 1;
    const item = task.item;
    selectedOrderItemsValues[orderItemId] = {
      volume: item.volume_si ? parseFloat(item.volume_si) * quantity : 0,
      weight: item.weight_si ? parseFloat(item.weight_si) * quantity : 0,
    };
  });

  const values = Object.values(selectedOrderItemsValues);
  const aggregatedData = {
    weight: sumBy(values, 'weight'),
    volume: sumBy(values, 'volume'),
  };
  yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'loadedTotal', aggregatedData });
}

function* updateSelectedWorkersAggregate() {
  const selectedWorkers = yield select((state) => state.planner && state.planner.selectedWorkers);
  if (selectedWorkers.length === 0) {
    yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'selectedWorkers', aggregatedData: null });
    return;
  }

  const selectedWorkersMap = selectedWorkers.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});

  const workers = yield select((state) => getValue(state, 'planner.workerData.data', []));
  const vehicleTypes = yield select((state) => getValue(state, 'planner.vehicleTypes'));

  let totalUnitCapacity = 0;
  let totalWeightCapacity = 0;
  let totalVolumeCapacity = 0;
  let usedUnitCapacity = 0;
  let usedWeightCapacity = 0;
  let usedVolumeCapacity = 0;

  workers.forEach((w) => {
    if (selectedWorkersMap[w.id]) {
      const capacity = VehicleCapacity.getWorkerVehicleCapacity(w, vehicleTypes);
      if (capacity) {
        totalUnitCapacity += capacity.capacityInfo.vCarryUnit || capacity.capacityInfo.wMaxQuantity;
        totalWeightCapacity += capacity.capacityInfo.vMaxCarryWeight || capacity.capacityInfo.wMaxWeight;
        totalVolumeCapacity += capacity.capacityInfo.vMaxLoadSpaceVolume || capacity.capacityInfo.wMaxVolume;
        usedUnitCapacity += capacity.capacityInfo.wMaxQuantity;
        usedWeightCapacity += capacity.capacityInfo.wMaxWeight;
        usedVolumeCapacity += capacity.capacityInfo.wMaxVolume;
      }
    }
  });

  const aggregatedData = {
    quantity:
      totalUnitCapacity > 0 && usedUnitCapacity > 0
        ? Math.round((usedUnitCapacity / totalUnitCapacity) * 10000) / 100
        : 0,
    weight:
      totalWeightCapacity > 0 && usedWeightCapacity > 0
        ? Math.round((usedWeightCapacity / totalWeightCapacity) * 10000) / 100
        : 0,
    volume:
      usedVolumeCapacity > 0 && totalVolumeCapacity > 0
        ? Math.round((usedVolumeCapacity / totalVolumeCapacity) * 10000) / 100
        : 0,
  };

  yield put({ type: 'SET_AGGREGATED_DATA', dataType: 'selectedWorkers', aggregatedData });
}

function* updateOptimisationResultAggregates({ request }) {
  const defaultVolumeUnit = yield select(getDefaultVolumeUnitSelector);
  yield put({ type: 'RESET_AGGREGATED_DATA', dataType: 'allOptimised' });
  yield put({ type: 'RESET_AGGREGATED_DATA', dataType: 'allPlanned' });

  const aggregatedOrderItemIds = {};

  let usedUnitCapacity = 0;
  let usedWeightCapacity = 0;
  let usedVolumeCapacity = 0;

  request.routes?.forEach((r) => {
    r.tour?.forEach((t) => {
      if (t.capacityDemand && !aggregatedOrderItemIds[t?.task?.order_item_id]) {
        aggregatedOrderItemIds[t?.task?.order_item_id] = true;
        if (t.capacityDemand.weight) {
          usedWeightCapacity += Math.abs(t.capacityDemand.weight) * Math.abs(t.capacityDemand.unit);
        }

        if (t.capacityDemand.unit) {
          usedUnitCapacity += Math.abs(t.capacityDemand.unit);
        }

        if (t.capacityDemand.volume) {
          const volume = convertVolume(Math.abs(t.capacityDemand.volume), SOLVER_VOLUME_UNIT, defaultVolumeUnit);
          usedVolumeCapacity += volume * Math.abs(t.capacityDemand.unit);
        }
      }
    });
  });

  yield put({
    type: 'SET_AGGREGATED_DATA',
    dataType: 'allOptimised',
    aggregatedData: {
      weight: usedWeightCapacity,
      quantity: usedUnitCapacity,
      volume: usedVolumeCapacity,
    },
  });

  getValue(request, 'droppedTaskData', []).forEach((t) => {
    if (!aggregatedOrderItemIds[t['order_item_id']]) {
      aggregatedOrderItemIds[t['order_item_id']] = true;
      const quantity = Math.abs(parseInt(t.task.quantity || 1));
      if (t.item.weight) {
        usedWeightCapacity += Math.abs(parseFloat(t.item.weight)) * quantity;
      }

      if (t.item.volume) {
        usedVolumeCapacity += Math.abs(parseFloat(t.item.volume)) * quantity;
      }

      if (quantity) {
        usedUnitCapacity += quantity;
      }
    }
  });

  yield put({
    type: 'SET_AGGREGATED_DATA',
    dataType: 'allPlanned',
    aggregatedData: {
      weight: usedWeightCapacity,
      quantity: usedUnitCapacity,
      volume: usedVolumeCapacity,
    },
  });
}

export function* fetchLegsFromES({ tasks, routePlanningId, calculateServiceTimes }) {
  const tasksArray = Object.values(tasks);
  const orderItemIds = _.uniq(tasksArray.map((t) => t?.['order_item_id']).filter((t) => !!t));
  if (!orderItemIds.length) {
    return;
  }

  if (routePlanningId) {
    yield put({
      type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
      id: routePlanningId,
      requestType: 'tasks',
      data: {
        status: STATUSES.IN_PROGRESS,
      },
    });
  } else {
    yield put({ type: 'LEGS_FETCH_STARTED', orderItemIds });
  }

  let fetchedTasks = [];

  if (orderItemIds.length) {
    const chunks = _.chunk(orderItemIds, 1000);
    let chunkIndex = 0;
    while (chunkIndex < chunks.length) {
      const { data: pageData } = yield searchTasksByOrderItemIds(chunks[chunkIndex], 10000, true);
      const filteredPageData = pageData.filter((t) => t.task && t.task.state === 'created');
      fetchedTasks = fetchedTasks.concat(filteredPageData);
      chunkIndex++;
    }
  }
  let uniqTasks = _.uniqBy(fetchedTasks, 'id');
  if (calculateServiceTimes) {
    const { tasks: tasksWithServiceTimes, serviceTimes } = yield fetchServiceTimes(uniqTasks);
    uniqTasks = tasksWithServiceTimes;
    if (routePlanningId) {
      yield put({
        type: 'UPDATE_SERVICE_TIME_CONDITIONS',
        id: routePlanningId,
        data: {
          data: serviceTimes,
        },
      });
    }
  }

  const groupedTasks = groupBy(uniqTasks, 'order_item_id');
  if (routePlanningId) {
    const { selectedTasks: filteredTasks } = filterSelectedTasksGroups(tasksArray, groupedTasks);
    const currentTasks = yield select((state) => state.routePlanner[routePlanningId].tasks.data);
    yield put({
      type: 'SET_OPTIMISATION_DATA_FETCH_DATA',
      id: routePlanningId,
      requestType: 'tasks',
      data: {
        status: STATUSES.COMPLETED,
        data: { ...currentTasks, ...groupBy(filteredTasks, 'order_item_id') },
      },
    });
  } else {
    yield put({ type: 'LEGS_FETCH_DONE', groupedTasks });
  }

  return uniqTasks;
}

export default function* sagas() {
  yield takeLatest('UPDATE_SELECTED_TASKS_AGGREGATE', updateSelectedTasksAggregate);
  yield takeLatest('UPDATE_LOADED_TASKS_AGGREGATE', updateLoadedTasksAggregate);
  yield takeLatest('UPDATE_SELECTED_WORKERS_AGGREGATE', updateSelectedWorkersAggregate);
  yield takeLatest('UPDATE_OPTIMISATION_RESULT_AGGREGATES', updateOptimisationResultAggregates);
  yield takeEvery('START_LEGS_FETCH_FROM_ES', fetchLegsFromES);
}
