import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class RegionsService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  fetchRegions = () => {
    return this.authService.get(this.getUmbrellaApiUrl('dispatcher/network/regions'));
  };
}

export const regionService = new RegionsService({ authService });
