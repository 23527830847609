export const DEFAULT_LEGEND_COLORS = [
  '#FFC265',
  '#2196f3',
  '#f44336',
  '#4caf50',
  '#3f51b5',
  '#9c27b0',
  '#009688',
  '#e91e63',
  '#ff5722',
  '#673ab7',
  '#75264e',
  '#779627',
  '#e233c3',
  '#295562',
  '#39c0df',
  '#9cc615',
  '#cece2e',
  '#f1b623',
  '#6e13f1',
  '#2d7513',
  '#f9703a',
  '#13e571',
  '#9578f1',
  '#3ad31d',
  '#674a33',
  '#62ff77',
  '#acea93',
  '#538e8a',
  '#37d3fd',
  '#141206',
  '#283084',
  '#321c3c',
  '#fc86dd',
  '#b3b0b5',
  '#2f8df8',
  '#ac4473',
  '#705d54',
  '#32745c',
  '#394747',
  '#f6fd1d',
  '#480797',
  '#c393ee',
  '#33025a',
  '#97de3b',
  '#a0076c',
  '#b95302',
  '#a5b286',
  '#bf421b',
  '#17cf75',
  '#b34fd8',
  '#3f6e23',
  '#350bb9',
  '#244295',
  '#35cc51',
  '#70a1a3',
  '#fa708e',
  '#5e3f6d',
  '#c1258e',
  '#6432ac',
  '#3948ec',
  '#1810a1',
  '#d17a82',
  '#c17ea5',
  '#da6396',
  '#fc9877',
  '#da26ee',
  '#0d3a17',
  '#fac50c',
  '#5a5c8e',
  '#056c69',
  '#078ccd',
  '#8bb38d',
  '#3173cd',
  '#73099e',
  '#8bcfa1',
  '#e116d1',
  '#47d8a3',
  '#6bfdb4',
  '#2d5ebd',
  '#24dd22',
  '#2d8629',
  '#838482',
  '#858a81',
  '#b4e646',
  '#5d8373',
  '#0a776e',
  '#ca75c8',
  '#f90024',
  '#0c5306',
  '#d19a5d',
  '#a76106',
  '#bb176b',
  '#d8d41e',
  '#bfd024',
  '#58ea0c',
  '#73671c',
  '#c08744',
  '#fe304e',
  '#5744c4',
  '#5a260a',
  '#f46673',
  '#0b860f',
  '#715669',
  '#9f5f82',
  '#43ab4a',
  '#86b7f2',
  '#9e8a55',
  '#c0274a',
  '#3216fd',
  '#60a32e',
  '#49cfd8',
  '#b242eb',
  '#1d62f2',
  '#8323e6',
  '#f890fe',
  '#0c9fac',
  '#849cd9',
  '#49116f',
  '#4d6852',
  '#1dedff',
  '#7df321',
  '#17e87b',
  '#09398c',
  '#dfb08c',
  '#bdf946',
  '#7fab8b',
  '#85453f',
  '#d66445',
  '#7f2d7d',
  '#f1c116',
  '#4db51b',
  '#db25d4',
  '#e6472b',
  '#90706f',
  '#bc4164',
  '#6f95a7',
  '#fb9887',
  '#7a73b5',
  '#626bd6',
  '#16049f',
  '#6e01e6',
  '#1de107',
  '#0d8906',
  '#727826',
  '#4a0a28',
  '#33ec07',
  '#e29095',
  '#01c384',
  '#0e262a',
  '#4df9ed',
  '#f228ca',
  '#653981',
  '#9437b6',
  '#2487fc',
  '#2aba46',
  '#93f554',
  '#937cd8',
  '#96fa74',
  '#b21e8a',
  '#8968be',
  '#0bce45',
  '#ff2869',
  '#5753bb',
  '#a64cd5',
  '#cb3ef1',
  '#04018d',
  '#f7a6fc',
  '#34d513',
  '#0e45dd',
  '#1e0cf9',
  '#4f7fda',
  '#6683a5',
  '#bdb131',
  '#2326d8',
  '#c7ec2a',
  '#36dfd6',
  '#20c8c9',
  '#306eea',
  '#02ce18',
  '#1faedc',
  '#2c5704',
  '#93a31b',
  '#70dd74',
  '#000cd8',
  '#929983',
  '#80e165',
  '#18d874',
  '#f74b34',
  '#295d1b',
  '#27a4f1',
  '#ffe99d',
  '#727e7d',
  '#bb5e8b',
  '#1b6f46',
  '#1e9a87',
  '#8fd35f',
  '#71a0fd',
  '#17d65e',
  '#ab32d1',
  '#fb1396',
  '#717ad8',
  '#b8f1ae',
  '#7e0ba3',
  '#a83d52',
  '#876ae5',
  '#aa1edc',
  '#8d23f6',
  '#2cf75a',
  '#90b1cd',
  '#2e2ff3',
  '#46d38b',
  '#cd56a2',
  '#d31a4c',
  '#12eefe',
  '#136d98',
  '#85b0cc',
  '#1364ea',
  '#1ce234',
  '#6e395e',
  '#229c63',
  '#982dc4',
  '#39e392',
  '#c45ea6',
  '#724925',
  '#f0468e',
  '#f15c36',
  '#7a5412',
  '#6ea263',
  '#c154e4',
  '#f2543b',
  '#92b2d7',
  '#686675',
  '#64d753',
  '#b2775b',
  '#2492ac',
  '#4ac57e',
  '#8027da',
  '#093d0d',
  '#68207d',
  '#70d855',
  '#1d1df2',
  '#30f22c',
  '#b9e433',
  '#cb3e65',
  '#5f8f8c',
  '#0f8b0f',
  '#59517a',
  '#0a8f42',
  '#46dd04',
  '#d60152',
  '#65342d',
  '#7b2fb6',
  '#4aaf92',
  '#63592a',
  '#7f511e',
  '#91e32f',
  '#5a840c',
  '#233442',
  '#0325fb',
  '#6e9a37',
  '#64e62d',
  '#f598eb',
  '#8cee77',
  '#6e2ebc',
  '#d7ee71',
  '#c90a25',
  '#d0884d',
  '#875c7a',
  '#766d09',
  '#27ae72',
  '#dbb0e7',
  '#fc98ef',
  '#243deb',
  '#6d798f',
  '#89d1c9',
  '#1923c5',
  '#d757b6',
  '#41b839',
  '#c1919f',
  '#5be783',
  '#ca128e',
  '#78c4b6',
  '#640f4f',
  '#47f90e',
  '#1c3461',
  '#6428e2',
  '#94bc1b',
];

// https://github.com/zenozeng/color-hash Can be used as alternative for this function
export const generateColor = (id) => {
  return DEFAULT_LEGEND_COLORS[id % DEFAULT_LEGEND_COLORS.length];
};
