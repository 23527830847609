import { call, put, takeLatest } from 'redux-saga/effects';

import { paymentService } from '@yojee/api/paymentService';

import { PAYMENT_ACTION_TYPES as TYPES } from './actions';

export default function* saga() {
  yield takeLatest(TYPES.FETCH_STRIPE_KEY, _fetchStripeKey);
  yield takeLatest(TYPES.CREATE_STRIPE_SUBSCRIPTION, _createStripeSubscription);
  yield takeLatest(TYPES.FETCH_PLAN_INFO, _fetchPlanInfo);
  yield takeLatest(TYPES.UPDATE_CREDIT_CARD, _updateCreditCard);
}

function* _fetchStripeKey() {
  const data = yield call(paymentService.getStripeKey);
  yield put({ type: TYPES.SET_STRIPE_KEY, stripeKey: data.stripe_key });
}

function* _fetchPlanInfo() {
  try {
    const data = yield call(paymentService.getPlanInfo);
    yield put({
      type: TYPES.FETCH_PLAN_INFO_RECEIVED,
      data: data.data.plans.find((plan) => plan.provider_plan_name === 'blitz'),
    });
  } catch (error) {
    yield put({ type: TYPES.FETCH_PLAN_INFO_ERROR, error });
  }
}

function* _createStripeSubscription({ payload: { customerToken, planUuid, providerType } }) {
  try {
    yield put({
      type: TYPES.SET_PAYMENT_LOADING,
      key: 'createStripeSubscription',
    });
    const data = yield call(paymentService.createStripeSubscription, { customerToken, planUuid, providerType });
    yield put({ type: TYPES.CREATE_STRIPE_SUBSCRIPTION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPES.CREATE_STRIPE_SUBSCRIPTION_FAILED, message: error });
  }
}

function* _updateCreditCard({ payload: { cardToken, providerType } }) {
  try {
    yield put({
      type: TYPES.SET_PAYMENT_LOADING,
      key: 'updateCreditCard',
    });

    const data = yield call(paymentService.updateCreditCard, { cardToken, providerType });
    yield put({ type: TYPES.UPDATE_CREDIT_CARD_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPES.UPDATE_CREDIT_CARD_FAILED, message: error });
  }
}
