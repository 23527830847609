import actionTypes from '../../saga/invoices/actionTypes';

export const defaultState = {
  invoices: {},
  loading: {
    areInvoicesGenerating: false,
    areInvoicesRedoing: false,
  },
  successfulMessage: undefined,
  errorMessage: undefined,
};

const ACTION_HANDLERS = {
  [actionTypes.updateState()]: (state, { newState }) => ({
    ...state,
    ...newState,
  }),
  [actionTypes.generateInvoices().loading()]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      areInvoicesGenerating: true,
    },
  }),
  [actionTypes.generateInvoices().success()]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      areInvoicesGenerating: false,
    },
    successfulMessage: 'Created Invoices Successfully',
  }),
  [actionTypes.generateInvoices().failed()]: (state, { error }) => {
    const { message } = error;
    return {
      ...state,
      loading: {
        ...state.loading,
        areInvoicesGenerating: false,
      },
      errorMessage: message || 'Created Invoices Failed',
    };
  },
  [actionTypes.redoInvoices().loading()]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      areInvoicesRedoing: true,
    },
  }),
  [actionTypes.redoInvoices().success()]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      areInvoicesRedoing: false,
    },
    successfulMessage: 'Redo Invoices Successfully',
  }),
  [actionTypes.redoInvoices().failed()]: (state, { error }) => {
    const { message } = error;
    return {
      ...state,
      loading: {
        ...state.loading,
        areInvoicesRedoing: false,
      },
      errorMessage: message || 'Redo Invoices Failed',
    };
  },
  [actionTypes.clearMessage()]: (state) => ({
    ...state,
    successfulMessage: undefined,
    errorMessage: undefined,
  }),
};

export const InvoicesReducer = {
  invoices: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    const newState = handler ? handler(state, action) : state;
    return newState;
  },
};
